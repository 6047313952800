import { CSSProperties, useId } from 'react';
import { ToggleButton } from 'react-aria-components';

import IconToggle from '@/icons/Toggle.svg';
import { createBEMClasses } from '@/utils/classname';

import './Toggle.css';

export type ToggleProps = {
  isSelected?: boolean;
  onChange?: (isSelected: boolean) => void;
  isDisabled?: boolean;
  label?: string;
  ariaLabel?: string;
  size?: 'small' | 'large';
  variant?: 'default' | 'brand';
  alignment?: 'left' | 'right';

  className?: string;
  style?: CSSProperties;
};

const { block } = createBEMClasses('toggle');

const Toggle = ({
  isSelected = false,
  onChange,
  isDisabled,
  label,
  ariaLabel,
  size = 'large',
  variant = 'default',
  alignment = 'right',
  className,
  style,
}: ToggleProps) => {
  const id = useId();

  return (
    <div
      className={block(
        { size, variant, alignment, selected: isSelected, disabled: isDisabled },
        className
      )}
      style={style}
    >
      <ToggleButton
        id={id}
        aria-label={ariaLabel}
        isSelected={isSelected}
        isDisabled={isDisabled}
        onChange={onChange}
      >
        <IconToggle on={isSelected} />
      </ToggleButton>
      {label && (
        <label htmlFor={id} className="paragraph-100-regular">
          {label}
        </label>
      )}
    </div>
  );
};

export default Toggle;
