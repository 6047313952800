import { type NotificationCategory, type UserNotificationSettings } from '@/models/user';

import { type SelectionState } from './getEmailSelectionStateByCategory';

/**
 * Update user notification settings depending on the value selected by the user
 *
 */
export const setNewEmailNotificationSettings = (
  selectionState: SelectionState,
  notificationCategoryToUpdate: NotificationCategory | 'all_emails',
  userNotificationSettings: UserNotificationSettings
): UserNotificationSettings => {
  if (notificationCategoryToUpdate === 'all_emails') {
    // Set all notification categories to true or false
    for (const [notificationCategory, channels] of Object.entries(userNotificationSettings)) {
      const newNotificationCategoryChannelsForCategory = getNewChannelsForNotificationCategory(
        selectionState,
        channels
      );

      userNotificationSettings[notificationCategory as NotificationCategory] =
        newNotificationCategoryChannelsForCategory;
    }

    return userNotificationSettings;
  }

  const newNotificationCategoryChannelsForCategory = getNewChannelsForNotificationCategory(
    selectionState,
    userNotificationSettings[notificationCategoryToUpdate]
  );

  return {
    ...userNotificationSettings,
    [notificationCategoryToUpdate]: newNotificationCategoryChannelsForCategory,
  };
};

const getNewChannelsForNotificationCategory = (
  selectionState: SelectionState,
  currentChannels: ('in_app' | 'email')[]
): ('in_app' | 'email')[] => {
  const notificationCategoryNewChannels: ('in_app' | 'email')[] =
    selectionState === 'selected'
      ? ([...new Set([...currentChannels, 'email'])] as ('in_app' | 'email')[]) // Remove duplicate values
      : currentChannels.filter((channel) => channel !== 'email');

  return notificationCategoryNewChannels;
};
