import { useEffect, useRef } from 'react';
import { Dialog, DialogTrigger, Popover } from 'react-aria-components';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import NotificationBadge from '@/design_system/NotificationBadge';
import { SideNavigationMenuItem } from '@/design_system/SideNavigation/SideNavigation';
import IconInbox from '@/icons/Inbox.svg';
import { useInfiniteNotifications } from '@/models/notification';
import { createBEMClasses } from '@/utils/classname';
import { refreshBrowserTabTitle } from '@/utils/refreshBrowserTabTitle';
import useViewPort from '@/utils/useViewport';

import { NotificationList } from './NotificationList/NotificationList';

const { element } = createBEMClasses('navbar');

export const NotificationMenuItem = ({
  isNotificationCenterOpen,
  setIsNotificationCenterOpen,
}: {
  isNotificationCenterOpen: boolean;
  setIsNotificationCenterOpen: (x: boolean) => void;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();
  const ref = useRef<HTMLElement>(null);

  const { data } = useInfiniteNotifications({ filter: 'unreadOnly' });

  const notificationCount = data?.pages[0]?.meta.count || 0;

  // Add the unread notifications count in the browser tab title
  useEffect(() => {
    document
      .querySelector('meta[name="notifications-count"]')
      ?.setAttribute('content', notificationCount?.toString());

    refreshBrowserTabTitle();
  }, [notificationCount]);

  return (
    <DialogTrigger isOpen={isNotificationCenterOpen} onOpenChange={setIsNotificationCenterOpen}>
      <SideNavigationMenuItem
        icon={<IconInbox />}
        iconRight={notificationCount > 0 && <NotificationBadge count={notificationCount} />}
        label={_(msg({ id: 'navbar.inbox.label', message: 'Inbox' }))}
        onPress={() => {}}
        className={element('notification-trigger')}
      />
      <Popover
        className={element('popover', { mobile: isMobile })}
        placement="left"
        offset={0}
        containerPadding={0}
        isNonModal={!isMobile}
        shouldCloseOnInteractOutside={() => false}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          onClick={(evt) => {
            if (!ref.current?.contains(evt.target as Node)) {
              setIsNotificationCenterOpen(false);
            }
          }}
        >
          <Dialog ref={ref}>
            <NotificationList onClose={() => setIsNotificationCenterOpen(false)} />
          </Dialog>
        </div>
      </Popover>
    </DialogTrigger>
  );
};
