import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';

export const DiscardChangesDialog = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <Dialog
      isOpen
      onOpenChange={onCancel}
      title={<Trans id="dialog.discard-changes.title">Discard changes</Trans>}
      style={{ maxWidth: '25rem' }}
    >
      <main>
        <p className="paragraph-100-regular">
          <Trans id="dialog.discard-changes.content">
            You&apos;re about to leave this page without saving your changes. All unsaved changes
            will be lost.
            <br />
            Are you sure?
          </Trans>
        </p>
      </main>
      <footer>
        <Button variant="secondary" size="small" onPress={onCancel}>
          <Trans id="dialog.discard-changes.cancel">Cancel</Trans>
        </Button>
        <Button variant="danger" size="small" onPress={onConfirm}>
          <Trans id="dialog.discard-changes.confirm">Discard changes</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};
