import { ReactNode } from 'react';

import { StatusDueDate } from '@/components/DueDate';
import Stack from '@/design_system/Stack';
import { ShipmentWithRelations } from '@/models/shipment';

export const ShipmentMessageTitle = ({
  children,
  shipment,
}: {
  children: ReactNode;
  shipment: ShipmentWithRelations;
}) => {
  return (
    <Stack row gap="1rem" alignItems="center" flexWrap="nowrap">
      <div>{children}</div>
      {shipment.statusDueAtDate && (
        <>
          <div
            style={{
              width: '1px',
              height: '16px',
              backgroundColor: 'var(--color-neutral-400)',
            }}
          />
          <StatusDueDate date={shipment.statusDueAtDate} variant="row" />
        </>
      )}
    </Stack>
  );
};
