export const formatDate = (date: Date, options: Intl.DateTimeFormatOptions) =>
  Intl.DateTimeFormat((navigator.languages as string[]) ?? 'en-GB', options).format(date);

export const fromNow = (date: Date, options?: { style?: Intl.RelativeTimeFormatStyle }): string => {
  const secondsDiff = (date.getTime() - Date.now()) / 1000;

  const rtFormat = new Intl.RelativeTimeFormat((navigator.languages as string[]) ?? 'en-GB', {
    style: 'long',
    numeric: 'auto',
    ...options,
  });

  if (Math.abs(secondsDiff) < 45) {
    return rtFormat.format(Math.round(secondsDiff), 'second');
  }

  const minutesDiff = secondsDiff / 60;
  if (Math.abs(minutesDiff) < 45) {
    return rtFormat.format(Math.round(minutesDiff), 'minute');
  }

  const hoursDiff = minutesDiff / 60;
  if (Math.abs(hoursDiff) < 22) {
    return rtFormat.format(Math.round(hoursDiff), 'hour');
  }

  const daysDiff = hoursDiff / 24;
  if (Math.abs(daysDiff) < 26) {
    return rtFormat.format(Math.round(daysDiff), 'day');
  }

  const monthsDiff = daysDiff / 30;
  if (Math.abs(monthsDiff) < 11) {
    return rtFormat.format(Math.round(monthsDiff), 'month');
  }

  const yearsDiff = daysDiff / 365;
  return rtFormat.format(Math.round(yearsDiff), 'year');
};
