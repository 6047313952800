import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconArchive from '@/icons/Archive.svg';

export const ArticleManuallyArchivedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_manually_archived'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="danger"
      icon={<IconArchive />}
      creatorName={activity.creator?.name ?? ''}
      message={
        activity.creator?.name ? (
          <BaseActivityText>
            <Trans id="activities.article_manually_archived.message">archived the item </Trans>
            <BaseActivityArticleName
              activity={activity}
              displayArticleName={displayArticleName}
            />{' '}
          </BaseActivityText>
        ) : (
          <BaseActivityText>
            <Trans id="activities.article_manually_archived.message_no_creator">
              The item{' '}
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />{' '}
              has been archived
            </Trans>
          </BaseActivityText>
        )
      }
    />
  );
};
