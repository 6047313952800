import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import Stack from '@/design_system/Stack';
import IconComment from '@/icons/Comment.svg';
import IconItemDelete from '@/icons/ItemDelete.svg';
import { ArticleWithRelations } from '@/models/request';

const RequalificationComment = ({ article }: { article: ArticleWithRelations }) => {
  if (!article.requalificationComment && !article.requalificationAnalysisComment) {
    return null;
  }

  return (
    <Stack gap="0.5rem">
      {!!article.requalificationComment && (
        <AlertBar
          type="info"
          size="large"
          icon={<IconComment />}
          title={
            <p className="paragraph-100-regular">
              <Trans id="article.requalification-comment">Requalification comment</Trans>
            </p>
          }
        >
          <p className="paragraph-100-regular">&quot;{article.requalificationComment}&quot;</p>
        </AlertBar>
      )}
      {!!article.requalificationAnalysisComment && (
        <AlertBar
          icon={<IconItemDelete />}
          type="error"
          size="large"
          title={
            <p className="paragraph-100-regular">
              <Trans id="article.requalification-comment.after-sales">
                Requalification refusal reason
              </Trans>
            </p>
          }
        >
          <p className="paragraph-100-regular">
            &quot;{article.requalificationAnalysisComment}&quot;
          </p>
        </AlertBar>
      )}
    </Stack>
  );
};

export default RequalificationComment;
