import { msg, Plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconCalendarOverdue from '@/icons/CalendarOverdue.svg';
import IconCalendarReminder from '@/icons/CalendarReminder.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const DueDateAlertingNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'due_date_alerting'>;
}) => {
  const { _ } = useLingui();

  const taskWording = _(TASK_WORDING[notification.data.task]);
  const reference = notification.request?.reference ?? notification.shipment?.reference;

  const label =
    notification.data.dueDelay === 0
      ? _(
          msg({
            id: 'notification.due-date.due-today.label',
            message: `You have ${taskWording} due today on ${reference}`,
          })
        )
      : _(
          msg({
            id: 'notification.due-date.overdue.label',
            message: `You have ${taskWording} overdue on ${reference}`,
          })
        );

  return (
    <BaseNotification
      icon={notification.data.dueDelay === 0 ? <IconCalendarReminder /> : <IconCalendarOverdue />}
      type={notification.data.dueDelay === 0 ? 'warning' : 'danger'}
      notification={notification}
      notificationLabel={label}
      notificationHeader={
        notification.data.dueDelay === 0 ? (
          <Trans id="notification.due-date.due-today">
            You have {taskWording} <span className="paragraph-100-medium">due today</span>.
          </Trans>
        ) : (
          <Trans id="notification.due-date.overdue">
            You have {taskWording}{' '}
            <span className="paragraph-100-medium">
              overdue by <Plural value={notification.data.dueDelay} one="# day" other="# days" />
            </span>
          </Trans>
        )
      }
    />
  );
};

const TASK_WORDING = {
  pending_service_choice: msg({
    id: 'notification.due-date.pending-service-choice',
    message: 'a service choice task',
  }),
  pending_external_payment: msg({
    id: 'notification.due-date.pending-external-payment',
    message: 'a payment task',
  }),
  pending_dispatch_acceptation: msg({
    id: 'notification.due-date.pending-dispatch-acceptation',
    message: 'a job validation task',
  }),
  pending_analysis: msg({
    id: 'notification.due-date.pending-analysis',
    message: 'an analysis task',
  }),
  pending_requalification_analysis: msg({
    id: 'notification.due-date.pending-requalification-analysis',
    message: 'a re-qualification analysis task',
  }),
  pending_repair: msg({
    id: 'notification.due-date.pending-repair',
    message: 'a repair task',
  }),
  pending_shipment_creation: msg({
    id: 'notification.due-date.pending-shipment-creation',
    message: 'a shipment creation task',
  }),
  pending_shipment_preparation: msg({
    id: 'notification.due-date.pending-shipment-preparation',
    message: 'a shipment preparation task',
  }),
  pending_shipment_finalisation: msg({
    id: 'notification.due-date.pending-shipment-finalisation',
    message: 'a shipment finalisation task',
  }),
  pending_shipment_dropoff: msg({
    id: 'notification.due-date.pending-shipment-dropoff',
    message: 'a shipment drop-off task',
  }),
  pending_shipment_verification: msg({
    id: 'notification.due-date.pending-shipment-verification',
    message: 'a shipment verification task',
  }),
} satisfies Record<TNotificationOfType<'due_date_alerting'>['data']['task'], any>;
