import { ReactNode } from 'react';

import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { createBEMClasses } from '@/utils/classname';

import './Card.css';

const { block, element } = createBEMClasses('card');

export const Card = ({
  children,
  className,
  squareTopCorners = false,
}: {
  children: ReactNode;
  className?: string;
  squareTopCorners?: boolean;
}) => {
  return (
    <Stack
      padding="16px"
      gap="1rem"
      className={block({ 'no-top-border-radius': squareTopCorners }, className)}
    >
      {children}
    </Stack>
  );
};

export const CardContent = ({ children }: { children: ReactNode }) => {
  return <div className={element('content')}>{children}</div>;
};

export const CardActions = ({ children }: { children: ReactNode }) => {
  return <div className={element('actions')}>{children}</div>;
};

export const CardItems = ({ children }: { children: ReactNode }) => {
  return (
    <Stack row gap="0.5rem" alignItems="flex-start" className={element('items')}>
      {children}
    </Stack>
  );
};

export const CardItem = ({ children, className }: { children: ReactNode; className?: string }) => (
  <Box
    className={element('item', {}, `paragraph-200-regular text-primary ${className}`)}
    padding="0.25rem 0.5rem"
  >
    <Stack row alignItems="center" gap="0.25rem">
      {children}
    </Stack>
  </Box>
);
