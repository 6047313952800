import { type CreationStepConfig } from '@/api';
import Stack from '@/design_system/Stack';
import { type ArticleWithRelations, type RequestWithRelations } from '@/models/request';
import { useWorkflow } from '@/models/workflow';

import { DefectsTable } from './DefectsTable';
import { ServiceChoice } from './ServiceChoice';

export const ArticleServices = ({
  request,
  article,
  isDisabled = false,
  isEditActionDisabled = false,
  isEditCustomActionWorkshopPriceDisabled = false,
  showServiceChoiceSelector = true,
  showDispatchSection = false,
  showWorkshopSelector = false,
  errors,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  isEditCustomActionWorkshopPriceDisabled?: boolean;
  showServiceChoiceSelector?: boolean;
  showDispatchSection?: boolean;
  showWorkshopSelector?: boolean;
  errors?: {
    actionsError?: string;
    workshopError?: string;
    missingDefectPhotoActionIds?: string[];
  };
}) => {
  const { data: workflow } = useWorkflow(request.workflowId);
  const defectsEnabled = !!workflow?.config.defectsEnabled;

  const defects = ['defect1', 'defect2']; // TODO:
  const isAtCreationStep = article.step?.step === 'creation';
  const requireServiceChoice = !!(article.step?.config as CreationStepConfig['config'] | undefined)
    ?.requireServiceChoice;
  const displayServiceChoice =
    !defectsEnabled ||
    (isAtCreationStep ? requireServiceChoice && !!defects.length : !!defects.length);

  return (
    <Stack gap="2rem">
      {defectsEnabled && <DefectsTable request={request} article={article} />}

      {displayServiceChoice && (
        <ServiceChoice
          request={request}
          article={article}
          isDisabled={isDisabled}
          isEditActionDisabled={isEditActionDisabled}
          isEditCustomActionWorkshopPriceDisabled={isEditCustomActionWorkshopPriceDisabled}
          showServiceChoiceSelector={!!showServiceChoiceSelector}
          showDispatchSection={showDispatchSection}
          showWorkshopSelector={showWorkshopSelector}
          errors={errors}
        />
      )}
    </Stack>
  );
};
