import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconAddArticle from '@/icons/AddArticle.svg';

const CartActions = ({
  onAddArticle,
  onValidateRequest,
}: {
  onAddArticle: () => void;
  onValidateRequest: () => void;
}) => {
  return (
    <Stack gap="1rem" alignItems="stretch">
      <Button size="large" variant="secondary-brand" onPress={onAddArticle}>
        <IconAddArticle />
        <Trans id="client.new.cart.add-article">Add another item</Trans>
      </Button>
      <Button size="large" variant="brand" onPress={onValidateRequest}>
        <Trans id="client.new.card.confirm-estimate-request">Confirm my request</Trans>
      </Button>
    </Stack>
  );
};

export default CartActions;
