import { Link } from 'react-aria-components';
import { Trans } from '@lingui/macro';

import Logo from '@/design_system/Logo';
import Stack from '@/design_system/Stack';
import { createBEMClasses } from '@/utils/classname';

import './PoweredBy.css';

const { block } = createBEMClasses('powered-by');

const PoweredBy = () => (
  <Stack row className={block()} gap="0.5rem">
    <p className="paragraph-200-regular text-secondary">
      <Trans id="client.new.footer.powered-by">Powered by</Trans>
    </p>
    <Link href="https://prolong.io/" target="_blank">
      <Logo />
    </Link>
  </Stack>
);

export default PoweredBy;
