import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import { useCompleteClientPickup } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';

export const CompleteClientPickup = ({
  article,
  onActionDone,
}: {
  article: ArticleWithRelations;
  onActionDone: () => void;
}) => {
  const {
    mutateAsync: completeClientPickup,
    isPending: isPendingCompleteClientPickup,
    isSuccess: isSuccessCompleteClientPickup,
  } = useCompleteClientPickup({
    articleId: article.id,
  });

  const handleCompleteClientPickup = async () => {
    await completeClientPickup();
    onActionDone();
  };

  return (
    <Button
      variant="primary"
      size="medium"
      onPress={handleCompleteClientPickup}
      isLoading={isPendingCompleteClientPickup || isSuccessCompleteClientPickup}
    >
      <Trans id="article.actions.complete_client_pickup.label">Client pickup done</Trans>
    </Button>
  );
};
