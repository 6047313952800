import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import {
  ClientRequestWithRelations,
  RequestWithRelations,
  useUpdateRequestClient,
} from '@/models/request';
import { useClientToken } from '@/services/auth';

import ClientForm, { ClientFormProps, getClientError, useClientState } from './ClientForm';

const ClientFormModal = ({
  isOpen,
  setIsOpen,
  request,
  ...props
}: Omit<ClientFormProps, 'state'> & {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  request: ClientRequestWithRelations | RequestWithRelations;
}) => {
  const { _ } = useLingui();
  const clientToken = useClientToken();

  const clientState = useClientState(request.client, request.vip);
  const [showErrors, setShowErrors] = useState(false);

  const { mutateAsync: updateRequestClient, isPending: isPendingUpdateRequestClient } =
    useUpdateRequestClient();

  const saveClient = async () => {
    setShowErrors(true);

    if (getClientError(clientState).hasError) {
      return;
    }

    await updateRequestClient({
      id: request.id,
      body: {
        client: {
          name: clientState.name,
          email: clientState.email,
          address: clientState.address ?? undefined,
          billingAddress: clientState.billingAddress ?? undefined,
          deliveryOption: clientState.deliveryOption,
          sendcloudServicePointId: clientState.sendcloudServicePointId,
          phone: clientState.phone,
        },
        vip: clientState.vip,
      },
    });

    setIsOpen(false);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      style={{ maxWidth: '37.5rem' }}
      title={
        clientToken
          ? _(msg({ id: 'client.request.my-info.edit.title', message: 'Edit my information' }))
          : _(
              msg({
                id: 'client.request.my-info.edit.title.client',
                message: "Edit client's information",
              })
            )
      }
    >
      <main>
        <ClientForm request={request} state={clientState} {...props} showErrors={showErrors} />
      </main>
      <footer>
        <Button
          variant={props.variant === 'brand' ? 'secondary-brand' : 'secondary'}
          size={props.size}
          onPress={() => setIsOpen(false)}
        >
          <Trans id="client.request.my-info.edit.cancel">Cancel</Trans>
        </Button>
        <Button
          variant={props.variant === 'brand' ? 'brand' : 'primary'}
          size={props.size}
          onPress={saveClient}
          isLoading={isPendingUpdateRequestClient}
        >
          <Trans id="client.request.my-info.edit.save">Save</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};

export default ClientFormModal;
