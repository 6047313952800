import { Trans } from '@lingui/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const AnalyzeArticleTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      title={<Trans id="article.task.analyze-article.title">Analyze the item</Trans>}
      message={
        <Trans id="article.task.analyze-article.message">
          Please analyse the item to determine if the required actions correspond to its needs.
        </Trans>
      }
      article={article}
    />
  );
};
