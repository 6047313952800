import { createContext, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import IconAddToList from '@/icons/AddToList.svg';
import {
  ActionTypeOrganizationWithRelations,
  PackActionTypeOrganizationWithRelations,
  useActionTypes,
} from '@/models/actionType';
import { useCreateAction } from '@/models/article';
import {
  ArticleActionWithRelations,
  ArticleWithRelations,
  RequestWithRelations,
} from '@/models/request';
import { useDatabaseTranslation } from '@/models/translation';
import { useWorkflow } from '@/models/workflow';
import { useWorkshops } from '@/models/workshop';
import { useCurrentSession, useFlags } from '@/services/auth';
import { Currency } from '@/utils/number';
import useDebouncedState from '@/utils/useDebouncedState';

import { ActionDropdownItem, CustomActionDropdownItem } from './ActionDropdownItem';
import { ActionsTable, LegacyActionsTable } from './ActionsTable';
import CustomActionDialog from './CustomActionDialog';
import { PackActionDropdownItem } from './PackActionDropdownItem';

interface ActionTypeSearchSelectContextData {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  showOldActions: boolean;
  actions: ArticleActionWithRelations[];
  oldActions?: ArticleActionWithRelations[];
  handleStartEditCustomAction: (customAction: ArticleActionWithRelations) => void;
  isDisabled: boolean;
  isEditActionDisabled: boolean;
  isEditCustomActionWorkshopPriceDisabled?: boolean;
  mode: 'need' | 'action' | 'both';
  showDefectPhotos?: boolean;
  showWorkshopPrice?: boolean;
  showOrganizationPrice?: boolean;
  showWarrantyCover?: boolean;
  isRequalification?: boolean;
  customActionWorkshopPriceCurrency: Currency;
  customActionOrganizationPriceCurrency: Currency;
}

export const ActionTypeSearchSelectContext = createContext({} as ActionTypeSearchSelectContextData);

const ActionTypeSearchSelect = ({
  article,
  request,
  isDisabled = false,
  isEditActionDisabled = false,
  isEditCustomActionWorkshopPriceDisabled = false,
  missingDefectPhotoActionIds,
  error,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  isEditCustomActionWorkshopPriceDisabled?: boolean;
  missingDefectPhotoActionIds?: string[];
  error?: string;
}) => {
  const { _ } = useLingui();
  const { _db } = useDatabaseTranslation();
  const { currentSession } = useCurrentSession();
  const { flags } = useFlags();
  const { data: workflow } = useWorkflow(request.workflowId);

  const [query, debouncedQuery, setQuery] = useDebouncedState<string>('', 500);

  const [isOpenCustomActionDialog, setIsOpenCustomActionDialog] = useState(false);
  const [customActionToEdit, setCustomActionToEdit] = useState<ArticleActionWithRelations>();
  const [showAddActionInput, setShowAddActionInput] = useState(false);

  const mode =
    article.step?.step === 'creation'
      ? article.step.config.requireCost || article.step.config.requirePrice
        ? 'both'
        : 'need'
      : 'both';

  const showOldActions = article.step?.step === 'analysis' && !!article.requalifiedAt;

  const allowCustomActions =
    (article.step &&
      'allowCustomActions' in article.step.config &&
      article.step.config.allowCustomActions) ??
    false;

  const allowWarranty =
    !!article.step && 'allowWarranty' in article.step.config
      ? article.step.config.allowWarranty
      : (article.steps?.find(
          (step) =>
            step.status === 'done' &&
            !!step.config &&
            'allowWarranty' in step.config &&
            step.config.allowWarranty
        ) ?? false);

  const showDefectPhotos = !workflow?.config.defectsEnabled;

  const showWarrantyCover =
    article.step?.step !== 'creation' && allowWarranty && !currentSession?.workshop?.external;

  const showWorkshopPrice =
    article.step?.step === 'creation' ? article.step.config.requireCost : true;

  const showOrganizationPrice =
    article.step?.step === 'creation'
      ? article.step.config.requirePrice
      : request.requestorType !== 'store' && !currentSession?.workshop;

  const isRequalification = article.task?.type === 'analyze_article';

  const actionTypeQueryMode = mode === 'need' ? 'needName' : 'both';

  const {
    data: { actionTypes, packActionTypes } = { actionTypes: [], packActionTypes: [] },
    isFetching,
  } = useActionTypes(
    {
      requestId: article.requestId,
      query: debouncedQuery || undefined,
      queryMode: debouncedQuery ? actionTypeQueryMode : undefined,
      productL1: article.productL1 ?? undefined,
      productL2: article.productL2 ?? undefined,
      productL3: article.productL3 ?? undefined,
    },
    {
      enabled: !isDisabled,
      keepPreviousData: true,
    }
  );

  const { data: { workshops: [internalWorkshop] } = { workshops: [] } } = useWorkshops(
    {
      internal: true,
      limit: 1,
    },
    {
      enabled: !article.workshopId,
    }
  );

  const customActionWorkshopPriceCurrency =
    article.workshop?.currency ?? internalWorkshop?.currency ?? 'EUR';
  const customActionOrganizationPriceCurrency = request.defaultPriceCurrency ?? 'EUR';

  const actions = article.snapshot.articleActions;
  const oldActions = showOldActions ? article.previousSnapshot?.articleActions : undefined;

  const { mutate: createAction } = useCreateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const handleSelectionChange = (id: string) => {
    if (id) {
      const newAction = actionTypes?.find(
        (actionTypeOrganization) => actionTypeOrganization.id === id
      );
      const newPackAction = packActionTypes?.find(
        (packActionTypeOrganization) => packActionTypeOrganization.id === id
      );

      if (newAction) {
        createAction({
          actionTypeOrganization: newAction,
        });
      } else if (newPackAction) {
        createAction({
          packActionTypeOrganization: newPackAction,
        });
      }

      setQuery('');
    }
  };

  const handleCloseCustomActionDialog = () => {
    setIsOpenCustomActionDialog(false);
    setCustomActionToEdit(undefined);
  };

  const handleStartEditCustomAction = (customAction: ArticleActionWithRelations) => {
    setIsOpenCustomActionDialog(true);
    setCustomActionToEdit(customAction);
  };

  const label = _(
    mode === 'need'
      ? msg({ id: 'article.form.actions.label.defect', message: 'Defects' })
      : msg({ id: 'article.form.actions.label', message: 'Care & repair actions' })
  );

  const customActionDialogTriggerLabel = _(
    msg({ id: 'article.form.custom-action.dialog.trigger.label', message: 'Add a new action' })
  );

  const actionTypeOptions = [...packActionTypes, ...actionTypes];

  // isStickyOption lets know InputSelect that this option should stick to the bottom of the options list
  const customOption = { isStickyOption: true } as const;

  const actionTypeAndCustomOptions: (
    | { isStickyOption: true }
    | PackActionTypeOrganizationWithRelations
    | ActionTypeOrganizationWithRelations
  )[] = allowCustomActions ? [...actionTypeOptions, customOption] : actionTypeOptions;

  const contextValue = {
    article,
    request,
    showOldActions,
    actions,
    oldActions,
    handleStartEditCustomAction,
    isDisabled,
    isEditActionDisabled,
    isEditCustomActionWorkshopPriceDisabled,
    mode,
    showDefectPhotos,
    showWorkshopPrice,
    showOrganizationPrice,
    showWarrantyCover,
    isRequalification,
    customActionWorkshopPriceCurrency,
    customActionOrganizationPriceCurrency,
  } satisfies ActionTypeSearchSelectContextData;

  return (
    <ActionTypeSearchSelectContext.Provider value={contextValue}>
      {flags['enable-defect-action-split'] ? (
        <Stack gap="0.5rem">
          {isDisabled && <p className="label-100 text-primary">{label}</p>}

          {/* TODO: when removing the feature flag enable-defect-action-split, make sure ActionsTable calls ActionTypeSearchSelect and not the opposite. Here, it was easier to do this way because of all the context used in both legacy and new ActionTypeSearchSelect */}
          <ActionsTable missingDefectPhotoActionIds={missingDefectPhotoActionIds}>
            <>
              {!showAddActionInput && (
                <Button
                  size="medium"
                  variant="secondary"
                  onPress={() => setShowAddActionInput(true)}
                  ariaLabel={_(msg({ id: 'article.form.actions.add', message: 'Add an action' }))}
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  style={{ flex: '0 auto' }}
                >
                  <IconAdd />
                  <Trans id="article.form.actions.add">Add an action</Trans>
                </Button>
              )}
              {showAddActionInput && (
                <InputSelect
                  variant="add"
                  aria-label={label}
                  placeholder={_(
                    mode === 'need'
                      ? msg({
                          id: 'article.form.actions.placeholder.defect',
                          message: 'Search a need or defect...',
                        })
                      : msg({
                          id: 'article.form.actions.placeholder',
                          message: 'Search a care or repair action...',
                        })
                  )}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  menuIsOpen
                  value={null} // Allows to automatically clear the input when selecting a value
                  inputValue={query}
                  onInputChange={setQuery}
                  options={actionTypeAndCustomOptions}
                  filterOption={null} // Avoid react-select to filter options only based on their label
                  getOptionValue={(actionType) =>
                    !!actionType && 'id' in actionType ? actionType.id : 'custom'
                  }
                  getOptionLabel={(actionType) => {
                    if (actionType) {
                      if ('isStickyOption' in actionType) {
                        return _(
                          msg({
                            id: 'article.form.actions.add-custom-action',
                            message: 'Add a custom action',
                          })
                        );
                      }

                      const valueToTranslate =
                        'actions' in actionType
                          ? actionType.name
                          : mode === 'need'
                            ? actionType.actionType.needName
                            : actionType.actionType.name;

                      return _db(valueToTranslate);
                    }

                    return '';
                  }}
                  formatOptionLabel={(actionType) => {
                    if (actionType) {
                      if ('isStickyOption' in actionType) {
                        return <CustomActionDropdownItem key="custom" />;
                      }
                      if ('actions' in actionType) {
                        return (
                          <PackActionDropdownItem key={actionType.id} packAction={actionType} />
                        );
                      }
                      return <ActionDropdownItem key={actionType.id} action={actionType} />;
                    }
                  }}
                  onChange={(actionType) => {
                    if (actionType) {
                      if ('isStickyOption' in actionType) {
                        setIsOpenCustomActionDialog(true);
                      } else {
                        handleSelectionChange(actionType.id);
                      }

                      setShowAddActionInput(false);
                    }
                  }}
                  onBlur={() => setShowAddActionInput(false)}
                  isLoading={isFetching || query !== debouncedQuery}
                  error={error}
                  style={{ flex: 1 }}
                />
              )}

              {!isEditActionDisabled && (
                <CustomActionDialog
                  isOpen={isOpenCustomActionDialog}
                  onClose={handleCloseCustomActionDialog}
                  initialCustomAction={customActionToEdit}
                />
              )}
            </>
          </ActionsTable>

          {error && <Message type="error">{error}</Message>}
        </Stack>
      ) : (
        <Stack gap="0.5rem">
          {isDisabled && <p className="label-100 text-primary">{label}</p>}
          {!isDisabled && (
            <Stack row gap="0.5rem">
              <InputSelect
                variant="select"
                label={label}
                placeholder={_(
                  mode === 'need'
                    ? msg({
                        id: 'article.form.actions.placeholder.defect',
                        message: 'Search a need or defect...',
                      })
                    : msg({
                        id: 'article.form.actions.placeholder',
                        message: 'Search a care or repair action...',
                      })
                )}
                value={null} // Allows to automatically clear the input when selecting a value
                inputValue={query}
                onInputChange={setQuery}
                options={actionTypeOptions}
                filterOption={null} // Avoid react-select to filter options only based on their label
                getOptionValue={(actionType) => actionType?.id || ''}
                getOptionLabel={(actionType) => {
                  if (actionType) {
                    const valueToTranslate =
                      'actions' in actionType
                        ? actionType.name
                        : mode === 'need'
                          ? actionType.actionType.needName
                          : actionType.actionType.name;
                    return _db(valueToTranslate);
                  }
                  return '';
                }}
                formatOptionLabel={(actionType) => {
                  if (actionType) {
                    if ('actions' in actionType) {
                      return <PackActionDropdownItem key={actionType.id} packAction={actionType} />;
                    }
                    return <ActionDropdownItem key={actionType.id} action={actionType} />;
                  }
                }}
                onChange={(actionType) => {
                  if (actionType) {
                    handleSelectionChange(actionType.id);
                  }
                }}
                isLoading={isFetching || query !== debouncedQuery}
                error={error}
                style={{ flex: 1 }}
              />
              {allowCustomActions && (
                <Button
                  size="large"
                  variant="secondary"
                  iconOnly
                  ariaLabel={customActionDialogTriggerLabel}
                  tooltip={customActionDialogTriggerLabel}
                  onPress={() => setIsOpenCustomActionDialog(true)}
                  style={{
                    marginTop: 24,
                    borderColor: error ? 'var(--color-danger-700)' : undefined,
                  }}
                >
                  <IconAddToList />
                </Button>
              )}
            </Stack>
          )}
          {!isEditActionDisabled && (
            <CustomActionDialog
              isOpen={isOpenCustomActionDialog}
              onClose={handleCloseCustomActionDialog}
              initialCustomAction={customActionToEdit}
            />
          )}
          {actions.length > 0 && <LegacyActionsTable />}
          {isDisabled && error && <Message type="error">{error}</Message>}
        </Stack>
      )}
    </ActionTypeSearchSelectContext.Provider>
  );
};

export default ActionTypeSearchSelect;
