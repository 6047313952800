import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconTools from '@/icons/Tools.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const PendingRepairNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'pending_repair'>;
}) => {
  const { _ } = useLingui();

  return (
    <BaseNotification
      notificationLabel={_(
        msg({
          id: 'notification.pending-repair.label',
          message: `Pending repair for request ${notification.request.reference}`,
        })
      )}
      icon={<IconTools />}
      type="new"
      notification={notification}
      notificationHeader={
        <Trans id="notification.pending-repair">
          <span className="paragraph-100-medium">You</span> have a new repair to do
        </Trans>
      }
    />
  );
};
