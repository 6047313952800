import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticlesNames,
  BaseActivityDetailedContent,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconRefusal from '@/icons/Refusal.svg';

export const JobRefusedActivity = ({
  activities,
}: {
  activities: ActivityOfType<'job_refused'>[];
}) => {
  const firstActivity = activities[0];

  return (
    <BaseActivity
      createdAt={firstActivity.createdAt}
      type="warning"
      icon={<IconRefusal />}
      creatorName={`${firstActivity.creator?.name ?? ''} (${firstActivity.workshop.name})`}
      message={
        <BaseActivityText>
          <Trans id="activities.job_refused.message">refused the job</Trans>
          {activities.length > 1 && (
            <>
              {' '}
              <Trans id="activities.job_refused.message_multiple_articles">with items:</Trans>{' '}
              <BaseActivityArticlesNames activities={activities} displayArticleName />
            </>
          )}
        </BaseActivityText>
      }
    >
      <BaseActivityDetailedContent>{firstActivity.data.refusalReason}</BaseActivityDetailedContent>
    </BaseActivity>
  );
};
