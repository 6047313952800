import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticlesNames,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconValidation from '@/icons/Validation.svg';

export const JobAutoAcceptedActivity = ({
  activities,
}: {
  activities: ActivityOfType<'job_auto_accepted'>[];
}) => {
  const firstActivity = activities[0];

  return (
    <BaseActivity
      createdAt={firstActivity.createdAt}
      type="primary"
      icon={<IconValidation />}
      creatorName={firstActivity.workshop.name}
      message={
        <BaseActivityText>
          <Trans id="activities.job_auto_accepted.message">auto-accepted the job</Trans>
          {activities.length > 1 && (
            <>
              {' '}
              <Trans id="activities.job_auto_accepted.message_multiple_articles">
                with items:
              </Trans>{' '}
              <BaseActivityArticlesNames activities={activities} displayArticleName />
            </>
          )}
        </BaseActivityText>
      }
    />
  );
};
