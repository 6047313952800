import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ArticleDisplayedStep, ArticleTask } from '@/api';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack/Stack';
import Tabs, { Tab, TabList, TabPanel } from '@/design_system/Tabs/Tabs';
import IconAdd from '@/icons/Add.svg';
import { RequestorType, useCreateDraftRequest } from '@/models/request';
import { useCurrentOrganization, useCurrentSession } from '@/services/auth';
import { useResetSimpleBlocker } from '@/utils/navigation';
import { useDebouncedSearchParamState } from '@/utils/useDebouncedState';
import { useSearchParamState, useSyncSearchParams } from '@/utils/useSearchParams';

import DoneTab from './Tabs/DoneTab';
import OngoingTab from './Tabs/OngoingTab';
import PriorityTab from './Tabs/PriorityTab';

const Requests = () => {
  const { _ } = useLingui();

  // Clear router blocker state after coming from requests/new
  useResetSimpleBlocker();

  const [tab, setTab, syncTab] = useSearchParamState<string>('request', 'tab', 'to-do');
  const [page, setPage, syncPage] = useSearchParamState<number>('request', 'page', 1);
  const [displayedSteps, setDisplayedSteps, syncDisplayedSteps] = useSearchParamState<
    ArticleDisplayedStep[]
  >('request', 'displayedSteps', []);
  const [tasks, setTasks, syncTasks] = useSearchParamState<ArticleTask['type'][]>(
    'request',
    'tasks',
    []
  );
  const [supervisor, setSupervisor, syncSupervisor] = useSearchParamState<string>(
    'request',
    'supervisor',
    ''
  );
  const [collaborators, setCollaborators, syncCollaborators] = useSearchParamState<string[]>(
    'request',
    'collaborators',
    []
  );
  const [requestorTypes, setRequestorTypes, syncRequestorTypes] = useSearchParamState<
    RequestorType[]
  >('request', 'requestorTypes', []);

  const resetPage = useCallback(() => setPage(1), [setPage]);
  const [search, debouncedSearch, debouncedSetSearch, syncSearch] =
    useDebouncedSearchParamState<string>('request', 'search', '', 500, resetPage);

  useSyncSearchParams([
    syncTab,
    syncPage,
    syncSearch,
    syncDisplayedSteps,
    syncTasks,
    syncSupervisor,
    syncCollaborators,
    syncRequestorTypes,
  ]);

  const navigate = useNavigate();

  const {
    mutateAsync: createRequest,
    isPending: isPendingCreateRequest,
    isSuccess: isSuccessCreateRequest,
  } = useCreateDraftRequest();

  const handleCreateRequest = async () => {
    const { id } = await createRequest();
    navigate(`/requests/new/${id}`);
  };

  const createRequestLabel = _(msg({ id: 'requests.new', message: 'New request' }));

  const { currentSession } = useCurrentSession();
  const [currentOrganization] = useCurrentOrganization();

  const canCreateRequest =
    currentSession?.hasPermission('create_client_request', {
      organizationId: currentOrganization?.id,
    }) ||
    currentSession?.hasPermission('create_store_request', {
      organizationId: currentOrganization?.id,
    });

  return (
    <div className="container container--list">
      <Stack gap="24px" style={{ height: '100%' }}>
        <Stack row alignItems="center">
          <h1 className="headline-200-bold" style={{ flex: 1 }}>
            <Trans id="requests.title">Requests</Trans>
          </h1>
          {canCreateRequest && (
            <>
              <Button
                className="is-hidden-mobile"
                size="medium"
                onPress={handleCreateRequest}
                isLoading={isPendingCreateRequest || isSuccessCreateRequest}
                dataTrackingId="requests.new"
              >
                <IconAdd />
                {createRequestLabel}
              </Button>
              <Button
                className="is-hidden-from-tablet"
                size="large"
                iconOnly
                onPress={handleCreateRequest}
                isLoading={isPendingCreateRequest || isSuccessCreateRequest}
                ariaLabel={createRequestLabel}
                tooltip={createRequestLabel}
                dataTrackingId="requests.new"
              >
                <IconAdd />
              </Button>
            </>
          )}
        </Stack>

        <Tabs
          gap="24px"
          selectedKey={tab}
          onSelectionChange={(tab) => {
            setTab(tab);
            setTasks([]);
            setDisplayedSteps([]);
            setCollaborators([]);
            setPage(1);
          }}
          style={{ flex: 1, paddingBottom: 24 }}
        >
          <TabList>
            <Tab id="to-do">
              <Trans id="requests.tabs.to-do">To do</Trans>
            </Tab>
            <Tab id="waiting">
              <Trans id="requests.tabs.waiting">Waiting</Trans>
            </Tab>
            <Tab id="archived">
              <Trans id="requests.tabs.archived">Archived</Trans>
            </Tab>
          </TabList>
          <TabPanel id="to-do" style={{ flex: 1 }}>
            <PriorityTab
              {...{
                search,
                debouncedSearch,
                debouncedSetSearch,
                page,
                setPage,
                tasks,
                setTasks,
                supervisor,
                setSupervisor,
                requestorTypes,
                setRequestorTypes,
              }}
            />
          </TabPanel>
          <TabPanel id="waiting" style={{ flex: 1 }}>
            <OngoingTab
              {...{
                search,
                debouncedSearch,
                debouncedSetSearch,
                page,
                setPage,
                displayedSteps,
                setDisplayedSteps,
                collaborators,
                setCollaborators,
                requestorTypes,
                setRequestorTypes,
              }}
            />
          </TabPanel>
          <TabPanel id="archived" style={{ flex: 1 }}>
            <DoneTab
              {...{
                search,
                debouncedSearch,
                debouncedSetSearch,
                page,
                setPage,
                collaborators,
                setCollaborators,
                requestorTypes,
                setRequestorTypes,
              }}
            />
          </TabPanel>
        </Tabs>
      </Stack>
    </div>
  );
};

export default Requests;
