import { CSSProperties, useEffect, useState } from 'react';

import { createBEMClasses } from '@/utils/classname';

import './Loader.css';

const { block } = createBEMClasses('loader');

const Loader = ({ style, delay = 200 }: { style?: CSSProperties; delay?: number }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  if (!isVisible) {
    return null;
  }

  return <span className={block()} style={style} />;
};

export default Loader;
