import { SendcloudMap } from '@/components/SendcloudMap/SendcloudMap';
import { ClientRequestWithRelations, useUpdateRequestClient } from '@/models/request';

export const PickupPointInfo = ({ request }: { request: ClientRequestWithRelations }) => {
  const { mutateAsync: updateRequestClient } = useUpdateRequestClient();

  if (request.client?.deliveryOption !== 'pickupPoint_mondialRelay') {
    return;
  }

  const hasFirstTransitBackToClientStarted = request.articles.some((article) =>
    article.steps?.some(
      (step) =>
        step.step === 'transit' && step.config.destinationType === 'client' && !!step.enterDate
    )
  );

  return (
    <SendcloudMap
      servicePointId={request.client.sendcloudServicePointId}
      setServicePointId={(sendcloudServicePointId) => {
        updateRequestClient({
          id: request.id,
          body: {
            client: {
              sendcloudServicePointId,
            },
          },
        });
      }}
      carrier="mondial_relay"
      isClientView
      address={request.client.address.line1}
      postalCode={request.client.address.zipCode}
      city={request.client.address.city}
      country={request.client.address.country}
      isEditable={!hasFirstTransitBackToClientStarted}
    />
  );
};
