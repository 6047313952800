import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import Badge from '@/design_system/Badge';
import Tooltip from '@/design_system/Tooltip';
import IconAnalysis from '@/icons/Analysis.svg';
import { Medium } from '@/models/medium';
import { createBEMClasses } from '@/utils/classname';

import PhotoCard from './PhotoCard';
import PhotoDialog from './PhotoDialog';

import './PhotoCardGrid.css';

const { block } = createBEMClasses('photo-card-grid');

export type PhotoCardGridProps = {
  media: Medium[];
  loadingUploads?: { name: string }[];
  onDelete?: (id: string) => void;
  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'brand';
  input?: React.ReactNode;
};

/**
 *
 * Handle the display of uploaded and loading photos under the same format in a grid
 */
const PhotoCardGrid = ({
  media,
  loadingUploads,
  onDelete,
  size = 'large',
  variant = 'default',
  input,
}: PhotoCardGridProps) => {
  const [openIndex, setOpenIndex] = useState<number | undefined>();

  const openMedium = openIndex !== undefined ? media?.[openIndex] : undefined;

  return (
    <div className={block({ size, 'with-input': !!input })}>
      {loadingUploads?.map((loadingFile, index) => (
        <PhotoCard key={loadingFile.name + index} size={size} name={loadingFile.name} loading />
      ))}

      {media.map((medium, index) => (
        <PhotoCard
          size={size}
          key={medium.id}
          name={medium.originalPath}
          alt={medium.originalPath}
          url={medium.url}
          onDelete={onDelete ? () => onDelete(medium.id) : undefined}
          onZoom={() => setOpenIndex(index)}
          variant={variant}
          customIcon={iconForType.find(({ type }) => type === medium.type)?.customIcon}
        />
      ))}

      {openIndex !== undefined && !!openMedium && (
        <PhotoDialog
          url={openMedium.url}
          alt={openMedium.originalPath}
          isOpen={openIndex !== undefined}
          onOpenChange={(isOpen) => {
            if (!isOpen) {
              setOpenIndex(undefined);
            }
          }}
          onPrevious={
            media.length > 1
              ? () => {
                  if (openIndex > 0) {
                    setOpenIndex(openIndex - 1);
                  }
                }
              : undefined
          }
          onNext={
            media.length > 1
              ? () => {
                  if (openIndex < media.length - 1) {
                    setOpenIndex(openIndex + 1);
                  }
                }
              : undefined
          }
          disablePrevious={openIndex === 0}
          disableNext={openIndex === media.length - 1}
          variant={variant}
          customIcon={iconForType.find(({ type }) => type === openMedium.type)?.customIcon}
        />
      )}

      {input}
    </div>
  );
};

const RequalificationDefectPhotoIcon = () => (
  <Tooltip content={<Trans id="requalification.photo.tooltip">Taken by the workshop</Trans>}>
    <Badge color="purple" variant="low" size="large" style={{ padding: 4 }} onClick={() => {}}>
      <IconAnalysis style={{ fontSize: '1rem' }} />
    </Badge>
  </Tooltip>
);

const iconForType = [
  { type: 'requalification-defect-photo', customIcon: <RequalificationDefectPhotoIcon /> },
];

export default PhotoCardGrid;
