import { ComponentPropsWithoutRef, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Checkbox from '@/design_system/Checkbox';
import CloseConfirmation from '@/design_system/Dialog/CloseConfirmation';
import { DangerMenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import { useShowToast } from '@/design_system/Toast';
import IconArchive from '@/icons/Archive.svg';
import { RequestWithRelations, useArchiveRequest } from '@/models/request';
import useViewPort from '@/utils/useViewport';

export const ArchiveRequestDialog = ({
  request,
  onArchive,
  onArchiveError,
  showArchivalConfirmation,
  setShowArchivalConfirmation,
}: {
  request: RequestWithRelations;
  onArchive?: () => void;
  onArchiveError?: (reason: unknown) => void;
  showArchivalConfirmation: boolean;
  setShowArchivalConfirmation: (showArchivalConfirmation: boolean) => void;
}) => {
  const { _ } = useLingui();

  const showToast = useShowToast();

  const { mutateAsync: archiveRequest } = useArchiveRequest();

  const [exportToZendesk, setExportToZendesk] = useState(false);

  const hasZendeskIntegration = !!request.organization.config.integrations?.zendesk;

  return (
    <CloseConfirmation
      title={<Trans id="requests.edit.archival-modal.title">Request archival</Trans>}
      content={
        <Stack gap="1rem">
          <p>
            <Trans id="requests.edit.archival-modal.content">
              Are you sure you want to archive this request? This action cannot be undone.
            </Trans>
          </p>
          {hasZendeskIntegration && (
            <Checkbox isSelected={exportToZendesk} onChange={setExportToZendesk}>
              <p>
                <Trans id="requests.edit.archival-modal.content.zendesk">
                  Transfer this request to Zendesk
                </Trans>
              </p>
            </Checkbox>
          )}
        </Stack>
      }
      confirm={<Trans id="requests.edit.archival-modal.confirm">Archive request</Trans>}
      onConfirm={() => {
        archiveRequest({
          id: request.id,
          query: exportToZendesk ? { export: 'zendesk' } : undefined,
        })
          .then(() => {
            setShowArchivalConfirmation(false);
            showToast({
              type: 'success',
              text: _(
                msg({
                  id: 'requests.edit.archival-modal.success',
                  message: 'The request has been successfully archived',
                })
              ),
            });
          })
          .then(onArchive)
          .catch(onArchiveError);
      }}
      showDialog={showArchivalConfirmation}
      setShowDialog={setShowArchivalConfirmation}
    />
  );
};

export const ArchiveRequestButton = ({
  request,
  onArchive,
  onArchiveError,
  iconOnly,
  size,
}: {
  request: RequestWithRelations;
  onArchive?: () => void;
  onArchiveError?: (reason: unknown) => void;
  iconOnly?: boolean;
  size?: ComponentPropsWithoutRef<typeof Button>['size'];
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();

  const [showArchivalConfirmation, setShowArchivalConfirmation] = useState(false);

  return (
    <>
      <Button
        variant="secondary"
        size={size ?? 'medium'}
        iconOnly={iconOnly}
        onPress={() => setShowArchivalConfirmation(true)}
        disabled={showArchivalConfirmation}
        style={isMobile ? { flex: 1 } : undefined}
        ariaLabel={_(msg({ id: 'requests.edit.actions.archive', message: 'Archive request' }))}
        tooltip={
          iconOnly
            ? _(msg({ id: 'requests.edit.actions.archive', message: 'Archive request' }))
            : undefined
        }
      >
        <IconArchive />
        {!isMobile && !iconOnly && (
          <Trans id="requests.edit.actions.archive">Archive request</Trans>
        )}
      </Button>
      <ArchiveRequestDialog
        request={request}
        onArchive={onArchive}
        onArchiveError={onArchiveError}
        showArchivalConfirmation={showArchivalConfirmation}
        setShowArchivalConfirmation={setShowArchivalConfirmation}
      />
    </>
  );
};

export const ArchiveRequestMenuItem = () => {
  return (
    <DangerMenuItem id="archive">
      <Stack row alignItems="center" gap="0.5rem" className="paragraph-100-regular">
        <IconArchive style={{ fontSize: '1.25rem' }} />
        <span>
          <Trans id="requests.edit.actions.archive">Archive request</Trans>
        </span>
      </Stack>
    </DangerMenuItem>
  );
};
