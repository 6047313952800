import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import { useRepair } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';

export const RepairArticleAction = ({
  article,
  onActionDone,
}: {
  article: ArticleWithRelations;
  onActionDone: () => void;
}) => {
  const {
    mutateAsync: repair,
    isPending: isPendingRepair,
    isSuccess: isSuccessRepair,
  } = useRepair({
    articleId: article.id,
  });

  const handleRepair = async () => {
    await repair();
    onActionDone();
  };

  return (
    <Button
      variant="primary"
      size="medium"
      onPress={handleRepair}
      isLoading={isPendingRepair || isSuccessRepair}
    >
      <Trans id="article.actions.repair_article.validate">Mark as repaired</Trans>
    </Button>
  );
};
