import { useState } from 'react';
import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconTask from '@/icons/Task.svg';
import { useAnalyze } from '@/models/article';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import useViewPort from '@/utils/useViewport';

import RequalificationDrawer from './RequalificationDrawer';

export const AnalyzeArticleAction = ({
  article,
  request,
  onActionDone,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  onActionDone: () => void;
}) => {
  const { isMobile } = useViewPort();
  const [isOpenRequalificationDialog, setIsOpenRequalificationDialog] = useState(false);

  const {
    mutateAsync: analyzeArticle,
    isPending: isAnalyzePending,
    isSuccess: isAnalyzeSuccess,
  } = useAnalyze({
    articleId: article.id,
  });

  const handleAnalyze = async () => {
    await analyzeArticle();
    onActionDone();
  };

  return (
    <>
      <Stack row={!isMobile} gap="0.5rem">
        <Button
          size="medium"
          variant="secondary"
          onPress={() => setIsOpenRequalificationDialog(true)}
        >
          <IconTask />
          <Trans id="article.analysis.footer.action.propose-requalification">
            Propose actions re-qualification
          </Trans>
        </Button>
        <Button
          variant="primary"
          size="medium"
          onPress={handleAnalyze}
          isLoading={isAnalyzePending || isAnalyzeSuccess}
        >
          <Trans id="article.analysis.footer.action.validate">Validate analysis</Trans>
        </Button>
      </Stack>

      <RequalificationDrawer
        article={article}
        request={request}
        isOpen={isOpenRequalificationDialog}
        setIsOpen={setIsOpenRequalificationDialog}
        onRequalified={onActionDone}
      />
    </>
  );
};
