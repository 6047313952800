import { Article } from '@/models/article';
import { Model } from '@/models/model';
import { Product } from '@/models/product';
import { User } from '@/models/user';

export const COMMENT_VISIBILITY = [
  'public',
  'organization',
  'external-workshop',
  // TODO: Handle external-store visibility (for multi-brands stores)
  // 'external-store',
] as const;
export type CommentVisibility = (typeof COMMENT_VISIBILITY)[number];

export class Comment extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);

    this.creator = new User(data.creator);
    this.article = data.article
      ? new Article(data.article).with('product', data.article.product)
      : null;
  }

  id!: string;
  organizationId!: string;
  content!: string;
  visibility!: CommentVisibility;

  creatorId!: string;
  creator: User;

  requestId!: string | null;

  articleId!: string | null;
  article?: (Article & { product: Product | null }) | null;

  shipmentId!: string | null;

  createdAt!: string;
  updatedAt!: string;

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get updatedAtDate() {
    return new Date(this.updatedAt);
  }
}
