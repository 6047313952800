import IconWrapper from './IconWrapper';

const IconError = IconWrapper({
  name: 'error',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM10.9999 15.5C10.9999 14.9477 11.4477 14.5 11.9999 14.5C12.5522 14.5 12.9999 14.9477 12.9999 15.5C12.9999 16.0523 12.5522 16.5 11.9999 16.5C11.4477 16.5 10.9999 16.0523 10.9999 15.5ZM12 13C11.4477 13 11 12.5523 11 12L11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8L13 12C13 12.5523 12.5523 13 12 13Z"
      />
    </svg>
  ),
});

export default IconError;
