import { EmptyState } from '@/components/EmptyState';
import { IllustrationShipmentsEmptyState } from '@/routes/Shipments/components/IllustrationShipmentsEmptyState';

interface ShipmentsEmptyStateProps {
  title?: React.ReactNode;
  subtitle: React.ReactNode;
}

export const ShipmentsEmptyState = ({ title, subtitle }: ShipmentsEmptyStateProps) => {
  return (
    <EmptyState
      title={title}
      subtitle={subtitle}
      illustration={<IllustrationShipmentsEmptyState />}
    />
  );
};
