import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import IconPackage from '@/icons/Package.svg';
import { ShipmentWithRelations } from '@/models/shipment';
import { ShipmentMessageTitle } from '@/routes/Shipments/Shipment/components/ShipmentStep/components/ShipmentMessageTitle';
import { useCurrentSession } from '@/services/auth';

const PreparationMessage = ({
  shipment,
  hideIcon,
  className,
}: {
  shipment: ShipmentWithRelations;
  hideIcon?: boolean;
  className?: string;
}) => {
  const { currentSession } = useCurrentSession();

  const canCreateShipment = shipment.canBeCreatedBy(currentSession);

  if (!canCreateShipment) {
    return (
      <AlertBar
        type="info"
        size="large"
        icon={<IconPackage />}
        hideIcon={hideIcon}
        className={className}
        title={
          <ShipmentMessageTitle shipment={shipment}>
            <Trans id="requests.transit.transit-pending-message.title.wait">
              Waiting for package preparation
            </Trans>
          </ShipmentMessageTitle>
        }
      >
        {!!shipment.originStoreId && !!shipment.destinationWorkshopId && (
          <Trans id="requests.transit.transit-pending-message.text.wait">
            The store is preparing the package for delivery to the workshop.
          </Trans>
        )}
        {!!shipment.originWorkshopId && !!shipment.destinationStoreId && (
          <Trans id="requests.delivery.delivery-pending-message.text.wait">
            The workshop is preparing the package for delivery to the store.
          </Trans>
        )}
        {!!shipment.originWorkshopId && !!shipment.destinationClientId && (
          <Trans id="requests.delivery.delivery-pending-message.to-client.text.wait">
            The workshop is preparing the package for delivery to the client.
          </Trans>
        )}
        {!!shipment.originWorkshopId && !!shipment.destinationWorkshopId && (
          <Trans id="requests.delivery.delivery-pending-message.to-workshop.text.wait">
            The workshop is preparing the package for delivery to another workshop.
          </Trans>
        )}
      </AlertBar>
    );
  }

  return (
    <AlertBar
      type="warning"
      size="large"
      icon={<IconPackage />}
      hideIcon={hideIcon}
      title={
        <ShipmentMessageTitle shipment={shipment}>
          <Trans id="shipment.preparation-message.title">Prepare the package</Trans>
        </ShipmentMessageTitle>
      }
      className={className}
    >
      <p>
        <Trans id="shipment.preparation-message.text">
          Print and place the QR code of each item added in the package then validate the
          preparation.
        </Trans>
      </p>
    </AlertBar>
  );
};

export default PreparationMessage;
