import React from 'react';
import ReactDOM from 'react-dom/client';

import { ApiProvider } from './services/api';
import { getBrowserLocale, I18nProvider, switchLanguage } from './services/i18n';
import { initSentry } from './services/sentry';
import { Router } from './router';

import '@fontsource/dm-sans/400.css';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/700.css';
import 'simplebar-react/dist/simplebar.min.css';
import './style/index.css';

switchLanguage(getBrowserLocale());

initSentry();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <I18nProvider>
      <ApiProvider>
        <Router />
      </ApiProvider>
    </I18nProvider>
  </React.StrictMode>
);
