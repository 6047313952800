import { msg, Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity, BaseActivityText } from '@/components/activities/BaseActivity/BaseActivity';
import Stack from '@/design_system/Stack';
import IconStar from '@/icons/Star.svg';

export const ArticleFeedbackSubmittedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'article_feedback_submitted'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconStar />}
      creatorName={msg({
        id: 'activities.article_feedback_submitted.client',
        message: 'The client',
      })}
      message={
        <div style={{ display: 'inline-flex', flexDirection: 'row', gap: '0.25rem' }}>
          <BaseActivityText>
            <Trans id="activities.article_feedback_submitted.message">left a review</Trans>
          </BaseActivityText>
          <Stack
            row
            style={{ color: 'var(--color-warning-500)', height: '22px', display: 'inline-flex' }}
            alignItems="center"
          >
            {([1, 2, 3, 4, 5] as const).map((rating) => (
              <IconStar key={rating} filled={rating <= (activity.data.feedback.quality ?? 0)} />
            ))}
          </Stack>
        </div>
      }
    />
  );
};
