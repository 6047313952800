import { Navigate, Outlet, useMatches } from 'react-router-dom';

import { GlobalToastRegion } from '@/design_system/Toast';
import { useCurrentSession } from '@/services/auth';
import { useHeap } from '@/services/heap';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import Navbar from './Navbar/Navbar';

import './App.css';

const { block } = createBEMClasses('app');

const App = () => {
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();
  const matchingRoutes = useMatches();
  const fullPage = matchingRoutes.some(({ handle }) => (handle as any)?.displayOnFullPage);
  const hideNavBar = isMobile && fullPage;

  useHeap();

  if (currentSession?.roles.length === 0) {
    return <Navigate to="/no-organizations" />;
  }

  return (
    <>
      {!hideNavBar && <Navbar />}
      <main
        className={block({
          'full-page': fullPage,
        })}
      >
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </main>
      <GlobalToastRegion />
    </>
  );
};

export default App;
