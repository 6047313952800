import React, { CSSProperties } from 'react';
import {
  Radio as AriaRadio,
  RadioGroup as AriaRadioGroup,
  RadioGroupProps as AriaRadioGroupProps,
  RadioProps as AriaRadioProps,
} from 'react-aria-components';
import { msg } from '@lingui/macro';

import { createBEMClasses } from '@/utils/classname';

import './RadioButtonGroup.css';

const { block, element } = createBEMClasses('radio-button-group');

interface RadioButtonGroupProps extends AriaRadioGroupProps {
  ariaLabel: string;
  size?: 'small' | 'large';
  variant?: 'primary' | 'brand';
  options: RadioButtonProps[];
  width?: CSSProperties['width'];
  className?: string;
}

interface RadioButtonProps extends AriaRadioProps {
  children?: React.ReactNode;
}

const RadioButtonGroup = ({
  ariaLabel,
  size = 'large',
  variant = 'primary',
  options,
  width,
  className,
  ...props
}: RadioButtonGroupProps) => (
  <AriaRadioGroup
    {...props}
    className={block({ size, variant }, className)}
    aria-label={ariaLabel}
    style={{ width }}
  >
    {options.map((option, index) => (
      <AriaRadio key={index} {...option} className={element('option')}>
        {option.children}
      </AriaRadio>
    ))}
  </AriaRadioGroup>
);

export const yesNoRadioOptions = [
  { value: 'no', children: msg({ id: 'design-system.radio-group.yes-no.no', message: 'No' }) },
  { value: 'yes', children: msg({ id: 'design-system.radio-group.yes-no.yes', message: 'Yes' }) },
];

export default RadioButtonGroup;
