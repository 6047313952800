import IconWrapper from './IconWrapper';

const IconBag = IconWrapper({
  name: 'bag',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.15949 6.45429C8.4099 5.45267 9.30986 4.75 10.3423 4.75H13.6577C14.6901 4.75 15.5901 5.45267 15.8405 6.45429L16.0394 7.25H8.31848H7.96056L8.15949 6.45429ZM6.41035 7.2662L6.70428 6.09049C7.12162 4.42111 8.62156 3.25 10.3423 3.25H13.6577C15.3784 3.25 16.8783 4.42112 17.2957 6.09049L17.5896 7.26614C19.3812 7.43229 20.8248 8.86242 20.9769 10.6886L21.5672 17.7716C21.7008 19.3749 20.4356 20.75 18.8267 20.75H5.1739C3.56504 20.75 2.29979 19.3749 2.4334 17.7716L3.02365 10.6886C3.17581 8.86264 4.61902 7.43264 6.41035 7.2662ZM6.76069 8.75C5.5905 8.75 4.61565 9.64699 4.51847 10.8131L3.92821 17.8962C3.86748 18.625 4.4426 19.25 5.1739 19.25H18.8267C19.558 19.25 20.1331 18.625 20.0724 17.8962L19.4821 10.8131C19.3849 9.64699 18.4101 8.75 17.2399 8.75H17H16.0912H8.31848H6.99998H6.76069Z"
      />
    </svg>
  ),
});

export default IconBag;
