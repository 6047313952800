import { useCallback, useRef } from 'react';

export const useScrollIntoView = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);

  const scrollIntoView = useCallback(
    (options: ScrollIntoViewOptions = {}) => {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        ...options,
      });
    },
    [ref]
  );

  return [ref, scrollIntoView] as const;
};
