import { useMemo } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import debounce from 'lodash.debounce';

import ClientForm, { getClientError, useClientState } from '@/components/ClientForm';
import Box from '@/design_system/Box';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import Stack from '@/design_system/Stack';
import {
  RequestWithRelations,
  useUpdateDraftRequest,
  useUpdateRequestClient,
} from '@/models/request';
import { useStores } from '@/models/store';
import { useCurrentSession } from '@/services/auth';
import { isEmailValid } from '@/utils/email';

import './ClientStoreForm.css';

const ClientStoreForm = ({
  request,
  hideBillingAddress,
  showErrors,
}: {
  request: RequestWithRelations;
  hideBillingAddress: boolean;
  showErrors: boolean;
}) => {
  return (
    <Stack gap="0.5rem" className="client-store-form">
      <h2 className="headline-400-bold">
        {request.client ? (
          <Trans id="requests.new.client.title">Client information</Trans>
        ) : (
          <Trans id="requests.new.store.title">Store information</Trans>
        )}
      </h2>
      <Box padding="16px" gap="1rem" style={{ flex: 'initial' }}>
        {!!request.client && (
          <ClientFormWrapper
            request={request}
            showErrors={showErrors}
            hideBillingAddress={hideBillingAddress}
          />
        )}
        {!request.client && <StoreFormWrapper request={request} showErrors={showErrors} />}
      </Box>
    </Stack>
  );
};

const ClientFormWrapper = ({
  request,
  hideBillingAddress,
  showErrors,
}: {
  request: RequestWithRelations;
  hideBillingAddress: boolean;
  showErrors: boolean;
}) => {
  const { mutateAsync: updateRequestClient } = useUpdateRequestClient();

  const debouncedUpdateRequestClient = useMemo(() => {
    return debounce(updateRequestClient, 500);
  }, [updateRequestClient]);

  const clientState = useClientState(request.client, request.vip, (data) => {
    debouncedUpdateRequestClient({
      id: request.id,
      body: {
        client: {
          name: data.name || undefined, // The field "name" starts as an empty string, that's why we use the operator "||"
          email: isEmailValid(data.email) ? data.email : undefined,
          address: data.address ?? undefined,
          billingAddress: data.billingAddress ?? undefined,
          phone: data.phone,
          deliveryOption: data.deliveryOption,
          sendcloudServicePointId: data.sendcloudServicePointId,
          locale: data.locale,
        },
        vip: data.vip,
      },
    });
  });

  return (
    <ClientForm
      request={request}
      state={clientState}
      hideDeliveryOption={!request.storeId}
      hideBillingAddress={hideBillingAddress}
      showErrors={showErrors}
    />
  );
};

const StoreFormWrapper = ({
  request,
  showErrors,
}: {
  request: RequestWithRelations;
  showErrors: boolean;
}) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  const currentSessionStores = currentSession?.getStoresWithPermission('create_store_request');

  const { data: { stores: allStores } = { stores: [] } } = useStores(
    { limit: 100, internal: true, embedded: true },
    { enabled: !currentSessionStores?.length }
  );

  const stores = currentSessionStores?.length ? currentSessionStores : allStores;
  const storeOptions = stores.map((store) => ({ id: store.id, text: store.name }));

  const { mutateAsync: updateDraftRequest } = useUpdateDraftRequest(request.id);

  return (
    <>
      {!currentSessionStores?.length && request.draft && (
        <InputSelect
          variant="select"
          label={_(msg({ id: 'requests.new.store-select.label', message: 'Store' }))}
          placeholder={_(
            msg({ id: 'requests.new.store-select.placeholder', message: 'Select a store' })
          )}
          isSearchable={false}
          value={storeOptions.find((storeOption) => storeOption.id === request.storeId)}
          options={storeOptions}
          getOptionValue={(storeOption) => storeOption.id}
          getOptionLabel={(storeOption) => storeOption.text}
          onChange={(storeOption) => {
            if (!!storeOption && storeOption.id !== request.storeId) {
              updateDraftRequest({ storeId: storeOption.id });
            }
          }}
          error={
            showErrors && !request.storeId
              ? _(
                  msg({
                    id: 'requests.client-store-form.error.no-store',
                    message: 'Please select a store',
                  })
                )
              : undefined
          }
          style={{ flex: 1 }}
        />
      )}
      {request.store && (
        <Box padding="16px" gap="1rem">
          <Stack gap="0.25rem">
            <p className="label-100">{request.store.name}</p>
            <p className="paragraph-100-regular">
              {request.store.address?.formattedStreet}
              <br />
              {request.store.address?.formattedZip}
              <br />
              {request.store.formattedPhone}
            </p>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default ClientStoreForm;

export const getClientStoreError = (request: RequestWithRelations, hideBillingAddress: boolean) => {
  return request.client
    ? getClientError(request.client, hideBillingAddress).hasError
    : !request.storeId;
};
