import { msg, Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticlesNames,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconUserRefusal from '@/icons/UserRefusal.svg';
import IconUserValidation from '@/icons/UserValidation.svg';

export const ArticleValidationChoiceActivity = ({
  activities,
  displayArticleName,
}: {
  activities: ActivityOfType<'article_validation_choice'>[];
  displayArticleName: boolean;
}) => {
  const firstActivity = activities[0];

  if (firstActivity.data.value === 'accepted') {
    return (
      <BaseActivity
        createdAt={firstActivity.createdAt}
        type="primary"
        icon={<IconUserValidation />}
        creatorName={msg({
          id: 'activities.article-validation-choice.accepted.client',
          message: 'The client',
        })}
        message={
          <>
            <BaseActivityText>
              {activities.length > 1 ? (
                <Trans id="activities.article-validation-choice.accepted.message.multi">
                  accepted the estimate for the items
                </Trans>
              ) : (
                <Trans id="activities.article-validation-choice.accepted.message.single">
                  accepted the estimate for the item
                </Trans>
              )}
            </BaseActivityText>
            <BaseActivityArticlesNames
              activities={activities}
              displayArticleName={displayArticleName}
            />
          </>
        }
      />
    );
  }

  return (
    <BaseActivity
      createdAt={firstActivity.createdAt}
      type="danger"
      icon={<IconUserRefusal />}
      creatorName={msg({
        id: 'activities.article-validation-choice.refused.client',
        message: 'The client',
      })}
      message={
        <>
          <BaseActivityText>
            {activities.length > 1 ? (
              <Trans id="activities.article-validation-choice.refused.message.multi">
                refused the estimate for the items
              </Trans>
            ) : (
              <Trans id="activities.article-validation-choice.refused.message.single">
                refused the estimate for the item
              </Trans>
            )}
          </BaseActivityText>
          <BaseActivityArticlesNames
            activities={activities}
            displayArticleName={displayArticleName}
          />
        </>
      }
    />
  );
};
