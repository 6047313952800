import IconWrapper from './IconWrapper';

const IconUserWaiting = IconWrapper({
  name: 'user-waiting',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.08105 7.41684C9.08105 5.80629 10.3875 4.5 11.9999 4.5C13.6122 4.5 14.9186 5.80629 14.9186 7.41684C14.9186 9.02739 13.6122 10.3337 11.9999 10.3337C10.3875 10.3337 9.08105 9.02739 9.08105 7.41684ZM11.9999 3C9.55979 3 7.58105 4.97711 7.58105 7.41684C7.58105 9.85657 9.55979 11.8337 11.9999 11.8337C14.4399 11.8337 16.4186 9.85657 16.4186 7.41684C16.4186 4.97711 14.4399 3 11.9999 3ZM11.9995 12.6255C11.2848 12.6255 10.5743 12.669 9.9008 12.7366C6.80649 13.0474 4.8291 15.8142 4.8291 18.7677C4.8291 20.001 5.8289 21.0008 7.06221 21.0008H11.674C11.4492 20.5276 11.2758 20.0252 11.1613 19.5008H7.06221C6.65733 19.5008 6.3291 19.1726 6.3291 18.7677C6.3291 16.4232 7.87347 14.4478 10.0507 14.2291C10.6848 14.1654 11.3443 14.1255 11.9995 14.1255C12.0558 14.1255 12.1122 14.1258 12.1686 14.1264C12.5275 13.587 12.9593 13.1004 13.4499 12.6804C12.9754 12.6458 12.4884 12.6255 11.9995 12.6255ZM14.125 18C14.125 15.8599 15.8599 14.125 18 14.125C20.1401 14.125 21.875 15.8599 21.875 18C21.875 20.1401 20.1401 21.875 18 21.875C15.8599 21.875 14.125 20.1401 14.125 18ZM18 12.625C15.0315 12.625 12.625 15.0315 12.625 18C12.625 20.9685 15.0315 23.375 18 23.375C20.9685 23.375 23.375 20.9685 23.375 18C23.375 15.0315 20.9685 12.625 18 12.625ZM18.75 16.5C18.75 16.0858 18.4142 15.75 18 15.75C17.5858 15.75 17.25 16.0858 17.25 16.5V18C17.25 18.1164 17.2765 18.2265 17.3238 18.3248C17.3595 18.3992 17.4082 18.469 17.4699 18.5306L18.9701 20.0299C19.2631 20.3227 19.7379 20.3226 20.0308 20.0296C20.3236 19.7366 20.3234 19.2618 20.0305 18.969L18.75 17.6892V16.5Z"
      />
    </svg>
  ),
});

export default IconUserWaiting;
