import { useEffect, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CreationStepConfig } from '@/api';
import FileUpload from '@/components/FileUpload';
import InputQuantity from '@/components/InputQuantity';
import RefashionLogo from '@/components/RefashionLogo';
import Button from '@/design_system/Button';
import RadioGroup from '@/design_system/RadioGroup/RadioGroup';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import {
  ActionTypeOrganizationPrice,
  NEED_CATEGORIES,
  NeedL1,
  NeedL2,
  NeedL3,
  NeedL4,
  useActionTypes,
} from '@/models/actionType';
import { useCreateAction, useUpdateAction } from '@/models/article';
import { Medium, useDeleteMedium } from '@/models/medium';
import {
  ArticleActionWithRelations,
  ClientArticleWithRelations,
  ClientRequestWithRelations,
} from '@/models/request';
import ArticleCard from '@/routes/Brand/Requests/New/components/Article/components/ArticleCard';
import { ProgressBar } from '@/routes/Brand/Requests/New/components/ProgressBar/ProgressBar';
import { createBEMClasses } from '@/utils/classname';
import { formatCurrency } from '@/utils/number';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import './ActionsForm.css';

const { block, element } = createBEMClasses('client-actions-form');

const ActionsForm = ({
  initialActions,
  article,
  request,
  onEditArticle,
  onDeleteArticle,
  onSaveActions,
  onCancel,
}: {
  initialActions: ArticleActionWithRelations[];
  article: ClientArticleWithRelations;
  request: ClientRequestWithRelations;
  onEditArticle: () => void;
  onDeleteArticle: () => void;
  onSaveActions: () => void;
  onCancel: () => void;
}) => {
  const isEdit = !!initialActions.length;
  const initialActionData = initialActions[0] as ArticleActionWithRelations | undefined;

  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const { data: { actionTypes } = { actionTypes: [] }, isSuccess } = useActionTypes({
    requestId: request.id,
    productL1: article.productL1 ?? undefined,
    productL2: article.productL2 ?? undefined,
    productL3: article.productL3 ?? undefined,
  });

  const [needL1, setNeedL1] = useState<NeedL1 | undefined>(
    initialActionData?.actionTypeOrganization?.actionType.needL1
  );
  const [_needL2, setNeedL2] = useState<NeedL2 | 'custom-action' | undefined>(
    initialActionData
      ? (initialActionData.actionTypeOrganization?.actionType.needL2 ?? 'custom-action')
      : undefined
  );

  // If there is no action type, we just want to display the custom action input
  const needL2 = !_needL2 && isSuccess && actionTypes.length === 0 ? 'custom-action' : _needL2;

  const [needL3, setNeedL3] = useState<NeedL3 | undefined>(
    initialActionData?.actionTypeOrganization?.actionType.needL3 ?? undefined
  );
  const [needL4, setNeedL4] = useState<NeedL4 | undefined>(
    initialActionData?.actionTypeOrganization?.actionType.needL4 ?? undefined
  );

  const [needL2Ref, scrollToNeedL2] = useScrollIntoView<HTMLDivElement>();
  const [needL3Ref, scrollToNeedL3] = useScrollIntoView<HTMLDivElement>();
  const [needL4Ref, scrollToNeedL4] = useScrollIntoView<HTMLDivElement>();

  const [defectPhotosByAction, setDefectPhotosByAction] = useState<Medium[][]>(
    initialActions.length ? initialActions.map((action) => action.media) : [[]]
  );

  const [action, setAction] = useState(initialActionData?.actionTypeOrganization);

  const [customActionDescription, setCustomActionDescription] = useState(
    initialActionData?.customDescription ?? ''
  );

  const [quantity, setQuantity] = useState(initialActions.length ? initialActions.length : 1);

  const [mediaToDelete, setMediaToDelete] = useState<string[]>([]);
  const { mutate: deleteMedium } = useDeleteMedium();

  const handleSetQuantity = (newQuantity: number) => {
    if (newQuantity > quantity) {
      setDefectPhotosByAction([...defectPhotosByAction, []]);
    }

    setQuantity(newQuantity);
  };

  const creationConfig = article.step?.config as CreationStepConfig['config'] | undefined;
  const showPrice = creationConfig?.requirePrice ?? false;
  const allowCustomActions = (creationConfig?.allowCustomActions && !initialActionData) ?? true;
  const allowActions = !initialActionData?.customDescription;

  const allActions = actionTypes;
  const actionsAfterL1 = allActions.filter(({ actionType }) => actionType.needL1 === needL1);
  const actionsAfterL2 = actionsAfterL1.filter(({ actionType }) => actionType.needL2 === needL2);
  const actionsAfterL3 = actionsAfterL2.filter(({ actionType }) => actionType.needL3 === needL3);

  const wordingsL1 =
    NEED_CATEGORIES.map((l1) => ({
      ...l1,
      actions: allActions.filter(({ actionType }) => actionType.needL1 === l1.id),
    })).filter(({ actions }) => actions.length > 0) ?? [];
  const needL1Wording = wordingsL1.find((l1) => l1.id === needL1);

  const wordingsL2AfterL1 =
    needL1Wording?.categories
      .map((l2) => ({
        ...l2,
        actions: actionsAfterL1.filter(({ actionType }) => actionType.needL2 === l2.id),
      }))
      .filter(({ actions }) => actions.length > 0) ?? [];
  const needL2Wording = wordingsL2AfterL1.find((l2) => l2.id === needL2);

  const wordingsL3AfterL2 =
    needL2Wording?.categories
      .map((l3) => ({
        ...l3,
        actions: actionsAfterL2.filter(({ actionType }) => actionType.needL3 === l3.id),
      }))
      .filter(({ actions }) => actions.length > 0) ?? [];
  const needL3Wording = wordingsL3AfterL2.find((l3) => l3.id === needL3);

  const wordingsL4AfterL3 =
    needL3Wording?.categories
      .map((l4) => ({
        ...l4,
        actions: actionsAfterL3.filter(({ actionType }) => actionType.needL4 === l4.id),
      }))
      .filter(({ actions }) => actions.length > 0) ?? [];

  const needL1Options = wordingsL1.map((l1) => ({
    value: l1.id,
    text: _(l1.label),
  }));

  const baseNeedL2Options = allowActions
    ? wordingsL2AfterL1.map((l2) => ({
        value: l2.id,
        text: _(l2.label),
        icon: 'icon' in l2 ? l2.icon : undefined,
      }))
    : [];

  const needL2Options = allowCustomActions
    ? [
        ...baseNeedL2Options,
        {
          value: 'custom-action',
          text: _(msg({ id: 'client.new.actions.form.custom-action.text', message: 'A doubt?' })),
          subText: _(
            msg({
              id: 'client.new.actions.form.custom-action.sub-text',
              message: 'Describe your issue',
            })
          ),
          icon: undefined,
        },
      ]
    : baseNeedL2Options;

  const needL3Options = wordingsL3AfterL2.map((l3) => ({
    value: l3.id,
    text:
      l3.categories.length === 0 ? (
        <OptionTextWithPrice
          label={_(l3.label)}
          price={l3.actions[0].dynamicPrice}
          refashionBonus={showPrice ? l3.actions[0].refashionBonus : null}
          showPrice={showPrice}
        />
      ) : (
        _(l3.label)
      ),
    icon: 'icon' in l3 ? l3.icon : undefined,
  }));

  const needL4Options = wordingsL4AfterL3.map((l4) => ({
    value: l4.id,
    text: (
      <OptionTextWithPrice
        label={_(l4.label)}
        price={l4.actions[0].dynamicPrice}
        refashionBonus={showPrice ? l4.actions[0].refashionBonus : null}
        showPrice={showPrice}
      />
    ),
  }));

  const openQuestionLabel = msg({
    id: 'client.new.actions.form.open-question',
    message: 'Could you specify?',
  });

  const quantityQuestionLabel = msg({
    id: 'client.new.actions.form.quantity-question',
    message: 'Please specify the quantity of this defect',
  });

  const areDefectPhotosValid =
    defectPhotosByAction.length >= quantity &&
    defectPhotosByAction.slice(0, quantity).every((defectPhotos) => defectPhotos.length > 0);

  const disableSave = (!action && !customActionDescription) || !areDefectPhotosValid;

  const { mutateAsync: createAction, isPending: isPendingCreateAction } = useCreateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const { mutateAsync: updateAction, isPending: isPendingUpdateAction } = useUpdateAction({
    articleId: article.id,
    requestId: request.id,
  });

  const saveActions = async () => {
    if (!action && !customActionDescription) {
      return;
    }

    if (action) {
      for (let i = 0; i < quantity; i++) {
        const existingAction = initialActions[i];

        if (existingAction) {
          await updateAction({
            actionId: existingAction.id,
            body: {
              actionTypeOrganizationId: action.id,
              defectPhotoIds: defectPhotosByAction[i]?.map(({ id }) => id) ?? [],
            },
          });
        } else {
          await createAction({
            actionTypeOrganization: action,
            defectPhotoIds: defectPhotosByAction[i]?.map(({ id }) => id) ?? [],
          });
        }
      }
    } else if (customActionDescription) {
      for (let i = 0; i < quantity; i++) {
        const existingAction = initialActions[i];

        if (existingAction) {
          await updateAction({
            actionId: existingAction.id,
            body: {
              description: customActionDescription,
              defectPhotoIds: defectPhotosByAction[i]?.map(({ id }) => id) ?? [],
            },
          });
        } else {
          await createAction({
            description: customActionDescription,
            defectPhotoIds: defectPhotosByAction[i]?.map(({ id }) => id) ?? [],
          });
        }
      }
    }

    const extraMediaToDelete = defectPhotosByAction
      .slice(quantity)
      .flatMap((media) => media.map(({ id }) => id));

    [...mediaToDelete, ...extraMediaToDelete].forEach((id) => deleteMedium(id));
    onSaveActions();
  };

  const discard = () => {
    const initialDefectPhotoIds = initialActions.flatMap((action) =>
      action.media.map(({ id }) => id)
    );
    const currentDefectPhotoIds = defectPhotosByAction.flatMap((media) =>
      media.map(({ id }) => id)
    );

    currentDefectPhotoIds
      .filter((id) => !initialDefectPhotoIds.includes(id))
      .forEach((id) => deleteMedium(id));

    onCancel();
  };

  useEffect(() => {
    if (needL1Options.length === 1) {
      setNeedL1(needL1Options[0].value);
    }
  }, [needL1Options]);

  const progress =
    needL1 || needL2 === 'custom-action'
      ? needL2
        ? needL3 || needL2 === 'custom-action' || !!action
          ? needL4 || needL2 === 'custom-action' || !!action
            ? areDefectPhotosValid
              ? isPendingCreateAction || isPendingUpdateAction
                ? 100
                : 95
              : 85
            : 75
          : 65
        : 55
      : 50;

  return (
    <div className={block()}>
      <main>
        <div className={element('article')}>
          <ArticleCard
            request={request}
            article={article}
            onEdit={onEditArticle}
            onDelete={onDeleteArticle}
          />
        </div>
        <Stack className={element('actions')} gap="3rem">
          {needL1Options.length > 1 && (
            <Stack gap="1.5rem">
              <h3 className="headline-300-bold">
                <Trans id="client.new.actions.form.title">What type of service do you need?</Trans>
              </h3>
              <RadioGroup
                theme="brand"
                aria-label={_(
                  msg({
                    id: 'client.new.actions.form.title',
                    message: 'What type of service do you need?',
                  })
                )}
                options={needL1Options}
                value={needL1 ?? 'invalid'}
                onChange={(value) => {
                  setNeedL1(value as NeedL1);
                  setNeedL2(undefined);
                  setNeedL3(undefined);
                  setNeedL4(undefined);
                  setAction(undefined);
                  handleSetQuantity(1);
                  scrollToNeedL2();
                }}
                grid="medium"
                scrollToOnMount
              />
            </Stack>
          )}
          {!!needL1 && needL2Options.length > 0 && (
            <RadioGroup
              theme="brand"
              label={_(
                needL1Wording && 'nextLabel' in needL1Wording
                  ? needL1Wording.nextLabel
                  : openQuestionLabel
              )}
              options={needL2Options}
              variant={needL2Options.some((option) => !!option.icon) ? 'icon' : 'text'}
              value={needL2 ?? 'invalid'}
              onChange={(value) => {
                setNeedL2(value as NeedL2 | 'custom-action');
                setNeedL3(undefined);
                setNeedL4(undefined);
                setAction(undefined);
                handleSetQuantity(1);
                scrollToNeedL3();
              }}
              grid="medium"
              ref={needL2Ref}
              scrollToOnMount
            />
          )}
          {!!needL2 && needL3Options.length > 0 && (
            <RadioGroup
              theme="brand"
              label={_(
                needL2Wording && 'nextLabel' in needL2Wording
                  ? needL2Wording.nextLabel
                  : openQuestionLabel
              )}
              options={needL3Options}
              variant={needL3Options.some((option) => !!option.icon) ? 'icon' : 'text'}
              value={needL3 ?? 'invalid'}
              onChange={(value) => {
                setNeedL3(value as NeedL3);
                setNeedL4(undefined);
                handleSetQuantity(1);

                const option = wordingsL3AfterL2.find((l3) => l3.id === value);

                if (option?.categories.length === 0) {
                  setAction(option.actions[0]);
                } else {
                  setAction(undefined);
                  scrollToNeedL4();
                }
              }}
              grid="medium"
              ref={needL3Ref}
              scrollToOnMount
            />
          )}
          {!!needL3 && needL4Options.length > 0 && (
            <RadioGroup
              theme="brand"
              label={_(
                needL3Wording && 'nextLabel' in needL3Wording
                  ? needL3Wording.nextLabel
                  : openQuestionLabel
              )}
              options={needL4Options}
              value={needL4 ?? 'invalid'}
              onChange={(value) => {
                setNeedL4(value as NeedL4);

                const option = wordingsL4AfterL3.find((l4) => l4.id === value)!;

                setAction(option.actions[0]);
                handleSetQuantity(1);
              }}
              grid="medium"
              ref={needL4Ref}
              scrollToOnMount
            />
          )}
          {needL2 === 'custom-action' && (
            <TextArea
              label={_(
                msg({
                  id: 'client.new.actions.form.custom-action.label',
                  message: "Describe the item's issues",
                })
              )}
              value={customActionDescription}
              onChange={(e) => setCustomActionDescription(e.target.value)}
              rows={6}
              size="large"
              scrollToAndFocusOnRender
            />
          )}
          {action?.actionType.isQuantifiable && (
            <Stack gap="0.5rem">
              <p className="paragraph-50-medium">{_(quantityQuestionLabel)}</p>
              <InputQuantity
                quantity={quantity}
                onDecrement={() => handleSetQuantity(quantity - 1)}
                onIncrement={() => handleSetQuantity(quantity + 1)}
                disableDelete
                size="large"
                variant="brand"
              />
            </Stack>
          )}
          {(!!action || needL2 === 'custom-action') &&
            defectPhotosByAction.slice(0, quantity).map((defectPhotos, index) => (
              <FileUpload
                key={index}
                uploadData={{ type: 'defect-photo' }}
                type="photo"
                label={
                  quantity > 1
                    ? _(
                        msg({
                          id: 'client.new.actions.form.photo.label.multiple',
                          message: `Add photo(s) of defect #${index + 1}`,
                        })
                      )
                    : _(
                        msg({
                          id: 'client.new.actions.form.photo.label.single',
                          message: `Add photo(s) of the defect`,
                        })
                      )
                }
                media={defectPhotos}
                onDelete={(idToDelete) => {
                  setMediaToDelete([...mediaToDelete, idToDelete]);
                  setDefectPhotosByAction((prevDefectPhotosByAction) =>
                    prevDefectPhotosByAction.map((prevDefectPhotos, _index) =>
                      _index === index
                        ? prevDefectPhotos.filter(({ id }) => id !== idToDelete)
                        : prevDefectPhotos
                    )
                  );
                }}
                onUpload={(newMedium) => {
                  setDefectPhotosByAction((prevDefectPhotosByAction) =>
                    prevDefectPhotosByAction.map((prevDefectPhotos, _index) =>
                      _index === index ? [...prevDefectPhotos, newMedium] : prevDefectPhotos
                    )
                  );
                }}
                size="large"
                theme="brand"
                onMount={() => {
                  window.scroll({
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                  });
                }}
              />
            ))}
        </Stack>
      </main>
      <footer>
        <ProgressBar progress={progress} />
        <Stack row gap={isMobile ? '1rem' : '2rem'}>
          {(article.hasActions || request.articles.length > 1) && (
            <Button
              size="large"
              variant="secondary-brand"
              style={{ flex: 1 }}
              onPress={() => {
                if (article.hasActions) {
                  discard();
                } else {
                  onDeleteArticle();
                }
              }}
              disabled={isPendingUpdateAction || isPendingCreateAction}
            >
              <Trans id="client.new.actions.form.cancel">Cancel</Trans>
            </Button>
          )}
          <Button
            size="large"
            variant="brand"
            style={{ flex: 1 }}
            disabled={disableSave}
            onPress={saveActions}
            isLoading={isPendingUpdateAction || isPendingCreateAction}
          >
            {isEdit ? (
              <Trans id="client.new.actions.form.save">Save</Trans>
            ) : (
              <Trans id="client.new.actions.form.add-to-cart">Add to my cart</Trans>
            )}
          </Button>
        </Stack>
      </footer>
    </div>
  );
};

const OptionTextWithPrice = ({
  label,
  price,
  refashionBonus,
  showPrice,
}: {
  label: string;
  price: ActionTypeOrganizationPrice | null;
  refashionBonus?: number | null;
  showPrice: boolean;
}) => {
  const amount = price
    ? refashionBonus
      ? price.amount - refashionBonus
      : price.amount
    : undefined;
  const hasDiscount = amount !== price?.amount;

  return (
    <Stack gap="0.25rem" alignItems="center">
      <p>
        {label}
        {hasDiscount && (
          <>
            {' '}
            <RefashionLogo status="applied" />
          </>
        )}
      </p>
      {showPrice && (
        <Stack row gap="0.25rem" alignItems="baseline">
          <span className="paragraph-50-medium paragraph-100-medium-mobile">
            {formatCurrency(amount, price?.currency)}
          </span>
          {hasDiscount && (
            <s
              role="deletion"
              className="paragraph-100-regular paragraph-200-regular-mobile text-disabled"
            >
              {formatCurrency(price?.amount, price?.currency)}
            </s>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default ActionsForm;
