import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { min } from 'date-fns';

import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import { useInvitations } from '@/models/userRole';
import { formatDate } from '@/utils/date';

import { InviteUser } from './InviteUser';
import { RemoveUser } from './RemoveUser';
import { ResendInvitation } from './ResendInvitation';
import { UpdateUserRoles } from './UpdateUserRoles';

export const Invitations = () => {
  const { _ } = useLingui();
  const { data: invitations } = useInvitations();

  if (!invitations?.length) {
    return null;
  }

  return (
    <Box padding="24px" gap="24px">
      <Stack row alignItems="center" justifyContent="space-between">
        <h2 className="headline-200-bold">
          <Trans id="settings.users.invited.title">Invited members</Trans>
        </h2>
        <InviteUser />
      </Stack>
      <Table
        ariaLabel={_(msg({ id: 'settings.users.invited.table.label', message: 'Invited members' }))}
        columnWidths="minmax(200px, 1fr) minmax(110px, 1fr) minmax(110px, 1fr) auto"
      >
        <Header>
          <Row>
            <Column>
              <Trans id="settings.users.invited.columns.email">Email</Trans>
            </Column>
            <Column>
              <Trans id="settings.users.invited.columns.roles">Roles</Trans>
            </Column>
            <Column>
              <Trans id="settings.users.invited.columns.invitation-date">Invitation date</Trans>
            </Column>
            <Column
              ariaLabel={_(
                msg({
                  id: 'settings.users.column.actions',
                  message: 'Actions',
                })
              )}
            />
          </Row>
        </Header>
        <Body>
          {invitations?.map((invitation) => (
            <Row key={invitation.email}>
              <Cell className="sentry-mask">
                <b>{invitation.email}</b>
              </Cell>
              <Cell>
                <Stack gap="0.25rem">
                  {invitation.userRoles
                    .map((userRole) => {
                      if (userRole.store) {
                        return `${userRole.role.name} (${userRole.store.name})`;
                      }

                      if (userRole.workshop) {
                        return `${userRole.role.name} (${userRole.workshop.name})`;
                      }

                      return userRole.role.name;
                    })
                    .map((label) => (
                      <div key={label}>{label}</div>
                    ))}
                </Stack>
              </Cell>
              <Cell>
                {formatDate(min(invitation.userRoles.map((userRole) => userRole.invitedAtDate)), {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                })}
              </Cell>
              <Cell>
                <Stack row gap="4px">
                  <ResendInvitation email={invitation.email} />
                  <UpdateUserRoles
                    user={{
                      email: invitation.email,
                      roles: invitation.userRoles,
                    }}
                  />
                  <RemoveUser
                    user={{
                      email: invitation.email,
                      roles: invitation.userRoles,
                    }}
                  />
                </Stack>
              </Cell>
            </Row>
          ))}
        </Body>
      </Table>
    </Box>
  );
};
