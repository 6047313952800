import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GridList, Toolbar } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Menu, { MenuItem } from '@/design_system/Menu/Menu';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconArchive from '@/icons/Archive.svg';
import IconCheck from '@/icons/Check.svg';
import IconChevron from '@/icons/Chevron.svg';
import IconEmailOpened from '@/icons/EmailOpened.svg';
import IconFilters from '@/icons/Filters.svg';
import IconInbox from '@/icons/Inbox.svg';
import IconMore from '@/icons/More.svg';
import IconUnarchive from '@/icons/Unarchive.svg';
import IconUnread from '@/icons/Unread.svg';
import {
  TNotification,
  TNotificationQueryFilter,
  useArchiveAllNotifications,
  useArchiveAllReadNotifications,
  useInfiniteNotifications,
  useMarkAllNotificationsAsRead,
  useMarkNotificationAsRead,
  useUnarchiveAllNotifications,
} from '@/models/notification';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import { Notification } from './components/Notification/Notification';
import NotificationEmptyState from './components/NotificationEmptyState/NotificationEmptyState';

import './NotificationList.css';

const { block, element } = createBEMClasses('notification-list');

export const NotificationList = ({ onClose }: { onClose?: () => void }) => {
  const { _ } = useLingui();

  const [filter, setFilter] = useState<TNotificationQueryFilter>('readAndUnread');

  const { isMobile } = useViewPort();

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteNotifications({
    filter,
  });

  const notifications = data?.pages.flatMap((page) => page.notifications);

  /**
   * When calling fetchNextPage() while there is no next page, as mentioned in the docs, it can overwrite other fetch requests.
   * In our case, it overwritten the refresh of notifications. So after some actions (like unarchive a notification), the list
   * of notifications wasn't correctly refreshed.
   */
  const fetchNextPageIfPossible = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  return (
    <Stack gap="16px" className={block()}>
      <Stack row className={element('header')}>
        <Stack row gap="8px" alignItems="center">
          <h3 className="paragraph-100-medium">
            <Trans id="notification-list.title">Inbox</Trans>
          </h3>
          <Toolbar>
            <Stack row gap="8px" alignItems="center">
              <FilterContext.Provider value={filter}>
                <FilterMenu setFilter={setFilter} />
              </FilterContext.Provider>

              {filter === 'archivedOnly' ? <UnarchiveAllButton /> : <MoreMenu filter={filter} />}
            </Stack>
          </Toolbar>
        </Stack>

        {!isMobile && (
          <Button
            variant="secondary"
            size="small"
            iconOnly
            onPress={() => onClose?.()}
            ariaLabel={_(msg({ id: 'notification-list.close', message: 'Close' }))}
            style={{ marginLeft: 'auto' }}
          >
            <IconChevron left />
          </Button>
        )}
      </Stack>

      {!notifications?.length && <NotificationEmptyState notificationFilter={filter} />}

      {!!notifications?.length && (
        <Notifications
          notifications={notifications}
          fetchNextPage={fetchNextPageIfPossible}
          isLoading={isFetchingNextPage}
        />
      )}
    </Stack>
  );
};

export const Notifications = ({
  notifications,
  fetchNextPage,
  isLoading,
}: {
  notifications: TNotification[];
  fetchNextPage: () => any;
  isLoading: boolean;
}) => {
  const { _ } = useLingui();
  const loadNextRef = useRef(null);
  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsRead();

  useEffect(() => {
    if (loadNextRef.current === null) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchNextPage();
        }
      },
      {
        // Root is the viewport
        root: null,
        threshold: 1.0,
      }
    );

    observer.observe(loadNextRef.current);

    return () => {
      observer.disconnect();
    };
  }, [fetchNextPage, loadNextRef]);

  return (
    <div style={{ overflow: 'auto' }}>
      <GridList
        aria-label={_(
          msg({
            id: 'notification-list.notification-list',
            message: 'Notification list',
          })
        )}
        onAction={(key) => {
          markNotificationAsRead({ notificationId: key as string });
        }}
        className={element('notifications')}
      >
        {notifications?.map((notification) => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </GridList>
      <div
        ref={loadNextRef}
        style={{
          display: isLoading ? 'none' : undefined,
          position: 'relative',
          bottom: '4rem',
        }}
      />
    </div>
  );
};

/**
 * There is a bug with React-Aria:
 * When a Menu is inside a Dialog, when the Menu is re-rendered,
 * the focus is lost outside the Dialog.
 * To fix this, we memoize the Filter component, and use a Context
 * to pass the value to the MenuItems.
 */
const FilterContext = React.createContext<TNotificationQueryFilter>('readAndUnread');

const FilterMenu = React.memo(function Filter({
  setFilter,
}: {
  setFilter: (filter: TNotificationQueryFilter) => void;
}) {
  const { _ } = useLingui();

  return (
    <Tooltip
      content={_(
        msg({
          id: 'notification-list.filters',
          message: 'Filters',
        })
      )}
      hideOnTouchDevice
    >
      <Menu
        trigger={
          <Button
            iconOnly={true}
            variant="secondary"
            size="small"
            ariaLabel={_(
              msg({
                id: 'notification-list.filters',
                message: 'Filters',
              })
            )}
          >
            <IconFilters />
          </Button>
        }
        placement="bottom left"
        onAction={(action: string) => {
          setFilter(action as TNotificationQueryFilter);
        }}
      >
        <FilterMenuItems />
      </Menu>
    </Tooltip>
  );
});

const FilterMenuItems = () => {
  const filter = useContext(FilterContext);

  return (
    <>
      <FilterMenuItem
        id="readAndUnread"
        label={<Trans id="notification-list.filters.read-and-unread">Read & unread</Trans>}
        isSelected={filter === 'readAndUnread'}
        icon={<IconInbox />}
      />

      <FilterMenuItem
        id="unreadOnly"
        label={<Trans id="notification-list.filters.unread-only">Unread only</Trans>}
        isSelected={filter === 'unreadOnly'}
        icon={<IconUnread />}
      />

      <FilterMenuItem
        id="archivedOnly"
        label={<Trans id="notification-list.filters.archived">Archived</Trans>}
        isSelected={filter === 'archivedOnly'}
        icon={<IconArchive />}
      />
    </>
  );
};

const FilterMenuItem = ({
  id,
  label,
  isSelected,
  icon,
}: {
  id: TNotificationQueryFilter;
  label: React.ReactNode;
  isSelected?: boolean;
  icon: React.ReactNode;
}) => {
  return (
    <MenuItem id={id}>
      <Stack row alignItems="center" justifyContent="space-between">
        <Stack row alignItems="center" gap="0.5rem" style={{ marginRight: '24px' }}>
          {icon}
          {label}
        </Stack>
        {isSelected && <IconCheck />}
      </Stack>
    </MenuItem>
  );
};

const MoreMenu = React.memo(function Options({ filter }: { filter: TNotificationQueryFilter }) {
  const { _ } = useLingui();
  const {
    mutateAsync: markAllNotificationsAsRead,
    isPending: isMarkAllNotificationsAsReadPending,
  } = useMarkAllNotificationsAsRead();
  const { mutateAsync: archiveAllNotifications, isPending: isArchiveAllNotificationsPending } =
    useArchiveAllNotifications();
  const {
    mutateAsync: archiveAllReadNotifications,
    isPending: isArchiveAllReadNotificationsPending,
  } = useArchiveAllReadNotifications();

  return (
    <Tooltip
      content={_(
        msg({
          id: 'notification-list.more',
          message: 'More',
        })
      )}
      hideOnTouchDevice
    >
      <Menu
        trigger={
          <Button
            iconOnly={true}
            variant="secondary"
            size="small"
            ariaLabel={_(
              msg({
                id: 'notification-list.more',
                message: 'More',
              })
            )}
            disabled={
              isMarkAllNotificationsAsReadPending ||
              isArchiveAllNotificationsPending ||
              isArchiveAllReadNotificationsPending
            }
          >
            <IconMore />
          </Button>
        }
        placement="bottom left"
        onAction={(action: string) => {
          if (action === 'markAllAsRead') {
            markAllNotificationsAsRead();
          }
          if (action === 'archiveRead') {
            archiveAllReadNotifications();
          }
          if (action === 'archiveAll') {
            archiveAllNotifications();
          }
        }}
      >
        <MoreMenuItems filter={filter} />
      </Menu>
    </Tooltip>
  );
});

const MoreMenuItems = ({ filter }: { filter: TNotificationQueryFilter }) => {
  return (
    <>
      <MoreMenuItem
        id="markAllAsRead"
        label={<Trans id="notification-list.more.mark-all-as-read">Mark all as read</Trans>}
        icon={<IconEmailOpened />}
      />

      {filter !== 'unreadOnly' && (
        <MoreMenuItem
          id="archiveRead"
          label={<Trans id="notification-list.more.archive-read">Archive read</Trans>}
          icon={<IconArchive />}
        />
      )}

      <MoreMenuItem
        id="archiveAll"
        label={<Trans id="notification-list.more.archive-all">Archive all</Trans>}
        icon={<IconArchive />}
      />
    </>
  );
};

const MoreMenuItem = ({
  id,
  label,
  icon,
}: {
  id: string;
  label: React.ReactNode;
  icon: React.ReactNode;
}) => {
  return (
    <MenuItem id={id}>
      <Stack row alignItems="center" justifyContent="space-between">
        <Stack row alignItems="center" gap="0.5rem" style={{ marginRight: '24px' }}>
          {icon}
          {label}
        </Stack>
      </Stack>
    </MenuItem>
  );
};

const UnarchiveAllButton = () => {
  const { _ } = useLingui();
  const { mutateAsync: unarchiveAllNotifications, isPending: isUnarchiveAllNotificationsPending } =
    useUnarchiveAllNotifications();

  return (
    <Tooltip
      content={_(
        msg({
          id: 'notification-list.unarchive-all',
          message: 'Unarchive all',
        })
      )}
      hideOnTouchDevice
    >
      <Button
        variant="secondary"
        size="small"
        iconOnly
        onPress={() => unarchiveAllNotifications()}
        ariaLabel={_(
          msg({
            id: 'notification-list.unarchive-all',
            message: 'Unarchive all',
          })
        )}
        disabled={isUnarchiveAllNotificationsPending}
      >
        <IconUnarchive />
      </Button>
    </Tooltip>
  );
};
