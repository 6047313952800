import { Trans } from '@lingui/macro';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import { CreationStepConfig } from '@/api';
import Loader from '@/components/Loader';
import { DrawerHeader } from '@/design_system/Drawer/Drawer';
import Stack from '@/design_system/Stack';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import ArticlePrice from '@/routes/Requests/Request/Article/ArticlePrice';

const NewArticleHeader = ({
  request,
  article,
  isEditing,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  isEditing: boolean;
}) => {
  const isFetching = useIsFetching({ queryKey: ['requests', request.id] });
  const isMutating = useIsMutating({ mutationKey: ['articles', article.id] });

  const name = useArticleName({ article });

  const creationConfig = article.step?.config as CreationStepConfig['config'] | undefined;

  return (
    <DrawerHeader>
      <Stack gap="0.5rem" alignItems="flex-start">
        <Stack row gap="16px" alignItems="center">
          <h1 className="headline-200-bold text-ellipsis">
            {isEditing ? (
              <Trans id="requests.new.articles.drawer.title-edit">Edit {name}</Trans>
            ) : (
              <Trans id="requests.new.articles.drawer.title">Add an item</Trans>
            )}
          </h1>
          {(isFetching > 0 || isMutating > 0) && (
            <Loader style={{ fontSize: '1.25rem' }} delay={500} />
          )}
        </Stack>
        <ArticlePrice
          article={article}
          request={request}
          showWorkshopPrice={creationConfig?.requireCost}
          showOrganizationPrice={creationConfig?.requirePrice}
          allowDiscount={creationConfig?.allowDiscount ?? false}
        />
      </Stack>
    </DrawerHeader>
  );
};

export default NewArticleHeader;
