import { PropsWithChildren } from 'react';

import { Organization } from '@/models/organization';
import { createBEMClasses } from '@/utils/classname';

import './Header.css';

const { block, element } = createBEMClasses('client-header');

const Header = ({
  organization,
  children,
}: PropsWithChildren<{ organization?: Organization | null }>) => {
  return (
    <header className={block()}>
      <div
        className={element('brand-color')}
        style={{ background: organization?.config.appStyle.headerColor }}
      />
      <div className={element('content')}>{children}</div>
    </header>
  );
};

export default Header;
