import cn from 'classnames';

interface LogoProps {
  size?: 'small' | 'large';
  className?: string;
}

const Logo = ({ size = 'large', className }: LogoProps) => {
  if (size === 'small') {
    return (
      <svg
        className={cn('logo small', className)}
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6189 14H46.3811L46.3828 14.0017C48.8654 14.0017 51.2027 14.4611 53.3299 15.3643C55.4537 16.2675 57.3434 17.5541 58.946 19.1912C60.547 20.8284 61.8045 22.7539 62.681 24.9178C63.5558 27.0816 64 29.4977 64 32.0017C64 34.5058 63.5558 36.8804 62.681 39.058C61.8045 41.2375 60.547 43.1734 58.9443 44.8105C57.34 46.4477 55.4503 47.736 53.3282 48.6374C51.2027 49.5424 48.8654 50 46.3811 50H17.6189C15.1346 50 12.7973 49.5406 10.6718 48.6374C8.54632 47.736 6.65663 46.4477 5.05569 44.8105C3.45133 43.1716 2.19382 41.2357 1.31902 39.058C0.444231 36.8838 0 34.5421 0 32.0017C0 29.4614 0.444231 27.0782 1.32073 24.9161C2.19553 22.7556 3.44279 20.8301 5.02835 19.1947C6.61733 17.5541 8.50873 16.2658 10.6513 15.3608C12.7921 14.4576 15.1363 14 17.6189 14ZM17.6189 40.8385H46.3811L46.3794 40.8368C48.0196 40.8368 49.3797 40.45 50.5364 39.6521C51.7153 38.8387 52.5935 37.787 53.2206 36.4348C53.8562 35.0653 54.1774 33.5387 54.1774 31.9016C54.1774 30.2661 53.8562 28.7499 53.2223 27.3977C52.5952 26.0627 51.7187 25.0266 50.5432 24.2287C49.3865 23.4447 48.0231 23.063 46.3794 23.063H17.6172C15.9718 23.063 14.6101 23.4447 13.4534 24.2287C12.2727 25.0283 11.3877 26.0679 10.747 27.408C10.1011 28.7568 9.77308 30.2661 9.77308 31.9033C9.77308 33.5404 10.1028 35.0619 10.7504 36.4279C11.3911 37.7818 12.2779 38.8387 13.4619 39.6538C14.6186 40.4517 15.9769 40.8385 17.6189 40.8385Z"
          fill="#3ED99B"
        />
      </svg>
    );
  }

  return (
    <svg
      className={cn('logo large', className)}
      width="434"
      height="53"
      viewBox="0 0 434 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5638_185)">
        <path
          d="M0 51.7166V1.35791H18.5687C22.7975 1.35791 26.2223 2.12498 28.8481 3.66161C31.4713 5.19824 33.3935 7.19908 34.6172 9.66414C35.8409 12.1317 36.4514 14.773 36.4514 17.5906C36.4514 21.1653 35.6373 24.1715 34.0066 26.6117C32.3759 29.0544 30.2476 30.8914 27.6244 32.1227C25.0012 33.3565 22.1618 33.9721 19.1039 33.9721H11.2317V51.7141H0V51.7166ZM11.2317 24.7623H17.9581C20.0462 24.7623 21.7271 24.1094 23.0011 22.7987C24.275 21.4905 24.9107 19.8297 24.9107 17.8165C24.9107 15.7039 24.2725 13.9786 23.0011 12.6455C21.7271 11.3125 20.021 10.6447 17.8802 10.6447H11.2317V24.7623Z"
          fill="#131514"
        />
        <path
          d="M46.691 51.7166V1.35791H65.6441C69.6167 1.35791 72.9284 2.07533 75.5768 3.51018C78.2251 4.94503 80.2126 6.85899 81.5368 9.24709C82.861 11.6377 83.5244 14.2691 83.5244 17.1363C83.5244 20.3585 82.7982 23.2009 81.3459 25.6659C79.8935 28.1335 77.8432 30.0971 75.1948 31.5543L86.5044 51.7116H73.8179L64.3426 33.215H57.9227V51.7116H46.6885L46.691 51.7166ZM57.9252 24.0846H65.031C67.1693 24.0846 68.8528 23.4566 70.074 22.198C71.2951 20.9394 71.9082 19.3556 71.9082 17.4416C71.9082 15.3787 71.2826 13.7428 70.0363 12.5338C68.7875 11.3249 67.1191 10.7217 65.031 10.7217H57.9252V24.0846Z"
          fill="#131514"
        />
        <path
          d="M117.375 53C113.603 53 110.116 52.3322 106.904 50.9992C103.696 49.6661 100.879 47.7918 98.4618 45.374C96.0421 42.9585 94.1676 40.1385 92.8459 36.9188C91.5217 33.6991 90.8584 30.2261 90.8584 26.5C90.8584 22.7242 91.5192 19.2414 92.8459 16.044C94.1701 12.8491 96.0295 10.0415 98.4241 7.62604C100.819 5.21063 103.633 3.33639 106.869 2.00084C110.103 0.667775 113.603 0 117.375 0C121.144 0 124.634 0.667775 127.843 2.00084C131.051 3.33639 133.868 5.21063 136.288 7.62604C138.707 10.0415 140.579 12.8491 141.903 16.044C143.228 19.2414 143.891 22.7242 143.891 26.5C143.891 30.2261 143.228 33.6966 141.903 36.9188C140.579 40.141 138.707 42.9585 136.288 45.374C133.868 47.7894 131.054 49.6661 127.843 50.9992C124.634 52.3322 121.144 53 117.375 53ZM117.375 42.2808C120.38 42.2808 122.965 41.551 125.131 40.0913C127.297 38.6317 128.953 36.6954 130.099 34.2775C131.245 31.8621 131.818 29.2183 131.818 26.3511C131.818 23.4814 131.245 20.8525 130.099 18.4619C128.953 16.0713 127.297 14.1573 125.131 12.7225C122.965 11.2876 120.38 10.5702 117.375 10.5702C114.37 10.5702 111.781 11.2876 109.618 12.7225C107.452 14.1573 105.784 16.0713 104.613 18.4619C103.439 20.8525 102.854 23.4814 102.854 26.3511C102.854 29.2207 103.439 31.8621 104.613 34.2775C105.784 36.6929 107.452 38.6317 109.618 40.0913C111.781 41.551 114.367 42.2808 117.375 42.2808Z"
          fill="#131514"
        />
        <path
          d="M153.518 51.7166V1.35791H165.056V41.5982H189.434V51.7141H153.518V51.7166Z"
          fill="#131514"
        />
        <path
          d="M331.065 51.7166V1.36035H342.068L364.155 34.0515V1.36035H375.54V51.7166H365.072L342.453 18.4221V51.7166H331.068H331.065Z"
          fill="#131514"
        />
        <path
          d="M414.361 33.2199V24.0076H434V45.8282C431.349 48.0426 428.256 49.7803 424.716 51.0364C421.175 52.295 417.137 52.923 412.604 52.923C407.051 52.923 402.287 51.8035 398.315 49.5643C394.342 47.3251 391.297 44.2295 389.184 40.2775C387.068 36.3279 386.013 31.7851 386.013 26.6514C386.013 22.8756 386.648 19.3779 387.922 16.1557C389.196 12.936 391.005 10.1159 393.347 7.70051C395.689 5.2851 398.478 3.40837 401.714 2.07531C404.948 0.742238 408.501 0.0744629 412.373 0.0744629C417.009 0.0744629 421.085 0.916008 424.6 2.60406C428.115 4.28964 430.992 6.64299 433.236 9.66163L425.442 16.9848C423.861 15.0709 422.08 13.5119 420.092 12.3029C418.105 11.094 415.685 10.4908 412.833 10.4908C409.725 10.4908 407.064 11.2206 404.848 12.6803C402.632 14.1399 400.935 16.0911 399.767 18.5314C398.594 20.9741 398.008 23.6775 398.008 26.6489C398.008 29.8687 398.684 32.6763 400.033 35.0669C401.383 37.4575 403.204 39.3069 405.499 40.6151C407.79 41.9234 410.388 42.5787 413.293 42.5787C415.331 42.5787 417.19 42.3404 418.871 41.8613C420.552 41.3847 422.055 40.6151 423.379 39.5576V33.2175H414.361V33.2199Z"
          fill="#131514"
        />
        <path
          d="M318.435 16.116C317.146 13.0055 315.296 10.2375 312.942 7.8842C310.585 5.53085 307.806 3.68144 304.683 2.38064C301.555 1.08233 298.117 0.421997 294.466 0.421997H222.016C218.367 0.421997 214.917 1.07984 211.769 2.37816C208.618 3.67895 205.837 5.53085 203.5 7.88916C201.168 10.24 199.334 13.0079 198.047 16.1135C196.758 19.219 196.107 22.6472 196.107 26.2964C196.107 29.9456 196.761 33.3143 198.047 36.4397C199.334 39.57 201.183 42.3528 203.542 44.7087C205.897 47.062 208.676 48.9139 211.802 50.2097C214.927 51.508 218.365 52.1684 222.018 52.1684H294.469C298.122 52.1684 301.56 51.5105 304.685 50.2097C307.806 48.9139 310.585 47.062 312.944 44.7087C315.301 42.3553 317.151 39.5725 318.44 36.4397C319.726 33.3093 320.38 29.896 320.38 26.2964C320.38 22.6969 319.726 19.224 318.44 16.1135L318.435 16.116ZM211.91 19.6981C212.852 17.7717 214.153 16.2773 215.89 15.1279C217.591 14.0009 219.593 13.4523 222.013 13.4523H294.464C296.881 13.4523 298.886 14.0009 300.587 15.1279C302.316 16.2748 303.605 17.7643 304.527 19.6832C305.459 21.627 305.932 23.8065 305.932 26.1574C305.932 28.5108 305.459 30.7052 304.525 32.6738C303.602 34.6175 302.311 36.1294 300.577 37.2986C298.876 38.4455 296.876 39.0015 294.464 39.0015H222.013C219.598 39.0015 217.601 38.4455 215.9 37.2986C214.159 36.1269 212.854 34.6076 211.912 32.6614C210.96 30.6978 210.475 28.5108 210.475 26.1574C210.475 23.8041 210.957 21.6344 211.907 19.6956L211.91 19.6981Z"
          fill="#3ED99B"
        />
      </g>
      <defs>
        <clipPath id="clip0_5638_185">
          <rect width="434" height="53" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
