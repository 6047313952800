import { useEffect } from 'react';

import Stack from '@/design_system/Stack';
import { BrandWrapper, BrandWrapperCenter } from '@/layouts/Brand';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import { captureException } from '@/services/sentry';

const UnhandledStep = ({ request }: { request: ClientRequestWithRelations }) => {
  useEffect(() => {
    captureException(
      new Error(
        // eslint-disable-next-line lingui/no-unlocalized-strings
        `Unhandled step for request ${request.id}: ${request.articles
          ?.map((article) => `${article.id} - ${article.step?.step}`)
          .join(', ')}`
      )
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrandWrapper>
      <BrandWrapperCenter>
        <Stack gap="1rem">
          <ClientArticlesTable request={request} showPrice={!!request.client} showArticleComment />
          {!!request.client && <ClientInfo request={request} canEdit={false} />}
        </Stack>
      </BrandWrapperCenter>
    </BrandWrapper>
  );
};

export default UnhandledStep;
