import React from 'react';
import {
  Label,
  Radio as AriaRadio,
  RadioGroup as AriaRadioGroup,
  RadioGroupProps as AriaRadioGroupProps,
  RadioProps as AriaRadioProps,
} from 'react-aria-components';
import { msg } from '@lingui/macro';

import Stack from '@/design_system/Stack';
import IconRadio from '@/icons/Radio.svg';
import { createBEMClasses } from '@/utils/classname';

import './BasicRadioGroup.css';

const { block, element } = createBEMClasses('basic-radio-group');

interface BasicRadioGroupProps extends AriaRadioGroupProps {
  label?: React.ReactNode;
  size?: 'small' | 'large';
  align?: 'left' | 'right';
  row?: boolean;
  boxed?: boolean;
  options: RadioProps[];
  className?: string;
}

interface RadioProps extends AriaRadioProps {
  size?: 'small' | 'large';
  align?: 'left' | 'right';
  boxed?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const BasicRadioGroup = ({
  label,
  row = true,
  size = 'large',
  align = 'left',
  boxed,
  options,
  className,
  ...props
}: BasicRadioGroupProps) => (
  <AriaRadioGroup {...props} className={block({}, className)}>
    {!!label && <Label className="label-100 text-primary">{label}</Label>}
    <Stack row={row} className={element('options', { row, size, align })}>
      {options.map((option, index) => (
        <AriaRadio
          key={index}
          {...option}
          className={element(
            'option',
            {
              size: option.size ?? size,
              align: option.align ?? align,
              boxed: option.boxed ?? boxed,
            },
            className
          )}
        >
          {({ isSelected }) => (
            <>
              <IconRadio selected={isSelected} />
              {option.children}
            </>
          )}
        </AriaRadio>
      ))}
    </Stack>
  </AriaRadioGroup>
);

export const yesNoRadioOptions = [
  { value: 'no', children: msg({ id: 'design-system.radio-group.yes-no.no', message: 'No' }) },
  { value: 'yes', children: msg({ id: 'design-system.radio-group.yes-no.yes', message: 'Yes' }) },
];

export default BasicRadioGroup;
