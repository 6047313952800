import { useContext } from 'react';
import { Trans } from '@lingui/macro';

import Stack from '@/design_system/Stack';
import IconAddToList from '@/icons/AddToList.svg';
import { ActionTypeOrganizationWithRelations } from '@/models/actionType';
import { useCurrentSession } from '@/services/auth';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import ActionName from './ActionName';
import { ActionTypeSearchSelectContext } from './ActionTypeSearchSelect';

export const ActionDropdownItem = ({ action }: { action: ActionTypeOrganizationWithRelations }) => {
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  const { showWorkshopPrice, showOrganizationPrice } = useContext(ActionTypeSearchSelectContext);

  return (
    <Stack
      row={!isMobile}
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent="space-between"
      gap="0.5rem"
      flexWrap="nowrap"
      style={{ flex: 1 }}
    >
      <ActionName
        actionType={action.actionType}
        refashionStatus={
          !!action.refashionBonus && !currentSession?.workshop ? 'applied' : undefined
        }
      />
      <Stack
        row
        alignItems="center"
        gap="0.5rem"
        flexWrap="nowrap"
        style={{ alignSelf: isMobile ? 'flex-end' : 'center' }}
      >
        {showWorkshopPrice && (
          <p className="paragraph-100-medium">
            {formatCurrency(action.dynamicCost?.amount, action.dynamicCost?.currency)}
          </p>
        )}
        {showOrganizationPrice && (
          <p className="paragraph-100-medium">
            {formatCurrency(action.dynamicPrice?.amount, action.dynamicPrice?.currency)}
          </p>
        )}
      </Stack>
    </Stack>
  );
};

export const CustomActionDropdownItem = () => {
  return (
    <Stack row gap="0.5rem" alignItems="center" style={{ flex: 1 }}>
      <IconAddToList style={{ fontSize: '1.25rem' }} />
      <p className="paragraph-100-regular">
        <Trans id="article.form.actions.add-custom-action">Add a custom action</Trans>
      </p>
    </Stack>
  );
};
