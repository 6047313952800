import IconWrapper from '@/icons/IconWrapper';

const IconFabricMaterials = IconWrapper({
  name: 'fabric-materials',
  source: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
    >
      <g className="fabric">
        <path
          fill="#C6C6C6"
          d="M89.5 62.437c5.33-3.141 12.457-2.618 19.57 4.489 6.888 6.883 8.641 16.948 5.26 25.443a23.535 23.535 0 0 1-5.26 7.984c-6.538 6.533-15.376 9.364-23.908 8.493l.003-.018a29.438 29.438 0 0 1-17.896-8.497c-4.61-4.605-12.847-2.558-12.847-2.558L12 55.383s8.238-2.046 12.848 2.56c8.946 8.95 22.218 10.953 33.104 6.02l24.028 24.01c-5.566-5.925-5.453-15.25.338-21.037a18.844 18.844 0 0 1 7.183-4.499Z"
          className="fill-grey"
        />
        <path
          fill="#fff"
          d="M66.648 24.537c-7.388-7.383-19.364-7.383-26.752 0-5.906 5.901-5.906 15.483 0 21.385l18.056 18.042 24.366 24.347c-5.906-5.902-5.906-15.484 0-21.385a19.02 19.02 0 0 1 1.21-1.1c7.431-6.25 18.546-5.89 25.542 1.1l-42.422-42.39Z"
          className="fill-accent"
        />
        <path
          fill="#575756"
          fillRule="evenodd"
          d="M67.355 23.83c-7.779-7.773-20.387-7.773-28.166 0-6.297 6.292-6.297 16.507 0 22.8l17.005 16.99c-10.25 4.023-22.363 1.895-30.64-6.386-2.57-2.568-6.093-3.232-8.834-3.299a21.497 21.497 0 0 0-4.554.388 12.435 12.435 0 0 0-.373.081l-.023.005-.007.002-.004.001a1 1 0 0 0-.466 1.678l42.422 42.39a1 1 0 0 0 .947.263h.002l.012-.003a6.646 6.646 0 0 1 .305-.066c.218-.044.54-.102.94-.16a19.5 19.5 0 0 1 3.172-.19c2.53.062 5.43.677 7.47 2.715a30.437 30.437 0 0 0 18.304 8.763c.062.019.126.032.193.039 8.819.9 17.957-2.026 24.716-8.78a24.523 24.523 0 0 0 5.47-8.29 24.676 24.676 0 0 0-.005-18.287l-.008.008a24.555 24.555 0 0 0-5.372-8.178.975.975 0 0 0-.085-.096L67.355 23.83Zm15.748 63.86a1.056 1.056 0 0 0-.078-.087 14.07 14.07 0 0 1-4.137-9.975 14.07 14.07 0 0 1 4.137-9.984c6.997-6.992 18.34-6.992 25.337 0 6.587 6.588 8.265 16.222 5.037 24.358a22.504 22.504 0 0 1-5.035 7.621l-.001.001c-11.15 11.141-29.238 11.141-40.387 0-2.57-2.569-6.093-3.232-8.835-3.299a21.499 21.499 0 0 0-4.396.357l-40.661-40.63c.726-.081 1.617-.14 2.587-.116 2.53.061 5.43.677 7.47 2.714 9.077 9.08 22.458 11.243 33.588 6.504L81.26 88.668l.001.002c.114.12.23.24.349.359 5.12 5.116 13.413 5.116 18.533 0 4.172-4.17 4.172-10.933 0-15.102a8.752 8.752 0 0 0-12.376 0c-2.811 2.809-2.809 7.361-.002 10.179l.002.001a5.962 5.962 0 0 0 8.431 0 4.967 4.967 0 0 0 0-7.022l-3.589-3.586a6.746 6.746 0 0 1 6.12 1.843 8.675 8.675 0 0 1 0 12.272c-4.306 4.302-11.26 4.335-15.606.098l-.021-.021Zm-6.215-10.06a16.065 16.065 0 0 1 4.723-11.412 20 20 0 0 1 1.272-1.157c5.48-4.609 12.861-5.805 19.323-3.58L65.941 25.244c-6.997-6.992-18.34-6.992-25.338 0-5.515 5.511-5.515 14.46 0 19.97l18.05 18.037a.257.257 0 0 1 .006.006L77.517 82.1a16.18 16.18 0 0 1-.629-4.47Zm13.673-3.352a6.77 6.77 0 0 0-1.38 1.064 5.2 5.2 0 0 0 .001 7.352 3.962 3.962 0 0 0 5.603-.002 2.967 2.967 0 0 0 0-4.192l-4.224-4.222Z"
          className="stroke"
          clipRule="evenodd"
        />
      </g>
    </svg>
  ),
});

export default IconFabricMaterials;
