import { useEffect, useRef } from 'react';
import { Plural, Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconPackage from '@/icons/Package.svg';
import IconPrint from '@/icons/Print.svg';
import IconTruck from '@/icons/Truck.svg';
import { BrandWrapper, BrandWrapperCenter } from '@/layouts/Brand';
import { ClientArticleWithRelations, ClientRequestWithRelations } from '@/models/request';
import { useShipments } from '@/models/shipment';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import IconSuccess from '@/routes/Brand/Requests/Request/components/shared/IconSuccess';
import { PickupPointInfo } from '@/routes/Brand/Requests/Request/components/shared/PickupPointInfo/PickupPointInfo';
import { useBlobUrl } from '@/utils/useBlobUrl';

const Transit = ({
  request,
  downloadShippingLabel,
}: {
  request: ClientRequestWithRelations;
  downloadShippingLabel: boolean;
}) => {
  const activeArticlesInTransit = request.articles.filter(
    (article) => article.step?.step === 'transit' && !article.cancelledAt
  );
  const isPendingTransit = activeArticlesInTransit.some(
    (article) => article.atClient || !!article.atStoreId
  );

  return (
    <BrandWrapper>
      <BrandWrapperCenter>
        <Stack gap="1rem">
          <ClientStepper request={request} />
          {isPendingTransit && (
            <PendingTransitHeader
              request={request}
              downloadShippingLabel={downloadShippingLabel}
              articlesInTransit={activeArticlesInTransit}
            />
          )}
          {!isPendingTransit && <InTransitHeader articlesInTransit={activeArticlesInTransit} />}
          <PickupPointInfo request={request} />
          <ClientArticlesTable request={request} showPrice showArticleComment />
          <ClientInfo request={request} />
        </Stack>
      </BrandWrapperCenter>
    </BrandWrapper>
  );
};

const PendingTransitHeader = ({
  request,
  downloadShippingLabel,
  articlesInTransit,
}: {
  request: ClientRequestWithRelations;
  downloadShippingLabel: boolean;
  articlesInTransit: ClientArticleWithRelations[];
}) => {
  const ref = useRef<HTMLButtonElement>();
  const { data: { shipments } = { shipments: [] }, isLoading: isLoadingShipment } = useShipments({
    requestId: request.id,
  });

  const shippingLabelUrl = useBlobUrl(shipments[0]?.shippingLabel, 'application/pdf');

  // Automatically download the shipping label if needed
  useEffect(() => {
    if (downloadShippingLabel && shippingLabelUrl) {
      ref.current?.click();
    }
  }, [downloadShippingLabel, shippingLabelUrl, ref]);

  return (
    <Stack gap="1rem">
      <Stack gap="0.25rem">
        {request.store && (
          <>
            <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap">
              <div>
                <IconSuccess />
              </div>
              <p className="headline-200-bold headline-300-bold-mobile color-primary-800">
                <Trans id="client.request.transit.pending.title">
                  Your care & repair service is confirmed
                </Trans>
              </p>
            </Stack>
            <p className="paragraph-50-regular paragraph-100-regular-mobile">
              <Trans id="client.request.transit.pending.store-shipment">
                Your <Plural value={articlesInTransit.length} one="item" other="items" /> will be
                sent to the repair workshop by the {request.store.name} store.
              </Trans>
            </p>
          </>
        )}
        {!request.store && (
          <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap">
            <div>
              <IconPackage className="headline-200-medium color-tertiary-700" />
            </div>
            <p className="paragraph-50-regular paragraph-100-regular-mobile">
              <Trans id="client.request.transit.pending.shipment-options.title">
                Please send your package
              </Trans>
            </p>
          </Stack>
        )}
      </Stack>
      {!request.store && (
        <Button
          ref={ref}
          variant="secondary-brand"
          size="large"
          disabled={isLoadingShipment || shipments.length === 0}
          href={shippingLabelUrl!}
          download={`${shipments[0]?.reference}.pdf`}
        >
          <IconPrint />
          <Trans id="client.request.transit.pending.shipment-option.client.action">
            Print shipping label
          </Trans>
        </Button>
      )}
    </Stack>
  );
};

const InTransitHeader = ({
  articlesInTransit,
}: {
  articlesInTransit: ClientArticleWithRelations[];
}) => {
  return (
    <>
      <Stack row gap="0.5rem">
        <IconTruck className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="client.request.transit.in-transit.store-shipment">
            Your{' '}
            <Plural
              value={articlesInTransit.length}
              one="item is on its"
              other="items are on their"
            />{' '}
            way to the workshop to be taken care of.
          </Trans>
        </p>
      </Stack>
    </>
  );
};

export default Transit;
