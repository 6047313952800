import { Trans } from '@lingui/macro';

export const GenericError = () => {
  return (
    <p className="paragraph-100-regular text-primary">
      <Trans id="_general.error.please-refresh">
        An error has occurred. Please {/* eslint-disable-next-line */}
        <a
          onClick={() => window.location.reload()}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          refresh the page
        </a>{' '}
        and try again.
      </Trans>
    </p>
  );
};
