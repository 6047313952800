import { Dispatch, SetStateAction, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocalStorageState } from '@/services/localStorage';

export const useSearchParamState = <T extends string | number | string[]>(
  group: string,
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>, [string, T, T]] => {
  const searchParams = new URLSearchParams(window.location.search);

  let initialValue = defaultValue;

  if (Array.isArray(defaultValue) && searchParams.getAll(`${key}[]`)) {
    initialValue = searchParams.getAll(`${key}[]`) as T;
  } else if (searchParams.get(key)) {
    if (typeof defaultValue === 'number') {
      initialValue = Number(searchParams.get(key)) as T;
    } else {
      initialValue = searchParams.get(key) as T;
    }
  }

  const [state, setState] = useLocalStorageState<T>(
    `search-param:${group}:${key}`,
    initialValue,
    !!window.location.search
  );

  return [state, setState, [key, state, defaultValue]];
};

/**
 * Important: search params are subject to race conditions.
 * Make sure this hook is used only once per page.
 */
export const useSyncSearchParams = (
  syncParams: ([string, number, number] | [string, string, string] | [string, string[], string[]])[]
) => {
  const navigate = useNavigate();

  const searchParams: string[] = [];

  syncParams
    .filter(([, value, defaultValue]) => value !== defaultValue)
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => searchParams.push(`${key}[]=${item}`));
      } else if (value !== undefined) {
        searchParams.push(`${key}=${value.toString()}`);
      }
    });

  const searchParamsString = searchParams.join('&');

  useEffect(() => {
    // TODO: this is a hack to prevent the search params from being added to the URL when creating a new request/shipment
    // We should find a better way to do this
    if (window.location.pathname.endsWith('/new')) {
      return;
    }
    navigate(`?${searchParamsString}`, { replace: true });
  }, [navigate, searchParamsString]);
};
