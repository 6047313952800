import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Stack from '@/design_system/Stack';
import IconStar from '@/icons/Star.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

import { Comment } from './components/Comment';

export const NewClientFeedbackNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'new_client_feedback'>;
}) => {
  const { _ } = useLingui();

  return (
    <BaseNotification
      notificationLabel={_(
        msg({
          id: 'notification.new-client-feedback.label',
          message: `Client has left a review for request ${notification.request.reference}`,
        })
      )}
      icon={<IconStar />}
      type="new"
      notification={notification}
      notificationHeader={
        <Trans id="notification.new-client-feedback">The client has left a review</Trans>
      }
      notificationContent={
        notification.data.feedback ? (
          <Stack gap="0.5rem">
            <Stack
              row
              style={{
                color: 'var(--color-warning-500)',
              }}
            >
              {([1, 2, 3, 4, 5] as const).map((rating) => (
                <IconStar key={rating} filled={rating <= notification.data.feedback!.global} />
              ))}
            </Stack>

            {!!notification.data.feedback.comment && (
              <Comment comment={notification.data.feedback.comment} />
            )}
          </Stack>
        ) : undefined
      }
      scrollTo="activity-section"
    />
  );
};
