import { useState } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import IconEdit from '@/icons/Edit.svg';

import { UserDialog, UserWithRoles } from './UserDialog';

export const UpdateUserRoles = ({ user }: { user: UserWithRoles }) => {
  const { _ } = useLingui();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="secondary"
        iconOnly
        size="small"
        ariaLabel={_(
          msg({
            id: 'settings.users.update-modal.trigger',
            message: `Manage ${user.name ?? user.email}`,
          })
        )}
        tooltip={
          <p className="sentry-mask">
            {_(
              msg({
                id: 'settings.users.update-modal.trigger',
                message: `Manage ${user.name ?? user.email}`,
              })
            )}
          </p>
        }
        onPress={() => setIsOpen(true)}
      >
        <IconEdit />
      </Button>
      <UserDialog isOpen={isOpen} setIsOpen={setIsOpen} user={user} />
    </>
  );
};
