import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ActionTypeSearchSelect from '@/components/ActionTypeSearchSelect';
import { ArticleServices } from '@/components/ArticleServices';
import { ArticleStepper } from '@/components/ArticleStepper/ArticleStepper';
import Loader from '@/components/Loader';
import {
  PageLayout,
  PageLayoutCenter,
  PageLayoutContent,
  PageLayoutHeader,
  PageLayoutLeftPart,
  PageLayoutLeftPartContent,
  PageLayoutRightPart,
  PageLayoutTopPart,
} from '@/components/PageLayout';
import AlertBar from '@/design_system/AlertBar';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconEdit from '@/icons/Edit.svg';
import IconPlace from '@/icons/Place.svg';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations, useRequest } from '@/models/request';
import { ArticleDispatch } from '@/routes/Requests/components/ArticleDispatch';
import ClientComment from '@/routes/Requests/Request/Article/ClientComment';
import RequalificationComment from '@/routes/Requests/Request/Article/RequalificationComment';
import Warranty from '@/routes/Requests/Request/Article/Warranty';
import { ArticleActivities } from '@/routes/Requests/Request/components/Activities/ArticleActivities';
import { RequestBreadcrumb } from '@/routes/Requests/Request/components/RequestBreadcrumb/RequestBreadcrumb';
import { RequestComments } from '@/routes/Requests/Request/components/RequestComments';
import { RequestContextProvider } from '@/routes/Requests/RequestContext';
import { useCurrentSession, useFlags } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { useResetSimpleBlocker } from '@/utils/navigation';
import { refreshBrowserTabTitle } from '@/utils/refreshBrowserTabTitle';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import ArticleActions from './components/ArticleActions';
import ArticleTaskOrStepMessage from './components/ArticleTaskOrStepMessage';
import { ArticleHeader } from './ArticleHeader';
import { ArticleInfoSidePanel, ArticleInfoTopPanel } from './ArticleInfo';
import { useArticleErrors } from './useArticleErrors';

import './Article.css';

const { block, element } = createBEMClasses('article');

interface ArticleContextData {
  article: ArticleWithRelations;
  isOpenArticleInfo: boolean;
  setIsOpenArticleInfo: Dispatch<SetStateAction<boolean>>;
  allowWarranty: boolean;
}

export const ArticleContext = createContext({} as ArticleContextData);

export const Article = () => {
  const navigate = useNavigate();
  const { flags } = useFlags();
  const { isMobile } = useViewPort();
  const { _ } = useLingui();
  const { requestId, articleId } = useParams();
  const { data: request, isLoading } = useRequest(requestId);
  const { currentSession } = useCurrentSession();
  const [commentsRef, scrollCommentsIntoView] = useScrollIntoView<HTMLDivElement>();
  const article = request?.allArticles.find(({ id }) => id === articleId);

  const articleName = useArticleName({ article });

  // Display the request reference in the browser tab title
  useEffect(() => {
    if (!request) {
      return;
    }

    const requestDetails = request.client?.name ?? request.store?.name;
    const requestReference = requestDetails
      ? `${request.reference} - ${articleName}`
      : request.reference;

    document.querySelector('meta[name="subtitle"]')?.setAttribute('content', requestReference);
    refreshBrowserTabTitle();

    return () => {
      document.querySelector('meta[name="subtitle"]')?.setAttribute('content', '');
      refreshBrowserTabTitle();
    };
  }, [request, articleName]);

  // Clear router blocker state after coming from requests/new
  useResetSimpleBlocker();

  const isOrganizationUser = currentSession?.organizations?.some(
    (organization) => organization.id === article?.organizationId
  );

  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();
  const [showErrors, setShowErrors] = useState(false);

  // 1st time the errors should be shown, the alert bar isn't visible yet that's why we need
  // this useEffect
  useEffect(() => {
    if (showErrors) {
      scrollAlertIntoView({
        block: 'center',
      });
    }
  }, [showErrors, scrollAlertIntoView]);

  const [isOpenArticleInfo, setIsOpenArticleInfo] = useState(false);

  const {
    productError,
    otherBrandError,
    actionsError,
    photosError,
    proofOfPurchaseError,
    workshopError,
    customsError,
  } = useArticleErrors(article, request);

  if (isLoading) {
    return (
      <PageLayoutCenter>
        <Loader style={{ height: '40px', width: '40px' }} />
        <p className="paragraph-100-regular">
          <Trans id="_general.loading">Loading...</Trans>
        </p>
      </PageLayoutCenter>
    );
  }

  if (!request || !article) {
    return (
      <PageLayoutCenter>
        <p className="paragraph-100-regular">
          <Trans id="article.unknown">Unknown article</Trans>
        </p>
      </PageLayoutCenter>
    );
  }

  const allowWarranty =
    article.steps?.some(
      (step) =>
        ['done', 'in-progress'].includes(step.status) &&
        !!step.config &&
        'allowWarranty' in step.config &&
        step.config.allowWarranty
    ) ?? false;

  const chooseArticleServiceError =
    !!productError ||
    !!otherBrandError ||
    !!proofOfPurchaseError ||
    !!photosError ||
    !!actionsError ||
    !!workshopError ||
    !!customsError
      ? _(
          msg({
            id: 'article.service-choice.error',
            message: 'Please fill the missing information in order to validate the service choice',
          })
        )
      : undefined;

  const acceptRequalificationError = actionsError
    ? _(
        msg({
          id: 'article.accept-requalification.error',
          message: 'Please fill the missing information in order to accept the requalification',
        })
      )
    : undefined;

  const taskError = {
    choose_article_service: chooseArticleServiceError,
    accept_requalification: acceptRequalificationError,
  }[article.task?.type as 'choose_article_service' | 'accept_requalification'];

  const checkErrors = () => {
    setShowErrors(true);

    if (taskError) {
      scrollAlertIntoView({
        block: 'center',
      });
    }

    return !!taskError;
  };

  return (
    <RequestContextProvider request={request}>
      <ArticleContext.Provider
        value={{
          article,
          isOpenArticleInfo,
          setIsOpenArticleInfo,
          allowWarranty,
        }}
      >
        <PageLayout className={block()}>
          <PageLayoutHeader>
            <ArticleHeader
              request={request}
              article={article}
              onCommentButtonPress={scrollCommentsIntoView}
            />
          </PageLayoutHeader>

          <PageLayoutContent>
            <PageLayoutLeftPart>
              <PageLayoutLeftPartContent>
                {isMobile && (
                  <PageLayoutTopPart>
                    <ErrorAlertBar alertRef={alertRef} error={showErrors ? taskError : undefined} />
                    <CustomsAlertBar />
                    <ArticleInfoTopPanel
                      article={article}
                      request={request}
                      showErrors={showErrors}
                    />
                  </PageLayoutTopPart>
                )}

                {!isMobile && (
                  <>
                    <ErrorAlertBar alertRef={alertRef} error={showErrors ? taskError : undefined} />
                    <CustomsAlertBar />
                    <RequestBreadcrumb
                      request={request}
                      article={article}
                      onCommentButtonPress={scrollCommentsIntoView}
                    />
                  </>
                )}

                <Stack className={element('main')}>
                  <Stack gap="1rem">
                    <ArticleStepper
                      size={isMobile ? 'small' : 'large'}
                      showStepCount={isMobile}
                      article={article}
                      mode="step"
                      labelVariant={undefined}
                    />
                    <ArticleTaskOrStepMessage article={article} request={request} />
                  </Stack>

                  <Stack gap="2rem">
                    {(article.task?.type === 'analyze_article' ||
                      article.task?.type === 'accept_requalification' ||
                      (article.step?.step === 'transit' &&
                        article.step.config.originType === 'workshop' &&
                        article.step.config.destinationType === 'workshop')) && (
                      <RequalificationComment article={article} />
                    )}

                    {!!request.client &&
                      allowWarranty &&
                      article.task?.type === 'choose_article_service' && (
                        <Stack gap="0.5rem">
                          <h2 className="headline-400-bold">
                            <Trans id="article.warranty">Warranty</Trans>
                          </h2>
                          <Box className={element('warranty')}>
                            <Warranty
                              request={request}
                              article={article}
                              key={`purchase-date ${article.purchaseDate ?? ''}`}
                              error={showErrors ? { proofOfPurchaseError } : undefined}
                            />
                          </Box>
                        </Stack>
                      )}

                    <Stack gap="0.5rem">
                      <h2 className="headline-400-bold">
                        <Trans id="article.services">Services</Trans>
                      </h2>

                      <Box className={element('services')}>
                        {flags['enable-defect-action-split'] ? (
                          <ArticleServices
                            request={request}
                            article={article}
                            isDisabled={article.task?.type !== 'choose_article_service'}
                            isEditActionDisabled={
                              article.task?.type !== 'choose_article_service' &&
                              article.task?.type !== 'accept_requalification'
                            }
                            isEditCustomActionWorkshopPriceDisabled={
                              article.task?.type !== 'choose_article_service'
                            }
                            showDispatchSection={!currentSession?.isResponsibleWorkshop(article)}
                            showWorkshopSelector={article.task?.type === 'choose_article_service'}
                            errors={showErrors ? { actionsError, workshopError } : undefined}
                          />
                        ) : (
                          <Stack gap="1rem">
                            <ActionTypeSearchSelect
                              request={request}
                              article={article}
                              isDisabled={article.task?.type !== 'choose_article_service'}
                              isEditActionDisabled={
                                article.task?.type !== 'choose_article_service' &&
                                article.task?.type !== 'accept_requalification'
                              }
                              isEditCustomActionWorkshopPriceDisabled={
                                article.task?.type !== 'choose_article_service'
                              }
                              error={showErrors ? actionsError : undefined}
                            />

                            {!currentSession?.isResponsibleWorkshop(article) && (
                              <ArticleDispatch
                                request={request}
                                article={article}
                                showSelector={article.task?.type === 'choose_article_service'}
                                error={showErrors ? workshopError : undefined}
                              />
                            )}
                          </Stack>
                        )}
                      </Box>
                    </Stack>

                    {isOrganizationUser && request.requestorType !== 'store' && (
                      <Stack gap="0.5rem">
                        <h2 className="headline-400-bold">
                          <Trans id="article.client-communication">
                            Communication to the client
                          </Trans>
                        </h2>
                        <Box className={element('client-communication')}>
                          <ClientComment
                            request={request}
                            article={article}
                            disabled={article.task?.type !== 'choose_article_service'}
                            key={`client-comment ${article.clientComment ?? ''}`}
                          />
                        </Box>
                      </Stack>
                    )}

                    <Stack gap={isMobile ? undefined : '0.5rem'} ref={commentsRef}>
                      <h2 className="headline-400-bold">
                        <Trans id="article.comment.title">Item comments</Trans>
                      </h2>
                      <ErrorBoundary>
                        <Box
                          className={element('comment', { 'no-background': isMobile })}
                          padding="0"
                          removeStyleOnMobile
                        >
                          <RequestComments requestId={request.id} articleId={article.id} />
                        </Box>
                      </ErrorBoundary>
                    </Stack>

                    <Stack gap={isMobile ? undefined : '0.5rem'} ref={null}>
                      <h2 className="headline-400-bold">
                        <Trans id="article.activity.title">Activity</Trans>
                      </h2>
                      <ErrorBoundary>
                        <Box
                          className={element('activity', { 'no-background': isMobile })}
                          padding="0"
                          removeStyleOnMobile
                        >
                          <ArticleActivities articleId={article.id} />
                        </Box>
                      </ErrorBoundary>
                    </Stack>
                  </Stack>
                </Stack>
              </PageLayoutLeftPartContent>

              <ArticleActions
                article={article}
                request={request}
                onActionDone={() => {
                  navigate(`/requests/${request.id}`);
                }}
                onCheckErrors={checkErrors}
              />
            </PageLayoutLeftPart>

            {!isMobile && (
              <PageLayoutRightPart>
                <ArticleInfoSidePanel article={article} request={request} showErrors={showErrors} />
              </PageLayoutRightPart>
            )}
          </PageLayoutContent>
        </PageLayout>
      </ArticleContext.Provider>
    </RequestContextProvider>
  );
};

const ErrorAlertBar = ({
  alertRef,
  error,
}: {
  alertRef: React.RefObject<HTMLDivElement>;
  error?: string;
}) => {
  return (
    <div ref={alertRef} style={{ display: error ? 'block' : 'none' }}>
      <AlertBar size="large" type="error" title={error} />
    </div>
  );
};

const CustomsAlertBar = () => {
  const { currentSession } = useCurrentSession();
  const { article, setIsOpenArticleInfo } = useContext(ArticleContext);

  if (
    article.areCustomsExpected &&
    !article.hasRequiredCustomsInfo &&
    currentSession!.hasPermission('edit_article', {
      organizationId: article.organizationId,
    })
  ) {
    const isError = article.step?.step === 'service-choice';

    return (
      <AlertBar type={isError ? 'error' : 'warning'} size="large" icon={<IconPlace warning />}>
        <Stack gap="0.5rem">
          <p>
            <Trans id="article.customs.missing-info">
              The item needs to go through customs. Please complete the missing information about
              the product to be allowed through.
            </Trans>
          </p>
          <div>
            <Button variant="secondary" onPress={() => setIsOpenArticleInfo(true)}>
              <IconEdit />
              <Trans id="article.customs.missing-info.action">Edit information</Trans>
            </Button>
          </div>
        </Stack>
      </AlertBar>
    );
  }

  return null;
};
