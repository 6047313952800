/* eslint-disable lingui/no-unlocalized-strings */

import { useEffect, useState } from 'react';

const mobileMediaQuery = window.matchMedia('screen and (max-width: 48rem)');
const tabletMediaQuery = window.matchMedia(
  'screen and (min-width: 48.001rem) and (max-width: 64rem)'
);
const desktopMediaQuery = window.matchMedia(
  'screen and (min-width: 64.001rem) and (max-width: 85.375rem)'
);
const widescreenMediaQuery = window.matchMedia('screen and (min-width: 85.376rem)');

const useViewPort = () => {
  const [type, setType] = useState(() => {
    if (mobileMediaQuery.matches) {
      return 'mobile';
    }
    if (tabletMediaQuery.matches) {
      return 'tablet';
    }
    if (desktopMediaQuery.matches) {
      return 'desktop';
    }
    if (widescreenMediaQuery.matches) {
      return 'widescreen';
    }
  });

  useEffect(() => {
    const setMobile = (e: MediaQueryListEvent) => e.matches && setType('mobile');
    const setTablet = (e: MediaQueryListEvent) => e.matches && setType('tablet');
    const setDesktop = (e: MediaQueryListEvent) => e.matches && setType('desktop');
    const setWidescreen = (e: MediaQueryListEvent) => e.matches && setType('widescreen');

    addEventListener(mobileMediaQuery, setMobile);
    addEventListener(tabletMediaQuery, setTablet);
    addEventListener(desktopMediaQuery, setDesktop);
    addEventListener(widescreenMediaQuery, setWidescreen);

    return () => {
      removeEventListener(mobileMediaQuery, setMobile);
      removeEventListener(tabletMediaQuery, setTablet);
      removeEventListener(desktopMediaQuery, setDesktop);
      removeEventListener(widescreenMediaQuery, setWidescreen);
    };
  }, []);

  return {
    type,
    isMobile: type === 'mobile',
    isTablet: type === 'tablet',
    isDesktop: type === 'desktop',
    isWidescreen: type === 'widescreen',
  };
};

export function checkIfTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

function addEventListener(
  mediaQuery: MediaQueryList,
  setViewPort: (e: MediaQueryListEvent) => void
) {
  if ('addEventListener' in mediaQuery) {
    mediaQuery.addEventListener('change', setViewPort);
  } else if ('addListener' in mediaQuery) {
    // @ts-ignore - In some older browsers (e.g: Safari 13), addEventListener is not available, but addListener is. Source: https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
    mediaQuery.addListener(setViewPort);
  }
}

function removeEventListener(
  mediaQuery: MediaQueryList,
  setViewPort: (e: MediaQueryListEvent) => void
) {
  if ('removeEventListener' in mediaQuery) {
    mediaQuery.removeEventListener('change', setViewPort);
  } else if ('removeListener' in mediaQuery) {
    // @ts-ignore - In some older browsers (e.g: Safari 13), removeEventListener is not available, but removeListener is. Source: https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
    mediaQuery.removeListener(setViewPort);
  }
}

export default useViewPort;
