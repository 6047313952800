import { UserAvatarGroup } from '@/components/UserAvatarGroup/UserAvatarGroup';
import Box from '@/design_system/Box';
import { User } from '@/models/user';

export const CollaboratorsCell = ({
  collaborators,
  variant,
}: {
  collaborators: User[];
  variant: 'card' | 'row';
}) => {
  if (!collaborators.length) {
    return;
  }

  if (variant === 'row') {
    return <UserAvatarGroup users={collaborators} />;
  }

  return (
    <Box padding="0.25rem 0.5rem" style={{ flex: 'none' }}>
      <UserAvatarGroup users={collaborators} size="xx-small" />
    </Box>
  );
};
