import IconWrapper from './IconWrapper';

const IconUserRefusal = IconWrapper({
  name: 'user-refusal',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.08105 7.41684C9.08105 5.80629 10.3875 4.5 11.9999 4.5C13.6122 4.5 14.9186 5.80629 14.9186 7.41684C14.9186 9.02739 13.6122 10.3337 11.9999 10.3337C10.3875 10.3337 9.08105 9.02739 9.08105 7.41684ZM11.9999 3C9.55979 3 7.58105 4.97711 7.58105 7.41684C7.58105 9.85657 9.55979 11.8337 11.9999 11.8337C14.4399 11.8337 16.4186 9.85657 16.4186 7.41684C16.4186 4.97711 14.4399 3 11.9999 3ZM11.9995 12.6255C11.2848 12.6255 10.5743 12.669 9.9008 12.7366C6.80649 13.0474 4.8291 15.8142 4.8291 18.7677C4.8291 20.001 5.8289 21.0008 7.06221 21.0008H11.674C11.4492 20.5276 11.2758 20.0252 11.1613 19.5008H7.06221C6.65733 19.5008 6.3291 19.1726 6.3291 18.7677C6.3291 16.4232 7.87347 14.4478 10.0507 14.2291C10.6848 14.1654 11.3443 14.1255 11.9995 14.1255C12.0558 14.1255 12.1122 14.1258 12.1686 14.1264C12.5275 13.587 12.9593 13.1004 13.4499 12.6804C12.9754 12.6458 12.4884 12.6255 11.9995 12.6255ZM18 21.875C20.1401 21.875 21.875 20.1401 21.875 18C21.875 15.8599 20.1401 14.125 18 14.125C15.8599 14.125 14.125 15.8599 14.125 18C14.125 20.1401 15.8599 21.875 18 21.875ZM23.375 18C23.375 20.9685 20.9685 23.375 18 23.375C15.0315 23.375 12.625 20.9685 12.625 18C12.625 15.0315 15.0315 12.625 18 12.625C20.9685 12.625 23.375 15.0315 23.375 18ZM16.9053 15.8447C16.6124 15.5518 16.1376 15.5518 15.8447 15.8447C15.5518 16.1376 15.5518 16.6124 15.8447 16.9053L16.9398 18.0005L15.8449 19.0955C15.552 19.3884 15.552 19.8632 15.8449 20.1561C16.1378 20.449 16.6126 20.449 16.9055 20.1561L18.0005 19.0611L19.0955 20.1561C19.3884 20.449 19.8632 20.449 20.1561 20.1561C20.449 19.8632 20.449 19.3884 20.1561 19.0955L19.0611 18.0005L20.1563 16.9053C20.4492 16.6124 20.4492 16.1376 20.1563 15.8447C19.8634 15.5518 19.3885 15.5518 19.0956 15.8447L18.0005 16.9398L16.9053 15.8447Z"
      />
    </svg>
  ),
});

export default IconUserRefusal;
