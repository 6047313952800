import { useLingui } from '@lingui/react';

import { getBrowserLocale, Locale } from '@/services/i18n';

import { Model } from './model';

export class Translation extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  en!: string;
  fr!: string;

  get value() {
    const locale = getBrowserLocale();

    return this[locale];
  }
}

export const useDatabaseTranslation = () => {
  const { i18n } = useLingui();

  return {
    _db: (translation: Translation) => translation[i18n.locale as Locale],
  };
};
