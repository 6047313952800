import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity, BaseActivityText } from '@/components/activities/BaseActivity/BaseActivity';
import IconPicture from '@/icons/Picture.svg';

export const RequestMediumAddedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'request_medium_added'>;
}) => {
  const { _ } = useLingui();

  let requestMediaAddedMessage: MessageDescriptor | undefined;
  if (activity.data.type === 'proof-of-external-payment') {
    requestMediaAddedMessage = msg({
      id: 'activities.request_medium_added.proof-of-external-payment',
      message: 'added a receipt / invoice to the request',
    });
  }

  if (!requestMediaAddedMessage) return null;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconPicture />}
      creatorName={activity.creator?.name ?? ''}
      message={<BaseActivityText>{_(requestMediaAddedMessage)}</BaseActivityText>}
    />
  );
};
