import IconWrapper from './IconWrapper';

const IconScan = IconWrapper({
  name: 'scan',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.01923 20.5H5.75C4.50736 20.5 3.5 19.4926 3.5 18.25L3.5 16.75C3.5 16.3358 3.16421 16 2.75 16C2.33579 16 2 16.3358 2 16.75V18.25C2 20.3211 3.67893 22 5.75 22H7.01923C7.43345 22 7.76923 21.6642 7.76923 21.25C7.76923 20.8358 7.43345 20.5 7.01923 20.5ZM20.5 18.25C20.5 19.4926 19.4926 20.5 18.25 20.5H16.9808C16.5666 20.5 16.2308 20.8358 16.2308 21.25C16.2308 21.6642 16.5666 22 16.9808 22H18.25C20.3211 22 22 20.3211 22 18.25V16.75C22 16.3358 21.6642 16 21.25 16C20.8358 16 20.5 16.3358 20.5 16.75V18.25ZM2 7.25C2 7.66422 2.33579 8 2.75 8C3.16421 8 3.5 7.66422 3.5 7.25L3.5 5.75C3.5 4.50736 4.50736 3.5 5.75 3.5L7.01923 3.5C7.43345 3.5 7.76923 3.16421 7.76923 2.75C7.76923 2.33579 7.43345 2 7.01923 2H5.75C3.67893 2 2 3.67893 2 5.75V7.25ZM20.5 7.25001C20.5 7.66423 20.8358 8.00001 21.25 8C21.6642 8 22 7.6642 22 7.24999L22 5.74994C21.9999 3.6789 20.321 2 18.25 2H16.9808C16.5666 2 16.2308 2.33579 16.2308 2.75C16.2308 3.16421 16.5666 3.5 16.9808 3.5L18.25 3.5C19.4926 3.5 20.5 4.50734 20.5 5.74996L20.5 7.25001ZM6 11.25C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75H18C18.4142 12.75 18.75 12.4142 18.75 12C18.75 11.5858 18.4142 11.25 18 11.25H6Z"
      />
    </svg>
  ),
});

export default IconScan;
