import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconComment from '@/icons/Comment.svg';

export const CommentAddedActivity = ({
  activity,
  isShipment,
  isArticle,
}: {
  activity: ActivityOfType<'comment_added'>;
  isShipment: boolean;
  isArticle: boolean;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconComment />}
      creatorName={activity.creator.name}
      message={
        <BaseActivityText>
          <Trans id="activities.comment_added.message">added a new comment</Trans>
          {activity.shipmentId && !isShipment && (
            <>
              {' '}
              <Trans id="activities.comment_added.on_a">on a</Trans>{' '}
              <Link className="text-link" to={`/shipments/${activity.shipmentId}`}>
                <Trans id="activities.comment_added.shipment">shipment</Trans>
              </Link>
            </>
          )}
          {activity.articleId && !isArticle && (
            <>
              {' '}
              <Trans id="activities.comment_added.on_article">on the item</Trans>{' '}
              <Link className="text-link" to={`./articles/${activity.articleId}`}>
                <BaseActivityArticleName activity={activity as any} displayArticleName={true} />
              </Link>
            </>
          )}
        </BaseActivityText>
      }
    />
  );
};
