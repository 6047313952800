import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Alpha2Code } from 'i18n-iso-countries';

import { InputSelect, InputSelectProps } from '@/design_system/InputSelect/InputSelect';
import { Country, useCountries } from '@/services/i18n';

type InputCountryProps = {
  value?: Alpha2Code | null;
  onChange: (value: Alpha2Code) => void;
} & Omit<InputSelectProps<Country, false>, 'value' | 'onChange'>;

export const InputCountry = ({ placeholder, value, onChange, ...props }: InputCountryProps) => {
  const { _ } = useLingui();
  const countries = useCountries();

  return (
    <InputSelect
      variant="select"
      placeholder={
        placeholder ?? _(msg({ id: 'components.input-country.label', message: 'Select a country' }))
      }
      options={countries}
      getOptionValue={(country) => country.name}
      getOptionLabel={(country) => country.name}
      value={countries.find((country) => country.code === value)}
      onChange={(country) => {
        if (!country) {
          return;
        }

        onChange(country.code);
      }}
      {...props}
    />
  );
};
