import { msg } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconZendesk from '@/icons/Zendesk.svg';

export const RequestSentToZendeskActivity = ({
  activity,
}: {
  activity: ActivityOfType<'request_sent_to_zendesk'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconZendesk />}
      creatorName={''}
      message={msg({
        id: 'activities.request_sent_to_zendesk.message',
        message: 'The request has been transferred to Zendesk',
      })}
    />
  );
};
