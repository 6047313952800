import IconWrapper from './IconWrapper';

const IconVisibilityOff = IconWrapper({
  name: 'visibility',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5303 3.53033C21.8232 3.23744 21.8232 2.76256 21.5303 2.46967C21.2374 2.17678 20.7626 2.17678 20.4697 2.46967L2.46967 20.4697C2.17678 20.7626 2.17678 21.2374 2.46967 21.5303C2.76256 21.8232 3.23744 21.8232 3.53033 21.5303L21.5303 3.53033ZM12.0006 4.75C13.1286 4.75 14.2368 5.06053 15.2741 5.54392L14.1319 6.68609C13.4155 6.40791 12.6981 6.25 12.0006 6.25C10.6411 6.25 9.20541 6.85002 7.84984 7.7712C6.50306 8.6864 5.30185 9.87385 4.42899 10.9377C3.93501 11.5397 3.95321 12.3911 4.48153 12.9918C4.96128 13.5373 5.52468 14.1167 6.14107 14.6769L5.07929 15.7387C4.43665 15.1509 3.85272 14.5481 3.3552 13.9825C2.35561 12.846 2.29587 11.1727 3.26937 9.98619C4.21276 8.83643 5.51701 7.54291 7.00676 6.53055C8.48771 5.52416 10.2196 4.75 12.0006 4.75ZM12.0003 8.75C12.0227 8.75 12.0451 8.75023 12.0673 8.75068L8.75102 12.067C8.75057 12.0447 8.75034 12.0224 8.75034 12C8.75034 10.2051 10.2054 8.75 12.0003 8.75ZM15.2497 11.9323L11.9327 15.2493C11.9552 15.2498 11.9777 15.25 12.0003 15.25C13.7953 15.25 15.2503 13.7949 15.2503 12C15.2503 11.9774 15.2501 11.9548 15.2497 11.9323ZM12.0006 17.75C11.3567 17.75 10.654 17.5725 9.92423 17.2577L8.79586 18.3861C9.83627 18.9064 10.9299 19.25 12.0006 19.25C13.6329 19.25 15.3182 18.4516 16.7873 17.4457C18.2746 16.4273 19.6375 15.1287 20.6456 13.9825C21.6451 12.846 21.7049 11.1727 20.7314 9.98618C20.2479 9.39682 19.6695 8.76969 19.02 8.16201L17.9587 9.22331C18.5688 9.7908 19.1152 10.3811 19.5718 10.9376C20.0657 11.5397 20.0475 12.3912 19.5192 12.9919C18.573 14.0677 17.3015 15.2756 15.9398 16.208C14.5597 17.153 13.1813 17.75 12.0006 17.75Z"
      />
    </svg>
  ),
});

const IconVisibilityOn = IconWrapper({
  name: 'visibility',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0005 4.75C10.2196 4.75 8.48764 5.52416 7.00668 6.53055C5.51693 7.54291 4.21268 8.83643 3.2693 9.98619C2.29579 11.1727 2.35553 12.846 3.35512 13.9825C4.36331 15.1287 5.72631 16.4273 7.21372 17.4457C8.68281 18.4516 10.3683 19.25 12.0005 19.25C13.6328 19.25 15.3182 18.4516 16.7872 17.4457C18.2745 16.4273 19.6374 15.1287 20.6455 13.9825C21.645 12.846 21.7048 11.1727 20.7313 9.98618C19.788 8.83643 18.4839 7.54291 16.9942 6.53055C15.5133 5.52416 13.7815 4.75 12.0005 4.75ZM4.42891 10.9377C5.30177 9.87385 6.50298 8.6864 7.84976 7.7712C9.20533 6.85002 10.641 6.25 12.0005 6.25C13.36 6.25 14.7956 6.85001 16.1511 7.77117C17.4978 8.68637 18.6989 9.87381 19.5717 10.9376C20.0657 11.5397 20.0475 12.3912 19.5191 12.9919C18.573 14.0677 17.3015 15.2756 15.9397 16.208C14.5596 17.153 13.1813 17.75 12.0005 17.75C10.8198 17.75 9.44133 17.153 8.06116 16.208C6.6993 15.2756 5.42768 14.0677 4.48145 12.9918C3.95313 12.3911 3.93494 11.5397 4.42891 10.9377ZM10.2503 12C10.2503 11.0335 11.0338 10.25 12.0003 10.25C12.9668 10.25 13.7503 11.0335 13.7503 12C13.7503 12.9665 12.9668 13.75 12.0003 13.75C11.0338 13.75 10.2503 12.9665 10.2503 12ZM12.0003 8.75C10.2053 8.75 8.75026 10.2051 8.75026 12C8.75026 13.7949 10.2053 15.25 12.0003 15.25C13.7952 15.25 15.2503 13.7949 15.2503 12C15.2503 10.2051 13.7952 8.75 12.0003 8.75Z"
      />
    </svg>
  ),
});

const IconVisibility = ({ on }: { on: boolean }) => {
  return on ? <IconVisibilityOn /> : <IconVisibilityOff />;
};

export default IconVisibility;
