import { useEffect } from 'react';
import { isWeekend } from '@internationalized/date';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import BasicRadioGroup from '@/design_system/BasicRadioGroup';
import Button from '@/design_system/Button';
import { Calendar } from '@/design_system/InputDate/InputDate';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import { AddressWithRelations } from '@/models/address';
import {
  ArticleWithRelations,
  SHIPMENT_CARRIER_SERVICES,
  SHIPMENT_CARRIERS,
  SHIPMENT_HANDOVER,
  ShipmentCarrierService,
  ShipmentHandover,
  useAvailableCarriers,
} from '@/models/shipment';

export const CarrierSelect = ({
  articles,
  origin,
  destination,
  carrierService,
  setCarrierService,
  handover,
  setHandover,
  pickupDate,
  setPickupDate,
  trackingId,
  setTrackingId,
}: {
  articles: ArticleWithRelations[];
  origin?: AddressWithRelations;
  destination?: AddressWithRelations;
  carrierService?: ShipmentCarrierService | 'private-carrier';
  setCarrierService: (carrier: ShipmentCarrierService | 'private-carrier') => void;
  handover?: ShipmentHandover;
  setHandover: (handover?: ShipmentHandover) => void;
  pickupDate?: string;
  setPickupDate: (pickupDate?: string) => void;
  trackingId?: string;
  setTrackingId: (trackingId?: string) => void;
}) => {
  const { i18n, _ } = useLingui();

  const organizationId =
    origin?.client?.organizationId ??
    destination?.client?.organizationId ??
    articles[0]?.request.organizationId;
  const originId = origin?.id;
  const destinationId = destination?.id;

  const { data: { carriers } = { carriers: [] }, isLoading } = useAvailableCarriers({
    organizationId,
    originId,
    destinationId,
  });

  useEffect(() => {
    if (carriers.length > 0 && !carrierService) {
      setCarrierService(carriers[0].service);

      const handoverOptions = carriers[0]?.handover ?? [];

      setHandover(handoverOptions.length === 1 ? handoverOptions[0] : undefined);
    }
  }, [carrierService, carriers, setCarrierService, setHandover]);

  if (!originId || !destinationId) {
    return (
      <>
        <p>
          <span className="paragraph-100-medium">
            <Trans id="shipments.new.carrier.label">Carrier</Trans>
          </span>
          <br />
          <span className="paragraph-100-regular text-secondary">
            <Trans id="shipments.new.carrier.select-origin-destination">
              Select an origin and destination before choosing the carrier
            </Trans>
          </span>
        </p>
      </>
    );
  }

  if (!organizationId && articles.length === 0) {
    return (
      <>
        <p>
          <span className="paragraph-100-medium">
            <Trans id="shipments.new.carrier.label">Carrier</Trans>
          </span>
          <br />
          <span className="paragraph-100-regular text-secondary">
            <Trans id="shipments.new.carrier.select-article">
              Select at least one item before choosing the carrier
            </Trans>
          </span>
        </p>
      </>
    );
  }

  if (isLoading) {
    return (
      <p className="paragraph-100-medium">
        <Trans id="shipments.new.carrier.label">Carrier</Trans>
      </p>
    );
  }

  const chosenCarrier = carriers.find((carrier) => carrier.service === carrierService);

  // Minimum date for pickup
  const minDate = new Date();

  // If today is Saturday or Sunday, the minimum date for pickup is Monday
  if (minDate.getDay() === 6) {
    minDate.setDate(minDate.getDate() + 2);
  } else if (minDate.getDay() === 0) {
    minDate.setDate(minDate.getDate() + 1);
  }

  return (
    <>
      <BasicRadioGroup
        label={_(msg({ id: 'shipments.new.carrier.label', message: 'Carrier' }))}
        options={[
          ...carriers.map((carrier) => {
            const carrierName =
              SHIPMENT_CARRIERS.find(({ id }) => id === carrier.name)?.name ?? carrier.name;
            const carrierServiceName =
              SHIPMENT_CARRIER_SERVICES.find(({ id }) => id === carrier.service)?.name ??
              carrier.service;

            return {
              value: carrier.service,
              children: `${carrierName} (${carrierServiceName})`,
            };
          }),
          {
            value: 'private-carrier',
            children: _(
              msg({ id: 'shipment.carrier.private-carrier', message: 'Private carrier' })
            ),
          },
        ]}
        value={carrierService}
        onChange={(carrierService) => {
          setCarrierService(carrierService as ShipmentCarrierService);

          const handoverOptions =
            carriers.find((carrier) => carrier.service === carrierService)?.handover ?? [];

          setHandover(handoverOptions.length === 1 ? handoverOptions[0] : undefined);
          setPickupDate(undefined);
        }}
      />
      {chosenCarrier && chosenCarrier.handover.length > 0 && (
        <BasicRadioGroup
          label={_(msg({ id: 'shipments.new.handover.label', message: 'Handover option' }))}
          options={SHIPMENT_HANDOVER.filter((handover) =>
            chosenCarrier.handover.includes(handover.id)
          ).map(({ id, name }) => ({ value: id, children: _(name) }))}
          value={handover ?? null}
          onChange={(handover) => {
            setHandover(handover as ShipmentHandover);
            setPickupDate(undefined);
          }}
        />
      )}
      {chosenCarrier && handover === 'pickup' && (
        <Stack gap="0.5rem">
          <p className="paragraph-100-regular">
            <Trans id="shipments.new.handover.pickup-date">Select a pickup date</Trans>
          </p>
          <Calendar
            minValue={minDate}
            isDateUnavailable={(date) => isWeekend(date, i18n.locale)}
            value={pickupDate}
            onChange={setPickupDate}
          />
        </Stack>
      )}
      {carrierService === 'private-carrier' && (
        <InputText
          label={
            <Stack row gap="4px" alignItems="baseline">
              <Trans id="shipments.new.tracking-number.label">Tracking Number</Trans>
              <span className="paragraph-200-regular">
                <Trans id="shipments.new.tracking-number.optional">(Optional)</Trans>
              </span>
              <Tooltip
                content={
                  <Trans id="shipments.new.tracking-number.tooltip">
                    You can add it later if you haven&apos;t already created the shipment on the
                    courier site
                  </Trans>
                }
              >
                <Button variant="style-less">
                  <IconInfo style={{ fontSize: '1rem', bottom: '-3px', position: 'relative' }} />
                </Button>
              </Tooltip>
            </Stack>
          }
          placeholder={_(
            msg({
              id: 'shipments.new.tracking-number.placeholder',
              message: 'Add courier tracking number',
            })
          )}
          value={trackingId}
          onChange={setTrackingId}
        />
      )}
    </>
  );
};
