import IconWrapper, { IconProps } from './IconWrapper';

const IconNotificationOutline = IconWrapper({
  name: 'notification',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.91091 5.25449C8.99988 4.12797 10.4713 3.5 12 3.5C13.5287 3.5 15.0001 4.12797 16.0891 5.25449C17.1789 6.38188 17.7955 7.9162 17.7955 9.52116V17H6.20455V9.52116C6.20455 7.9162 6.82111 6.38188 7.91091 5.25449ZM20.25 17H19.2955V9.52116C19.2955 7.53447 18.5328 5.62425 17.1676 4.21196C15.8015 2.7988 13.9432 2 12 2C10.0568 2 8.19848 2.7988 6.83243 4.21196C5.46721 5.62425 4.70455 7.53447 4.70455 9.52116V17H3.75C3.33579 17 3 17.3358 3 17.75C3 18.1642 3.33579 18.5 3.75 18.5H5.45455H8.25821C8.38685 20.4545 10.0129 22 12 22C13.9871 22 15.6132 20.4545 15.7418 18.5H18.5455H20.25C20.6642 18.5 21 18.1642 21 17.75C21 17.3358 20.6642 17 20.25 17ZM14.2363 18.5H9.76374C9.8881 19.625 10.8419 20.5 12 20.5C13.1581 20.5 14.1119 19.625 14.2363 18.5Z"
      />
    </svg>
  ),
});

export const IconNotificationPlain = IconWrapper({
  name: 'notification',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2955 17H20.25C20.6642 17 21 17.3358 21 17.75C21 18.1642 20.6642 18.5 20.25 18.5H18.5455H15.7418C15.6132 20.4545 13.9871 22 12 22C10.0129 22 8.38685 20.4545 8.25821 18.5H5.45455H3.75C3.33579 18.5 3 18.1642 3 17.75C3 17.3358 3.33579 17 3.75 17H4.70455V9.52116C4.70455 7.53447 5.46721 5.62425 6.83243 4.21196C8.19848 2.7988 10.0568 2 12 2C13.9432 2 15.8015 2.7988 17.1676 4.21196C18.5328 5.62425 19.2955 7.53447 19.2955 9.52116V17ZM9.76374 18.5C9.8881 19.625 10.8419 20.5 12 20.5C13.1581 20.5 14.1119 19.625 14.2363 18.5H9.76374Z"
      />
    </svg>
  ),
});

const IconNotification = ({ plain, ...props }: IconProps & { plain?: boolean }) => {
  return plain ? <IconNotificationPlain {...props} /> : <IconNotificationOutline {...props} />;
};

export default IconNotification;
