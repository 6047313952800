import IconWrapper from './IconWrapper';

const IconDownload = IconWrapper({
  name: 'download',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4697 17.2803C11.7626 17.5732 12.2374 17.5732 12.5303 17.2803L18.0303 11.7803C18.3232 11.4874 18.3232 11.0126 18.0303 10.7197C17.7374 10.4268 17.2626 10.4268 16.9697 10.7197L12.75 14.9393L12.75 2.75C12.75 2.33579 12.4142 2 12 2C11.5858 2 11.25 2.33579 11.25 2.75L11.25 14.9393L7.03033 10.7197C6.73744 10.4268 6.26256 10.4268 5.96967 10.7197C5.67678 11.0126 5.67678 11.4874 5.96967 11.7803L11.4697 17.2803ZM2.75 20.5C2.33579 20.5 2 20.8358 2 21.25C2 21.6642 2.33579 22 2.75 22H21.25C21.6642 22 22 21.6642 22 21.25C22 20.8358 21.6642 20.5 21.25 20.5H2.75Z"
        fill="#3F4643"
      />
    </svg>
  ),
});

export default IconDownload;
