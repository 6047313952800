import Stack from '@/design_system/Stack';
import { createBEMClasses } from '@/utils/classname';

import IllustrationEmptySearch from './IllustrationEmptySearch';

import './WorkshopListEmptyState.css';

const { block } = createBEMClasses('workshop-list-empty-state');

const WorkshopListEmptyState = ({ emptyStateText }: { emptyStateText: string }) => (
  <Stack gap="16px" alignItems="center" className={block()}>
    <IllustrationEmptySearch />
    <p className="paragraph-100-regular">{emptyStateText}</p>
  </Stack>
);

export default WorkshopListEmptyState;
