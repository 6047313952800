import { ShippingOption } from '@/api';
import { Locale } from '@/services/i18n';

import Phone, { formatPhone } from './partials/phone';
import { Address } from './address';
import { Model } from './model';

export class Client extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  organizationId!: string;
  name!: string;
  email!: string;
  address!: Address | null;
  billingAddress!: Address | null;
  deliveryOption!: DeliveryOption | null;
  shippingChoice!: ShippingOption | null;
  sendcloudServicePointId!: string | null;
  phone!: Phone | null;
  locale!: Locale | null;

  addressId!: string | null;

  createdAt!: string;

  get formattedPhone() {
    return formatPhone(this.phone);
  }
}

export type DeliveryOption = 'store' | 'home' | 'pickupPoint_mondialRelay';
