import { useState } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ClientFormModal } from '@/components/ClientForm';
import ContactDetails from '@/components/ContactDetails';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconEdit from '@/icons/Edit.svg';
import { ClientRequestWithRelations } from '@/models/request';
import { useClientToken } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

const ClientInfo = ({
  request,
  canEdit = true,
}: {
  request: ClientRequestWithRelations;
  canEdit?: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const clientToken = useClientToken();
  const [isOpenClientFormModal, setIsOpenClientFormModal] = useState(false);

  const label = clientToken
    ? _(msg({ id: 'client.request.my-info', message: 'My information' }))
    : _(msg({ id: 'client.request.client-info', message: "Client's information" }));

  const editLabel = clientToken
    ? _(msg({ id: 'client.request.my-info.edit', message: 'Edit my information' }))
    : _(msg({ id: 'client.request.client-info.edit', message: "Edit client's information" }));

  return (
    <>
      <Box gap="0.75rem" padding="12px 16px" ariaLabel={label}>
        <Stack row justifyContent="space-between" alignItems="center">
          <p className="paragraph-50-medium">{label}</p>
          {canEdit && (
            <Button
              iconOnly={isMobile}
              variant="secondary-brand"
              size="medium"
              onPress={() => setIsOpenClientFormModal(true)}
              ariaLabel={isMobile ? editLabel : undefined}
              tooltip={isMobile ? editLabel : undefined}
            >
              <IconEdit />
              {!isMobile && editLabel}
            </Button>
          )}
        </Stack>
        <hr />
        <ContactDetails
          name={request.client?.name}
          email={request.client?.email}
          address={request.client?.address}
          phone={request.client?.phone}
        />
      </Box>
      <ClientFormModal
        isOpen={isOpenClientFormModal}
        setIsOpen={setIsOpenClientFormModal}
        request={request}
        disableEmailEdit={!!clientToken}
        hideVip
        hideLanguage={!!clientToken}
        hideDeliveryOption={!!clientToken}
        variant="brand"
        size="large"
      />
    </>
  );
};

export default ClientInfo;
