import IconWrapper from './IconWrapper';

const IconUndo = IconWrapper({
  name: 'undo',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.46967 3.96967C7.76256 3.67678 8.23744 3.67678 8.53033 3.96967C8.82322 4.26256 8.82322 4.73744 8.53033 5.03033L6.81066 6.75H13.5C13.5021 6.75 13.5041 6.75001 13.5062 6.75003C16.9551 6.75338 19.75 9.55031 19.75 13C19.75 16.4518 16.9518 19.25 13.5 19.25V19.25H9.5C9.08579 19.25 8.75 18.9142 8.75 18.5C8.75 18.0858 9.08579 17.75 9.5 17.75H13.5C13.5012 17.75 13.5024 17.75 13.5036 17.75C16.1253 17.748 18.25 15.6222 18.25 13C18.25 10.3767 16.1234 8.25002 13.5 8.25002V8.25H6.81066L8.53033 9.96967C8.82322 10.2626 8.82322 10.7374 8.53033 11.0303C8.23744 11.3232 7.76256 11.3232 7.46967 11.0303L4.46967 8.03033C4.17678 7.73744 4.17678 7.26256 4.46967 6.96967L7.46967 3.96967Z"
      />
    </svg>
  ),
});

export default IconUndo;
