import { Outlet } from 'react-router-dom';

import Box from '@/design_system/Box';
import Logo from '@/design_system/Logo';
import { createBEMClasses } from '@/utils/classname';

const { block, element } = createBEMClasses('auth-layout');

import { ErrorBoundary } from '@/services/sentry';

import './Auth.css';

const AuthLayout = () => {
  return (
    <section className={block()}>
      <div className={element('container')}>
        <Logo />
        <Box padding="32px" gap="2rem" className="bg-neutral-0">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </div>
    </section>
  );
};

export default AuthLayout;
