import { Label, Separator } from 'react-aria-components';
import { Link } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { startOfDay } from 'date-fns/startOfDay';

import ContactDetails from '@/components/ContactDetails';
import { StatusDueDate } from '@/components/DueDate';
import Accordion from '@/design_system/Accordion';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import IconCalendar from '@/icons/Calendar.svg';
import IconEdit from '@/icons/Edit.svg';
import IconFile from '@/icons/File.svg';
import IconPlace from '@/icons/Place.svg';
import { ShipmentWithRelations, useCarrierName } from '@/models/shipment';
import { ShipmentOverview } from '@/routes/Shipments/Shipment/components/ShipmentOverview';
import { TrackingIdModal } from '@/routes/Shipments/Shipment/components/TrackingIdModal/TrackingIdModal';
import { useCurrentSession } from '@/services/auth';
import { formatDate } from '@/utils/date';
import { formatWeight } from '@/utils/number';

export const ShipmentInfoSidePanel = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();

  return (
    <Stack gap="0.5rem">
      <Accordion
        openByDefault
        trigger={
          <p className="paragraph-100-medium">
            <Trans id="shipment.information">Information</Trans>
          </p>
        }
      >
        <Stack ariaLabel={_(msg({ id: 'shipment.information', message: 'Information' }))}>
          <ShipmentInfo shipment={shipment} />
        </Stack>
      </Accordion>
      <Separator />
      <Accordion
        openByDefault
        trigger={
          <p className="paragraph-100-medium">
            <Trans id="shipment.origin.title">Origin</Trans>
          </p>
        }
      >
        <Stack ariaLabel={_(msg({ id: 'shipment.origin.title', message: 'Origin' }))}>
          <ContactDetails
            placeIcon="start"
            addressName={shipment.origin?.name}
            address={shipment.originAddress}
            phone={shipment.origin?.phone}
            size="small"
          />
        </Stack>
      </Accordion>
      <Separator />
      <Accordion
        openByDefault
        trigger={
          <p className="paragraph-100-medium">
            <Trans id="shipment.destination.title">Destination</Trans>
          </p>
        }
      >
        <Stack ariaLabel={_(msg({ id: 'shipment.destination.title', message: 'Destination' }))}>
          <ContactDetails
            placeIcon="end"
            addressName={shipment.destination?.name}
            address={shipment.destinationAddress}
            phone={shipment.destination?.phone}
            size="small"
          />
        </Stack>
      </Accordion>
    </Stack>
  );
};

export const ShipmentInfoTopPanel = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  return (
    <Stack gap="1rem" className="paragraph-100-regular">
      <ShipmentOverview shipment={shipment} />
      <Box padding="0">
        <Accordion
          trigger={
            <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
              <IconFile style={{ fontSize: '1.25rem' }} />
              <Trans id="shipment.information">Information</Trans>
            </Stack>
          }
        >
          <Stack ariaLabel={_(msg({ id: 'shipment.information', message: 'Information' }))}>
            <ShipmentInfo shipment={shipment} />
          </Stack>
        </Accordion>
        <Separator />
        <Accordion
          trigger={
            <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
              <IconPlace start style={{ fontSize: '1.25rem' }} />
              {shipment.origin?.name ?? _(msg({ id: 'shipment.origin.title', message: 'Origin' }))}
            </Stack>
          }
        >
          <Stack ariaLabel={_(msg({ id: 'shipment.origin.title', message: 'Origin' }))}>
            <ContactDetails
              placeIcon="start"
              address={shipment.originAddress}
              phone={shipment.origin?.phone}
              size="small"
            />
          </Stack>
        </Accordion>
        <Separator />
        <Accordion
          trigger={
            <Stack row gap="0.5rem" alignItems="center" className="paragraph-100-regular">
              <IconPlace end style={{ fontSize: '1.25rem' }} />
              {shipment.destination?.name ??
                _(msg({ id: 'shipment.destination.title', message: 'Destination' }))}
            </Stack>
          }
        >
          <Stack ariaLabel={_(msg({ id: 'shipment.destination.title', message: 'Destination' }))}>
            <ContactDetails
              placeIcon="end"
              address={shipment.destinationAddress}
              phone={shipment.destination?.phone}
              size="small"
            />
          </Stack>
        </Accordion>
      </Box>
    </Stack>
  );
};

const ShipmentInfo = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  return (
    <Stack gap="0.75rem">
      <ShipmentRef shipment={shipment} />
      <CreationDate shipment={shipment} />
      {shipment.statusDueAtDate && <StatusDueDate date={shipment.statusDueAtDate} variant="info" />}
      <Carrier shipment={shipment} />
      <TrackingNumber shipment={shipment} />
      <NumberOfArticles shipment={shipment} />
      <TotalWeight shipment={shipment} />
      {shipment.pickupDate && <PickupDate shipment={shipment} />}
      {(shipment.arrivalDate || shipment.updatedEtaDate || shipment.originalEtaDate) && (
        <DeliveryDate shipment={shipment} />
      )}
    </Stack>
  );
};

export const ShipmentRef = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'shipment.reference.label', message: 'Prolong ref.' }));

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <span className="paragraph-100-medium" aria-label={label}>
        {shipment.reference}
      </span>
    </Stack>
  );
};

export const CreationDate = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'shipment.creation-date.label', message: 'Creation date' }));

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <Stack
        row
        className="paragraph-100-medium"
        alignItems="center"
        gap="0.25rem"
        aria-label={label}
      >
        <IconCalendar style={{ fontSize: '1rem' }} />
        {formatDate(shipment.createdAtDate, { dateStyle: 'medium' })}
      </Stack>
    </Stack>
  );
};

export const Carrier = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'shipment.carrier.label', message: 'Carrier' }));
  const carrierName = useCarrierName(shipment);

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <span className="paragraph-100-medium">{carrierName}</span>
    </Stack>
  );
};

export const TrackingNumber = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  const canEditTrackingNumber =
    shipment.carrier === 'private-carrier' &&
    !['received', 'validated'].includes(shipment.status) &&
    shipment.canBeCreatedBy(currentSession);

  const trackingId = shipment.trackingUrl ? (
    <Link
      to={shipment.trackingUrl}
      target="_blank"
      rel="noreferrer"
      className="paragraph-100-medium text-primary"
    >
      {shipment.trackingId ?? <Trans id="shipment.trackingNumber.unknown">Unknown</Trans>}
    </Link>
  ) : (
    <span className="paragraph-100-medium">
      {shipment.trackingId ?? <Trans id="shipment.trackingNumber.unknown">Unknown</Trans>}
    </span>
  );

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="shipment.trackingNumber.label">Tracking number</Trans>
      </span>
      {canEditTrackingNumber && (
        <TrackingIdModal shipment={shipment}>
          <Label style={{ cursor: 'pointer' }}>
            <Stack row alignItems="center" gap="4px">
              {!shipment.trackingId && (
                <>
                  <Button
                    variant="secondary"
                    size="small"
                    iconOnly
                    ariaLabel={_(
                      msg({
                        id: 'shipment.trackingNumber.add',
                        message: 'Add tracking number',
                      })
                    )}
                  >
                    <IconAdd />
                  </Button>
                  <span className="paragraph-100-regular text-disabled">
                    <Trans id="shipment.trackingNumber.unknown">Unknown</Trans>
                  </span>
                </>
              )}
              {shipment.trackingId && (
                <>
                  {trackingId}
                  <Button
                    variant="secondary"
                    size="small"
                    iconOnly
                    ariaLabel={_(
                      msg({
                        id: 'shipment.trackingNumber.edit',
                        message: 'Edit tracking number',
                      })
                    )}
                  >
                    <IconEdit />
                  </Button>
                </>
              )}
            </Stack>
          </Label>
        </TrackingIdModal>
      )}
      {!canEditTrackingNumber && trackingId}
    </Stack>
  );
};

const NumberOfArticles = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'shipment.articles.label', message: 'Items' }));

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label} </span>
      <span className="paragraph-100-medium" aria-label={label}>
        {shipment.articles.length}
      </span>
    </Stack>
  );
};

export const TotalWeight = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'shipment.weight.label', message: 'Weight' }));

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <span className="paragraph-100-medium" aria-label={label}>
        {formatWeight(
          shipment.articles.reduce(
            (acc, { article }) => acc + (article.product?.data.weight ?? 0),
            0
          )
        )}
      </span>
    </Stack>
  );
};

export const PickupDate = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'shipment.pickup-date.label', message: 'Pickup date' }));

  if (!shipment.pickupDate) {
    return null;
  }

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <Stack
        row
        className="paragraph-100-medium"
        alignItems="center"
        gap="0.25rem"
        aria-label={label}
      >
        <IconCalendar style={{ fontSize: '1rem' }} />
        {formatDate(new Date(shipment.pickupDate), { dateStyle: 'medium' })}
      </Stack>
    </Stack>
  );
};

export const DeliveryDate = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { _ } = useLingui();
  const label = _(msg({ id: 'shipment.delivery-date.label', message: 'Delivery date' }));

  return (
    <Stack>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <Stack
        row
        className="paragraph-100-medium"
        alignItems="center"
        gap="0.25rem"
        aria-label={label}
      >
        <IconCalendar style={{ fontSize: '1rem' }} />
        <ArrivalDate shipment={shipment} />
      </Stack>
    </Stack>
  );
};

export const ArrivalDate = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { arrivalDate, updatedEtaDate, originalEtaDate } = shipment;

  if (arrivalDate || (shipment.carrier === 'private-carrier' && originalEtaDate)) {
    return formatDate(arrivalDate ?? originalEtaDate!, { dateStyle: 'medium' });
  }

  const eta = updatedEtaDate ?? originalEtaDate;

  if (eta) {
    const isLate = startOfDay(eta) < startOfDay(new Date());

    if (isLate) {
      return (
        <>
          {formatDate(eta, { dateStyle: 'medium' })}{' '}
          <Trans id="shipment.estimated.short">(est.)</Trans>
        </>
      );
    }

    return (
      <>
        {formatDate(eta, { dateStyle: 'medium' })}{' '}
        <Trans id="shipment.estimated.short">(est.)</Trans>
      </>
    );
  }

  return '-';
};
