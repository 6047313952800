import { Trans } from '@lingui/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const AcceptDispatchTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      title={<Trans id="article.task.accept-dispatch.title">Your expertise is required</Trans>}
      message={
        <Trans id="article.task.accept-dispatch.message">
          Please review the item details and confirm the acceptance of this job.
        </Trans>
      }
      article={article}
    />
  );
};
