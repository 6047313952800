import { msg } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconPackage from '@/icons/Package.svg';

export const ShipmentFinalisationCompletedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'shipment_finalisation_completed'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconPackage />}
      creatorName={activity.creator?.name ?? ''}
      message={
        activity.creator
          ? msg({
              id: 'activities.shipment_finalisation_completed.message',
              message: 'finalized the package preparation',
            })
          : msg({
              id: 'activities.shipment_finalisation_completed.message.no-creator',
              message: 'The package finalisation was completed',
            })
      }
    />
  );
};
