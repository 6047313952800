import React, { forwardRef, Ref } from 'react';

import { createBEMClasses } from '@/utils/classname';

import './Stack.css';

interface StackProps {
  row?: boolean;
  mobileColumn?: boolean;
  gap?: React.CSSProperties['gap'];
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  flexWrap?: React.CSSProperties['flexWrap'];
  padding?: React.CSSProperties['padding'];

  ariaLabel?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const { block } = createBEMClasses('stack');

const Stack = forwardRef(function Stack(
  {
    row,
    mobileColumn,
    gap,
    alignItems,
    justifyContent,
    flexWrap,
    padding,
    ariaLabel,
    className,
    style,
    children,
  }: StackProps,
  ref: Ref<HTMLDivElement>
) {
  return (
    <div
      aria-label={ariaLabel}
      className={block({ row, 'mobile-column': mobileColumn }, className)}
      style={{
        display: 'flex',
        alignItems,
        justifyContent,
        gap,
        flexWrap: flexWrap ?? (row ? 'wrap' : undefined),
        padding,
        ...style,
      }}
      ref={ref}
    >
      {children}
    </div>
  );
});

export default Stack;
