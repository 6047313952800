import { Trans } from '@lingui/macro';

import { EmptyState } from '@/components/EmptyState';
import IllustrationNotificationEmptyState from '@/layouts/App/Navbar/NotificationList/components/IllustrationNotificationEmptyState/IllustrationNotificationEmptyState';
import { type TNotificationQueryFilter } from '@/models/notification';

const NotificationEmptyState = ({
  notificationFilter,
}: {
  notificationFilter?: TNotificationQueryFilter;
}) => {
  const title =
    notificationFilter === 'archivedOnly' ? (
      <Trans id="notification.empty-state.archived-only.title">No archived notifications</Trans>
    ) : (
      <Trans id="notification.empty-state.title">You&apos;re all caught up!</Trans>
    );
  const subtitle =
    notificationFilter === 'archivedOnly' ? (
      <Trans id="notification.empty-state.archived-only.subtitle">
        All your archived notifications will appear here
      </Trans>
    ) : (
      <Trans id="notification.empty-state.subtitle">
        You don&apos;t have any notifications to display
      </Trans>
    );

  return (
    <EmptyState
      title={title}
      subtitle={subtitle}
      illustration={<IllustrationNotificationEmptyState />}
    />
  );
};

export default NotificationEmptyState;
