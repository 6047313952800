import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityDetailedContent,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconAnalysisIssue from '@/icons/AnalysisIssue.svg';

export const ArticleRequalifiedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_requalified'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="warning"
      icon={<IconAnalysisIssue />}
      creatorName={`${activity.creator.name} (${activity.workshop.name})`}
      message={
        <>
          <BaseActivityText>
            <Trans id="activities.article_requalified.message">
              submitted a re-qualification for the item
            </Trans>
          </BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
        </>
      }
    >
      {activity.data.comment && activity.data.comment.trim() !== '' && (
        <BaseActivityDetailedContent>{activity.data.comment}</BaseActivityDetailedContent>
      )}
      {/* TODO: Add actions, custom actions and photo here */}
    </BaseActivity>
  );
};
