import { Button as AriaButton } from 'react-aria-components';

import IconCancel from '@/icons/Cancel.svg';
import { createBEMClasses } from '@/utils/classname';

import './Chip.css';

const { block } = createBEMClasses('chip');

interface ChipProps {
  size?: 'large' | 'small' | 'x-small';
  children: React.ReactNode;
  selected?: boolean;
  onCancel?: () => void;
}

const Chip = ({ size = 'small', children, selected, onCancel }: ChipProps) => {
  const className = block({
    size,
    selected,
  });

  if (onCancel) {
    return (
      <AriaButton id="" onPress={onCancel} className={className}>
        {children}
        <IconCancel />
      </AriaButton>
    );
  }

  return <div className={className}>{children}</div>;
};

export default Chip;
