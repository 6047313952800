import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { useArticleName } from '@/models/article';
import { ShipmentWithRelations, useVerifyArticle } from '@/models/shipment';

const ReportIssueDialog = ({
  shipmentArticle,
  isOpen,
  setIsOpen,
}: {
  shipmentArticle: ShipmentWithRelations['articles'][number];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const { _ } = useLingui();

  return (
    <Dialog
      title={_(msg({ id: 'report-issue-modal.title', message: 'Report a shipment issue' }))}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
    >
      <ReportIssueForm shipmentArticle={shipmentArticle} close={() => setIsOpen(false)} />
    </Dialog>
  );
};

const ReportIssueForm = ({
  shipmentArticle,
  close,
}: {
  shipmentArticle: ShipmentWithRelations['articles'][number];
  close: () => void;
}) => {
  const { _ } = useLingui();

  const [issue, setIssue] = useState(shipmentArticle.issue ?? '');
  const [showErrors, setShowErrors] = useState(false);

  const articleName = useArticleName({ article: shipmentArticle.article });

  const {
    mutateAsync: verifyArticle,
    isPending,
    isSuccess,
  } = useVerifyArticle({
    shipmentId: shipmentArticle.shipmentId,
    articleId: shipmentArticle.articleId,
  });

  const save = async () => {
    setShowErrors(true);

    if (!issue) {
      return;
    }

    await verifyArticle({
      verified: true,
      issue,
    });

    close();
  };

  return (
    <>
      <main>
        <Stack gap="1rem">
          <p className="paragraph-100-regular text-secondary">
            <Trans id="report-issue-modal.intro">
              If you notice a shipment issue with the <b>{articleName}</b> item (damaged,
              missing...), please let us know below:
            </Trans>
          </p>
          <TextArea
            ariaLabel={_(
              msg({
                id: 'report-issue-modal.label',
                message: 'Shipment issue description',
              })
            )}
            placeholder={_(
              msg({
                id: 'report-issue-modal.placeholder',
                message: 'Describe the issue...',
              })
            )}
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
            error={
              showErrors && !issue
                ? _(
                    msg({
                      id: 'report-issue-modal.error',
                      message: 'Please describe the shipment issue',
                    })
                  )
                : undefined
            }
            rows={4}
          />
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" size="medium" onPress={close}>
          <Trans id="report-issue-modal.actions.cancel">Cancel</Trans>
        </Button>
        <Button variant="danger" size="medium" onPress={save} isLoading={isPending || isSuccess}>
          <Trans id="report-issue-modal.actions.report">Report issue</Trans>
        </Button>
      </footer>
    </>
  );
};

export default ReportIssueDialog;
