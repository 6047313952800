import IconWrapper from './IconWrapper';

const IconCross = IconWrapper({
  name: 'cross',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7824 5.21967C19.0753 5.51256 19.0753 5.98744 18.7824 6.28033L13.0617 12.0011L18.7824 17.7218C19.0753 18.0147 19.0753 18.4895 18.7824 18.7824C18.4895 19.0753 18.0147 19.0753 17.7218 18.7824L12.0011 13.0617L6.28033 18.7824C5.98744 19.0753 5.51256 19.0753 5.21967 18.7824C4.92678 18.4895 4.92678 18.0147 5.21967 17.7218L10.9404 12.0011L5.21967 6.28033C4.92678 5.98744 4.92678 5.51256 5.21967 5.21967C5.51256 4.92678 5.98744 4.92678 6.28033 5.21967L12.0011 10.9404L17.7218 5.21967C18.0147 4.92678 18.4895 4.92678 18.7824 5.21967Z"
      />
    </svg>
  ),
});

export default IconCross;
