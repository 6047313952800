import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconVisibility from '@/icons/Visibility.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const NewSupervisorAssignmentNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'new_supervisor_assignment'>;
}) => {
  const { _ } = useLingui();
  return (
    <BaseNotification
      notificationLabel={_(
        msg({
          id: 'notification.new-supervisor-assignment.label',
          message: `You have been assigned as the supervisor of the request ${notification.request.reference} by ${notification.data.authorName}`,
        })
      )}
      icon={<IconVisibility on />}
      type="new"
      notification={notification}
      notificationHeader={
        <Trans id="notification.new-supervisor-assignment">
          You have been assigned as the supervisor of the request by{' '}
          <span className="paragraph-100-medium">{notification.data.authorName}</span>
        </Trans>
      }
    />
  );
};
