import { Trans } from '@lingui/macro';

import Stack from '@/design_system/Stack';
import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';
import { GoToShipment } from './GoToShipment';

export const FinaliseShipmentTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      article={article}
      title={<Trans id="article.task.finalise-shipment.title">Finalize the package</Trans>}
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          <p>
            <Trans id="article.task.finalise-shipment.text">
              Go to the shipment page and print the shipping label.
            </Trans>
          </p>
          <GoToShipment article={article} />
        </Stack>
      }
    />
  );
};
