import IconWrapper from './IconWrapper';

const IconClock = IconWrapper({
  name: 'clock',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V12.0843L16.3975 14.364C16.7488 14.5835 16.8555 15.0463 16.636 15.3975C16.4165 15.7488 15.9538 15.8555 15.6025 15.636L11.6131 13.1426C11.5924 13.1301 11.5723 13.1167 11.553 13.1023C11.4636 13.0361 11.3928 12.9531 11.3422 12.8605C11.2932 12.7713 11.2618 12.6711 11.2527 12.5645C11.2505 12.5387 11.2496 12.5128 11.25 12.4868V7C11.25 6.58579 11.5858 6.25 12 6.25Z"
      />
    </svg>
  ),
});

export default IconClock;
