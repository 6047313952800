import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const ArticleArchivedMessage = ({ article }: { article: ArticleWithRelations }) => {
  if (!article.archivalDetail) {
    return null;
  }

  if (article.archivalDetail.type === 'completed') {
    if (article.cancellationDetail) {
      return <BaseMessage article={article} />;
    } else {
      return <CompletedMessage />;
    }
  }

  if (article.archivalDetail.type === 'manual') {
    return <ManuallyArchivedMesage article={article} />;
  }

  if (article.archivalDetail.type === 'automatic') {
    return <AutomaticallyArchivedMessage />;
  }

  return null;
};

const CompletedMessage = () => {
  return (
    <AlertBar
      type="success"
      size="large"
      hideIcon
      title={<Trans id="article.archived-message.completed.title">The item is completed</Trans>}
    />
  );
};

const ManuallyArchivedMesage = ({ article }: { article: ArticleWithRelations }) => {
  if (article.hasIssue) {
    return (
      <AlertBar
        type="error"
        size="large"
        hideIcon
        title={
          <Trans id="article.archived-message.issue.title">
            This item has been archived after a shipment issue
          </Trans>
        }
      >
        {!!article.archivalDetail?.reason && (
          <p>
            <Trans id="article.archived-message.issue.text">Archival reason:</Trans> &quot;
            {article.archivalDetail.reason}
            &quot;
          </p>
        )}
      </AlertBar>
    );
  }

  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={<Trans id="article.archived-message.manual.title">The item is archived</Trans>}
    >
      <p>
        <Trans id="article.archived-message.manual.text">
          The item has been manually archived before its completion.
        </Trans>
      </p>
    </AlertBar>
  );
};

const AutomaticallyArchivedMessage = () => {
  return (
    <AlertBar
      type="error"
      size="large"
      hideIcon
      title={
        <Trans id="article.archived-message.automatic.title">
          The item has been automatically archived
        </Trans>
      }
    />
  );
};
