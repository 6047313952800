import { ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';
import { ToastAria, useToast, useToastRegion } from '@react-aria/toast';
import type { QueuedToast, ToastState } from '@react-stately/toast';
import { ToastQueue, useToastQueue } from '@react-stately/toast';

import IconDone from '@/icons/Done.svg';
import IconError from '@/icons/Error.svg';
import IconInfo from '@/icons/Info.svg';
import IconWarning from '@/icons/Warning.svg';
import { createBEMClasses } from '@/utils/classname';

import './Toast.css';

type ToastType = 'info' | 'success' | 'warning' | 'error';

interface ToastProps {
  text: ReactNode;
  type?: ToastType;
}

const iconForType = {
  info: <IconInfo />,
  success: <IconDone />,
  warning: <IconWarning />,
  error: <IconError />,
};

export const toastQueue = new ToastQueue<ToastProps>({
  maxVisibleToasts: 5,
  hasExitAnimation: true,
});

export const GlobalToastRegion = () => {
  const state = useToastQueue<ToastProps>(toastQueue);
  const ref = useRef<HTMLDivElement>(null);
  const { regionProps } = useToastRegion({}, state, ref);

  if (state.visibleToasts.length) {
    return createPortal(
      <div {...regionProps} ref={ref} className="toast-region">
        {state.visibleToasts.map((toast) => (
          <Toast key={toast.key} toast={toast} state={state} />
        ))}
      </div>,
      document.body
    );
  }

  return null;
};

const Toast = ({
  state,
  toast,
}: {
  state: ToastState<ToastProps>;
  toast: QueuedToast<ToastProps>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { toastProps, titleProps } = useToast({ toast }, state, ref);

  return (
    <div
      {...toastProps}
      ref={ref}
      className="toast-wrapper"
      data-animation={toast.animation}
      onAnimationEnd={() => {
        // Remove the toast when the exiting animation completes.
        if (toast.animation === 'exiting') {
          state.remove(toast.key);
        }
      }}
    >
      <ToastElement text={toast.content.text} type={toast.content.type} titleProps={titleProps} />
    </div>
  );
};

const { block } = createBEMClasses('toast');

export const ToastElement = ({
  text,
  type = 'success',
  titleProps,
}: ToastProps & { titleProps?: ToastAria['titleProps'] }) => {
  return (
    <div className={block({ type })}>
      {iconForType[type]}
      <div {...titleProps}>{text}</div>
    </div>
  );
};

export const useShowToast = () => {
  return (details: ToastProps) => {
    toastQueue.add(details, { timeout: 5000 });
  };
};
