import IconWrapper from './IconWrapper';

const IconInbox = IconWrapper({
  name: 'inbox',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.93636 6.60343C4.43453 5.05212 5.87743 4 7.50677 4H16.4907C18.1201 4 19.563 5.05212 20.0612 6.60344L21.9614 12.5207C21.9957 12.6278 21.9988 12.7378 21.9973 12.8491L21.9973 15.302C21.9973 16.2005 21.9973 16.9497 21.9174 17.5445C21.833 18.1723 21.6473 18.7391 21.1918 19.1945C20.7363 19.65 20.1695 19.8357 19.5417 19.9201C18.947 20.0001 18.1977 20 17.2993 20H17.2473H6.75024H6.69824C5.79976 20 5.05054 20.0001 4.45576 19.9201C3.82796 19.8357 3.26116 19.65 2.80569 19.1945C2.35022 18.7391 2.16455 18.1723 2.08015 17.5445C2.00018 16.9497 2.00021 16.2005 2.00024 15.302L2.00024 12.85C1.99878 12.7384 2.00166 12.6281 2.03615 12.5207L3.93636 6.60343ZM3.77881 12H8.92647H8.95854C9.32359 12 9.63562 12.2628 9.69764 12.6226C9.87878 13.6733 10.8277 14.5 11.9988 14.5C13.1698 14.5 14.1187 13.6733 14.2999 12.6226C14.3619 12.2628 14.6739 12 15.039 12H15.071H20.2187L18.633 7.06206C18.3341 6.13127 17.4683 5.5 16.4907 5.5H7.50677C6.52917 5.5 5.66343 6.13127 5.36452 7.06206L3.77881 12ZM15.6135 13.5C15.0846 14.9659 13.6566 16 11.9988 16C10.3409 16 8.91292 14.9659 8.38402 13.5H3.50024V15.25C3.50024 16.214 3.50183 16.8616 3.56677 17.3446C3.62881 17.8061 3.73604 18.0036 3.86635 18.1339C3.99667 18.2642 4.19417 18.3714 4.65563 18.4335C5.13867 18.4984 5.78623 18.5 6.75024 18.5H17.2473C18.2113 18.5 18.8588 18.4984 19.3419 18.4335C19.8033 18.3714 20.0008 18.2642 20.1312 18.1339C20.2615 18.0036 20.3687 17.8061 20.4307 17.3446C20.4957 16.8616 20.4973 16.214 20.4973 15.25V13.5H15.6135Z"
      />
    </svg>
  ),
});

export default IconInbox;
