import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Endpoints, PickupPoints } from '@/api';
import { Locale } from '@/services/i18n';
import { useFetch } from '@/utils/fetch';

import Phone, { formatPhone } from './partials/phone';
import { Address, AddressRaw } from './address';
import { Model } from './model';

export class Store extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  name!: string;
  defaultLocale!: Locale;
  address!: Address | null;
  phone!: Phone | null;
  addressId!: string | null;
  data!: Record<string, any>;
  pickupPoints!: PickupPoints;
  organizationConfig?: { externalId: string | null; external: boolean };
  createdAt!: string;

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get formattedPhone() {
    return formatPhone(this.phone);
  }
}

export const useStore = (id?: string | null) => {
  const fetch = useFetch<Endpoints['GET /stores/:id']>();

  return useQuery({
    queryKey: ['stores', id],
    queryFn: () =>
      fetch(`/stores/${id!}`).then((store) =>
        new Store(store).with('address', store.address ? new Address(store.address) : null)
      ),
    enabled: !!id,
  });
};

export const useStores = (
  params: Endpoints['GET /stores']['query'],
  options?: {
    enabled?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /stores']>();

  return useQuery({
    queryKey: ['stores', params],
    queryFn: () =>
      fetch('/stores', params).then(({ stores, meta }) => ({
        stores: stores.map((store) =>
          new Store(store).with('address', store.address ? new Address(store.address) : null)
        ),
        meta,
      })),
    enabled: options?.enabled,
    placeholderData: params?.search ? keepPreviousData : undefined,
  });
};

export const useCreateStore = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /stores']>();

  return useMutation({
    mutationFn: (body: {
      name: string;
      externalId?: string;
      external?: boolean;
      defaultLocale?: Locale;
      address: AddressRaw;
      phone: Phone;
    }) => fetch('/stores', undefined, { method: 'POST', body }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['stores'] });
    },
  });
};

export const useUpdateStore = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /stores/:id']>();

  return useMutation({
    mutationFn: ({
      storeId,
      ...body
    }: {
      storeId: string;
    } & Endpoints['PATCH /stores/:id']['body']) =>
      fetch(`/stores/${storeId}`, undefined, { method: 'PATCH', body }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['stores'] });
    },
  });
};

export const useUpdateExternalStore = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /stores/external/:id']>();

  return useMutation({
    mutationFn: ({
      storeId,
      ...body
    }: {
      storeId: string;
    } & Endpoints['PATCH /stores/external/:id']['body']) =>
      fetch(`/stores/external/${storeId}`, undefined, { method: 'PATCH', body }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['stores'] });
    },
  });
};

export const useDeleteStore = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['DELETE /stores/:id']>();

  return useMutation({
    mutationFn: (storeId: string) => fetch(`/stores/${storeId}`, undefined, { method: 'DELETE' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['stores'] });
    },
  });
};
