import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { Endpoints, ProductL1, ProductL2, ProductL3 } from '@/api';
import { useFetch } from '@/utils/fetch';

import { NeedL1, NeedL2, NeedL3, NeedL4 } from './actionType';
import { Model } from './model';
import { ArticleDefectWithRelations } from './request';
import { Translation, useDatabaseTranslation } from './translation';

export class DefectType extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
    this.name = new Translation(data.name);
  }

  id!: string;
  l1!: NeedL1;
  l2!: NeedL2 | null;
  l3!: NeedL3 | null;
  l4!: NeedL4 | null;
  name!: Translation;
  nameId!: string;
  isQuantifiable!: boolean;
  createdAt!: string;
}

export class DefectTypeOrganization extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  productL1!: ProductL1[];
  productL2!: ProductL2[];
  productL3!: ProductL3[];
  defectTypeId!: string;
  createdAt!: string;
}

export class ArticleDefect extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  id!: string;
  defectTypeOrganizationId!: string | null;
  customDescription!: string | null;
  isReparable!: boolean;
  createdAt!: string;
  articleActions!: { id: string }[];
}

export const instanciateDefectTypeOrganization = (
  defectTypeOrganization: Endpoints['GET /defect-types']['response'][number]
) =>
  new DefectTypeOrganization(defectTypeOrganization).with(
    'defectType',
    new DefectType(defectTypeOrganization.defectType)
  );

export type DefectTypeOrganizationWithRelations = ReturnType<
  typeof instanciateDefectTypeOrganization
>;

export const useDefectTypes = (
  params: Endpoints['GET /defect-types']['query'],
  options?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /defect-types']>();

  return useQuery({
    queryKey: ['defect-types', params],
    queryFn: () =>
      fetch('/defect-types', params).then((defectTypes) =>
        defectTypes.map(instanciateDefectTypeOrganization)
      ),
    enabled: options?.enabled,
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
  });
};

export const useGetDefectName = () => {
  const { _db } = useDatabaseTranslation();

  return (defect: ArticleDefectWithRelations) => {
    if (defect.defectTypeOrganization) {
      return _db(defect.defectTypeOrganization.defectType.name);
    } else {
      return defect.customDescription ?? '';
    }
  };
};

export const useDefectName = (defect: ArticleDefectWithRelations) => {
  const getDefectName = useGetDefectName();

  return getDefectName(defect);
};

/**
 *
 * @returns Returns true if both defects are of the same type
 */
const areDefectsDuplicates = (
  defect1: ArticleDefectWithRelations,
  defect2: ArticleDefectWithRelations
): boolean => {
  return (
    (!!defect1.customDescription && defect1.customDescription === defect2.customDescription) ||
    (!!defect1.defectTypeOrganizationId &&
      defect1.defectTypeOrganizationId === defect2.defectTypeOrganizationId)
  );
};

/**
 * Formats a list of defects so that defects of the same type are merged into a single defect for which:
 * - `quantity` is total of merged defects
 * - `ids` is a list of the merged defects' ids
 * - the other fields simply correspond to the first of the merged defects
 *
 * Defects are of the same type if they have the same `customDescription` or `defectTypeOrganizationId`
 */
export const getGroupedDefects = (defects: ArticleDefectWithRelations[]) => {
  return defects.reduce<(ArticleDefectWithRelations & { ids: string[]; quantity: number })[]>(
    (acc, defect) => {
      const existingDefect = acc.find((accDefect) => areDefectsDuplicates(defect, accDefect));

      if (!existingDefect) {
        acc.push(
          Object.assign(structuredClone(defect), {
            ids: [defect.id],
            quantity: 1,
          })
        );
      } else {
        existingDefect.ids = [...existingDefect.ids, defect.id];
        existingDefect.quantity = existingDefect.quantity + 1;
      }

      return acc;
    },
    []
  );
};
