import { Activities } from '@/components/activities/Activities/Activities';
import { RequestWithRelations, useInfiniteActivities } from '@/models/request';

export const RequestActivities = ({ request }: { request: RequestWithRelations }) => {
  const { data, isLoading, isError, fetchNextPage } = useInfiniteActivities({
    requestId: request.id,
  });

  const activities = data?.pages.flatMap((page) => page.activities) ?? [];

  return (
    <Activities
      activities={activities}
      isLoading={isLoading}
      isError={isError}
      nbOfArticles={request.allArticles.length}
      loadNextPage={() => {
        fetchNextPage();
      }}
    />
  );
};
