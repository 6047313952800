import { useMemo } from 'react';

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

/**
 * iOS doesn't allow to download through base64 data urls.
 * Therefore, we must first translate it into a Blob url.
 *
 * Sources:
 * - https://bugs.webkit.org/show_bug.cgi?id=167341
 * - https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 */

export function useBlobUrl(base64: string | null, contentType: string = '') {
  return useMemo(() => {
    if (!base64) {
      return null;
    }

    return URL.createObjectURL(b64toBlob(base64, contentType));
  }, [base64, contentType]);
}
