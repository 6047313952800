import { Trans } from '@lingui/macro';

import VipBadge from '@/components/VipBadge';
import Stack from '@/design_system/Stack';
import IconEmail from '@/icons/Email.svg';
import IconPhone from '@/icons/Phone.svg';
import IconPlace from '@/icons/Place.svg';
import IconStore from '@/icons/Store.svg';
import IconTruck from '@/icons/Truck.svg';
import IconPerson from '@/icons/User.svg';
import { Address } from '@/models/address';
import { DeliveryOption } from '@/models/client';
import Phone, { formatPhone } from '@/models/partials/phone';

export type ContactDetailsProps = {
  type?: 'client' | 'store';
  name?: string;
  role?: string;
  email?: string | null;
  addressName?: string;
  address?: Address | null;
  phone?: Phone | null;
  deliveryChoice?: DeliveryOption | null;
  size?: 'small' | 'medium';
  isVIP?: boolean;
  placeIcon?: 'start' | 'end';
};

const ContactDetails = ({
  type = 'client',
  name,
  role,
  email,
  addressName,
  address,
  phone,
  deliveryChoice,
  size = 'medium',
  isVIP,
  placeIcon = 'end',
}: ContactDetailsProps) => {
  const Icon = {
    client: IconPerson,
    store: IconStore,
  }[type];

  const textStyle = size === 'small' ? 'paragraph-200-regular' : 'paragraph-100-regular';
  const iconSize = size === 'small' ? '1rem' : '1.25rem';

  return (
    <Stack gap={size === 'small' ? '0.5rem' : '0.25rem'} className="sentry-mask">
      {(name || role) && (
        <Stack row flexWrap="nowrap" gap="0.5rem">
          <Icon style={{ fontSize: iconSize, paddingTop: '0.125rem' }} />
          <div>
            <Stack row flexWrap="nowrap" gap="0.5rem">
              <p className={size === 'small' ? 'paragraph-200-medium' : 'paragraph-100-medium'}>
                {name}
              </p>
              {isVIP && <VipBadge />}
            </Stack>
            {role && (
              <p className="paragraph-200-regular" style={{ paddingBottom: '0.25rem' }}>
                {role}
              </p>
            )}
          </div>
        </Stack>
      )}
      {email && (
        <Stack row flexWrap="nowrap" gap="0.5rem" alignItems="center">
          <IconEmail style={{ fontSize: iconSize }} />
          <p style={{ overflow: 'auto' }} className={textStyle}>
            {email}
          </p>
        </Stack>
      )}
      {phone && (
        <Stack row flexWrap="nowrap" gap="0.5rem" alignItems="center">
          <IconPhone style={{ fontSize: iconSize }} />
          <p className={textStyle}>{formatPhone(phone)}</p>
        </Stack>
      )}
      {(addressName || address) && (
        <Stack row flexWrap="nowrap" gap="0.5rem">
          <IconPlace
            end={placeIcon === 'end'}
            start={placeIcon === 'start'}
            style={{
              fontSize: iconSize,
              paddingTop: '0.125rem',
              marginTop: addressName ? '0.125rem' : undefined,
            }}
          />
          <div>
            {addressName && <p className="paragraph-100-medium">{addressName}</p>}
            {address && (
              <>
                <p className={textStyle}>{address.formattedStreet}</p>
                <p className={textStyle}>{address.formattedZip}</p>
              </>
            )}
          </div>
        </Stack>
      )}
      {deliveryChoice && (
        <Stack row flexWrap="nowrap" gap="0.5rem" alignItems="center">
          <IconTruck style={{ fontSize: iconSize }} />
          <p className={textStyle}>
            {deliveryChoice === 'store' && (
              <Trans id="contact-details.delivery.in-store">Shipping back in store</Trans>
            )}
            {deliveryChoice === 'home' && (
              <Trans id="contact-details.delivery.at-home">Shipping back at home</Trans>
            )}
            {deliveryChoice === 'pickupPoint_mondialRelay' && (
              <Trans id="contact-details.delivery.pickup-point">
                Shipping back in pickup point (Mondial Relay)
              </Trans>
            )}
          </p>
        </Stack>
      )}
    </Stack>
  );
};

export default ContactDetails;
