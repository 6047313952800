import IconWrapper from '@/icons/IconWrapper';

const IconSlider = IconWrapper({
  name: 'slider',
  source: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
    >
      <g className="cursor">
        <g className="fill-accent">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="m81.902 90.58-5.144-41.572C75.96 42.574 70.494 37.745 64 37.745c-6.494 0-11.961 4.829-12.758 11.263L46.098 90.58C45.153 98.243 51.134 105 58.856 105H69.13c7.736-.014 13.717-6.771 12.772-14.42Zm-13.015 9.712c2.35 0 4.644-.89 6.237-2.616 1.647-1.794 2.403-4.114 2.093-6.515a10.188 10.188 0 0 0-10.112-8.93h-6.21c-5.144 0-9.477 3.83-10.112 8.93-.297 2.4.46 4.72 2.106 6.515 1.58 1.726 3.875 2.616 6.224 2.616h9.774Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            d="M65.674 46.486h-3.348a1.852 1.852 0 0 1-1.85-1.848V31.783c0-1.012.824-1.848 1.85-1.848h3.348c1.012 0 1.85.823 1.85 1.848v12.855a1.843 1.843 0 0 1-1.85 1.848Z"
          />
          <path
            fill="#fff"
            d="M73.433 41.86h.166V31.35A7.354 7.354 0 0 0 66.24 24h-4.469a7.354 7.354 0 0 0-7.357 7.351V41.86h.152A12.843 12.843 0 0 1 64 37.745a12.84 12.84 0 0 1 9.433 4.114Z"
          />
        </g>
        <g className="stroke">
          <path
            fill="#3F4643"
            d="M60.476 39.277v-2.08c-5.368 1.407-9.517 5.956-10.226 11.688l-5.144 41.573C44.087 98.717 50.534 106 58.856 106h10.276c8.332-.015 14.781-7.295 13.762-15.542L77.75 48.885a13.843 13.843 0 0 0-10.227-11.688v2.08a11.842 11.842 0 0 1 8.243 9.854l5.143 41.572c.871 7.05-4.642 13.285-11.78 13.297H58.856c-7.122 0-12.636-6.232-11.765-13.297l5.143-41.572a11.842 11.842 0 0 1 8.242-9.854Z"
          />
          <path
            fill="#3F4643"
            fillRule="evenodd"
            d="M49.79 91.037a11.188 11.188 0 0 1 11.105-9.806h6.21a11.189 11.189 0 0 1 11.104 9.804c.348 2.7-.507 5.311-2.348 7.317l-.002.002c-1.804 1.955-4.378 2.938-6.972 2.938h-9.774c-2.593 0-5.169-.982-6.961-2.94-1.842-2.007-2.695-4.618-2.361-7.315Zm11.105-7.806a9.188 9.188 0 0 0-9.12 8.053c-.26 2.105.399 4.134 1.85 5.715l.002.002c1.367 1.494 3.38 2.291 5.486 2.291h9.774c2.104 0 4.12-.796 5.501-2.293 1.452-1.582 2.109-3.61 1.837-5.71v-.005a9.188 9.188 0 0 0-9.12-8.053h-6.21Zm1.431-52.296c-.47 0-.85.385-.85.848v12.855c0 .467.384.848.85.848h3.348c.475 0 .85-.376.85-.848V31.783a.852.852 0 0 0-.85-.848h-3.348Zm-2.85.848a2.852 2.852 0 0 1 2.85-2.848h3.348c1.56 0 2.85 1.265 2.85 2.848v12.855a2.843 2.843 0 0 1-2.85 2.848h-3.348a2.852 2.852 0 0 1-2.85-2.848V31.783Z"
            clipRule="evenodd"
          />
          <path
            fill="#3F4643"
            d="M55.415 31.351A6.354 6.354 0 0 1 61.772 25h4.47a6.354 6.354 0 0 1 6.357 6.351v8.38a13.84 13.84 0 0 0-5.076-2.534v2.08c2 .62 3.78 1.756 5.177 3.262l.297.32h1.602V31.351c0-4.613-3.743-8.351-8.358-8.351h-4.469c-4.615 0-8.357 3.738-8.357 8.351V42.86h1.588l.297-.32a11.862 11.862 0 0 1 5.176-3.262v-2.08a13.842 13.842 0 0 0-5.061 2.523v-8.369Z"
          />
        </g>
      </g>
    </svg>
  ),
});

export default IconSlider;
