import { StatusDueDate } from '@/components/DueDate';
import { ShipmentWithRelations } from '@/models/shipment';

export const ShipmentStatusDueAtCell = ({
  shipment,
  variant,
}: {
  shipment: ShipmentWithRelations;
  variant: 'card' | 'row';
}) => {
  if (!shipment.statusDueAtDate) {
    return null;
  }

  return <StatusDueDate date={shipment.statusDueAtDate} variant={variant} displayDayMonthOnly />;
};
