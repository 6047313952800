import { RequestWithRelations } from '@/models/request';

import { PaymentAction } from './components/PaymentAction';

export const RequestStepActions = ({ request }: { request: RequestWithRelations }) => {
  if (request.isInPaymentStep) {
    return <PaymentAction request={request} />;
  }

  return null;
};
