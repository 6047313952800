import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import AddressForm, {
  getAddressError,
  isAddressValid,
  useAddressState,
} from '@/components/AddressForm/AddressForm';
import { InputLocale } from '@/components/InputLocale/InputLocale';
import InputPhone, {
  getValidCountryCallingCode,
  isPhoneValid,
  usePhoneState,
} from '@/components/InputPhone/InputPhone';
import InputText from '@/design_system/InputText/InputText';
import Stack from '@/design_system/Stack';
import { AddressRaw } from '@/models/address';
import Phone from '@/models/partials/phone';
import { useGeoIpCountry } from '@/services/auth';
import { Locale } from '@/services/i18n';

export interface StoreRaw {
  id: string;
  name: string;
  address: AddressRaw | undefined | null;
  defaultLocale: Locale;
  phone: Phone | null;
  organizationConfig: { externalId: string | null; external: boolean };
}

export const StoreForm = ({
  state,
  error,
  isDisabled = false,
  size = 'small',
  variant = 'default',
  allowExternalIdEdition = false,
}: {
  state: StoreState;
  error?: string;
  isDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'brand';
  allowExternalIdEdition?: boolean;
}) => {
  const { _ } = useLingui();
  const {
    name,
    setName,
    address,
    setAddress,
    phone,
    setPhone,
    externalId,
    setExternalId,
    defaultLocale,
    setDefaultLocale,
  } = state;

  const { nameError, addressError, phoneError } = getStoreError(state);

  return (
    <Stack gap="24px">
      <InputText
        label={<Trans id="components.store-form.field.name.label">Name of the store</Trans>}
        type="text"
        placeholder={_(
          msg({
            id: 'components.store-form.field.name.placeholder',
            message: 'Name of the store',
          })
        )}
        size={size}
        value={name}
        onChange={setName}
        isDisabled={isDisabled}
        error={
          state.showErrors && nameError
            ? _(
                msg({
                  id: 'components.store-form.field.name.error',
                  message: 'Please add a name',
                })
              )
            : undefined
        }
      />
      <AddressForm
        value={address}
        onChange={setAddress}
        isDisabled={isDisabled}
        contact={true}
        size={size}
        variant={variant}
        error={state.showErrors && addressError ? getAddressError(address, true) : undefined}
      />
      <InputPhone
        label={<Trans id="components.store-form.field.phone.label">Phone</Trans>}
        value={phone}
        onChange={setPhone}
        size={size}
        isDisabled={isDisabled}
        variant={variant}
        isInvalid={state.showErrors && phoneError}
      />
      {allowExternalIdEdition && (
        <InputText
          label={<Trans id="components.store-form.field.external-id.label">External ID</Trans>}
          value={externalId}
          onChange={setExternalId}
          size={size}
          isDisabled={isDisabled}
        />
      )}
      <InputLocale
        value={defaultLocale}
        onChange={setDefaultLocale}
        size={size}
        isDisabled={isDisabled}
      />
      {error && <p className="paragraph-100-medium text-danger text-center">{error}</p>}
    </Stack>
  );
};

export const useStoreState = (initialState?: Partial<StoreRaw> | null) => {
  const [name, setName] = useState(initialState?.name ?? '');
  const geoIpCountry = useGeoIpCountry();
  const [address, setAddress] = useAddressState(
    initialState?.address ?? { country: geoIpCountry ?? 'FR' }
  );
  const [phone, setPhone] = usePhoneState(
    initialState?.phone ?? {
      countryCode: getValidCountryCallingCode(geoIpCountry ?? 'FR'),
      number: '',
    }
  );
  const [external, setExternal] = useState(initialState?.organizationConfig?.external ?? false);
  const [externalId, setExternalId] = useState(initialState?.organizationConfig?.externalId ?? '');
  const [defaultLocale, setDefaultLocale] = useState(initialState?.defaultLocale ?? 'en');
  const [showErrors, setShowErrors] = useState(false);

  return {
    name,
    setName,
    address,
    setAddress,
    phone,
    setPhone,
    external,
    setExternal,
    externalId,
    setExternalId,
    defaultLocale,
    setDefaultLocale,
    showErrors,
    setShowErrors,
  };
};

export type StoreState = ReturnType<typeof useStoreState>;

export const getStoreError = (state: StoreState) => {
  const nameError = !state.name;

  const addressError = !isAddressValid(state?.address, true);
  const phoneError = state?.phone ? !isPhoneValid(state.phone) : true;

  return {
    nameError,
    addressError,
    phoneError,
    hasError: nameError || addressError || phoneError,
  };
};
