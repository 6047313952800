import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconTrash from '@/icons/Trash.svg';

export const ArticleMediumDeletedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_medium_deleted'>;
  displayArticleName: boolean;
}) => {
  const { _ } = useLingui();

  let articleMediaDeleteMessage: MessageDescriptor | undefined;
  if (activity.data.type === 'article-photo' || activity.data.type === 'defect-photo') {
    articleMediaDeleteMessage = msg({
      id: 'activities.article_medium_deleted.message_defect_photo',
      message: 'deleted a photo from item',
    });
  } else if (activity.data.type === 'proof-of-purchase') {
    articleMediaDeleteMessage = msg({
      id: 'activities.article_medium_deleted.message_proof_of_purchase',
      message: 'deleted a proof of purchase from item',
    });
  }

  if (!articleMediaDeleteMessage) return null;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconTrash />}
      type="danger"
      creatorName={activity.creator?.name ?? ''}
      message={
        <>
          <BaseActivityText>{_(articleMediaDeleteMessage)}</BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
        </>
      }
    />
  );
};
