import { Model } from './model';

export class ShipmentArticle extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  articleId!: string;
  shipmentId!: string;

  verified!: boolean;
  issue!: string | null;
  issueResolution!: string | null;

  createdAt!: string;
}
