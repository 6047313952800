import { Trans } from '@lingui/macro';

import { BrandWrapper } from '@/layouts/Brand';

const Error = () => {
  return (
    <BrandWrapper>
      <Trans id="brand.error">Something went wrong. Please try again later.</Trans>
    </BrandWrapper>
  );
};

export default Error;
