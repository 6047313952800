import { Comments } from '@/components/comments/Comments/Comments';
import {
  useComments,
  useCreateComment,
  useDeleteComment,
  useUpdateComment,
} from '@/models/shipment';

export const ShipmentComments = ({ shipmentId }: { shipmentId: string }) => {
  const { data: { comments } = { comments: [] } } = useComments({ shipmentId });
  const { mutateAsync: sendCreateCommentRequest } = useCreateComment({ shipmentId });
  const { mutateAsync: sendUpdateCommentRequest } = useUpdateComment({ shipmentId });
  const { mutateAsync: sendDeleteCommentRequest } = useDeleteComment({ shipmentId });

  return (
    <Comments
      comments={comments}
      onCommentCreated={async (data) => {
        await sendCreateCommentRequest(data);
      }}
      onCommentUpdated={async (data) => {
        await sendUpdateCommentRequest(data);
      }}
      onCommentDeleted={async (data) => {
        await sendDeleteCommentRequest(data);
      }}
    />
  );
};
