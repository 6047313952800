import { ReactNode } from 'react';

import { PageLayoutLeftPartFooter } from '@/components/PageLayout';
import { RequestWithRelations } from '@/models/request';
import CompleteDeliveryPendingClientAction from '@/routes/Requests/Request/components/RequestActionsBar/components/CompleteDeliveryPendingClientAction';
import { useCurrentSession } from '@/services/auth';

import { ValidatePaymentAction } from './components/ValidatePaymentAction/ValidatePaymentAction';

export const RequestActionsBar = ({
  request,
  onActionDone,
}: {
  request: RequestWithRelations;
  onActionDone: () => void;
}) => {
  const { currentSession } = useCurrentSession();

  const isResponsibleStore =
    request.storeId && currentSession?.stores.map((store) => store.id).includes(request.storeId);

  if (request.isArchived) {
    return null;
  }

  let actions: ReactNode = null;

  const areAllArticlesInPendingClientPickup = request.articles.every(
    (article) =>
      article.step?.step === 'transit' &&
      article.step?.config.originType === 'store' &&
      article.step?.config.destinationType === 'client'
  );

  // request.isInPendingClientDeliveryStep is still used to handle pre-workflow requests.
  // It should be removed when all legacy requests are archived.

  if (
    (areAllArticlesInPendingClientPickup || request.isInLegacyPendingClientDeliveryStep) &&
    isResponsibleStore
  ) {
    actions = (
      <CompleteDeliveryPendingClientAction request={request} onPickupCompleted={onActionDone} />
    );
  }

  if (request.isInPaymentStep) {
    actions = <ValidatePaymentAction request={request} onPaymentValidate={onActionDone} />;
  }

  if (!actions) {
    return null;
  }

  return <PageLayoutLeftPartFooter>{actions}</PageLayoutLeftPartFooter>;
};
