import { Button as AriaButton, Link as AriaLink } from 'react-aria-components';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Loader from '@/components/Loader';
import Stack from '@/design_system/Stack';
import IconCross from '@/icons/Cross.svg';
import IconError from '@/icons/Error.svg';
import IconFile from '@/icons/File.svg';
import { createBEMClasses } from '@/utils/classname';

import './FileCard.css';

export type FileCardProps = {
  name: string;
  url?: string;
  size: number;
  error?: boolean;
  loading?: boolean;
  onDelete?: () => void;
  ariaLabel?: string;
};

const { block } = createBEMClasses('file-card');

const FileCard = ({
  ariaLabel,
  name,
  url,
  size,
  error = false,
  onDelete,
  loading,
}: FileCardProps) => {
  const { _ } = useLingui();

  const formattedSize =
    size < 1024
      ? `${size}B`
      : size < 1024 * 1024
        ? `${(size / 1024).toFixed(0)}KB`
        : `${(size / 1024 / 1024).toFixed(1)}MB`;

  return (
    <Stack
      row
      gap="0.5rem"
      alignItems="center"
      ariaLabel={ariaLabel}
      className={block({ error, loading })}
      flexWrap="nowrap"
    >
      {loading ? <Loader style={{ margin: '2px' }} /> : error ? <IconError /> : <IconFile />}
      {url ? (
        <AriaLink href={url} target="_blank" rel="noreferrer" className="text-ellipsis">
          <span className="paragraph-100-regular text-primary" title={name}>
            {name}
          </span>
        </AriaLink>
      ) : (
        <span className="paragraph-100-regular text-primary text-ellipsis">{name}</span>
      )}
      <span className="paragraph-100-regular text-disabled">{formattedSize}</span>
      {onDelete && (
        <AriaButton
          onPress={onDelete}
          aria-label={_(
            msg({ id: 'design-system.file-card.remove', message: `Remove file ${name}` })
          )}
        >
          <IconCross />
        </AriaButton>
      )}
    </Stack>
  );
};

export default FileCard;
