import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  RequestOrganizationPrice,
  RequestWorkshopPrice,
} from '@/components/ArticlesTableCells/ArticleMoneyCell';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import IconArchive from '@/icons/Archive.svg';
import IconClock from '@/icons/Clock.svg';
import IconTask from '@/icons/Task.svg';
import {
  ArchivedArticlesCardList,
  ArchivedArticlesTable,
  CompletedArticlesCardList,
  CompletedArticlesTable,
  OnGoingArticlesCardList,
} from '@/routes/Requests/components/ArticlesTable';
import { OnGoingArticlesTable } from '@/routes/Requests/components/ArticlesTable/OnGoingArticlesTable';
import { useRequestContext } from '@/routes/Requests/RequestContext';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './RequestArticles.css';

const { block, element } = createBEMClasses('request-articles');

export const RequestArticles = () => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const { currentSession, isWorkshop } = useCurrentSession();

  const {
    request,
    view: { price, cost },
  } = useRequestContext();

  const articlesWithTaskToDo = request.articles.filter((article) =>
    article.task?.userRoles.some(({ permission, scope }) =>
      currentSession?.hasPermission(permission, scope)
    )
  );

  const completedArticles = request.articles.filter((article) => article.completed);

  const otherArticles = request.articles.filter(
    (article) => !articlesWithTaskToDo.includes(article) && !completedArticles.includes(article)
  );

  return (
    <Stack gap="1rem" className={block()}>
      {articlesWithTaskToDo.length > 0 && (
        <Box padding="0">
          <Stack>
            <Stack className={element('pending-tasks')} row>
              <IconTask />
              <span className="paragraph-100-medium">
                <Trans id="request.articles.table.pending-tasks.label">Pending item tasks</Trans>
              </span>
            </Stack>
            {isMobile ? (
              <OnGoingArticlesCardList
                articles={articlesWithTaskToDo}
                mode="task"
                label={_(
                  msg({
                    id: 'request.articles.table.pending-tasks.label',
                    message: 'Pending item tasks',
                  })
                )}
              />
            ) : (
              <OnGoingArticlesTable articles={articlesWithTaskToDo} mode="task" />
            )}
          </Stack>
        </Box>
      )}
      {otherArticles.length > 0 && (
        <Box padding="0">
          <Stack>
            <Stack className={element('awaiting-tasks')} row>
              <IconClock />
              <span className="paragraph-100-medium">
                <Trans id="request.articles.table.waiting-tasks.label">Waiting for others</Trans>
              </span>
            </Stack>
            {isMobile ? (
              <OnGoingArticlesCardList
                articles={otherArticles}
                mode="step"
                label={_(
                  msg({
                    id: 'request.articles.table.waiting-tasks.label',
                    message: 'Waiting for others',
                  })
                )}
              />
            ) : (
              <OnGoingArticlesTable articles={otherArticles} mode="step" />
            )}
          </Stack>
        </Box>
      )}
      {completedArticles.length > 0 && (
        <Box padding="0">
          <Stack>
            <Stack className={element('completed-articles')} row>
              <IconClock />
              <span className="paragraph-100-medium">
                <Trans id="request.articles.table.completed.label">Completed items</Trans>
              </span>
            </Stack>
            {isMobile ? (
              <CompletedArticlesCardList articles={completedArticles} />
            ) : (
              <CompletedArticlesTable articles={completedArticles} />
            )}
          </Stack>
        </Box>
      )}
      {request.archivedArticles.length > 0 && (
        <Box padding="0">
          <Stack>
            <Stack className={element('archived-articles')} row>
              <IconArchive />
              <span className="paragraph-100-medium">
                <Trans id="request.articles.table.archived-articles.label">Archived items</Trans>
              </span>
            </Stack>
            {isMobile ? (
              <ArchivedArticlesCardList articles={request.archivedArticles} />
            ) : (
              <ArchivedArticlesTable articles={request.archivedArticles} />
            )}
          </Stack>
        </Box>
      )}
      {!isWorkshop && (
        <>
          {price.enabled && !!request.price && (
            <RequestOrganizationPrice
              price={request.price?.amountPerCurrency[0]}
              label={price.label}
            />
          )}
          {cost.enabled &&
            request.cost?.amountPerCurrency.map((amount) => (
              <RequestWorkshopPrice
                key={amount.currency}
                currency={amount.currency}
                priceAggregate={request.cost}
                label={
                  request.cost!.amountPerCurrency.length > 1
                    ? `${cost.label} (${amount.currency})`
                    : cost.label
                }
                articles={request.allArticles}
              />
            ))}
        </>
      )}
      {isWorkshop && price.enabled && !!request.price && (
        <RequestWorkshopPrice
          currency={request.price.amountPerCurrency[0].currency}
          priceAggregate={request.price}
          label={price.label}
          articles={request.allArticles}
        />
      )}
    </Stack>
  );
};
