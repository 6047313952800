import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityDetailedContent,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconComment from '@/icons/Comment.svg';

export const ArticleClientCommentUpdatedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_client_comment_updated'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      key={activity.id}
      createdAt={activity.createdAt}
      type="neutral"
      icon={<IconComment />}
      creatorName={activity.creator.name}
      message={
        <>
          <BaseActivityText>
            {activity.data.comment ? (
              <Trans id="activities.article_client_comment_updated.message">
                added a client communication comment on the item
              </Trans>
            ) : (
              <Trans id="activities.article_client_comment_updated.message-no-comment">
                removed a client communication comment on the item
              </Trans>
            )}
          </BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
        </>
      }
    >
      {!!activity.data.comment && (
        <BaseActivityDetailedContent>{activity.data.comment}</BaseActivityDetailedContent>
      )}
    </BaseActivity>
  );
};
