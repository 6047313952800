import React, { createContext, CSSProperties, useContext, useState } from 'react';
import { Link, To, useLinkClickHandler } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import { InputMoney, InputMoneyProps } from '@/design_system/InputNumber/InputNumber';
import TextArea, { TextAreaProps } from '@/design_system/TextArea/TextArea';
import { createBEMClasses } from '@/utils/classname';

import './Table.css';

const { block, element } = createBEMClasses('table');

export const Table = ({
  ariaLabel,
  columnWidths,
  theme = 'default',
  variant = 'lines',
  bordered,
  className,
  children,
}: {
  ariaLabel?: string;
  columnWidths: string | (string | boolean | undefined | null)[];
  variant?: 'lines' | 'grid';
  theme?: 'default' | 'brand';
  bordered?: boolean;
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <table
      aria-label={ariaLabel}
      className={block(
        {
          theme,
          variant,
          bordered,
        },
        className
      )}
      style={{
        // @ts-ignore CSS variable
        '--column-widths': Array.isArray(columnWidths)
          ? columnWidths.filter((w) => !!w).join(' ')
          : columnWidths,
      }}
    >
      {children}
    </table>
  );
};

export const Header = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <thead className={className}>{children}</thead>;
};

export const Body = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <tbody className={className}>{children}</tbody>;
};

export const Footer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <tfoot className={className}>{children}</tfoot>;
};

const RowContext = createContext({
  to: undefined as To | undefined,
  navigationOptions: undefined as Parameters<typeof useLinkClickHandler>[1] | undefined,
});

export const Row = ({
  to,
  navigationOptions,
  isExtensionRow,
  children,
  className,
  style,
}: {
  to?: To;
  navigationOptions?: Parameters<typeof useLinkClickHandler>[1];
  isExtensionRow?: boolean;
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}) => {
  const handleClick = useLinkClickHandler<HTMLTableRowElement>(to ?? '', navigationOptions);

  return (
    <RowContext.Provider value={{ to, navigationOptions }}>
      <tr
        onClick={(event) => {
          if (to) {
            // If the user clicks on the actual anchor, the navigation is already triggered, we should not do it a second time
            if (!(event.target as HTMLElement).closest('a')) {
              handleClick(event);
            }
          }
        }}
        className={element(
          'row',
          { clickable: !!to, 'is-extension-row': !!isExtensionRow },
          className
        )}
        style={style}
      >
        {children}
      </tr>
    </RowContext.Provider>
  );
};

export const Column = ({
  alignItems = 'center',
  justifyContent = 'flex-start',
  ariaLabel,
  children,
  className,
}: {
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  ariaLabel?: string;
  children?: React.ReactNode;
  className?: string;
}) => {
  return (
    <th
      aria-label={ariaLabel}
      className={element('column', undefined, className)}
      style={{
        alignItems,
        justifyContent,
      }}
    >
      {children}
    </th>
  );
};

interface CellProps {
  isLink?: boolean;
  isWholeRow?: boolean;
  isEditable?: boolean;
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  stretch?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const Cell = ({
  isLink,
  isWholeRow,
  isEditable,
  alignItems = 'center',
  justifyContent = 'flex-start',
  stretch,
  children,
  className,
  style,
}: CellProps) => {
  const { to, navigationOptions } = useContext(RowContext);

  let cellContent = children;

  if (isLink && to) {
    cellContent = (
      <Link to={to} {...navigationOptions}>
        {children}
      </Link>
    );
  }

  return (
    <td
      className={element(
        'cell',
        {
          'is-link': !!isLink && !!to,
          'is-whole-row': isWholeRow,
          'is-editable': isEditable,
          stretch,
        },
        className
      )}
      style={{
        alignItems,
        justifyContent,
        ...style,
      }}
    >
      {cellContent}
    </td>
  );
};

interface EditableCellProps extends Omit<CellProps, 'children'> {
  ariaLabel?: string;
  children: ({
    isEditing,
    setIsEditing,
  }: {
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
  }) => React.ReactNode;
}

const EditableCell = ({ children, ...props }: EditableCellProps) => {
  const [isEditing, setIsEditing] = useState(false);

  let content = children({ isEditing, setIsEditing });

  if (!isEditing) {
    content = (
      <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className={element('cell__edit-button')} onClick={() => setIsEditing(true)}>
          {content}
        </div>
        <button
          className={element('cell__edit-button-aria', undefined, 'visually-hidden')}
          onClick={() => setIsEditing(true)}
        >
          <Trans id="table.input-money-cell.edit">Edit {props.ariaLabel}</Trans>
        </button>
      </>
    );
  }

  return (
    <Cell isEditable {...props}>
      {content}
    </Cell>
  );
};

export const TextAreaCell = ({
  children,
  onBlur,
  cellProps,
  ...props
}: TextAreaProps & { cellProps?: Omit<EditableCellProps, 'children'> }) => (
  <EditableCell {...cellProps}>
    {({ isEditing, setIsEditing }) =>
      isEditing ? (
        <TextArea
          {...props}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onBlur={(evt) => {
            setIsEditing(false);
            onBlur?.(evt);
          }}
        />
      ) : (
        children
      )
    }
  </EditableCell>
);

export const InputMoneyCell = ({
  children,
  onBlur,
  cellProps,
  ...props
}: InputMoneyProps & {
  children: React.ReactNode;
  cellProps?: Omit<EditableCellProps, 'children'>;
}) => (
  <EditableCell {...cellProps}>
    {({ isEditing, setIsEditing }) =>
      isEditing ? (
        <InputMoney
          {...props}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onBlur={(evt) => {
            setIsEditing(false);
            onBlur?.(evt);
          }}
        />
      ) : (
        children
      )
    }
  </EditableCell>
);
