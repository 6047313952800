import IconWrapper from './IconWrapper';

const IconValidationWaiting = IconWrapper({
  name: 'validation-waiting',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4726 2.4176C12.1971 2.19413 11.8029 2.19413 11.5274 2.4176C9.8148 3.80724 7.33866 4.5391 5.63603 4.8943C4.31916 5.16901 3.25 6.31307 3.25 7.73577V14.3833C3.25 16.0412 4.11441 17.5792 5.53068 18.441L10.5704 21.508C11.065 21.809 11.6309 21.9404 12.1878 21.9024C11.8435 21.3905 11.565 20.8308 11.3644 20.2351L11.3502 20.2266L6.31046 17.1597C5.34144 16.57 4.75 15.5177 4.75 14.3833V7.73577C4.75 7.09948 5.24363 6.50845 5.94236 6.36268C7.56408 6.02437 10.052 5.32532 12 3.941C13.948 5.32532 16.4359 6.02437 18.0576 6.36268C18.7564 6.50845 19.25 7.09948 19.25 7.73577V11.1113C19.7724 11.2055 20.2747 11.3576 20.75 11.5608V7.73577C20.75 6.31307 19.6808 5.16901 18.364 4.8943C16.6613 4.5391 14.1852 3.80724 12.4726 2.4176ZM11.5619 15.2475C12.3274 13.4594 13.816 12.0546 15.66 11.4007L16.5303 10.5303C16.8232 10.2374 16.8232 9.76256 16.5303 9.46967C16.2374 9.17678 15.7626 9.17678 15.4697 9.46967L11.5 13.4393L9.53033 11.4697C9.23744 11.1768 8.76256 11.1768 8.46967 11.4697C8.17678 11.7626 8.17678 12.2374 8.46967 12.5303L10.9697 15.0303C11.1319 15.1925 11.3499 15.2649 11.5619 15.2475ZM14.125 18C14.125 15.8599 15.8599 14.125 18 14.125C20.1401 14.125 21.875 15.8599 21.875 18C21.875 20.1401 20.1401 21.875 18 21.875C15.8599 21.875 14.125 20.1401 14.125 18ZM18 12.625C15.0315 12.625 12.625 15.0315 12.625 18C12.625 20.9685 15.0315 23.375 18 23.375C20.9685 23.375 23.375 20.9685 23.375 18C23.375 15.0315 20.9685 12.625 18 12.625ZM18.75 16.5C18.75 16.0858 18.4142 15.75 18 15.75C17.5858 15.75 17.25 16.0858 17.25 16.5V18C17.25 18.1164 17.2765 18.2265 17.3238 18.3248C17.3595 18.3992 17.4082 18.469 17.4699 18.5306L18.9701 20.0299C19.2631 20.3227 19.7379 20.3226 20.0308 20.0296C20.3236 19.7366 20.3234 19.2618 20.0305 18.969L18.75 17.6892V16.5Z"
      />
    </svg>
  ),
});

export default IconValidationWaiting;
