import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Message from '@/design_system/Message';
import { type AddressWithRelations } from '@/models/address';
import {
  type ArticleWithRelations,
  type ShipmentWithRelations,
  useUpdateDraftShipment,
} from '@/models/shipment';
import { ArticleSelect } from '@/routes/Shipments/Shipment/components/ShipmentForm/components/ArticleSelect';
import { newAddressWithRelations } from '@/routes/Shipments/Shipment/components/ShipmentForm/components/PlaceSelect';

export const AddArticlesModal = ({
  isOpen,
  setIsOpen,
  shipment,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  shipment: ShipmentWithRelations;
}) => {
  const { _ } = useLingui();

  const [origin, setOrigin] = useState<AddressWithRelations | undefined>(
    newAddressWithRelations(
      shipment.originAddress,
      shipment.originClient,
      shipment.originStore,
      shipment.originWorkshop
    )
  );
  const [destination, setDestination] = useState<AddressWithRelations | undefined>(
    newAddressWithRelations(
      shipment.destinationAddress,
      shipment.destinationClient,
      shipment.destinationStore,
      shipment.destinationWorkshop
    )
  );
  const [addedArticles, setAddedArticles] = useState<ArticleWithRelations[]>(
    shipment.articles.map(({ article }) => article)
  );
  const [error, setError] = useState<string>();

  const { mutateAsync: updateDraftShipment, isPending: isPendingUpdateDraftShipment } =
    useUpdateDraftShipment(shipment.id);

  const onSubmit = async () => {
    if (addedArticles.length === 0) {
      setError(
        _(
          msg({
            id: 'shipment.add-articles-modal.error.add-one-item',
            message: 'Please add at least one item',
          })
        )
      );
      return;
    }

    try {
      await updateDraftShipment({
        articles: addedArticles.map((addedArticle) => addedArticle.id),
      });

      setError('');
      setIsOpen(false);
    } catch (err) {
      console.error(err);
      setError(
        (err as Error).message ??
          _(
            msg({
              id: 'shipment.add-articles-modal.error.unknown',
              message: 'Unknown error. Please contact Support',
            })
          )
      );
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setAddedArticles(shipment.articles.map(({ article }) => article));
  };

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={handleCancel}
      style={{ width: '62.5rem', maxHeight: '56.25rem' }}
      title={_(
        msg({ id: 'shipment.add-articles-modal.title', message: 'Add items to the shipment' })
      )}
    >
      <main>
        <ArticleSelect
          label={
            <div className="paragraph-100-regular">
              <Trans id="shipments.add-articles-modal.search.label">
                You can add shippable items from other requests that match the destination.
              </Trans>
            </div>
          }
          ariaLabel={_(
            msg({
              id: 'shipments.add-articles-modal.search.label',
              message:
                'You can add shippable items from other requests that match the destination.',
            })
          )}
          addedArticles={addedArticles}
          setAddedArticles={(articles: ArticleWithRelations[]) => {
            setAddedArticles(articles);
            setError('');
          }}
          origin={origin}
          setOrigin={setOrigin}
          destination={destination}
          setDestination={setDestination}
        />

        {error && (
          <Message style={{ marginTop: '0.5rem' }} type="error">
            {error}
          </Message>
        )}
      </main>

      <footer>
        <Button
          variant="secondary"
          size="small"
          onPress={handleCancel}
          disabled={isPendingUpdateDraftShipment}
          ariaLabel={_(msg({ id: 'shipment.add-articles-modal.cancel', message: 'Cancel' }))}
        >
          <Trans id="shipment.add-articles-modal.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          size="small"
          onPress={onSubmit}
          isLoading={isPendingUpdateDraftShipment}
          ariaLabel={_(msg({ id: 'shipment.add-articles-modal.save', message: 'Save' }))}
        >
          <Trans id="shipment.add-articles-modal.save">Save</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};
