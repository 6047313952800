import IconWrapper from './IconWrapper';

const IconSend = IconWrapper({
  name: 'send',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.8907 5.38224C21.4017 3.96767 20.0323 2.59827 18.6178 3.10929L2.17224 9.05036C0.692876 9.58479 0.587826 11.6368 2.00486 12.3195L7.63962 15.0342C7.68359 15.0554 7.7269 15.0777 7.76955 15.101L13.5037 9.36686C13.8157 9.05486 14.3215 9.05486 14.6335 9.36686C14.9455 9.67886 14.9455 10.1847 14.6335 10.4967L8.89926 16.2309C8.9225 16.2734 8.94466 16.3166 8.96578 16.3604L11.6805 21.9951C12.3632 23.4122 14.4152 23.3071 14.9496 21.8278L20.8907 5.38224Z" />
    </svg>
  ),
});

export default IconSend;
