import { useCallback, useMemo } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Alpha2Code } from 'i18n-iso-countries';
import debounce from 'lodash.debounce';

import { InputCountry } from '@/components/InputCountry/InputCountry';
import { InputMoney } from '@/design_system/InputNumber';
import InputText from '@/design_system/InputText';
import { useUpdateArticle } from '@/models/article';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';

export const CustomsFields = ({
  request,
  article,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
}) => {
  const { _ } = useLingui();

  const { mutate: updateArticle } = useUpdateArticle({
    requestId: request.id,
    articleId: article.id,
  });

  const handleSelectionChange = useCallback(
    (property: 'tariffCode' | 'madein' | 'mid' | 'customsValue', value: any) => {
      updateArticle({
        data: {
          data: {
            ...article.data,
            [property]: value,
          },
        },
      });
    },
    [article.data, updateArticle]
  );

  const debouncedHandleSelectionChange = useMemo(() => {
    return debounce(handleSelectionChange, 300);
  }, [handleSelectionChange]);

  if (!article.areCustomsExpected && !article.wentThroughCustoms) {
    return null;
  }

  const customsValue = article.product?.data.customsValue ?? article.data.customsValue;

  // For each field:
  // - Use value from product data if it exists, article data otherwise
  // - Disable field if data comes from product
  // - Show error if empty and customs are expected
  return (
    <>
      <InputText
        label={<Trans id="article.form.customs.tariff-code">Tariff code</Trans>}
        placeholder={_(
          msg({
            id: 'article.form.customs.tariff-code.placeholder',
            message: 'Enter the Tariff code...',
          })
        )}
        defaultValue={article.product?.data.tariffCode ?? article.data.tariffCode}
        onChange={(value) => debouncedHandleSelectionChange('tariffCode', value)}
        isDisabled={!!article.product?.data.tariffCode}
        error={
          article.areCustomsExpected &&
          !article.product?.data.tariffCode &&
          !article.data.tariffCode
            ? _(
                msg({
                  id: 'article.form.customs.tariff-code.error',
                  message: 'Please enter the Tariff code',
                })
              )
            : undefined
        }
      />
      <InputCountry
        label={<Trans id="article.form.customs.made-in">Made in</Trans>}
        placeholder={_(
          msg({
            id: 'article.form.customs.made-in.placeholder',
            message: 'Select the country',
          })
        )}
        value={(article.product?.data.madein ?? article.data.madein) as Alpha2Code | undefined}
        onChange={(country) => handleSelectionChange('madein', country)}
        isDisabled={!!article.product?.data.madein}
        error={
          article.areCustomsExpected && !article.product?.data.madein && !article.data.madein
            ? _(
                msg({
                  id: 'article.form.customs.made-in.error',
                  message: 'Please select the country',
                })
              )
            : undefined
        }
      />
      <InputText
        label={<Trans id="article.form.customs.manufacturer-code">Manufacturer code</Trans>}
        placeholder={_(
          msg({
            id: 'article.form.customs.manufacturer-code.placeholder',
            message: 'Enter the manufacturer Identification Code...',
          })
        )}
        defaultValue={article.product?.data.mid ?? article.data.mid}
        onChange={(value) => debouncedHandleSelectionChange('mid', value)}
        isDisabled={!!article.product?.data.mid}
        error={
          article.areCustomsExpected && !article.product?.data.mid && !article.data.mid
            ? _(
                msg({
                  id: 'article.form.customs.manufacturer-code.error',
                  message: 'Please enter the Manufacturer code',
                })
              )
            : undefined
        }
      />
      <InputMoney
        label={<Trans id="article.form.customs.customs-value">Customs value</Trans>}
        placeholder={_(
          msg({
            id: 'article.form.customs.customs-value.placeholder',
            message: 'Enter Product value for customs...',
          })
        )}
        minValue={0}
        value={customsValue?.amount}
        currency={customsValue?.currency ?? 'EUR'}
        onChange={(value) =>
          debouncedHandleSelectionChange(
            'customsValue',
            Number.isNaN(value)
              ? undefined
              : { amount: value, currency: customsValue?.currency ?? 'EUR' }
          )
        }
        isDisabled={!!article.product?.data.customsValue}
        error={
          article.areCustomsExpected &&
          !article.product?.data.customsValue &&
          !article.data.customsValue
            ? _(
                msg({
                  id: 'article.form.customs.customs-value.error',
                  message: 'Please enter the Customs value',
                })
              )
            : undefined
        }
      />
    </>
  );
};
