const IllustrationRequestsEmptyState = () => (
  <svg width="65" height="69" viewBox="0 0 65 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.6749 1.30519L14.9145 4.95992C9.43888 5.6808 5.58442 10.704 6.30529 16.1796L12.0484 59.8032C12.7693 65.2788 17.7926 69.1333 23.2682 68.4124L27.473 67.8588H54.9715C60.4944 67.8588 64.9715 63.3817 64.9715 57.8588V13.8588C64.9715 8.33597 60.4944 3.85881 54.9715 3.85881H50.7495C48.6447 1.92524 45.7302 0.902952 42.6749 1.30519ZM52.4247 5.85881C53.1822 7.05042 53.6982 8.4222 53.8946 9.91438L59.6378 53.5379C60.3587 59.0135 56.5042 64.0368 51.0286 64.7577L42.6645 65.8588H54.9715C59.3898 65.8588 62.9715 62.2771 62.9715 57.8588V13.8588C62.9715 9.44054 59.3898 5.85881 54.9715 5.85881H52.4247ZM8.28818 15.9186C7.71148 11.5381 10.7951 7.51951 15.1755 6.94281L42.936 3.28808C47.3165 2.71138 51.3351 5.79495 51.9118 10.1754L57.6549 53.799C58.2316 58.1795 55.148 62.1981 50.7676 62.7748L23.0071 66.4295C18.6266 67.0062 14.608 63.9226 14.0313 59.5422L8.28818 15.9186Z"
      fill="#3AD98F"
    />
    <path
      d="M1.16329 10.7492L4.67162 9.99793C4.87537 9.95262 5.03508 10.0731 5.08039 10.2769C5.11285 10.4488 5.02415 10.5957 4.90974 10.6789L1.76807 12.4293C1.34837 12.6729 0.806225 12.5219 0.549782 12.0704C0.306193 11.6507 0.457238 11.1085 0.908758 10.8521C0.991349 10.7817 1.05499 10.756 1.16329 10.7492Z"
      fill="#3AD98F"
    />
    <path
      d="M1.923 3.74374L5.46793 6.1974C5.68517 6.36869 5.76905 6.66788 5.59776 6.88512C5.45828 7.08951 5.19091 7.16053 4.96756 7.06573L1.04887 5.24418C0.602174 5.05457 0.415469 4.50086 0.617934 4.08598C0.807544 3.63929 1.36126 3.45258 1.77614 3.65505C1.83366 3.70582 1.87833 3.72478 1.923 3.74374Z"
      fill="#3AD98F"
    />
    <path
      d="M8.92951 4.87281L9.14069 1.45671C9.13949 1.43752 9.1387 1.41934 9.13796 1.40229C9.13451 1.32312 9.13214 1.26856 9.09538 1.25296C9.01087 0.768985 8.51275 0.452114 8.04163 0.568443C7.55766 0.652956 7.24079 1.15107 7.35712 1.62219L8.07597 4.9586C8.10843 5.13053 8.28099 5.28286 8.46578 5.28222C8.72705 5.28769 8.91119 5.10226 8.92951 4.87281Z"
      fill="#3AD98F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1755 6.94281C10.7951 7.51951 7.71148 11.5381 8.28818 15.9186L14.0313 59.5422C14.608 63.9226 18.6266 67.0062 23.0071 66.4295L50.7676 62.7748C55.148 62.1981 58.2316 58.1795 57.6549 53.799L51.9118 10.1754C51.3351 5.79495 47.3165 2.71138 42.936 3.28808L15.1755 6.94281ZM42.9115 27.7839C43.3529 28.1158 43.4418 28.7427 43.1099 29.1841L33.5717 41.8726C33.4103 42.0874 33.1693 42.2281 32.9029 42.2632C32.6366 42.2983 32.3673 42.2247 32.1558 42.059L25.9072 37.1653C25.4724 36.8248 25.3959 36.1962 25.7365 35.7614C26.077 35.3266 26.7055 35.2502 27.1404 35.5907L32.5859 39.8556L41.5112 27.9824C41.8431 27.5409 42.47 27.4521 42.9115 27.7839Z"
      fill="#F2FDF8"
    />
    <path
      d="M52.4247 5.85881C53.1822 7.05042 53.6982 8.4222 53.8946 9.91438L59.6378 53.5379C60.3587 59.0135 56.5042 64.0368 51.0286 64.7577L42.6645 65.8588H54.9715C59.3898 65.8588 62.9715 62.2771 62.9715 57.8588V13.8588C62.9715 9.44054 59.3898 5.85881 54.9715 5.85881H52.4247Z"
      fill="#F2FDF8"
    />
    <path
      d="M43.1099 29.1841C43.4418 28.7427 43.3529 28.1158 42.9115 27.7839C42.47 27.4521 41.8431 27.5409 41.5112 27.9824L32.5859 39.8556L27.1404 35.5907C26.7055 35.2502 26.077 35.3266 25.7365 35.7614C25.3959 36.1962 25.4724 36.8248 25.9072 37.1653L32.1558 42.059C32.3673 42.2247 32.6366 42.2983 32.9029 42.2632C33.1693 42.2281 33.4103 42.0874 33.5717 41.8726L43.1099 29.1841Z"
      fill="#3AD98F"
    />
  </svg>
);

export default IllustrationRequestsEmptyState;
