import { useState } from 'react';
import { DialogTrigger, Popover } from 'react-aria-components';
import { Trans } from '@lingui/macro';

import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import Avatar from '@/design_system/Avatar';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { User } from '@/models/user';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';

import './UserAvatarGroup.css';

const { block, element } = createBEMClasses('user-avatar-group');

const NUMBER_AVATARS_TO_DISPLAY = 4;

export const UserAvatarGroup = ({
  users,
  size,
}: {
  users: User[];
  size?: 'xx-small' | 'x-small' | 'small';
}) => {
  const [open, setOpen] = useState(false);
  const { currentSession } = useCurrentSession();

  // Pick the first users to display their avatars
  const firstUsers =
    users.length <= NUMBER_AVATARS_TO_DISPLAY
      ? users
      : users.slice(0, NUMBER_AVATARS_TO_DISPLAY - 1);
  const firstAvatars = <FirstUserAvatars users={firstUsers} size={size} />;

  if (users.length <= NUMBER_AVATARS_TO_DISPLAY) {
    return <div className={block({ size })}>{firstAvatars}</div>;
  }

  // Then display all the others with a count and a popover
  return (
    <DialogTrigger key="count" onOpenChange={setOpen} isOpen={open} aria-label={''}>
      <div className={block({ size })}>
        {firstAvatars}
        <Avatar
          size={size ?? 'small'}
          count={users.length - NUMBER_AVATARS_TO_DISPLAY + 1}
          isButton
        />
      </div>

      <Popover placement="bottom end" offset={4}>
        <Box padding="0" className={element('others')}>
          <ul className={element('list')}>
            <>
              {users.map((user) => {
                return (
                  <li key={user.id} className={element('item')}>
                    <UserAvatar size={size} user={user} />
                    <Stack>
                      <span
                        className="sentry-mask text-ellipsis paragraph-200-regular"
                        style={{ flex: '1', textAlign: 'initial' }}
                        title={user.name}
                      >
                        {user.name}
                      </span>
                      {user.id === currentSession?.id && (
                        <span className="paragraph-300-regular text-disabled">
                          <Trans id="user-avatar-group.you">You</Trans>
                        </span>
                      )}
                    </Stack>
                  </li>
                );
              })}
            </>
          </ul>
        </Box>
      </Popover>
    </DialogTrigger>
  );
};

const FirstUserAvatars = ({
  users,
  size,
}: {
  users: User[];
  size?: 'xx-small' | 'x-small' | 'small';
}) => {
  return users.map((user) => (
    <UserAvatar
      key={user.id}
      user={user}
      size={size ?? 'small'}
      tooltip={<p className="sentry-mask">{user.name}</p>}
    />
  ));
};
