import IconWrapper from './IconWrapper';

const IconPrint = IconWrapper({
  name: 'print',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 3.5C8.80964 3.5 8.25 4.05964 8.25 4.75V5.75H16.25V4.75C16.25 4.05964 15.6904 3.5 15 3.5H9.5ZM17.75 5.75V4.75C17.75 3.23122 16.5188 2 15 2H9.5C7.98122 2 6.75 3.23122 6.75 4.75V5.75H6C3.92893 5.75 2.25 7.42893 2.25 9.5V15C2.25 17.0711 3.92893 18.75 6 18.75H7.75V19.25C7.75 20.7688 8.98122 22 10.5 22H13.5C15.0188 22 16.25 20.7688 16.25 19.25V18.75H18C20.0711 18.75 21.75 17.0711 21.75 15V9.5C21.75 7.42893 20.0711 5.75 18 5.75H17.75ZM16.25 17.25H18C19.2426 17.25 20.25 16.2426 20.25 15V9.5C20.25 8.25736 19.2426 7.25 18 7.25H17H7.5H6C4.75736 7.25 3.75 8.25736 3.75 9.5V15C3.75 16.2426 4.75736 17.25 6 17.25H7.75V15.5H6.5C6.08579 15.5 5.75 15.1642 5.75 14.75C5.75 14.3358 6.08579 14 6.5 14H8.5H15.5H17.5C17.9142 14 18.25 14.3358 18.25 14.75C18.25 15.1642 17.9142 15.5 17.5 15.5H16.25V17.25ZM14.75 15.5V18V19.25C14.75 19.9404 14.1904 20.5 13.5 20.5H10.5C9.80964 20.5 9.25 19.9404 9.25 19.25V18V15.5H14.75ZM5.75 10.75C5.75 10.3358 6.08579 10 6.5 10H9.5C9.91421 10 10.25 10.3358 10.25 10.75C10.25 11.1642 9.91421 11.5 9.5 11.5H6.5C6.08579 11.5 5.75 11.1642 5.75 10.75Z"
      />
    </svg>
  ),
});

export default IconPrint;
