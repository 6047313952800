import { createBEMClasses } from '@/utils/classname';

import './Hint.css';

const { block } = createBEMClasses('hint');

interface HintProps {
  children: React.ReactNode;
}

const Hint = ({ children }: HintProps) => {
  return <div className={block()}>{children}</div>;
};

export default Hint;
