import IconWrapper from './IconWrapper';

const IconAddToList = IconWrapper({
  name: 'add-to-list',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 5.25C2.33579 5.25 2 5.58579 2 6C2 6.41421 2.33579 6.75 2.75 6.75L21.25 6.75C21.6642 6.75 22 6.41421 22 6C22 5.58579 21.6642 5.25 21.25 5.25L2.75 5.25ZM2.75 11.25C2.33579 11.25 2 11.5858 2 12C2 12.4142 2.33579 12.75 2.75 12.75L21.25 12.75C21.6642 12.75 22 12.4142 22 12C22 11.5858 21.6642 11.25 21.25 11.25L2.75 11.25ZM2.25 18C2.25 17.5858 2.58579 17.25 3 17.25L10.5 17.25C10.9142 17.25 11.25 17.5858 11.25 18C11.25 18.4142 10.9142 18.75 10.5 18.75L3 18.75C2.58579 18.75 2.25 18.4142 2.25 18ZM18.2491 16C18.6633 16 18.9991 16.3358 18.9991 16.75L18.9991 18.9991L21.25 18.9991C21.6642 18.9991 22 19.3349 22 19.7491C22 20.1634 21.6642 20.4991 21.25 20.4991L18.9991 20.4991L18.9991 22.75C18.9991 23.1642 18.6633 23.5 18.2491 23.5C17.8349 23.5 17.4991 23.1642 17.4991 22.75L17.4991 20.4991L15.25 20.4991C14.8358 20.4991 14.5 20.1634 14.5 19.7491C14.5 19.3349 14.8358 18.9991 15.25 18.9991L17.4991 18.9991L17.4991 16.75C17.4991 16.3358 17.8349 16 18.2491 16Z"
      />
    </svg>
  ),
});

export default IconAddToList;
