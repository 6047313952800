import IconWrapper from './IconWrapper';

const IconDone = IconWrapper({
  name: 'done',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.7873 9.9751C17.0773 9.67932 17.0726 9.20447 16.7768 8.91449C16.481 8.62451 16.0062 8.62921 15.7162 8.92499L11.2506 13.4799L9.28555 11.4756C8.99557 11.1798 8.52072 11.1751 8.22494 11.4651C7.92917 11.755 7.92447 12.2299 8.21445 12.5257L10.715 15.0762C10.8561 15.2201 11.0491 15.3012 11.2506 15.3012C11.4521 15.3012 11.6451 15.2201 11.7861 15.0762L16.7873 9.9751Z"
      />
    </svg>
  ),
});

export default IconDone;
