import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import DiscardChangesDialog from '@/components/DiscardChangesDialog';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutLeftPart,
  PageLayoutLeftPartContent,
  PageLayoutLeftPartFooter,
  PageLayoutSimpleHeader,
} from '@/components/PageLayout';
import AlertBar from '@/design_system/AlertBar';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconClock from '@/icons/Clock.svg';
import IconInfo from '@/icons/Info.svg';
import { RequestWithRelations, useRequest, useSendDraftRequest } from '@/models/request';
import { useWorkflow } from '@/models/workflow';
import { ArchiveRequestButton } from '@/routes/Requests/components/ArchiveRequestButton';
import Articles, { useArticlesError } from '@/routes/Requests/New/components/Articles';
import ClientStoreForm, {
  getClientStoreError,
} from '@/routes/Requests/New/components/ClientStoreForm';
import {
  PrintDropOffButton,
  PrintDropOffModal,
} from '@/routes/Requests/New/components/PrintDropOff';
import SettingsForm from '@/routes/Requests/New/components/SettingsForm';
import { RequestComments } from '@/routes/Requests/Request/components/RequestComments';
import { RequestContextProvider } from '@/routes/Requests/RequestContext';
import { useShowWarningBeforeLeavingApp, useSimpleBlocker } from '@/utils/navigation';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import './New.css';

const New = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: request, isError } = useRequest(id);

  useEffect(() => {
    if (isError) {
      navigate('/requests', { state: { skipRouterBlocker: true, replace: true } });
    }
  }, [navigate, isError]);

  // Block going away
  const blocker = useSimpleBlocker();
  useShowWarningBeforeLeavingApp();

  if (!request) {
    return null;
  }

  return (
    <RequestContextProvider request={request} isCreation>
      <RequestForm request={request} />
      <Outlet context={request} />
      {blocker.state === 'blocked' && (
        <DiscardChangesDialog
          onConfirm={() => blocker.proceed()}
          onCancel={() => blocker.reset()}
        />
      )}
    </RequestContextProvider>
  );
};

export default New;

const RequestForm = ({ request }: { request: RequestWithRelations }) => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { isMobile } = useViewPort();

  const [showPrintDropOffModal, setShowPrintDropOffModal] = useState(false);

  const goToRequests = ({ skipRouterBlocker }: { skipRouterBlocker: boolean }) =>
    navigate('/requests', { state: { skipRouterBlocker }, replace: true });
  const goToRequest = ({ skipRouterBlocker }: { skipRouterBlocker: boolean }) =>
    navigate(`/requests/${request.id}`, { state: { skipRouterBlocker }, replace: true });

  const { data: workflow } = useWorkflow(request.workflowId);

  // The workflow does not have an internal payment step (where we generate an invoice).
  // Therefore, we do not have to ask for a billing address.
  const hideBillingAddress = !workflow?.nodes.find(
    (node) => 'step' in node && node.step === 'payment' && node.config.externalPayment === false
  );

  // Errors
  const clientStoreError = getClientStoreError(request, hideBillingAddress);
  const { noArticlesError, incompleteDraftArticlesError, incompleteSavedArticlesError } =
    useArticlesError(request);

  const hasSaveForLaterError =
    clientStoreError || !!noArticlesError || !!incompleteDraftArticlesError;
  const hasSaveError = clientStoreError || !!noArticlesError || !!incompleteSavedArticlesError;

  const [showSaveForLaterErrors, setShowSaveForLaterErrors] = useState(false);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const showErrors =
    (showSaveForLaterErrors && hasSaveForLaterError) || (showSaveErrors && hasSaveError);

  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();
  const [apiError, setApiError] = useState('');

  // Actions

  const handleApiError = (err: any) => {
    console.error(err);
    setApiError(
      (err.message as string) ?? _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
    );
  };

  const { mutateAsync: sendDraftRequest, isPending: isPendingSendDraftRequest } =
    useSendDraftRequest(request.id);

  const handleSaveForLater = () => {
    setShowSaveForLaterErrors(true);
    setShowSaveErrors(false);

    if (hasSaveForLaterError) {
      scrollAlertIntoView();
      return;
    }

    const isDraft = request.draft;

    sendDraftRequest({ saveForLater: true })
      .then(() => {
        if (request.client && request.store && isDraft) {
          setShowPrintDropOffModal(true);
        } else {
          goToRequests({ skipRouterBlocker: true });
        }
      })
      .catch(handleApiError);
  };

  const handleSaveRequest = () => {
    setShowSaveForLaterErrors(false);
    setShowSaveErrors(true);

    if (hasSaveError) {
      scrollAlertIntoView();
      return;
    }

    sendDraftRequest({ saveForLater: false })
      .then(() => {
        goToRequest({ skipRouterBlocker: true });
      })
      .catch(handleApiError);
  };

  const saveForLaterLabel = _(
    msg({ id: 'requests.edit.actions.save-for-later', message: 'Save for later' })
  );

  // Only draft requests here
  if (!request.draft && !request.isInDraftStep) {
    goToRequest({ skipRouterBlocker: true });
    return null;
  }

  return (
    <PageLayout>
      <PageLayoutSimpleHeader
        title={
          request.draft
            ? _(msg({ id: 'requests.new.title', message: 'New request' }))
            : _(msg({ id: 'requests.edit.title', message: 'Edit request' }))
        }
        onClose={() => goToRequests({ skipRouterBlocker: !request.draft })}
      />

      <PageLayoutContent>
        <PageLayoutLeftPart>
          <PageLayoutLeftPartContent>
            <Stack padding={isMobile ? '1rem' : undefined} gap="1rem" ref={alertRef}>
              {showErrors && (
                <AlertBar
                  type="error"
                  title={
                    request.draft
                      ? _(
                          msg({
                            id: 'requests.new.error',
                            message:
                              'Please fill the missing information in order to be able to create a request',
                          })
                        )
                      : _(
                          msg({
                            id: 'requests.edit.error',
                            message:
                              'Please fill the missing information in order to be able to edit the request',
                          })
                        )
                  }
                />
              )}
              <Stack gap="2rem">
                <SettingsForm request={request} workflow={workflow} />
                <ClientStoreForm
                  request={request}
                  hideBillingAddress={hideBillingAddress}
                  showErrors={showSaveForLaterErrors || showSaveErrors}
                />
                <Articles
                  error={
                    showSaveErrors
                      ? noArticlesError || incompleteSavedArticlesError
                      : showSaveForLaterErrors
                        ? noArticlesError || incompleteDraftArticlesError
                        : undefined
                  }
                />
                <Stack gap={isMobile ? undefined : '0.5rem'}>
                  <h2 className="headline-400-bold">
                    <Stack row alignItems="center" gap="0.25rem">
                      <Trans id="comments.title">Comments</Trans>
                      <Tooltip
                        content={_(
                          msg({
                            id: 'comments.title.tooltip',
                            message: "These comments won't be visible by the client",
                          })
                        )}
                      >
                        <Button variant="style-less">
                          <IconInfo className="headline-300-bold text-disabled" />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </h2>
                  <Box padding="0" removeStyleOnMobile>
                    <RequestComments requestId={request.id} />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </PageLayoutLeftPartContent>

          <PageLayoutLeftPartFooter>
            {apiError && (
              <Stack alignItems="center">
                <Message type="error">{apiError}</Message>
              </Stack>
            )}
            {request.draft ? (
              <>
                <Button
                  variant="secondary"
                  size="medium"
                  onPress={handleSaveForLater}
                  isLoading={showSaveForLaterErrors && isPendingSendDraftRequest}
                  dataTrackingId="requests.new.actions.save-for-later"
                >
                  <IconClock />
                  {saveForLaterLabel}
                </Button>
              </>
            ) : (
              <>
                <Stack row gap={isMobile ? '0.5rem' : '1rem'}>
                  <ArchiveRequestButton
                    request={request}
                    onArchive={() => goToRequests({ skipRouterBlocker: true })}
                    onArchiveError={(reason) => {
                      setApiError(
                        ((reason as any).body?.message as string) ??
                          _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
                      );
                    }}
                  />
                  {request.client && request.store && <PrintDropOffButton request={request} />}
                  <Button
                    variant="secondary"
                    size="medium"
                    onPress={handleSaveForLater}
                    isLoading={showSaveForLaterErrors && isPendingSendDraftRequest}
                    style={isMobile ? { flex: 1 } : undefined}
                    ariaLabel={isMobile ? saveForLaterLabel : undefined}
                    dataTrackingId="requests.new.actions.save-for-later"
                  >
                    <IconClock />
                    {!isMobile && saveForLaterLabel}
                  </Button>
                </Stack>
              </>
            )}
            <Button
              variant="primary"
              size="medium"
              onPress={handleSaveRequest}
              isLoading={showSaveErrors && isPendingSendDraftRequest}
              dataTrackingId="requests.new.actions.create"
            >
              {request.draft ? (
                <Trans id="requests.new.actions.create">Create request</Trans>
              ) : (
                <Trans id="requests.edit.actions.save-and-create">Save and create</Trans>
              )}
            </Button>
          </PageLayoutLeftPartFooter>
        </PageLayoutLeftPart>
      </PageLayoutContent>
      {showPrintDropOffModal && (
        <PrintDropOffModal
          request={request}
          onClose={() => goToRequests({ skipRouterBlocker: true })}
        />
      )}
    </PageLayout>
  );
};
