import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';

import { PriceAggregate, PriceAmountWithDetails, PriceFeeDetail } from '@/api';
import { CardItem } from '@/components/Card/Card';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import IconPay from '@/icons/Pay.svg';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';
import { useWorkshops, Workshop } from '@/models/workshop';
import { useCurrentSession } from '@/services/auth';
import { Currency, extractFeesFromPrice, formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

const BaseMoneyCell = ({
  price,
  label,
  cancelledPriceTooltip,
}: {
  price: PriceAmountWithDetails | undefined | null;
  label?: string;
  cancelledPriceTooltip?: string;
}) => {
  const { isMobile } = useViewPort();

  const hasDiscounts = !!price?.details.some(
    (detail) => detail.type === 'discount' && detail.amount !== 0
  );

  // TODO: check mobile
  if (isMobile) {
    return (
      <Stack alignItems="flex-end" row gap="0.25rem">
        {label && <p className="paragraph-200-regular text-secondary">{label}</p>}
        {!isNil(price?.amount) ? (
          <Stack
            row
            flexWrap="nowrap"
            gap="0.25rem"
            alignItems="center"
            className="paragraph-200-regular text-primary"
          >
            {price.cancelled ? (
              <s className="text-disabled">{formatCurrency(price.amount, price.currency)}</s>
            ) : (
              formatCurrency(price.amount, price.currency)
            )}
            {hasDiscounts && (
              <s role="deletion" className="paragraph-400-regular text-disabled">
                {formatCurrency(price.details[0]?.amount, price.details[0]?.currency)}
              </s>
            )}
            {hasDiscounts && <DiscountDetail details={price.details} />}
          </Stack>
        ) : (
          <p className="paragraph-200-regular">-</p>
        )}
      </Stack>
    );
  }

  return (
    <Stack alignItems="flex-end">
      {label && <p className="paragraph-100-regular text-secondary">{label}</p>}
      {!isNil(price?.amount) ? (
        <>
          {hasDiscounts && (
            <s role="deletion" className="paragraph-100-regular text-disabled">
              {formatCurrency(price.details[0]?.amount, price.details[0]?.currency)}
            </s>
          )}
          <Stack
            row
            alignItems="center"
            className="paragraph-100-regular"
            style={{ whiteSpace: 'nowrap' }}
            gap="0.25rem"
          >
            {hasDiscounts && (
              <>
                <DiscountDetail details={price.details} />{' '}
              </>
            )}
            {!!cancelledPriceTooltip && (
              <Tooltip content={cancelledPriceTooltip}>
                <Button variant="style-less" className="text-disabled">
                  <IconInfo style={{ fontSize: '1.25rem' }} />
                </Button>
              </Tooltip>
            )}
            {price.cancelled ? (
              <s className="text-disabled">{formatCurrency(price.amount, price.currency)}</s>
            ) : (
              formatCurrency(price.amount, price.currency)
            )}
          </Stack>
        </>
      ) : (
        <p className="paragraph-100-regular text-disabled">-</p>
      )}
    </Stack>
  );
};

const DiscountDetail = ({ details }: { details: PriceAmountWithDetails['details'] }) => {
  const { _ } = useLingui();

  const groupedDiscounts = details.reduce<
    {
      type: 'refashion' | 'rate';
      amount: number;
      currency: Currency;
    }[]
  >((acc, detail) => {
    if (detail.type === 'discount' && detail.amount !== 0) {
      const type = detail.subType === 'refashion' ? 'refashion' : 'rate';
      const existingDiscount = acc.find((discount) => discount.type === type);

      if (existingDiscount) {
        existingDiscount.amount += detail.amount;
      } else {
        acc.push({
          type,
          amount: detail.amount,
          currency: detail.currency,
        });
      }
    }

    return acc;
  }, []);

  const labelForType = {
    refashion: _(msg({ id: 'article-money-cell.refashion', message: 'Bonus amount' })),
    rate: _(msg({ id: 'article-money-cell.discount', message: 'Discount amount' })),
  };

  return (
    <Tooltip
      content={
        <Stack>
          {groupedDiscounts?.map((discount) => (
            <p key={discount.type}>
              {labelForType[discount.type]}: {formatCurrency(discount.amount, discount.currency)}
            </p>
          ))}
        </Stack>
      }
    >
      <Button variant="style-less" style={{ verticalAlign: 'text-top' }}>
        <IconInfo style={{ fontSize: '1rem' }} />
      </Button>
    </Tooltip>
  );
};

export const ArticleCostCardItem = ({
  article,
  costLabel,
}: {
  article: ArticleWithRelations;
  costLabel: string;
}) => {
  return (
    <CardItem>
      <IconPay payOut style={{ fontSize: '1rem' }} />
      <span>
        {costLabel}
        <Trans id="_general.colon">:</Trans>
      </span>
      <BaseMoneyCell
        price={article.snapshot.cost?.amountPerEntity.find(
          (amount) => amount.entityId === article.workshopId
        )}
      />
    </CardItem>
  );
};

export const ArticlePriceCardItem = ({
  article,
  priceLabel,
}: {
  article: ArticleWithRelations;
  priceLabel: string;
}) => {
  return (
    <CardItem>
      <IconPay payIn style={{ fontSize: '1rem' }} />
      <span>
        {priceLabel}
        <Trans id="_general.colon">:</Trans>
      </span>
      <BaseMoneyCell price={article.snapshot.price?.amountPerCurrency[0]} />
    </CardItem>
  );
};

export const RequestOrganizationPrice = ({
  price,
  label,
}: {
  price: PriceAmountWithDetails | undefined | null;
  label?: string;
}) => {
  const { isMobile } = useViewPort();
  const { _ } = useLingui();

  const hasRefashionDiscount = !!price?.details.find(
    (detail) => detail.type === 'discount' && detail.subType === 'refashion' && detail.amount !== 0
  );
  const hasRateDiscounts = !!price?.details.find(
    (detail) => detail.type === 'discount' && detail.subType !== 'refashion' && detail.amount !== 0
  );

  return (
    <Stack
      className="bg-neutral-300"
      gap="0.5rem"
      padding="0.75rem 1rem"
      style={{ borderRadius: '0.5rem' }}
    >
      {label && <span className="paragraph-100-medium text-primary">{label}</span>}

      <Stack>
        <Stack gap="2px">
          <Stack
            row
            flexWrap="nowrap"
            justifyContent="space-between"
            className="paragraph-200-regular text-secondary"
          >
            <p>
              {isMobile ? (
                <Trans id="client.articles.table.footer.subtotal">Subtotal</Trans>
              ) : hasRateDiscounts && hasRefashionDiscount ? (
                <Trans id="client.articles.table.footer.subtotal-bonus-and-discount">
                  Subtotal with discount and Refashion bonus
                </Trans>
              ) : hasRateDiscounts ? (
                <Trans id="client.articles.table.footer.subtotal-discount">
                  Subtotal with discount
                </Trans>
              ) : hasRefashionDiscount ? (
                <Trans id="client.articles.table.footer.subtotal-bonus">
                  Subtotal with Refashion bonus
                </Trans>
              ) : (
                <Trans id="client.articles.table.footer.subtotal">Subtotal</Trans>
              )}
            </p>
            <p style={{ whiteSpace: 'nowrap' }}>
              {!!price && (hasRefashionDiscount || hasRateDiscounts) && (
                <>
                  <DiscountDetail details={price.details} />{' '}
                </>
              )}
              {formatCurrency(price?.amount, price?.currency)}
            </p>
          </Stack>
          <Stack
            row
            flexWrap="nowrap"
            justifyContent="space-between"
            className="paragraph-200-regular text-secondary"
          >
            <p>
              <Trans id="client.articles.table.total.shipment.label">Shipping fees</Trans>
            </p>
            <p>
              <Trans id="client.articles.table.total.shipment.offered">Offered</Trans>
            </p>
          </Stack>
        </Stack>
        <Stack
          row
          flexWrap="nowrap"
          justifyContent="space-between"
          className="paragraph-100-medium text-primary"
          ariaLabel={
            label ??
            _(msg({ id: 'client.articles.table.footer.total-price', message: 'Total price' }))
          }
        >
          <p>
            <Trans id="client.articles.table.footer.total-simple">Total</Trans>
          </p>
          <p>{formatCurrency(price?.amount, price?.currency)}</p>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const RequestWorkshopPrice = ({
  currency,
  priceAggregate,
  label,
  articles,
}: {
  currency: Currency;
  priceAggregate: PriceAggregate | null | undefined;
  label?: string;
  articles: ArticleWithRelations[];
}) => {
  const price = priceAggregate?.amountPerCurrency.find((amount) => amount.currency === currency);

  const {
    cancellationFeesAmount,
    shippingManagementFeesAmount,
    totalFeesAmount,
    initialAmountWithoutFees,
  } = extractFeesFromPrice(price);

  return (
    <Stack
      className="bg-neutral-200"
      gap="0.5rem"
      padding="0.75rem 1rem"
      style={{ borderRadius: '0.5rem' }}
      ariaLabel={label}
    >
      {label && <span className="paragraph-100-medium text-primary">{label}</span>}
      <Stack gap="2px">
        {!!totalFeesAmount && totalFeesAmount > 0 && (
          <>
            <Stack
              row
              flexWrap="nowrap"
              justifyContent="space-between"
              className="paragraph-200-regular text-secondary"
            >
              <p>
                <Trans id="client.articles.table.cost.subtotal.label">Subtotal</Trans>
              </p>
              <p>{formatCurrency(initialAmountWithoutFees, currency)}</p>
            </Stack>

            {!!cancellationFeesAmount && cancellationFeesAmount > 0 && (
              <Stack
                row
                flexWrap="nowrap"
                justifyContent="space-between"
                className="paragraph-200-regular text-secondary"
              >
                <Stack row alignItems="center" gap="0.25rem">
                  <Trans id="client.articles.table.cost.cancellation-fees.label">
                    Cancellation fees
                  </Trans>
                  <RequestFeesDetails
                    priceAggregate={priceAggregate!}
                    currency={currency}
                    articles={articles}
                    feeType="cancellation"
                  />
                </Stack>
                <p>{formatCurrency(cancellationFeesAmount, currency)}</p>
              </Stack>
            )}

            {!!shippingManagementFeesAmount && shippingManagementFeesAmount > 0 && (
              <Stack
                row
                flexWrap="nowrap"
                justifyContent="space-between"
                className="paragraph-200-regular text-secondary"
              >
                <Stack row alignItems="center" gap="0.25rem">
                  <Trans id="client.articles.table.cost.shipping-management-fees.label">
                    Shipping management fees
                  </Trans>
                  <RequestFeesDetails
                    priceAggregate={priceAggregate!}
                    currency={currency}
                    articles={articles}
                    feeType="shipping-management"
                  />
                </Stack>
                <p>{formatCurrency(shippingManagementFeesAmount, currency)}</p>
              </Stack>
            )}
          </>
        )}

        <Stack
          row
          flexWrap="nowrap"
          justifyContent="space-between"
          className="paragraph-100-medium text-primary"
        >
          <p>
            <Trans id="client.articles.table.footer.total-simple">Total</Trans>
          </p>
          <p>{formatCurrency(price?.amount, price?.currency)}</p>
        </Stack>
      </Stack>
    </Stack>
  );
};

const RequestFeesDetails = ({
  priceAggregate,
  currency,
  articles,
  feeType,
}: {
  priceAggregate: PriceAggregate;
  currency: Currency;
  articles: ArticleWithRelations[];
  feeType: 'shipping-management' | 'cancellation';
}) => {
  const { currentSession } = useCurrentSession();

  const articlesAmounts = priceAggregate.components
    .filter((componentPriceAggregate) => componentPriceAggregate.subType === 'article')
    .map((componentPriceAggregate) => {
      return {
        article: articles.find(
          (article) => article.id === componentPriceAggregate.componentId
        ) as ArticleWithRelations,
        fees: componentPriceAggregate.amountPerEntity
          // Remove fees that are not of the correct type or currency
          .filter(
            (amount) =>
              amount.currency === currency &&
              amount.details.some((detail) => detail.type === 'fee' && detail.subType === feeType)
          )
          .map((amount) => ({
            // For both 'shipping-management' and 'cancellation' fees, the `entityId` is the workshop id if user is not a workshop user
            workshopId: currentSession?.workshop ? currentSession?.workshop.id : amount.entityId!,
            priceDetail: amount.details.find(
              (detail) => detail.type === 'fee' && detail.subType === feeType
            ) as PriceFeeDetail,
          })),
      };
    })
    .filter(({ fees }) => fees.length > 0);

  const workshopsIds = [
    ...new Set(articlesAmounts.map(({ fees }) => fees.map(({ workshopId }) => workshopId)).flat()),
  ];

  const { data: { workshops } = { workshops: [] }, isFetching: isFetchingWorkshops } = useWorkshops(
    {
      ids: workshopsIds,
    }
  );

  if (isFetchingWorkshops) {
    return null;
  }

  return (
    <Tooltip
      content={
        <Stack gap="0.25rem">
          {articlesAmounts.map(({ article, fees }) => (
            <ArticleFees
              key={article.id}
              article={article}
              fees={fees.map(({ workshopId, priceDetail }) => ({
                workshop: workshops.find((workshop) => workshop.id === workshopId)!,
                priceDetail,
              }))}
            />
          ))}
        </Stack>
      }
      textAlign="start"
    >
      <Button variant="style-less">
        <IconInfo style={{ fontSize: '1rem' }} />
      </Button>
    </Tooltip>
  );
};

const ArticleFees = ({
  article,
  fees,
}: {
  article: ArticleWithRelations;
  fees: {
    workshop: Workshop;
    priceDetail: PriceFeeDetail;
  }[];
}) => {
  const articleName = useArticleName({ article });

  return (
    <Stack key={article.id}>
      <p className="paragraph-200-regular text-secondary text-disabled">{articleName}</p>
      {fees.map(({ workshop, priceDetail }) => (
        <Stack key={workshop.id} row gap="0.25rem">
          <p className="paragraph-200-regular text-secondary text-neutral-0">
            <Trans id="client.articles.table.cost.fees-details">
              • {workshop.name}: {formatCurrency(priceDetail.amount, priceDetail.currency)}
            </Trans>
          </p>
        </Stack>
      ))}
    </Stack>
  );
};

export default BaseMoneyCell;
