import { useRef, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import TextArea from '@/design_system/TextArea';
import IconEdit from '@/icons/Edit.svg';
import IconTrash from '@/icons/Trash.svg';

const NewComment = ({
  comment = null,
  setComment,
  ariaLabel,
  isDisabled,
  isLoading,
}: {
  comment?: string | null;
  setComment: (comment: string | null) => void;
  ariaLabel?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { _ } = useLingui();

  const commentRef = useRef<HTMLTextAreaElement>(null);

  const [liveComment, setLiveComment] = useState(comment);
  const [isEditing, setIsEditing] = useState(!comment);

  const startEditComment = () => {
    setIsEditing(true);
    setLiveComment(comment);
    commentRef.current?.focus();
  };

  const cancelEditComment = () => {
    setIsEditing(false);
    setLiveComment(comment);
  };

  const saveComment = () => {
    if (comment !== liveComment) {
      setComment(liveComment);
    }
  };

  const deleteComment = () => {
    if (comment !== null) {
      setComment(null);
    }
  };

  return (
    <TextArea
      ariaLabel={
        ariaLabel ?? _(msg({ id: 'components.new-comment.label', message: 'Write a comment' }))
      }
      placeholder={_(
        msg({ id: 'components.new-comment.placeholder', message: 'Write a comment...' })
      )}
      rows={4}
      value={(isEditing ? liveComment : comment) ?? ''}
      onChange={(evt) => setLiveComment(evt.target.value)}
      isReadOnly={!isEditing}
      ref={commentRef}
      disabled={isDisabled}
      style={{ flex: 1 }}
      actions={
        <>
          {isEditing && (
            <>
              {!!comment && (
                <Button
                  size="small"
                  variant="secondary"
                  isLoading={isLoading}
                  disabled={!liveComment}
                  onPress={cancelEditComment}
                  ariaLabel={_(
                    msg({
                      id: 'components.new-comment.cancel-edit.label',
                      message: 'Cancel comment edition',
                    })
                  )}
                >
                  <Trans id="components.new-comment.cancel-edit.text">Cancel</Trans>
                </Button>
              )}
              <Button
                size="small"
                isLoading={isLoading}
                disabled={!liveComment}
                onPress={saveComment}
                ariaLabel={_(
                  msg({ id: 'components.new-comment.save.label', message: 'Save comment' })
                )}
              >
                {comment ? (
                  <Trans id="components.new-comment.save.text">Save</Trans>
                ) : (
                  <Trans id="components.new-comment.add">Add</Trans>
                )}
              </Button>
            </>
          )}

          {!isEditing && !isDisabled && (
            <>
              <Button
                size="medium"
                onPress={startEditComment}
                variant="secondary"
                iconOnly
                tooltip={_(msg({ id: 'components.new-comment.edit', message: 'Edit comment' }))}
                ariaLabel={_(msg({ id: 'components.new-comment.edit', message: 'Edit comment' }))}
              >
                <IconEdit />
              </Button>
              <Button
                size="medium"
                onPress={deleteComment}
                isLoading={isLoading}
                variant="secondary"
                iconOnly
                tooltip={_(msg({ id: 'components.new-comment.delete', message: 'Delete comment' }))}
                ariaLabel={_(
                  msg({ id: 'components.new-comment.delete', message: 'Delete comment' })
                )}
              >
                <IconTrash />
              </Button>
            </>
          )}
        </>
      }
    />
  );
};

export default NewComment;
