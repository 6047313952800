import parsePhoneNumber from 'libphonenumber-js';

interface Phone {
  countryCode: string;
  number: string;
}

export const formatPhone = (phone: Phone | null) => {
  if (!phone) {
    return '';
  }

  return parsePhoneNumber(`+${phone.countryCode}${phone.number}`)?.formatInternational();
};

export default Phone;
