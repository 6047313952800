import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CreationStepConfig } from '@/api';
import { BaseMoneyCell, RequestOrganizationPrice } from '@/components/ArticlesTableCells';
import RefashionLogo from '@/components/RefashionLogo';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import IconEdit from '@/icons/Edit.svg';
import IconTrash from '@/icons/Trash.svg';
import { getGroupedActions, useGetActionName } from '@/models/actionType';
import { useDeleteAction } from '@/models/article';
import { ClientArticleWithRelations, ClientRequestWithRelations } from '@/models/request';
import EstimateDisclaimer from '@/routes/Brand/components/EstimateDisclaimer';
import { ArticleSummary } from '@/routes/Brand/Requests/New/components/Article/components/ArticleSummary/ArticleSummary';
import DeleteArticleModal from '@/routes/Brand/Requests/New/components/DeleteArticleModal';
import EmptyCartModal from '@/routes/Brand/Requests/New/components/EmptyCartModal';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './CartContent.css';

const LegacyCartContent = ({
  request,
  onEditArticle,
  onAddActionsToArticle,
  onEditActionsOnArticle,
  onDeleteArticle,
  onDeleteAllArticles,
  onEditCart,
  collapseHeader = false,
  recap = false,
}: {
  request: ClientRequestWithRelations;
  onAddActionsToArticle?: (index: number) => void;
  onEditActionsOnArticle?: (data: { actionIds: string[]; articleIndex: number }) => void;
  onEditArticle?: (index: number) => void;
  onDeleteArticle?: (index: number) => void;
  onDeleteAllArticles?: () => void;
  onEditCart?: () => void;
  collapseHeader?: boolean;
  recap?: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const [showEmptyCartModal, setShowEmptyCartModal] = useState(false);

  const creationConfig = request.articles[0].step?.config as
    | CreationStepConfig['config']
    | undefined;
  const showPrice = creationConfig?.requirePrice ?? false;
  const isStoreOnlyRequest = request.store && !request.client;

  return (
    <Stack gap="1rem">
      <Stack
        row
        gap="0.5rem"
        justifyContent={collapseHeader ? 'flex-start' : 'space-between'}
        alignItems="center"
      >
        <Stack row gap="0.25rem" alignItems="baseline">
          <h3 className="headline-300-bold paragraph-50-medium-mobile">
            {recap ? (
              <Trans id="client.new.cart.title.recap">Cart recap</Trans>
            ) : (
              <Trans id="client.new.cart.title">Cart</Trans>
            )}
          </h3>
          {!recap && <span className="paragraph-100-medium">({request.actionQuantity})</span>}
        </Stack>
        {!recap && (
          <Stack row gap="0.5rem">
            <Button
              iconOnly
              variant="secondary-brand"
              size="small"
              tooltip={_(msg({ id: 'client.new.cart.delete-cart', message: 'Delete cart' }))}
              ariaLabel={_(msg({ id: 'client.new.cart.delete-cart', message: 'Delete cart' }))}
              onPress={() => setShowEmptyCartModal(true)}
            >
              <IconTrash />
            </Button>
            <EmptyCartModal
              isOpen={showEmptyCartModal}
              onOpenChange={setShowEmptyCartModal}
              onDelete={onDeleteAllArticles}
              request={request}
            />
          </Stack>
        )}
        {recap && (
          <Button
            iconOnly
            variant="secondary-brand"
            size="medium"
            tooltip={_(msg({ id: 'client.new.cart.edit-cart', message: 'Edit cart' }))}
            ariaLabel={_(msg({ id: 'client.new.cart.edit-cart', message: 'Edit cart' }))}
            onPress={onEditCart}
          >
            <IconEdit />
          </Button>
        )}
      </Stack>
      {request.articles.map((article, index) => (
        <Article
          key={article.id}
          article={article}
          onAddActions={() => onAddActionsToArticle?.(index)}
          onEdit={() => onEditArticle?.(index)}
          onEditActions={(actionIds) => {
            onEditActionsOnArticle?.({
              articleIndex: index,
              actionIds,
            });
          }}
          onDelete={() => {
            if (request.articles.length === 1) {
              onDeleteAllArticles?.();
            } else {
              onDeleteArticle?.(index);
            }
          }}
          request={request}
          recap={recap}
          showPrice={showPrice}
        />
      ))}
      {showPrice && <RequestOrganizationPrice price={request.cost?.amountPerCurrency[0]} />}
      {!isStoreOnlyRequest && !(recap && isMobile) && <EstimateDisclaimer />}
    </Stack>
  );
};

const { block } = createBEMClasses('cart-content-article');

const Article = ({
  article,
  onAddActions,
  onEdit,
  onEditActions,
  onDelete,
  request,
  recap,
  showPrice,
}: {
  article: ClientArticleWithRelations;
  onAddActions: () => void;
  onEdit: () => void;
  onEditActions: (actionIds: string[]) => void;
  onDelete: () => void;
  request: ClientRequestWithRelations;
  recap?: boolean;
  showPrice?: boolean;
}) => {
  const { _ } = useLingui();
  const getActionName = useGetActionName();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { mutate: deleteAction } = useDeleteAction({
    articleId: article.id,
    requestId: request.id,
  });

  const removeActions = (ids: string[]) => {
    if (groupedActions.length === 1) {
      setShowDeleteModal(true);
      return;
    }

    ids.map((id) => deleteAction(id));
  };

  const articleActions = article.snapshot.articleActions;
  const totalActions = articleActions.length;
  const groupedActions = getGroupedActions(articleActions);

  return (
    <Stack className={block()} gap="0.75rem">
      <Stack row gap="0.5rem" alignItems="center" flexWrap="nowrap" justifyContent="space-between">
        <ArticleSummary article={article} size="small" />
        {!recap && (
          <Stack row gap="0.5rem">
            <Button
              iconOnly
              size="small"
              variant="secondary-brand"
              onPress={onAddActions}
              tooltip={_(
                msg({ id: 'client.new.cart.article.add-action', message: 'Add other services' })
              )}
              ariaLabel={_(
                msg({ id: 'client.new.cart.article.add-action', message: 'Add other services' })
              )}
            >
              <IconAdd />
            </Button>
            <Button
              iconOnly
              size="small"
              variant="secondary-brand"
              onPress={onEdit}
              tooltip={_(msg({ id: 'client.new.cart.article.edit', message: 'Edit item' }))}
              ariaLabel={_(msg({ id: 'client.new.cart.article.edit', message: 'Edit item' }))}
            >
              <IconEdit />
            </Button>
            <Button
              iconOnly
              size="small"
              variant="secondary-brand"
              onPress={() => setShowDeleteModal(true)}
              tooltip={_(msg({ id: 'client.new.cart.article.delete', message: 'Delete item' }))}
              ariaLabel={_(msg({ id: 'client.new.cart.article.delete', message: 'Delete item' }))}
            >
              <IconTrash />
            </Button>
          </Stack>
        )}
      </Stack>
      <hr />
      {totalActions > 0 ? (
        <>
          {groupedActions.map((action) => (
            <Stack key={action.id} gap="0.25rem">
              <Stack gap="0.5rem" row>
                <p className="paragraph-100-regular" style={{ flex: 1 }}>
                  {getActionName({ action, mode: 'need' })}{' '}
                  {/* eslint-disable-line lingui/no-unlocalized-strings */}
                  (x{action.quantity})
                  {showPrice && !!action.costRefashionStatus && (
                    <>
                      {' '}
                      <RefashionLogo status={action.costRefashionStatus} />
                    </>
                  )}
                </p>
                {showPrice &&
                  (action.actionTypeOrganization ? (
                    <BaseMoneyCell price={action.cost?.amountPerCurrency[0]} />
                  ) : (
                    <div style={{ minWidth: 60, textAlign: 'right' }}>
                      {/* The custom action will never have a price during the diag form */}
                      <p className="paragraph-100-medium">
                        <Trans id="client.new.cart.need-assessment">Need assessment</Trans>
                      </p>
                    </div>
                  ))}
              </Stack>
              {!recap && (
                <ActionButtons
                  onEdit={() => onEditActions(action.ids)}
                  onRemove={() => removeActions(action.ids)}
                />
              )}
            </Stack>
          ))}
        </>
      ) : (
        <p className="paragraph-100-regular" style={{ paddingLeft: 8 }}>
          <Trans id="client.new.cart.no-actions">Please add a service or delete this item.</Trans>
        </p>
      )}
      <DeleteArticleModal
        lastArticle={request.articles.length === 1}
        articleId={article.id}
        isOpen={showDeleteModal}
        onOpenChange={setShowDeleteModal}
        onDelete={onDelete}
      />
    </Stack>
  );
};

const ActionButtons = ({ onEdit, onRemove }: { onEdit: () => void; onRemove: () => void }) => {
  const { _ } = useLingui();

  return (
    <Stack row gap="0.5rem">
      <Button
        iconOnly
        size="small"
        variant="secondary-brand"
        onPress={onEdit}
        tooltip={_(msg({ id: 'client.new.cart.action.edit', message: 'Edit service' }))}
        ariaLabel={_(msg({ id: 'client.new.cart.action.edit', message: 'Edit service' }))}
      >
        <IconEdit />
      </Button>
      <Button
        iconOnly
        size="small"
        variant="secondary-brand"
        onPress={onRemove}
        tooltip={_(msg({ id: 'client.new.cart.action.delete', message: 'Delete service' }))}
        ariaLabel={_(msg({ id: 'client.new.cart.action.delete', message: 'Delete service' }))}
      >
        <IconTrash />
      </Button>
    </Stack>
  );
};

export default LegacyCartContent;
