import IconWrapper from './IconWrapper';

const IconStarEmpty = IconWrapper({
  name: 'star-empty',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2234 6.21695C25.2935 4.40684 22.706 4.40686 21.776 6.21695L16.8166 15.8703L6.1032 17.6039C4.09433 17.929 3.29474 20.3899 4.72888 21.8337L12.3772 29.5334L10.7154 40.2582C10.4038 42.2692 12.4971 43.7901 14.3134 42.8723L23.9997 37.9776L33.6861 42.8723C35.5024 43.7901 37.5957 42.2692 37.2841 40.2582L35.6223 29.5334L43.2706 21.8337C44.7048 20.3899 43.9051 17.929 41.8963 17.6039L31.1829 15.8703L26.2234 6.21695ZM19.3696 17.4659L23.9997 8.45353L28.6299 17.4659C28.9929 18.1725 29.6701 18.6645 30.4542 18.7914L40.4563 20.4099L33.3158 27.5984C32.756 28.162 32.4973 28.9581 32.619 29.7431L34.1705 39.7558L25.1273 35.1861C24.4183 34.8279 23.5812 34.8279 22.8722 35.1861L13.829 39.7558L15.3805 29.7431C15.5022 28.9581 15.2435 28.162 14.6837 27.5984L7.54316 20.4099L17.5453 18.7914C18.3294 18.6645 19.0066 18.1725 19.3696 17.4659Z"
      />
    </svg>
  ),
});

const IconStarFilled = IconWrapper({
  name: 'star-filled',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2234 6.21695C25.2935 4.40684 22.706 4.40686 21.776 6.21695L16.8166 15.8703L6.1032 17.6039C4.09433 17.929 3.29474 20.3899 4.72888 21.8337L12.3772 29.5334L10.7154 40.2582C10.4038 42.2692 12.4971 43.7901 14.3134 42.8723L23.9997 37.9776L33.6861 42.8723C35.5024 43.7901 37.5957 42.2692 37.2841 40.2582L35.6223 29.5334L43.2706 21.8337C44.7048 20.3899 43.9051 17.929 41.8963 17.6039L31.1829 15.8703L26.2234 6.21695Z"
      />
    </svg>
  ),
});

const IconStar = ({ filled }: { filled?: boolean }) => {
  if (filled) return <IconStarFilled />;
  else return <IconStarEmpty />;
};

export default IconStar;
