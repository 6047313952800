import IconWrapper from './IconWrapper';

const IconFilters = IconWrapper({
  name: 'filters',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 3C16.6642 3 17 3.33579 17 3.75V5.20036H20.25C20.6642 5.20036 21 5.53615 21 5.95036C21 6.36458 20.6642 6.70036 20.25 6.70036H17V8.14975C17 8.56396 16.6642 8.89975 16.25 8.89975C15.8358 8.89975 15.5 8.56396 15.5 8.14975V5.95036V3.75C15.5 3.33579 15.8358 3 16.25 3ZM3.75 5.20036C3.33579 5.20036 3 5.53615 3 5.95036C3 6.36458 3.33579 6.70036 3.75 6.70036H13.25H14V5.20036H13.25H3.75ZM7.75 14.9504C7.33579 14.9504 7 14.6146 7 14.2004V12.75L3.75 12.75C3.33579 12.75 3 12.4142 3 12C3 11.5858 3.33579 11.25 3.75 11.25L7 11.25V9.80061C7 9.38639 7.33579 9.05061 7.75 9.05061C8.16421 9.05061 8.5 9.38639 8.5 9.80061L8.5 12V14.2004C8.5 14.6146 8.16421 14.9504 7.75 14.9504ZM10.75 11.25H10L10 12.75H10.75L20.25 12.75C20.6642 12.75 21 12.4142 21 12C21 11.5858 20.6642 11.25 20.25 11.25L10.75 11.25ZM14 15.8493C14 15.4351 13.6642 15.0993 13.25 15.0993C12.8358 15.0993 12.5 15.4351 12.5 15.8493V18.0497V20.2491C12.5 20.6633 12.8358 20.9991 13.25 20.9991C13.6642 20.9991 14 20.6633 14 20.2491V18.7997H20.25C20.6642 18.7997 21 18.4639 21 18.0497C21 17.6355 20.6642 17.2997 20.25 17.2997H14V15.8493ZM3 18.0497C3 17.6355 3.33579 17.2997 3.75 17.2997H10.25H11V18.7997H10.25H3.75C3.33579 18.7997 3 18.4639 3 18.0497Z"
      />
    </svg>
  ),
});

export default IconFilters;
