import IconWrapper from './IconWrapper';

const IconPerson = IconWrapper({
  name: 'person',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.081 7.41403C9.081 5.80203 10.3878 4.49524 11.9998 4.49524C13.6118 4.49524 14.9186 5.80203 14.9186 7.41403C14.9186 9.02604 13.6118 10.3328 11.9998 10.3328C10.3878 10.3328 9.081 9.02604 9.081 7.41403ZM11.9998 2.99524C9.55936 2.99524 7.581 4.9736 7.581 7.41403C7.581 9.85447 9.55936 11.8328 11.9998 11.8328C14.4402 11.8328 16.4186 9.85447 16.4186 7.41403C16.4186 4.9736 14.4402 2.99524 11.9998 2.99524ZM11.9995 12.6258C11.2855 12.6258 10.5758 12.6693 9.90295 12.7368C6.80714 13.0476 4.8291 15.8159 4.8291 18.7708C4.8291 20.0046 5.82933 21.0048 7.06317 21.0048H16.9358C18.1697 21.0048 19.1699 20.0046 19.1699 18.7708C19.1699 15.8159 17.1918 13.0476 14.096 12.7368C13.4232 12.6693 12.7135 12.6258 11.9995 12.6258ZM10.0528 14.2293C10.6862 14.1657 11.345 14.1258 11.9995 14.1258C12.654 14.1258 13.3128 14.1657 13.9462 14.2293C16.1247 14.4481 17.6699 16.4248 17.6699 18.7708C17.6699 19.1762 17.3412 19.5048 16.9358 19.5048H7.06317C6.65775 19.5048 6.3291 19.1762 6.3291 18.7708C6.3291 16.4248 7.87426 14.4481 10.0528 14.2293Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C9.63052 3 7.70968 4.96306 7.70968 7.38462C7.70968 9.80617 9.63052 11.7692 12 11.7692C14.3695 11.7692 16.2903 9.80617 16.2903 7.38462C16.2903 4.96306 14.3695 3 12 3ZM9.06452 7.38462C9.06452 5.72776 10.3788 4.38462 12 4.38462C13.6212 4.38462 14.9355 5.72776 14.9355 7.38462C14.9355 9.04147 13.6212 10.3846 12 10.3846C10.3788 10.3846 9.06452 9.04147 9.06452 7.38462Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12.6923C11.2993 12.6923 10.6025 12.7359 9.9415 12.8037C6.93026 13.1127 5 15.8657 5 18.8141C5 20.0213 5.95761 21 7.13888 21H16.8611C18.0424 21 19 20.0213 19 18.8141C19 15.8657 17.0697 13.1127 14.0585 12.8037C13.3975 12.7359 12.7007 12.6923 12 12.6923ZM10.0768 14.1814C10.7022 14.1172 11.353 14.0769 12 14.0769C12.647 14.0769 13.2978 14.1172 13.9232 14.1814C16.1059 14.4053 17.6452 16.4277 17.6452 18.8141C17.6452 19.2566 17.2941 19.6154 16.8611 19.6154H7.13888C6.70586 19.6154 6.35484 19.2566 6.35484 18.8141C6.35484 16.4277 7.89411 14.4053 10.0768 14.1814Z"
      />
    </svg>
  ),
});

export default IconPerson;
