import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity, BaseActivityText } from '@/components/activities/BaseActivity/BaseActivity';
import IconTrash from '@/icons/Trash.svg';

export const RequestMediumDeletedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'request_medium_deleted'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconTrash />}
      type="danger"
      creatorName={activity.creator?.name ?? ''}
      message={
        <BaseActivityText>
          <Trans id="activities.request_medium_deleted.message">
            deleted a medium from the request
          </Trans>
        </BaseActivityText>
      }
    />
  );
};
