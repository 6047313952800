import { GridList, GridListItem } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import SimpleBar from 'simplebar-react';

import ArticlePhoto from '@/components/ArticlePhoto';
import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconCross from '@/icons/Cross.svg';
import IconLightBulb from '@/icons/LightBulb.svg';
import { useArticleName } from '@/models/article';
import { type ArticleWithRelations } from '@/models/shipment';
import { ArticleNameCell } from '@/routes/Shipments/Shipment/components/ShipmentForm/components/ArticleSelect/components/ArticleNameCell';
import { createBEMClasses } from '@/utils/classname';
import useViewPort from '@/utils/useViewport';

import './AddedArticlesSection.css';

const { block, element } = createBEMClasses('added-articles-section');

export const AddedArticlesSection = ({
  addedArticles,
  unaddedArticles,
  setAddedArticles,
  setRemovedArticles,
}: {
  addedArticles: ArticleWithRelations[];
  unaddedArticles: ArticleWithRelations[];
  setAddedArticles: (articles: ArticleWithRelations[]) => void;
  setRemovedArticles: (article: ArticleWithRelations) => void;
}) => {
  const { _ } = useLingui();
  const { isMobile, isTablet } = useViewPort();

  const handleRemoveArticle = (article: ArticleWithRelations) => {
    setRemovedArticles(article);
    setAddedArticles(addedArticles.filter((a) => a.id !== article.id));
  };

  if (isMobile || isTablet) {
    if (!addedArticles.length) {
      return;
    }

    return (
      <GridList
        className={element('mobile', undefined, 'bg-neutral-100')}
        aria-label={_(
          msg({
            id: 'shipments.new.articles.added.label',
            message: 'Added',
          })
        )}
      >
        {addedArticles.map((article) => (
          <AddedArticleMobile
            key={article.id}
            article={article}
            onRemove={() => {
              handleRemoveArticle(article);
            }}
          />
        ))}
      </GridList>
    );
  }

  return (
    <Stack className={block(undefined, 'bg-neutral-100')}>
      <div className={element('label', undefined, 'paragraph-100-medium')}>
        <Trans id="shipments.new.articles.added.label">Added</Trans>
      </div>
      <SimpleBar className={element('scrollbar')} autoHide={false}>
        {!addedArticles.length ? (
          <p className="paragraph-100-regular text-secondary" style={{ paddingLeft: '0.5rem' }}>
            <Trans id="shipments.new.articles.added.no-items">
              No items added to the shipment yet.
            </Trans>
          </p>
        ) : (
          <>
            <GridList
              aria-label={_(
                msg({
                  id: 'shipments.new.articles.added.label',
                  message: 'Added',
                })
              )}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {addedArticles.map((article) => (
                <AddedArticleRow
                  key={article.id}
                  article={article}
                  onRemove={() => {
                    handleRemoveArticle(article);
                  }}
                />
              ))}
            </GridList>
            {addedArticles.length === 1 && unaddedArticles.length > 0 && <AddArticlesAlert />}
          </>
        )}
      </SimpleBar>
    </Stack>
  );
};

const AddedArticleRow = ({
  article,
  onRemove,
}: {
  article: ArticleWithRelations;
  onRemove: () => void;
}) => {
  const articleName = useArticleName({ article });

  return (
    <GridListItem className={element('added-article-row')} textValue={articleName} id={article.id}>
      <ArticleNameCell article={article} photoSize="large" />
      <RemoveArticleButton
        className={element('added-article-row__remove-btn')}
        size="medium"
        onRemove={onRemove}
      />
    </GridListItem>
  );
};

const AddedArticleMobile = ({
  article,
  onRemove,
}: {
  article: ArticleWithRelations;
  onRemove: () => void;
}) => {
  const articleName = useArticleName({ article });

  return (
    <GridListItem
      className={element('mobile__article')}
      textValue={articleName}
      aria-label={articleName}
    >
      <ArticlePhoto photo={article.articlePhoto} size="large" />
      <div style={{ overflow: 'hidden' }}>
        <p className="paragraph-100-medium text-ellipsis">{articleName}</p>
      </div>

      <RemoveArticleButton
        className={element('mobile__article__remove-btn')}
        size="small"
        onRemove={onRemove}
      />
    </GridListItem>
  );
};

const AddArticlesAlert = () => {
  const { _ } = useLingui();

  return (
    <AlertBar
      className={element('add-articles-alert')}
      title={_(
        msg({
          id: 'shipments.new.articles.added.alert.title',
          message: 'Add items to this shipment',
        })
      )}
      type="info"
      size="large"
      icon={<IconLightBulb />}
    >
      <Trans id="shipments.new.articles.added.alert.text">
        You can add other items with the same destination in order to pool shipments.
      </Trans>
    </AlertBar>
  );
};

const RemoveArticleButton = ({
  className,
  size,
  onRemove,
}: {
  className?: string;
  size: 'small' | 'medium';
  onRemove: () => void;
}) => {
  const { _ } = useLingui();

  return (
    <Button
      className={className}
      size={size}
      variant="secondary"
      onPress={onRemove}
      iconOnly
      ariaLabel={_(msg({ id: 'shipments.new.articles.remove', message: 'Remove item' }))}
      tooltip={_(msg({ id: 'shipments.new.articles.remove', message: 'Remove item' }))}
    >
      <IconCross />
    </Button>
  );
};
