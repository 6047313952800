import { Trans } from '@lingui/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const RepairArticleTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <BaseMessage
      title={<Trans id="article.task.repair-article.title">Repair the item</Trans>}
      message={<Trans id="article.task.repair-article.message">You can now repair the item</Trans>}
      article={article}
    />
  );
};
