import Stack from '@/design_system/Stack';
import IconPackageIssue from '@/icons/PackageIssue.svg';

const ArticleIssueCell = ({ issue }: { issue: string }) => {
  return (
    <div
      className="paragraph-100-regular bg-danger-100"
      style={{ padding: 16, borderRadius: 8, whiteSpace: 'pre-wrap' }}
    >
      <Stack row gap="0.5rem" alignItems="flex-start">
        <IconPackageIssue style={{ fontSize: '1.5rem' }} className="text-danger" />
        <p>&rdquo;{issue}&rdquo;</p>
      </Stack>
    </div>
  );
};

export default ArticleIssueCell;
