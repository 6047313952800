import { Trans } from '@lingui/macro';

import Stack from '@/design_system/Stack';
import { BrandWrapper, BrandWrapperCenter } from '@/layouts/Brand';
import { ClientRequestWithRelations } from '@/models/request';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';

export const Archived = ({ request }: { request: ClientRequestWithRelations }) => {
  return (
    <BrandWrapper>
      <BrandWrapperCenter>
        <Stack gap="1.5rem">
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            {!request.client && (
              <Trans id="store.request.archived.message.store">
                Your care & repair service has been archived.
              </Trans>
            )}
            {!!request.client && (
              <Trans id="store.request.archived.message.client">
                The care & repair service of your client <strong>{request.client.name}</strong> has
                been archived.
              </Trans>
            )}
          </p>
          <Stack gap="1rem">
            {!!request.client && <ClientInfo request={request} canEdit={false} />}
          </Stack>
        </Stack>
      </BrandWrapperCenter>
    </BrandWrapper>
  );
};
