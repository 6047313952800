import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconExternalLink from '@/icons/ExternalLink.svg';
import { ArticleWithRelations } from '@/models/request';
import { useArticleOngoingShipment, useCarrierName } from '@/models/shipment';

import { BaseMessage } from './BaseMessage';
import { GoToShipment } from './GoToShipment';

export const DropoffShipmentTaskMessage = ({ article }: { article: ArticleWithRelations }) => {
  const { shipment } = useArticleOngoingShipment(article);
  const carrierName = useCarrierName(shipment);

  return (
    <BaseMessage
      article={article}
      title={<Trans id="article.task.dropoff-shipment.title">Drop the package</Trans>}
      message={
        <Stack gap="0.5rem" alignItems="flex-start">
          {!!carrierName && (
            <p>
              <Trans id="article.task.dropoff-shipment.text">
                Go to the nearest {carrierName} pickup point and drop the package
              </Trans>
            </p>
          )}
          <Stack row flexWrap="wrap" gap="0.5rem">
            {shipment?.carrier === 'mondial_relay' && (
              <Button
                variant="secondary"
                size="medium"
                href="https://www.mondialrelay.fr/trouver-le-point-relais-le-plus-proche-de-chez-moi/"
                target="_blank"
              >
                <Trans id="article.task.dropoff-shipment.text.mondial-relay-points">
                  View Mondial Relay drop-off points
                </Trans>
                <IconExternalLink />
              </Button>
            )}
            <GoToShipment article={article} />
          </Stack>
        </Stack>
      }
    />
  );
};
