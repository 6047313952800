import { useRef, useState } from 'react';
import { Dialog, Popover } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import isNil from 'lodash/isNil';

import { PriceAmountWithDetails } from '@/api';
import Loader from '@/components/Loader';
import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import { InputPercentage } from '@/design_system/InputNumber';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconEdit from '@/icons/Edit.svg';
import IconInfo from '@/icons/Info.svg';
import IconWarning from '@/icons/Warning.svg';
import { useArticleName, useUpdateArticle } from '@/models/article';
import { ArticleWithRelations, Request, RequestWithRelations } from '@/models/request';
import { useRequestContext } from '@/routes/Requests/RequestContext';
import { useCurrentSession } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { extractFeesFromPrice, formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import './ArticleHeaderPrice.css';

export const ArticlePriceWithTitle = ({
  request,
  article,
  allowDiscount,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  allowDiscount: boolean;
}) => {
  const { isMobile } = useViewPort();
  const isFetching = useIsFetching({ queryKey: ['requests', request.id] });
  const isMutating = useIsMutating({ mutationKey: ['articles', article.id] });

  const name = useArticleName({ article });

  return (
    <Stack gap={'1rem'}>
      {!isMobile && (
        <Stack row gap="16px" alignItems="center">
          <h1 className="headline-400-bold">{name}</h1>
          {(isFetching > 0 || isMutating > 0) && (
            <Loader style={{ fontSize: '1.25rem' }} delay={500} />
          )}
        </Stack>
      )}
      <ArticlePrice article={article} request={request} allowDiscount={allowDiscount} />
    </Stack>
  );
};

const ArticlePrice = ({
  article,
  request,
  allowDiscount,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  showWorkshopPrice?: boolean;
  showOrganizationPrice?: boolean;
  allowDiscount: boolean;
}) => {
  const { isWorkshop } = useCurrentSession();
  const { isMobile } = useViewPort();

  const {
    view: { price, cost },
  } = useRequestContext();

  if (!price.enabled && !cost.enabled) {
    return null;
  }

  return (
    <Stack
      row
      gap={'1.5rem'}
      alignItems="center"
      justifyContent={!isMobile ? 'space-between' : 'flex-start'}
      flexWrap="nowrap"
    >
      {cost.enabled && (
        <WorkshopPrice
          price={article.snapshot.cost?.amountPerEntity.find(
            (amount) => amount.entityId === article.workshopId
          )}
          label={cost.label}
        />
      )}
      {price.enabled &&
        (isWorkshop ? (
          <WorkshopPrice price={article.snapshot.price?.amountPerCurrency[0]} label={price.label} />
        ) : (
          <OrganizationPrice
            request={request}
            article={article}
            label={price.label}
            allowDiscount={allowDiscount}
          />
        ))}
    </Stack>
  );
};

const WorkshopPrice = ({
  price,
  label,
}: {
  price: PriceAmountWithDetails | undefined;
  label: string;
}) => {
  const { cancellationFeesAmount, shippingManagementFeesAmount, totalFeesAmount } =
    extractFeesFromPrice(price);

  const initialPrice = price?.details[0];
  const initialCurrency = initialPrice?.currency;

  const updatedPrice = price;
  const updatedCurrency = updatedPrice?.currency;

  const updatedPriceWithoutFees = price
    ? {
        ...price,
        amount: price.amount - (totalFeesAmount ?? 0),
      }
    : undefined;
  const updatedAmountWithoutFees = updatedPriceWithoutFees?.amount;

  return (
    <Stack ariaLabel={label}>
      <Stack row gap="0.25rem">
        <span className="paragraph-200-regular  text-secondary">{label}</span>
        {!!(totalFeesAmount && totalFeesAmount > 0) && (
          <Tooltip
            textAlign="start"
            content={
              <Stack alignItems="start" justifyContent="start">
                <div>
                  <Trans id="article.header.cost.workshop-price.total-actions-cost">
                    Total actions cost:
                  </Trans>{' '}
                  {formatCurrency(updatedAmountWithoutFees, updatedCurrency)}
                </div>

                {cancellationFeesAmount && cancellationFeesAmount > 0 ? (
                  <div>
                    <Trans id="article.header.cost.workshop-price.cancellation-fees">
                      Cancellation fees:
                    </Trans>{' '}
                    {formatCurrency(cancellationFeesAmount, initialCurrency)}
                  </div>
                ) : null}

                {shippingManagementFeesAmount && shippingManagementFeesAmount > 0 ? (
                  <div>
                    <Trans id="article.header.cost.workshop-price.shipping-management-fees">
                      Shipping management fees:
                    </Trans>{' '}
                    {formatCurrency(shippingManagementFeesAmount, initialCurrency)}
                  </div>
                ) : null}
              </Stack>
            }
          >
            <Button variant="style-less">
              <IconInfo style={{ fontSize: '1rem' }} />
            </Button>
          </Tooltip>
        )}
      </Stack>

      <Price price={updatedPrice} />
    </Stack>
  );
};

const OrganizationPrice = ({
  request,
  article,
  label,
  allowDiscount,
}: {
  request: Request;
  article: ArticleWithRelations;
  label: string;
  allowDiscount: boolean;
}) => {
  const { _ } = useLingui();

  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const popoverAnchorRef = useRef<HTMLDivElement>(null);

  const priceAmount = article.snapshot.price?.amountPerCurrency[0];

  const initialPrice = priceAmount?.details[0];
  const initialAmount = initialPrice?.amount;
  const initialCurrency = initialPrice?.currency;

  const updatedPrice = priceAmount;
  const updatedAmount = priceAmount?.amount;
  const updatedCurrency = priceAmount?.currency;

  const hasUpdatedPrice =
    !isNil(updatedAmount) &&
    (updatedAmount !== initialAmount || updatedCurrency !== initialCurrency);

  const showWarning =
    !request.vip &&
    article.snapshot.articleActions.some(
      (action) => action.priceRefashionStatus === 'not-applied-discount'
    );

  return (
    <Stack ariaLabel={label}>
      <span className="paragraph-200-regular text-secondary">{label}</span>
      <Stack row gap="0.25rem" alignItems="center" ref={popoverAnchorRef}>
        {allowDiscount && showWarning && (
          <Tooltip
            content={_(
              msg({
                id: 'article.header.price.edit.warning',
                message:
                  'This discount conflicts with the Refashion bonus, preventing it from applying to some actions.',
              })
            )}
            placement="bottom"
          >
            <Button variant="style-less">
              <IconWarning className="headline-200-medium text-warning" />
            </Button>
          </Tooltip>
        )}
        <Price price={updatedPrice} />
        {allowDiscount && hasUpdatedPrice && !isNil(initialAmount) && (
          <s className="paragraph-100-regular">{formatCurrency(initialAmount, initialCurrency)}</s>
        )}
        {allowDiscount && !isPopoverOpened && (
          <Button
            size="small"
            variant="secondary"
            iconOnly
            ariaLabel={_(msg({ id: 'article.header.price.edit.trigger', message: 'Edit price' }))}
            onPress={() => setIsPopoverOpened(true)}
          >
            <IconEdit />
          </Button>
        )}
        <Popover
          placement="bottom left"
          triggerRef={popoverAnchorRef}
          isOpen={isPopoverOpened}
          onOpenChange={setIsPopoverOpened}
          offset={4}
        >
          <EditOrganizationPricePopover article={article} showWarning={showWarning} />
        </Popover>
      </Stack>
    </Stack>
  );
};

const Price = ({
  price,
}: {
  price?: { amount: number; currency: string; cancelled?: boolean };
}) => {
  if (!price) {
    return <span className="paragraph-50-medium">-</span>;
  }

  if (price.cancelled) {
    return (
      <s className="paragraph-50-medium text-disabled">
        {formatCurrency(price.amount, price.currency)}
      </s>
    );
  }

  return (
    <span className="paragraph-50-medium">{formatCurrency(price.amount, price.currency)}</span>
  );
};

const { block } = createBEMClasses('article-header-price');

const EditOrganizationPricePopover = ({
  article,
  showWarning,
}: {
  article: ArticleWithRelations;
  showWarning: boolean;
}) => {
  const { _ } = useLingui();
  const [priceManualDiscount, setPriceManualDiscount] = useState(
    article.currentPriceManualDiscount ? -article.currentPriceManualDiscount : null
  );

  const {
    mutateAsync: updateArticle,
    isPending: isUpdatingArticle,
    isError,
  } = useUpdateArticle({
    articleId: article.id,
    requestId: article.requestId,
  });

  return (
    <Dialog
      className={block()}
      aria-label={_(msg({ id: 'article.header.price.edit', message: 'Edit price' }))}
    >
      <Stack gap="1rem">
        <p className="paragraph-100-regular text-primary">
          <Trans id="article.header.price.edit.content">
            Apply a discount percentage. This will apply if you add new actions.
          </Trans>
        </p>
        <InputPercentage
          ariaLabel={_(msg({ id: 'article.header.price.edit.label', message: 'Price discount' }))}
          size="small"
          placeholder={_(
            msg({ id: 'article.header.price.edit.placeholder', message: 'i.e: - 10%' })
          )}
          isLoading={isUpdatingArticle}
          value={priceManualDiscount ?? undefined}
          minValue={-1}
          maxValue={1}
          onChange={(value) => {
            const negativeValue = -Math.abs(value);
            setPriceManualDiscount(negativeValue);

            if (value !== article.currentPriceManualDiscount) {
              updateArticle({
                data: { priceManualDiscount: isNaN(value) ? null : Math.abs(value) },
              });
            }
          }}
          error={
            isError ? _(msg({ id: '_general.error.unknown', message: 'Unknown error' })) : undefined
          }
        />
        {showWarning && (
          <AlertBar type="warning" size="large">
            <Trans id="article.header.price.edit.warning">
              This discount conflicts with the Refashion bonus, preventing it from applying to some
              actions.
            </Trans>
          </AlertBar>
        )}
      </Stack>
    </Dialog>
  );
};

export default ArticlePrice;
