import { Navigate, useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import { useReference } from '@/models/reference';

function Reference() {
  const { brand, ref } = useParams();

  const { data, isLoading } = useReference({ brand, ref });

  if (!brand || !ref) {
    return <Navigate to="/" replace />;
  }

  if (isLoading) {
    return <Trans id="_general.loading">Loading...</Trans>;
  }

  if (data?.request) {
    return <Navigate to={`/requests/${data.request.id}`} replace />;
  }

  if (data?.shipment) {
    return <Navigate to={`/shipments/${data.shipment.id}`} replace />;
  }

  return <Navigate to="/" replace />;
}

export default Reference;

/**
 * @deprecated To be removed once all current (05/2024) active requests are archived
 */
export function LegacyRequestReference() {
  const { ref } = useParams();

  return <Navigate to={`/ref/fusalp/${ref?.startsWith('R24-') ? ref : 'R24-' + ref}`} replace />;
}
