import React from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import cn from 'classnames';

import Box from '@/design_system/Box';
import IconCancel from '@/icons/Cancel.svg';
import IconDone from '@/icons/Done.svg';
import IconZendesk from '@/icons/Zendesk.svg';
import {
  ARTICLE_ARCHIVAL_TYPES,
  ARTICLE_CANCELLATION_TYPES,
  ARTICLE_DISPLAYED_STEPS,
} from '@/models/article';
import { RequestWithRelations } from '@/models/request';
import useViewPort from '@/utils/useViewport';

import './RequestDisplayedSteps.css';

const useRequestDisplayedSteps = (
  request: RequestWithRelations
): {
  label: string;
  icon?: React.ReactNode;
  variant?: 'success' | 'warning' | 'danger';
}[] => {
  const { _ } = useLingui();

  const articles = request.isManuallyArchived
    ? request.allArticles
    : request.allArticles.filter((article) => article.archivalDetail?.type !== 'manual');

  const isArchived = request.isArchived;
  const archivalDetail = articles[0].archivalDetail;
  const cancellationDetail = articles[0].cancellationDetail;
  const archiveType = ARTICLE_ARCHIVAL_TYPES.find(({ id }) => id === archivalDetail?.type);
  const cancellationType = ARTICLE_CANCELLATION_TYPES.find(
    ({ id }) => id === cancellationDetail?.type
  );
  const displayedSteps = ARTICLE_DISPLAYED_STEPS.filter(({ id }) =>
    articles.find(({ displayedStep }) => displayedStep === id)
  );

  if (isArchived && archivalDetail?.reason === 'export-to-zendesk') {
    return [
      {
        label: _(msg({ id: 'article.archival-type.zendesk', message: 'Transferred to Zendesk' })),
        icon: <IconZendesk />,
      },
    ];
  }

  if (isArchived && cancellationType) {
    return [
      {
        label: _(cancellationType.name),
        icon: <IconCancel />,
        variant: 'danger',
      },
    ];
  }

  if (isArchived && archiveType) {
    if (archiveType.id === 'completed') {
      return [
        {
          label: _(archiveType.name),
          icon: <IconDone />,
          variant: 'success',
        },
      ];
    } else if (!displayedSteps.length) {
      return [
        {
          label: _(archiveType.name),
          icon: <IconCancel />,
          variant: 'danger',
        },
      ];
    } else {
      return displayedSteps.map((displayedStep) => ({
        label: `${_(archiveType.name)}: ${_(displayedStep.name)}`,
        icon: <IconCancel />,
        variant: 'danger',
      }));
    }
  }

  return displayedSteps.map((displayedStep) => {
    const hasIssue = articles.some(
      (article) => article.displayedStep === displayedStep.id && article.hasIssue
    );

    if (hasIssue) {
      return {
        label: `${_(msg({ id: 'article.issue.troubleshooting', message: 'Troubleshooting' }))}: ${_(displayedStep.name)}`,
        variant: 'danger',
      };
    } else {
      return {
        label: _(displayedStep.name),
      };
    }
  });
};

export const RequestDisplayedSteps = ({ request }: { request: RequestWithRelations }) => {
  const displayedSteps = useRequestDisplayedSteps(request);
  const { isMobile } = useViewPort();

  if (isMobile) {
    return (
      <>
        {displayedSteps.map((step, index) => {
          return (
            <Box padding="0.25rem 0.5rem" style={{ flex: 'none' }} key={index}>
              <div
                className={cn('request-displayed-step paragraph-200-regular', {
                  'has-icon': !!step.icon,
                  'text-success': step.variant === 'success',
                  'text-warning': step.variant === 'warning',
                  'text-danger': step.variant === 'danger',
                })}
              >
                {step.icon}
                {step.label}
              </div>
            </Box>
          );
        })}
      </>
    );
  }

  const hasIcon = displayedSteps.some((step) => !!step.icon);

  return (
    <ul
      style={displayedSteps.length <= 1 || hasIcon ? { listStyle: 'none', padding: 0 } : undefined}
    >
      {!displayedSteps.length && <li>-</li>}
      {!!displayedSteps.length &&
        displayedSteps.map((step, index) => (
          <li
            key={index}
            className={cn('request-displayed-step', {
              'has-icon': !!step.icon,
              'text-success': step.variant === 'success',
              'text-warning': step.variant === 'warning',
              'text-danger': step.variant === 'danger',
            })}
          >
            {step.icon}
            {step.label}
          </li>
        ))}
    </ul>
  );
};
