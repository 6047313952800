import IconWrapper from './IconWrapper';

const IconRefusal = IconWrapper({
  name: 'refusal',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4726 2.4176C12.1971 2.19413 11.8029 2.19413 11.5274 2.4176C9.8148 3.80724 7.33866 4.5391 5.63603 4.8943C4.31916 5.16901 3.25 6.31307 3.25 7.73577V14.3833C3.25 16.0412 4.11441 17.5792 5.53068 18.441L10.5704 21.508C11.4485 22.0424 12.5514 22.0424 13.4296 21.508L18.4693 18.441C19.8856 17.5792 20.75 16.0412 20.75 14.3833V7.73577C20.75 6.31307 19.6808 5.16901 18.364 4.8943C16.6613 4.5391 14.1852 3.80724 12.4726 2.4176ZM5.94236 6.36268C7.56408 6.02437 10.052 5.32532 12 3.941C13.948 5.32532 16.4359 6.02437 18.0576 6.36268C18.7564 6.50845 19.25 7.09948 19.25 7.73577V14.3833C19.25 15.5177 18.6586 16.57 17.6895 17.1597L12.6498 20.2266C12.2507 20.4695 11.7493 20.4695 11.3502 20.2266L6.31046 17.1597C5.34144 16.57 4.75 15.5177 4.75 14.3833V7.73577C4.75 7.09948 5.24363 6.50845 5.94236 6.36268ZM8.79384 8.794C9.08674 8.50111 9.56162 8.50111 9.85452 8.794L12.0009 10.9403L14.1472 8.794C14.4401 8.50111 14.915 8.50111 15.2079 8.794C15.5008 9.08688 15.5008 9.56175 15.2079 9.85464L13.0616 12.0009L15.2076 14.1469C15.5005 14.4398 15.5005 14.9147 15.2076 15.2076C14.9147 15.5004 14.4398 15.5004 14.1469 15.2076L12.0009 13.0616L9.85483 15.2076C9.56193 15.5004 9.08705 15.5004 8.79415 15.2076C8.50126 14.9147 8.50126 14.4398 8.79415 14.1469L10.9402 12.0009L8.79384 9.85464C8.50095 9.56175 8.50095 9.08688 8.79384 8.794Z"
      />
    </svg>
  ),
});

export default IconRefusal;
