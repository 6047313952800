import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import config from '@/config';
import Button from '@/design_system/Button';
import IconQrCode from '@/icons/QrCode.svg';
import { Organization } from '@/models/organization';
import useViewPort from '@/utils/useViewport';

export const ArticlePrintArticleSheetActionCell = ({
  article,
  organization,
}: {
  article: { id: string };
  organization: Organization;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const label = _(msg({ id: 'article.print-item-sheet.message', message: 'Print' }));

  return (
    <Button
      size="medium"
      variant="secondary"
      iconOnly={!isMobile}
      href={config.apiUrl + `/articles/${article.id}/recap?organization=${organization.slug}`}
      target="_blank"
      ariaLabel={label}
      tooltip={label}
      style={{ flex: 1 }}
    >
      <IconQrCode />
      {isMobile && <div>{label}</div>}
    </Button>
  );
};
