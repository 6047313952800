import React, { useEffect, useState } from 'react';
import { Link } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { add, isBefore } from 'date-fns';
import { Alpha2Code } from 'i18n-iso-countries';

import { InputCountry } from '@/components/InputCountry/InputCountry';
import Button from '@/design_system/Button';
import { InputSelect } from '@/design_system/InputSelect/InputSelect';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import IconArrow from '@/icons/Arrow.svg';
import IconHelp from '@/icons/Help.svg';
import { useOrganizationWorkflows } from '@/models/organization';
import { useCreateDraftGuestRequest } from '@/models/request';
import { Store, useStores } from '@/models/store';
import { ExternalStoreInfo } from '@/routes/Brand/Requests/New/components/ExternalStoreInfo';
import PoweredBy from '@/routes/Brand/Requests/New/components/PoweredBy';
import { useCurrentOrganization, useGeoIpCountry } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import {
  REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY,
  useRequestInProgress,
} from '@/utils/requestInProgress';
import useDebouncedState from '@/utils/useDebouncedState';

import './Welcome.css';

const { block, element } = createBEMClasses('client-new-welcome');

const requestTypes = ['external-store', 'internal-store', 'web-client'] as const;
type RequestType = (typeof requestTypes)[number];

const Welcome = () => {
  const { _ } = useLingui();
  const [organization] = useCurrentOrganization();
  const navigate = useNavigate();

  const codeInUrl = new URLSearchParams(window.location.search).get('code');

  const geoIpCountry = useGeoIpCountry();
  const [country, setCountry] = useState<Alpha2Code>(geoIpCountry ?? 'FR');
  const [type, setType] = useState<RequestType>(codeInUrl ? 'external-store' : 'web-client');
  const [internalStoreId, setInternalStoreId] = useState<string>();
  const [storeAccessCode, debouncedStoreAccessCode, setStoreAccessCode] = useDebouncedState<string>(
    codeInUrl ?? '',
    500
  );

  const { data: { stores: internalStores } = { stores: [] } } = useStores(
    {
      internal: true,
      embedded: false,
      limit: 100,
    },
    {
      enabled: type === 'internal-store',
    }
  );

  const internalStore = internalStores.find((store) => store.id === internalStoreId);

  const { data: { stores: [externalStore] } = { stores: [] }, isLoading: isExternalStoresLoading } =
    useStores(
      {
        accessCode: debouncedStoreAccessCode,
        internal: false,
        embedded: false,
        limit: 1,
      },
      {
        enabled: debouncedStoreAccessCode.length === 12,
      }
    );

  const { mutateAsync: createDraftRequest, isPending: isPendingCreateDraftRequest } =
    useCreateDraftGuestRequest();
  const { requestInProgress, setRequestInProgress } = useRequestInProgress();

  useEffect(() => {
    if (requestInProgress) {
      const storageRequestDatePlus24h = add(requestInProgress.date, { hours: 24 });
      const isStorageRequestDateMoreThan24hAgo = isBefore(storageRequestDatePlus24h, new Date());

      if (isStorageRequestDateMoreThan24hAgo) {
        window.localStorage.removeItem(REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY);
      } else {
        const tokenQueryParam = requestInProgress.clientToken
          ? `clientToken=${requestInProgress.clientToken}`
          : `storeToken=${requestInProgress.storeToken}`;

        navigate(
          `/brand/${organization?.slug}/requests/new?id=${requestInProgress.id}&${tokenQueryParam}`,
          { replace: true }
        );

        setRequestInProgress({
          ...requestInProgress,
          date: new Date(),
        });
      }
    }
  }, [navigate, organization?.slug, requestInProgress, setRequestInProgress]);

  const handleContinue = async () => {
    if (disableContinue) {
      return;
    }

    if (type === 'web-client') {
      const response = await createDraftRequest({ country });

      if ('clientToken' in response) {
        setRequestInProgress({
          id: response.id,
          clientToken: response.clientToken,
          date: new Date(),
        });

        navigate(`?id=${response.id}&clientToken=${response.clientToken}`, { replace: true });
      }
    } else if (type === 'internal-store') {
      const response = await createDraftRequest({ storeId: internalStoreId });

      if ('storeToken' in response) {
        setRequestInProgress({
          id: response.id,
          storeToken: response.storeToken,
          date: new Date(),
        });

        navigate(`?id=${response.id}&storeToken=${response.storeToken}`, { replace: true });
      }
    } else if (type === 'external-store') {
      const response = await createDraftRequest({ storeId: externalStore?.id });

      if ('storeToken' in response) {
        setRequestInProgress({
          id: response.id,
          storeToken: response.storeToken,
          date: new Date(),
        });

        navigate(`?id=${response.id}&storeToken=${response.storeToken}`, { replace: true });
      }
    }
  };

  const { data: workflows } = useOrganizationWorkflows(organization?.slug);

  const clientCreationConfig = workflows
    ?.find((workflow) => workflow.type === 'client')
    ?.nodes.find((node) => 'step' in node && node.step === 'creation')?.config;

  const supportsStoreWorkflow = !!workflows?.find(
    (workflow) => workflow.type === 'client_via_store' || workflow.type === 'store'
  );

  const askForCountry = type === 'web-client' && (clientCreationConfig?.requirePrice ?? false);

  const disableContinue =
    (type === 'web-client' && askForCountry && !country) ||
    (type === 'internal-store' && !internalStore) ||
    (type === 'external-store' && !externalStore);

  const requestorTypeOptions = [
    {
      id: 'web-client',
      text: _(
        msg({
          id: 'client.new.welcome.type.web_client',
          message: 'Individual Customer',
        })
      ),
    },
    {
      id: 'external-store',
      text: _(
        msg({
          id: 'client.new.welcome.type.client_via_external_store',
          message: 'Retailer / Wholesaler',
        })
      ),
    },
    {
      id: 'internal-store',
      text: _(
        msg({
          id: 'client.new.welcome.type.client_via_internal_store',
          message: 'Outlet / Affiliated / Proper store',
        })
      ),
    },
  ];

  return (
    <div className={block()}>
      <Stack className={element('header')}>
        <Stack className={element('header__image')} />
        <Stack className={element('header__text')}>
          <h1
            className={element(
              'header__text__title',
              undefined,
              'headline-50-bold headline-200-bold-mobile'
            )}
          >
            {organization?.name ? (
              <Trans id="client.new.welcome.title-with-organization">
                {organization.name}&apos;s Care & Repair Service
              </Trans>
            ) : (
              <Trans id="client.new.welcome.title-without-organization">
                Care & Repair Service
              </Trans>
            )}
          </h1>
          <Stack className="headline-200-medium paragraph-50-medium-mobile">
            <Trans id="client.new.welcome.subtitle">
              Extend the Life of Your Loved Apparels & Accessories.
            </Trans>
          </Stack>
        </Stack>
      </Stack>

      <main>
        <Stack className={element('main')}>
          <Stack gap="1.5rem">
            {supportsStoreWorkflow && (
              <InputSelect
                variant="select"
                label={_(
                  msg({
                    id: 'client.new.welcome.type.label',
                    message: 'Tell us who you are',
                  })
                )}
                placeholder={_(
                  msg({
                    id: 'client.new.welcome.type.placeholder',
                    message: 'Select a requestor type',
                  })
                )}
                isSearchable={false}
                options={requestorTypeOptions}
                value={requestorTypeOptions.filter(
                  (requestorTypeOption) => type === requestorTypeOption.id
                )}
                getOptionValue={(requestorTypeOption) => requestorTypeOption.id}
                getOptionLabel={(requestorTypeOption) => requestorTypeOption.text}
                onChange={(requestorTypeOption) => {
                  // @ts-ignore requestTypes is a const array but we want to check if key is in it
                  if (requestorTypeOption && requestTypes.includes(requestorTypeOption.id)) {
                    setType(requestorTypeOption.id as RequestType);
                  }

                  if (requestorTypeOption?.id === 'web-client') {
                    setStoreAccessCode('');
                  }
                }}
                styleVariant="brand"
                size="large"
                style={{ alignSelf: 'stretch' }}
              />
            )}

            {askForCountry && (
              <InputCountry
                label={_(
                  msg({
                    id: 'client.new.welcome.country.label',
                    message: 'Please tell us your country to adapt the currency',
                  })
                )}
                placeholder={_(
                  msg({ id: 'client.new.welcome.country.placeholder', message: 'Select a country' })
                )}
                value={country}
                onChange={setCountry}
                styleVariant="brand"
                size="large"
                style={{ alignSelf: 'stretch' }}
              />
            )}

            {type === 'external-store' && (
              <Stack gap="1rem">
                <ExternalStoreAccessCodeInput
                  storeAccessCode={storeAccessCode}
                  debouncedStoreAccessCode={debouncedStoreAccessCode}
                  setStoreAccessCode={setStoreAccessCode}
                  isExternalStoresLoading={isExternalStoresLoading}
                  store={externalStore}
                />
                {!!externalStore && <ExternalStoreInfo store={externalStore} />}
              </Stack>
            )}

            {type === 'internal-store' && (
              <InputSelect
                variant="select"
                label={_(
                  msg({
                    id: 'client.new.welcome.internal-store.label',
                    message: 'Select your store',
                  })
                )}
                placeholder={_(
                  msg({
                    id: 'client.new.welcome.internal-store.placeholder',
                    message: 'Select your store',
                  })
                )}
                isSearchable={false}
                options={internalStores}
                value={internalStores.filter(
                  (internalStoreOption) => internalStoreId === internalStoreOption.id
                )}
                getOptionValue={(internalStoreOption) => internalStoreOption.id}
                getOptionLabel={(internalStoreOption) => internalStoreOption.name}
                onChange={(internalStoreOption) => {
                  if (internalStoreOption) {
                    setInternalStoreId(internalStoreOption.id);
                  }
                }}
                hintText={
                  <ContactUs>
                    <Trans id="client.new.welcome.contact-us.cannot-find">
                      Don&apos;t find your store?
                    </Trans>
                  </ContactUs>
                }
                styleVariant="brand"
                size="large"
                style={{ alignSelf: 'stretch' }}
              />
            )}
          </Stack>

          <div className={element('stepper')}>
            <div className={element('stepper__image')} />
            <Stack gap="1rem">
              <p className="headline-300-bold">
                <Trans id="client.new.welcome.stepper.title">How does it work?</Trans>
              </p>
              <ul>
                <li className={element('stepper__item')}>
                  <div className={element('stepper__item__index')}>1</div>
                  <div>
                    <p className="paragraph-50-medium">
                      <Trans id="client.new.welcome.stepper.1.title">Get a free estimate</Trans>
                    </p>
                    <p className="paragraph-100-regular text-secondary">
                      <Trans id="client.new.welcome.stepper.1.detail">
                        Share the details of your item(s) needing attention. It takes just 1 minute
                        to get a free estimate.
                      </Trans>
                    </p>
                  </div>
                </li>
                <li className={element('stepper__item')}>
                  <div className={element('stepper__item__index')}>2</div>
                  <div>
                    <p className="paragraph-50-medium">
                      <Trans id="client.new.welcome.stepper.2.title">Send your item(s)</Trans>
                    </p>
                    <p className="paragraph-100-regular text-secondary">
                      <Trans id="client.new.welcome.stepper.2.detail">
                        Ship your item(s) to our trusted expert partner.
                      </Trans>
                    </p>
                  </div>
                </li>
                <li className={element('stepper__item')}>
                  <div className={element('stepper__item__index')}>3</div>
                  <div>
                    <p className="paragraph-50-medium">
                      <Trans id="client.new.welcome.stepper.3.title">Sit back and relax</Trans>
                    </p>
                    <p className="paragraph-100-regular text-secondary">
                      <Trans id="client.new.welcome.stepper.3.detail">
                        Our experts will handle the rest. You&apos;ll be notified as soon as your
                        item is ready.
                      </Trans>
                    </p>
                  </div>
                </li>
                <li className={element('stepper__item')}>
                  <div className={element('stepper__item__index')}>4</div>
                  <div>
                    <p className="paragraph-50-medium">
                      <Trans id="client.new.welcome.stepper.4.title">
                        Receive your revamped item
                      </Trans>
                    </p>
                    <p className="paragraph-100-regular text-secondary">
                      <Trans id="client.new.welcome.stepper.4.detail">
                        You&apos;ll get your item back shortly, ready to wear!
                      </Trans>
                    </p>
                  </div>
                </li>
              </ul>
            </Stack>
          </div>

          <PoweredBy />
        </Stack>
      </main>
      <footer>
        <Stack>
          <Button
            size="large"
            variant="brand"
            style={{ flex: 1 }}
            disabled={disableContinue}
            isLoading={isPendingCreateDraftRequest}
            onPress={handleContinue}
          >
            <Trans id="client.new.welcome.continue">Continue</Trans>
            <IconArrow right />
          </Button>
        </Stack>
      </footer>
    </div>
  );
};

const ExternalStoreAccessCodeInput = ({
  storeAccessCode,
  debouncedStoreAccessCode,
  setStoreAccessCode,
  isExternalStoresLoading,
  store,
}: {
  storeAccessCode: string;
  debouncedStoreAccessCode: string;
  setStoreAccessCode: (storeAccessCode: string) => void;
  isExternalStoresLoading: boolean;
  store?: Store | null;
}) => {
  const { _ } = useLingui();

  let error;

  if (debouncedStoreAccessCode.length && debouncedStoreAccessCode.length !== 12) {
    error = _(
      msg({
        id: 'client.new.welcome.external-store-code.invalid-length',
        message: 'Code must be 12 characters long',
      })
    );
  } else if (debouncedStoreAccessCode.length === 12 && !isExternalStoresLoading && !store) {
    error = _(
      msg({
        id: 'client.new.welcome.external-store-code.invalid',
        message: 'Invalid code',
      })
    );
  }

  return (
    <InputText
      size="large"
      label={_(
        msg({
          id: 'client.new.welcome.external-store-code.label',
          message: 'After-Sales code',
        })
      )}
      aria-label={_(
        msg({
          id: 'client.new.welcome.external-store-code.label',
          message: 'After-Sales code',
        })
      )}
      placeholder={_(
        msg({
          id: 'client.new.welcome.external-store-code.label.placeholder',
          message: 'Type your 12 characters code',
        })
      )}
      value={storeAccessCode}
      onChange={(value) => setStoreAccessCode(value)}
      isLoading={isExternalStoresLoading}
      error={error}
      hintText={
        store ? undefined : (
          <ContactUs>
            <Trans id="client.new.welcome.contact-us.forgot-code">Forgot your code?</Trans>
          </ContactUs>
        )
      }
      showMessageIcon={false}
    />
  );
};

const ContactUs = ({ children }: { children: React.ReactNode }) => {
  const [currentOrganization] = useCurrentOrganization();

  if (!currentOrganization?.config.afterSalesServiceEmail) {
    return null;
  }

  return (
    <Stack row alignItems="center">
      <IconHelp className="text-secondary" style={{ marginRight: '0.25rem' }} />
      <p className="paragraph-100-regular">
        <span className="text-secondary">{children}</span>{' '}
        <Trans id="client.new.welcome.contact-us.link">
          Contact us at{' '}
          <Link
            className="color-tertiary-700"
            href={`mailto:${currentOrganization?.config.afterSalesServiceEmail}`}
            target="_blank"
          >
            {currentOrganization?.config.afterSalesServiceEmail}
          </Link>
        </Trans>
      </p>
    </Stack>
  );
};

export default Welcome;
