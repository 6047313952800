import { useCallback, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { isAddressValid, useAddressState } from '@/components/AddressForm';
import AddressForm from '@/components/AddressForm/AddressForm';
import InputPhone, {
  getValidCountryCallingCode,
  isPhoneValid,
  usePhoneState,
} from '@/components/InputPhone/InputPhone';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import InputSearch from '@/design_system/InputSearch';
import InputText from '@/design_system/InputText/InputText';
import { PaginationFooter } from '@/design_system/Pagination/Pagination';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconEdit from '@/icons/Edit.svg';
import { useUsers } from '@/models/user';
import { useUpdateWorkshop, useWorkshops, Workshop } from '@/models/workshop';
import { useGeoIpCountry } from '@/services/auth';
import useDebouncedState from '@/utils/useDebouncedState';

const WORKSHOPS_PER_PAGE = 10;

export const InternalWorkshops = () => {
  const { _ } = useLingui();
  const [page, setPage] = useState(1);
  const resetPage = useCallback(() => setPage(1), [setPage]);
  const [search, debouncedSearch, setSearch] = useDebouncedState('', 300, resetPage);

  const { data: { workshops, meta } = {} } = useWorkshops({
    limit: WORKSHOPS_PER_PAGE,
    offset: (page - 1) * WORKSHOPS_PER_PAGE,
    search: debouncedSearch,
    internal: true,
  });

  const noData = workshops && workshops?.length === 0;

  return (
    <Stack gap="40px">
      <Box padding="24px" gap="24px">
        <Stack row alignItems="center" justifyContent="space-between">
          <h2 className="headline-200-bold">
            <Trans id="settings.internal-workshops.title">
              Internal workshops of this organization
            </Trans>
          </h2>
        </Stack>
        <InputSearch
          ariaLabel={_(msg({ id: 'settings.internal-workshops.search.label', message: 'Search' }))}
          placeholder={_(
            msg({
              id: 'settings.internal-workshops.search.placeholder',
              message: 'Search by name, address, contact name…',
            })
          )}
          style={{ flex: 1, minWidth: 175, maxWidth: 500 }}
          size="medium"
          value={search}
          onChange={setSearch}
        />
        {noData && (
          <p className="paragraph-100-regular">
            <Trans id="settings.internal-workshops.empty">No items for now</Trans>
          </p>
        )}
        {!noData && (
          <Table
            ariaLabel={_(
              msg({
                id: 'settings.internal-workshops.table.label',
                message: 'Internal workshops',
              })
            )}
            columnWidths="minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) 80px auto"
          >
            <Header>
              <Row>
                <Column>
                  <Trans id="settings.internal-workshops.column.name">Name</Trans>
                </Column>
                <Column>
                  <Trans id="settings.internal-workshops.column.address">Address</Trans>
                </Column>
                <Column>
                  <Trans id="settings.internal-workshops.column.contact">Contact</Trans>
                </Column>
                <Column justifyContent="center">
                  <Trans id="settings.internal-workshops.column.users">Users</Trans>
                </Column>
                <Column
                  ariaLabel={_(
                    msg({
                      id: 'settings.internal-workshops.column.actions',
                      message: 'Actions',
                    })
                  )}
                />
              </Row>
            </Header>
            <Body>
              {workshops?.map((workshop) => (
                <InternalWorkshopRow key={workshop.id} workshop={workshop} />
              ))}
            </Body>
            <PaginationFooter
              page={page}
              itemsPerPage={WORKSHOPS_PER_PAGE}
              count={meta?.count}
              onPageChange={setPage}
            />
          </Table>
        )}
      </Box>
    </Stack>
  );
};

const InternalWorkshopRow = ({ workshop }: { workshop: Workshop }) => {
  const { data: { meta } = {} } = useUsers({
    limit: 1,
    workshopId: workshop.id,
  });

  return (
    <Row>
      <Cell>
        <b>{workshop.name}</b>
      </Cell>
      <Cell>
        <p>
          {workshop.address?.formattedStreet ?? '-'}
          <br />
          {workshop.address?.formattedZip ?? '-'}
        </p>
      </Cell>
      <Cell>
        <p>
          {workshop.address?.contactName ?? '-'}
          <br />
          {workshop.address?.contactEmail ?? '-'}
          <br />
          {workshop.formattedPhone ?? '-'}
        </p>
      </Cell>
      <Cell justifyContent="center">{meta?.count}</Cell>
      <Cell>
        <Stack row gap="4px">
          <UpdateInternalWorkshop workshop={workshop} />
        </Stack>
      </Cell>
    </Row>
  );
};

const UpdateInternalWorkshop = ({ workshop }: { workshop: Workshop }) => {
  const { _ } = useLingui();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  const [name, setName] = useState(workshop.name);
  const [address, setAddress] = useAddressState(workshop.address);
  const [phone, setPhone] = usePhoneState(workshop.phone);
  const geoIpCountry = useGeoIpCountry();

  const { mutateAsync: updateWorkshop, isPending } = useUpdateWorkshop();

  return (
    <Dialog
      isOpen={open}
      onOpenChange={(next) => {
        if (isPending) return;

        setName(workshop.name);
        setAddress(workshop.address ?? { country: geoIpCountry ?? 'FR' });
        setPhone(
          workshop.phone ?? {
            countryCode: getValidCountryCallingCode(geoIpCountry ?? 'FR'),
            number: '',
          }
        );

        setOpen(next);
      }}
      trigger={
        <Button
          variant="secondary"
          iconOnly
          size="small"
          ariaLabel={_(
            msg({
              id: 'settings.internal-workshops.edit-modal.trigger',
              message: `Edit ${workshop.name}`,
            })
          )}
          tooltip={_(
            msg({
              id: 'settings.internal-workshops.edit-modal.trigger',
              message: `Edit ${workshop.name}`,
            })
          )}
        >
          <IconEdit />
        </Button>
      }
      title={<Trans id="settings.internal-workshops.edit-modal.title">Edit workshop</Trans>}
    >
      <form
        style={{ overflow: 'auto' }}
        onSubmit={(evt) => {
          evt.preventDefault();

          if (!isAddressValid(address)) {
            setError(
              _(
                msg({
                  id: 'settings.internal-workshops.edit-modal.error.invalid-address',
                  message: 'Invalid address',
                })
              )
            );
            return;
          }

          if (!isPhoneValid(phone)) {
            setError(
              _(
                msg({
                  id: 'settings.internal-workshops.edit-modal.error.invalid-phone',
                  message: 'Invalid phone number',
                })
              )
            );
            return;
          }

          updateWorkshop({ workshopId: workshop.id, name, address, phone })
            .then(() => {
              setOpen(false);
              setError('');
            })
            .catch((err) => {
              setError(
                (err.message as string) ??
                  _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
              );
            });
        }}
      >
        <main>
          <Stack gap="24px">
            <InputText
              label={
                <Trans id="settings.internal-workshops.edit-modal.field.name.label">Name</Trans>
              }
              type="text"
              placeholder={_(
                msg({
                  id: 'settings.internal-workshops.edit-modal.field.name.placeholder',
                  message: 'Name of the workshop',
                })
              )}
              value={name}
              onChange={setName}
              isRequired
              isDisabled={isPending}
              isInvalid={!!error}
            />
            <AddressForm value={address} onChange={setAddress} />
            <InputPhone
              label={
                <Trans id="settings.internal-workshops.edit-modal.field.phone.label">Phone</Trans>
              }
              value={phone}
              onChange={setPhone}
            />
            <InputText
              label={
                <Trans id="settings.internal-workshops.edit-modal.field.region.label">Region</Trans>
              }
              type="text"
              value={workshop.region}
              isRequired
              isDisabled
            />
            <InputText
              label={
                <Trans id="settings.internal-workshops.edit-modal.field.currency.label">
                  Currency
                </Trans>
              }
              type="text"
              value={workshop.currency ?? ''}
              isRequired
              isDisabled
            />
            {error && <p className="paragraph-100-medium text-danger text-center">{error}</p>}
          </Stack>
        </main>
        <footer>
          <Button variant="primary" size="small" type="submit" isLoading={isPending}>
            <Trans id="settings.internal-workshops.edit-modal.submit">Save</Trans>
          </Button>
        </footer>
      </form>
    </Dialog>
  );
};
