import { Trans } from '@lingui/macro';

import config from '@/config';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconQrCode from '@/icons/QrCode.svg';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

import { BaseMessage } from './BaseMessage';

export const ServiceChoiceStepMessage = ({
  article,
  request,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
}) => {
  const { currentSession } = useCurrentSession();

  if (!article.serviceChosenAt) {
    const showPrintItemSheetMessage =
      article.atStoreId &&
      request.storeId &&
      currentSession?.stores.map((store) => store.id).includes(article.atStoreId);

    return (
      <BaseMessage
        title={<Trans id="article.step.service-choice.title">Waiting for service selection</Trans>}
        message={
          showPrintItemSheetMessage ? (
            <Stack gap="0.5rem" alignItems="flex-start">
              <p>
                <Trans id="article.step.service-choice.text">
                  In the meantime, please make sure to print out and attach the QR code to the item.
                </Trans>
              </p>
              <Stack row gap="8px">
                <Button
                  variant="secondary"
                  size="medium"
                  href={
                    config.apiUrl +
                    `/articles/${article.id}/recap?organization=${request.organization.slug}`
                  }
                  target="_blank"
                  className="text-no-wrap"
                >
                  <IconQrCode />
                  <Trans id="article.step.service-choice.action">Print item sheet</Trans>
                </Button>
              </Stack>
            </Stack>
          ) : null
        }
        article={article}
      />
    );
  }

  if (article.serviceChoice === 'care-repair' && !article.workshopAcceptedAt) {
    return (
      <BaseMessage
        title={
          <Trans id="article.step.service-choice.workshop-validation.title">
            Waiting for workshop validation
          </Trans>
        }
        message={
          <Trans id="article.step.service-choice.workshop-validation.text">
            The workshop is analysing the item to determine if it can handle it.
          </Trans>
        }
        article={article}
      />
    );
  }

  return null;
};
