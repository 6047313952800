import IconPicture from '@/icons/Picture.svg';
import { Medium } from '@/models/medium';
import { createBEMClasses } from '@/utils/classname';

import './ArticlePhoto.css';

const { block, element } = createBEMClasses('article-photo');

const ArticlePhoto = ({
  photo,
  showPlaceholder,
  size = 'medium',
  withBorder,
  count,
}: {
  photo?: Medium;
  showPlaceholder?: boolean;
  size?: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';
  withBorder?: boolean;
  count?: number;
}) => {
  if (!photo || !photo.url) {
    if (showPlaceholder) {
      return (
        <div className={block({ size, border: withBorder, placeholder: showPlaceholder })}>
          <IconPicture className="text-disabled" />
        </div>
      );
    }

    if (count) {
      return (
        <div className={block({ count: true, size, border: withBorder }, 'text-secondary')}>
          +{count}
        </div>
      );
    }

    return <div className={block({ size, loading: true })}>-</div>;
  }

  return (
    <div className={block({ size, border: withBorder }, element('photo'))}>
      <img src={photo.url} alt="" />
    </div>
  );
};

export default ArticlePhoto;
