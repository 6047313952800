import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconFile from '@/icons/File.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const PendingDispatchAcceptationNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'pending_dispatch_acceptation'>;
}) => {
  const { _ } = useLingui();

  return (
    <BaseNotification
      notificationLabel={_(
        msg({
          id: 'notification.pending-dispatch-acceptation.label',
          message: `New job to validate for request ${notification.request.reference}`,
        })
      )}
      icon={<IconFile />}
      type="new"
      notification={notification}
      notificationHeader={
        <Trans id="notification.pending-dispatch-acceptation">
          <span className="paragraph-100-medium">You</span> have a new job to validate
        </Trans>
      }
    />
  );
};
