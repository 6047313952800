import IconWrapper from './IconWrapper';

const IconInfo = IconWrapper({
  name: 'info',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM13.0001 8.5C13.0001 9.05228 12.5523 9.5 12.0001 9.5C11.4478 9.5 11.0001 9.05228 11.0001 8.5C11.0001 7.94771 11.4478 7.5 12.0001 7.5C12.5523 7.5 13.0001 7.94771 13.0001 8.5ZM12 11C12.5523 11 13 11.4477 13 12L13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16L11 12C11 11.4477 11.4477 11 12 11Z"
      />
    </svg>
  ),
});

export default IconInfo;
