import IconWrapper from './IconWrapper';

const IconCalendarOverdue = IconWrapper({
  name: 'calendar-overdue',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 2C9.16422 2 9.5 2.33579 9.5 2.75V3.5H14.5V2.75C14.5 2.33579 14.8358 2 15.25 2C15.6642 2 16 2.33579 16 2.75V3.50002C17.0403 3.50029 17.8887 3.50498 18.5738 3.58221C19.3281 3.66725 19.9766 3.84807 20.5329 4.27492C20.7929 4.47443 21.0256 4.70715 21.2251 4.96714C21.6519 5.52343 21.8328 6.17195 21.9178 6.92622C22 7.65571 22 8.57039 22 9.7048V11.5H20.5V10.5H3.5V15.25C3.5 16.6854 3.50159 17.6865 3.60315 18.4419C3.7018 19.1757 3.88225 19.5642 4.15901 19.841C4.43578 20.1178 4.82435 20.2982 5.55812 20.3969C6.31347 20.4984 7.31459 20.5 8.75 20.5H11.5V22H8.69513C7.32754 22 6.22522 22 5.35825 21.8835C4.45814 21.7625 3.70027 21.5036 3.09835 20.9017C2.49644 20.2997 2.23754 19.5419 2.11653 18.6418C1.99997 17.7748 1.99998 16.6725 2 15.3049V9.70481C1.99999 8.57041 1.99998 7.6557 2.08222 6.92622C2.16725 6.17195 2.34808 5.52343 2.77493 4.96714C2.97443 4.70715 3.20715 4.47443 3.46715 4.27492C4.02343 3.84807 4.67195 3.66725 5.42622 3.58221C6.11129 3.50498 6.95968 3.50029 8 3.50002V2.75C8 2.33579 8.33579 2 8.75 2ZM8 5.00004C6.94201 5.00052 6.1844 5.00624 5.59426 5.07277C4.97376 5.14272 4.63193 5.27187 4.38029 5.46496C4.22429 5.58466 4.08466 5.72429 3.96496 5.88029C3.77187 6.13193 3.64273 6.47376 3.57277 7.09426C3.51723 7.58697 3.50407 8.19644 3.50096 9H20.499C20.4959 8.19644 20.4828 7.58697 20.4272 7.09426C20.3573 6.47376 20.2281 6.13193 20.035 5.88029C19.9153 5.72429 19.7757 5.58466 19.6197 5.46496C19.3681 5.27187 19.0262 5.14272 18.4057 5.07277C17.8156 5.00624 17.058 5.00052 16 5.00004V5.75C16 6.16421 15.6642 6.5 15.25 6.5C14.8358 6.5 14.5 6.16421 14.5 5.75V5H9.5V5.75C9.5 6.16421 9.16422 6.5 8.75 6.5C8.33579 6.5 8 6.16421 8 5.75V5.00004Z"
      />
      <path d="M6.75 13.5C6.75 13.0858 7.08579 12.75 7.5 12.75H9.5C9.91422 12.75 10.25 13.0858 10.25 13.5C10.25 13.9142 9.91422 14.25 9.5 14.25H7.5C7.08579 14.25 6.75 13.9142 6.75 13.5Z" />
      <path d="M6.75 17.5C6.75 17.0858 7.08579 16.75 7.5 16.75H9.5C9.91422 16.75 10.25 17.0858 10.25 17.5C10.25 17.9142 9.91422 18.25 9.5 18.25H7.5C7.08579 18.25 6.75 17.9142 6.75 17.5Z" />
      <path d="M21.7791 15.2803C22.072 14.9874 22.072 14.5126 21.7791 14.2197C21.4862 13.9268 21.0113 13.9268 20.7184 14.2197L17.9994 16.9387L15.2803 14.2197C14.9874 13.9268 14.5126 13.9268 14.2197 14.2197C13.9268 14.5126 13.9268 14.9874 14.2197 15.2803L16.9387 17.9994L14.2197 20.7184C13.9268 21.0113 13.9268 21.4862 14.2197 21.7791C14.5126 22.072 14.9874 22.072 15.2803 21.7791L17.9994 19.06L20.7184 21.7791C21.0113 22.072 21.4862 22.072 21.7791 21.7791C22.072 21.4862 22.072 21.0113 21.7791 20.7184L19.06 17.9994L21.7791 15.2803Z" />
    </svg>
  ),
});

export default IconCalendarOverdue;
