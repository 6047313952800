import { GridList, GridListItem } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  ArticleCostCardItem,
  ArticleNameCell,
  ArticlePriceCardItem,
  ArticleWorkshopCell,
  BaseMoneyCell,
} from '@/components/ArticlesTableCells';
import {
  ArticleServiceTypeCardItem,
  ArticleServiceTypeCell,
} from '@/components/ArticlesTableCells/ArticleServiceTypeCell';
import { ArticleWorkshopCardItem } from '@/components/ArticlesTableCells/ArticleWorkshopCell';
import { Card, CardActions, CardContent, CardItems } from '@/components/Card/Card';
import Badge from '@/design_system/Badge';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconCancel from '@/icons/Cancel.svg';
import IconEdit from '@/icons/Edit.svg';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations, useDeleteDraftRequestArticle } from '@/models/request';
import {
  useArticleDetailsError,
  useArticleServicesError,
} from '@/routes/Requests/New/components/ArticleForm';
import { useRequestContext } from '@/routes/Requests/RequestContext';
import useViewPort from '@/utils/useViewport';

export const CreationArticlesTable = () => {
  const { _ } = useLingui();
  const {
    request,
    view: { workshop, price, cost },
  } = useRequestContext();

  return (
    <Table
      ariaLabel={_(msg({ id: 'request.articles.table.label', message: 'Items' }))}
      columnWidths={[
        'minmax(220px, 2fr)',
        'minmax(128px, 2fr)',
        workshop.enabled && 'minmax(160px, 2fr)',
        !workshop.enabled && cost.enabled && 'minmax(100px, 1fr)',
        price.enabled && 'minmax(100px, 1fr)',
        '100px',
      ]}
    >
      <CreationArticlesTableHeader />
      <Body>
        {request.articles.map((article) => (
          <CreationArticlesTableRow key={article.id} article={article} />
        ))}
      </Body>
    </Table>
  );
};

const CreationArticlesTableHeader = () => {
  const {
    view: { workshop, price, cost },
  } = useRequestContext();

  return (
    <Header>
      <Row>
        <Column>
          <Trans id="request.articles.table.label">Items</Trans>
        </Column>
        <Column>
          <Trans id="request.articles.table.column.service-type.label">Service Type</Trans>
        </Column>
        {workshop.enabled && (
          <Column>
            <Trans id="request.articles.table.column.workshop.label">Workshop</Trans>
          </Column>
        )}
        {!workshop.enabled && cost.enabled && <Column justifyContent="end">{cost.label}</Column>}
        {price.enabled && <Column justifyContent="end">{price.label}</Column>}
        <Column justifyContent="end"></Column>
      </Row>
    </Header>
  );
};

const CreationArticlesTableRow = ({ article }: { article: ArticleWithRelations }) => {
  const {
    request,
    view: { workshop, price, cost },
  } = useRequestContext();
  const navigate = useNavigate();

  return (
    <Row>
      <Cell>
        <ArticleNameCell article={article} badge={<DraftBadge article={article} />} noLink />
      </Cell>
      <Cell>
        <ArticleServiceTypeCell article={article} />
      </Cell>
      {workshop.enabled && (
        <Cell>
          <ArticleWorkshopCell article={article} showWorkshopPrice={cost.enabled} />
        </Cell>
      )}
      {!workshop.enabled && cost.enabled && (
        <Cell justifyContent="end">
          <BaseMoneyCell
            price={article.snapshot.cost?.amountPerEntity.find(
              (amount) => amount.entityId === article.workshopId
            )}
          />
        </Cell>
      )}
      {price.enabled && (
        <Cell justifyContent="end">
          <BaseMoneyCell price={article.snapshot.price?.amountPerCurrency[0]} />
        </Cell>
      )}
      <Cell justifyContent="end">
        <Stack row gap="0.5rem">
          <EditButton
            onEdit={() =>
              navigate(`/requests/new/${request.id}/articles/${article.id}`, {
                state: { from: 'request', isNew: false, skipRouterBlocker: true },
                replace: true,
              })
            }
          />
          {request.draft && <DeleteButton article={article} />}
        </Stack>
      </Cell>
    </Row>
  );
};

export const CreationArticlesCardList = () => {
  const { _ } = useLingui();
  const { request } = useRequestContext();

  return (
    <GridList
      aria-label={_(
        msg({
          id: 'requests.new.articles.label',
          message: 'Items',
        })
      )}
    >
      {request.articles.map((article) => (
        <CreationArticleCard key={article.id} article={article} />
      ))}
    </GridList>
  );
};

const CreationArticleCard = ({ article }: { article: ArticleWithRelations }) => {
  const navigate = useNavigate();
  const {
    request,
    view: { workshop, price, cost },
  } = useRequestContext();

  const articleName = useArticleName({ article });

  return (
    <GridListItem id={article.id} textValue={articleName} style={{ marginBottom: '0.5rem' }}>
      <Card>
        <CardContent>
          <ArticleNameCell
            id={article.id}
            article={article}
            badge={<DraftBadge article={article} />}
            noLink
          />
          <CardItems>
            {article.hasActions && <ArticleServiceTypeCardItem article={article} />}
            {workshop.enabled && <ArticleWorkshopCardItem article={article} />}
            {cost.enabled && <ArticleCostCardItem article={article} costLabel={cost.label} />}
            {price.enabled && <ArticlePriceCardItem article={article} priceLabel={price.label} />}
          </CardItems>
        </CardContent>
        <CardActions>
          {request.draft && <DeleteButton article={article} />}
          <EditButton
            onEdit={() =>
              navigate(`/requests/new/${request.id}/articles/${article.id}`, {
                state: { from: 'request', isNew: false, skipRouterBlocker: true },
                replace: true,
              })
            }
          />
        </CardActions>
      </Card>
    </GridListItem>
  );
};

const EditButton = ({ onEdit }: { onEdit: () => void }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  if (isMobile) {
    return (
      <Button style={{ flex: 1 }} variant="secondary" size="medium" onPress={onEdit}>
        <IconEdit />
        <Trans id="request.articles.table.column.actions.edit">Edit</Trans>
      </Button>
    );
  }

  return (
    <Button
      iconOnly
      variant="secondary"
      size="medium"
      tooltip={_(msg({ id: 'request.articles.table.column.actions.edit', message: 'Edit' }))}
      ariaLabel={_(msg({ id: 'request.articles.table.column.actions.edit', message: 'Edit' }))}
      onPress={onEdit}
    >
      <IconEdit />
    </Button>
  );
};

const DeleteButton = ({ article }: { article: ArticleWithRelations }) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const {
    mutateAsync: deleteArticle,
    isPending: isPendingDeleteArticle,
    isSuccess: isSuccessDeleteArticle,
  } = useDeleteDraftRequestArticle(article.requestId);

  if (isMobile) {
    return (
      <Button
        style={{ flex: 1 }}
        variant="secondary"
        size="medium"
        onPress={() => {
          deleteArticle(article.id);
        }}
        isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
      >
        <IconCancel />
        <Trans id="request.articles.table.column.actions.remove">Remove</Trans>
      </Button>
    );
  }

  return (
    <Button
      iconOnly
      variant="secondary"
      size="medium"
      tooltip={_(msg({ id: 'request.articles.table.column.actions.remove', message: 'Remove' }))}
      ariaLabel={_(msg({ id: 'request.articles.table.column.actions.remove', message: 'Remove' }))}
      onPress={() => {
        deleteArticle(article.id);
      }}
      isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
    >
      <IconCancel />
    </Button>
  );
};

const DraftBadge = ({ article }: { article: ArticleWithRelations }) => {
  const articleDetailsError = useArticleDetailsError(article);
  const articleServicesError = useArticleServicesError(article);

  const isDraft = articleDetailsError.hasError || articleServicesError.hasError;

  if (!isDraft) {
    return null;
  }

  return (
    <Badge color="black" variant="low">
      <Trans id="request.articles.table.status.draft">Draft</Trans>
    </Badge>
  );
};
