import { useEffect, useState } from 'react';

/**
 * On iOS, the keyboard height is not taken into account when the keyboard is opened.
 * This means that the keyboard will often cover element of the app.
 * When those element are in fixed position, like in a dialog or a drawer, the user can't scroll to see them.
 * To fix this, we can add a margin with the height of the keyboard to move up the elements.
 */

export const useWebkitVirtualKeyboardHeight = () => {
  const [webkitKeyboardHeight, setWebkitKeyboardHeight] = useState(0);

  useEffect(() => {
    window.visualViewport?.addEventListener('resize', (evt: any) => {
      if (evt.target.height) {
        setWebkitKeyboardHeight(Math.max(window.innerHeight - evt.target.height, 0));
      }
    });
  }, []);

  return webkitKeyboardHeight;
};
