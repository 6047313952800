import { CSSProperties } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconAdd from '@/icons/Add.svg';
import IconSubtract from '@/icons/Subtract.svg';
import IconTrash from '@/icons/Trash.svg';
import { createBEMClasses } from '@/utils/classname';

import './InputQuantity.css';

const { block } = createBEMClasses('input-quantity');

const InputQuantity = ({
  quantity,
  onDecrement,
  onIncrement,
  disableDelete = false,
  disableAdd = false,
  isDisabled,
  size = 'small',
  variant = 'default',
  style,
}: {
  quantity: number;
  onDecrement?: () => void;
  onIncrement?: () => void;
  disableDelete?: boolean;
  disableAdd?: boolean;
  isDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'brand' | 'default';
  style?: CSSProperties;
}) => {
  const { _ } = useLingui();

  return (
    <Stack row flexWrap="nowrap" alignItems="center" style={style} className={block({ size })}>
      {!isDisabled && (
        <Button
          iconOnly
          size={size}
          variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
          onPress={onDecrement}
          tooltip={_(msg({ id: 'components.input-quantity.remove', message: 'Remove' }))}
          ariaLabel={_(msg({ id: 'components.input-quantity.remove', message: 'Remove' }))}
          disabled={disableDelete && quantity === 1}
        >
          {quantity === 1 && !disableDelete ? <IconTrash /> : <IconSubtract />}
        </Button>
      )}
      <span>{quantity}</span>
      {!isDisabled && (
        <Button
          iconOnly
          size={size}
          variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
          onPress={onIncrement}
          tooltip={_(msg({ id: 'components.input-quantity.add', message: 'Add' }))}
          ariaLabel={_(msg({ id: 'components.input-quantity.add', message: 'Add' }))}
          disabled={disableAdd}
        >
          <IconAdd />
        </Button>
      )}
    </Stack>
  );
};

export default InputQuantity;
