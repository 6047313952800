import { StatusDueDate } from '@/components/DueDate';
import { RequestWithRelations } from '@/models/request';

export const RequestStatusDueAtCell = ({
  request,
  variant,
}: {
  request: RequestWithRelations;
  variant: 'card' | 'row';
}) => {
  if (!request.statusDueAtDate) {
    return variant === 'row' ? <>-</> : null;
  }

  return (
    <StatusDueDate
      date={request.statusDueAtDate}
      variant={variant}
      displayDayMonthOnly={variant === 'card'}
    />
  );
};
