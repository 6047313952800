import { useState } from 'react';
import { DialogTrigger, Popover } from 'react-aria-components';
import { useLingui } from '@lingui/react';

import ArticlePhoto from '@/components/ArticlePhoto';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import {
  Article,
  ARTICLE_DISPLAYED_STEPS,
  ARTICLE_TASK_TYPES,
  useArticleName,
} from '@/models/article';
import { createBEMClasses } from '@/utils/classname';

import './ArticlePhotoGroup.css';

const NUMBER_PHOTOS_TO_DISPLAY = 4;

const { block, element } = createBEMClasses('article-photo-group');

type ArticleWithProduct = Article & {
  product: { name: string } | null;
};

export const ArticlePhotoGroup = ({
  articles,
  mode,
}: {
  articles: ArticleWithProduct[];
  mode?: 'task' | 'displayed-step';
}) => {
  const [open, setOpen] = useState(false);

  // Pick the first articles to display their photos
  const firstArticles =
    articles.length <= NUMBER_PHOTOS_TO_DISPLAY
      ? articles
      : articles.slice(0, NUMBER_PHOTOS_TO_DISPLAY - 1);
  const firstPhotos = <ArticlePhotosToDisplay articles={firstArticles} mode={mode} />;

  if (articles.length <= NUMBER_PHOTOS_TO_DISPLAY) {
    return <div className={block()}>{firstPhotos}</div>;
  }

  return (
    <DialogTrigger key="count" onOpenChange={setOpen} isOpen={open} aria-label={''}>
      <div className={block()}>
        {firstPhotos}
        <Button variant="style-less" className={element('photo')}>
          <ArticlePhoto
            withBorder
            count={articles.length - NUMBER_PHOTOS_TO_DISPLAY + 1}
            size="x-small"
          />
        </Button>
      </div>

      <Popover placement="bottom end" offset={4}>
        <Box padding="0" className={element('box')}>
          {
            <ul className={element('list')}>
              <>
                {articles.map((article) => (
                  <ArticleLine key={article.id} article={article} mode={mode} />
                ))}
              </>
            </ul>
          }
        </Box>
      </Popover>
    </DialogTrigger>
  );
};

const ArticleLine = ({
  article,
  mode,
}: {
  article: ArticleWithProduct;
  mode?: 'task' | 'displayed-step';
}) => {
  const { _ } = useLingui();

  const articleName = useArticleName({ article });

  const articleTask = ARTICLE_TASK_TYPES.find(({ id }) => id === article.task?.type);
  const articleDisplayedStep = ARTICLE_DISPLAYED_STEPS.find(
    ({ id }) => id === article.displayedStep
  );

  return (
    <li key={article.id} className={element('item')}>
      <ArticlePhoto size="xx-small" photo={article.articlePhoto} showPlaceholder />
      <Stack>
        <span className="text-ellipsis paragraph-200-regular" style={{ flex: '1' }}>
          {articleName}
        </span>
        {mode === 'task' && articleTask && (
          <span className="paragraph-300-regular text-disabled">{_(articleTask.label)}</span>
        )}
        {mode === 'displayed-step' && articleDisplayedStep && (
          <span className="paragraph-300-regular text-disabled">
            {_(articleDisplayedStep.name)}
          </span>
        )}
      </Stack>
    </li>
  );
};

const ArticlePhotosToDisplay = ({
  articles,
  mode,
}: {
  articles: ArticleWithProduct[];
  mode?: 'task' | 'displayed-step';
}) => {
  return articles.map((article) => (
    <ArticlePhotoElement key={article.id} article={article} mode={mode} />
  ));
};

const ArticlePhotoElement = ({
  article,
  mode,
}: {
  article: ArticleWithProduct;
  mode?: 'task' | 'displayed-step';
}) => {
  const { _ } = useLingui();
  const articleName = useArticleName({ article });

  const articleTask = ARTICLE_TASK_TYPES.find(({ id }) => id === article.task?.type);
  const articleDisplayedStep = ARTICLE_DISPLAYED_STEPS.find(
    ({ id }) => id === article.displayedStep
  );

  const tooltipContent = (
    <Stack className="paragraph-200-regular">
      <span>{articleName}</span>
      {mode === 'task' && articleTask && (
        <span className="text-disabled">{_(articleTask.label)}</span>
      )}
      {mode === 'displayed-step' && articleDisplayedStep && (
        <span className="text-disabled">{_(articleDisplayedStep.name)}</span>
      )}
    </Stack>
  );

  return (
    <Tooltip content={tooltipContent}>
      <Button variant="style-less" aria-label={article.product?.name} className={element('photo')}>
        <ArticlePhoto withBorder photo={article.articlePhoto} size="x-small" showPlaceholder />
      </Button>
    </Tooltip>
  );
};
