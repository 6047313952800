import { msg, Plural, plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Stack from '@/design_system/Stack';
import IconStar from '@/icons/Star.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import { type TNotificationOfType } from '@/models/notification';

export const ArticlesFeedbackSubmittedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'articles_feedback_submitted'>;
}) => {
  const { _ } = useLingui();
  const { articles } = notification.data;
  const hasOnlyOneArticle = articles.length === 1;

  return (
    <BaseNotification
      notification={notification}
      notificationLabel={_(
        msg({
          id: 'notification.articles-feedback-submitted.label',
          message: plural(articles.length, {
            one: `The client left a review on their item`,
            other: `The client left a review on their items`,
          }),
        })
      )}
      notificationHeader={
        <Trans id="notification.articles-feedback-submitted">
          <span className="paragraph-100-heavy">The client</span> left a review on{' '}
          <Plural value={articles.length} one="their item" other="their items" />
        </Trans>
      }
      notificationContent={
        hasOnlyOneArticle && (
          <Stack
            row
            style={{
              color: 'var(--color-warning-500)',
            }}
          >
            {([1, 2, 3, 4, 5] as const).map((rating) => (
              <IconStar key={rating} filled={rating <= (articles[0].feedback.quality ?? 0)} />
            ))}
          </Stack>
        )
      }
      icon={<IconStar />}
      type="new"
      scrollTo="activity-section"
    />
  );
};
