import { useState } from 'react';
import { DialogTrigger, Popover } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import InputSearch from '@/design_system/InputSearch';
import IconCheck from '@/icons/Check.svg';
import { UserWithRelations } from '@/models/user';
import { createBEMClasses } from '@/utils/classname';

import './UserPicker.css';

interface UserPickerProps {
  size: 'xx-small' | 'x-small' | 'small' | 'large';
  ariaLabel: string;
  selectedUser?: { id: string; name: string } | null;
  users: UserWithRelations[];
  onSelectUser?: (user: UserWithRelations | null) => void;
  onSearchChange?: (searchTerm?: string) => void;
}

const { block, element } = createBEMClasses('user-picker');

export const UserPicker = ({
  size,
  ariaLabel,
  selectedUser,
  users,
  onSelectUser,
  onSearchChange,
}: UserPickerProps) => {
  const { _ } = useLingui();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const label = _(
    msg({
      id: 'user-picker.search.label',
      message: 'Search users...',
    })
  );

  return (
    <DialogTrigger onOpenChange={setOpen} isOpen={open} aria-label={ariaLabel}>
      <UserAvatar
        ariaLabel={ariaLabel}
        size={size}
        user={selectedUser}
        tooltip={selectedUser ? <p className="sentry-mask">{selectedUser.name}</p> : null}
        isButton
      />
      <Popover placement="bottom end" offset={4}>
        <Box padding="0" className={block()}>
          <InputSearch
            className={element('search')}
            ariaLabel={label}
            placeholder={label}
            value={searchTerm}
            onChange={(value) => {
              setSearchTerm(value);
              onSearchChange?.(value);
            }}
          />
          <div className={element('list')}>
            {users.map((user) => (
              <Button
                className={element('item')}
                key={user.id}
                variant="style-less"
                onPress={() => {
                  onSelectUser?.(selectedUser?.id === user.id ? null : user);
                  setOpen(false);
                  setSearchTerm(undefined);
                }}
              >
                <UserAvatar user={user} size="x-small" />
                <span
                  className="sentry-mask paragraph-100-regular text-ellipsis"
                  style={{ flex: '1', textAlign: 'initial' }}
                  title={user.name}
                >
                  {user.name}
                </span>
                {selectedUser?.id === user.id && <IconCheck style={{ fontSize: '20px' }} />}
              </Button>
            ))}
            {users.length === 0 && (
              <span className={element('no-items', {}, 'paragraph-200-regular')}>
                <Trans id="user-picker.search.no-users">
                  No results. Please try using different keywords.
                </Trans>
              </span>
            )}
          </div>
        </Box>
      </Popover>
    </DialogTrigger>
  );
};
