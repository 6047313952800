import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import RadioGroup, { yesNoRadioOptions } from '@/design_system/RadioGroup';
import Stack from '@/design_system/Stack';
import { ClientArticleWithRelations, ClientRequestWithRelations } from '@/models/request';
import ArticleCard from '@/routes/Brand/Requests/New/components/Article/components/ArticleCard';
import { createBEMClasses } from '@/utils/classname';

import './AddAnotherDefect.css';

const { block } = createBEMClasses('client-add-another-defect');

const AddAnotherDefect = ({
  request,
  article,
  onEditArticle,
  onDeleteArticle,
  onYes,
  onNo,
}: {
  request: ClientRequestWithRelations;
  article: ClientArticleWithRelations;
  onEditArticle: () => void;
  onDeleteArticle: () => void;
  onYes: () => void;
  onNo: () => void;
}) => {
  const { _ } = useLingui();

  return (
    <div className={block()}>
      <ArticleCard
        request={request}
        article={article}
        onEdit={onEditArticle}
        onDelete={onDeleteArticle}
      />
      <Stack gap="1.5rem" padding="32px 0">
        <h3 className="headline-300-bold">
          <Trans id="client.new.add-another-action.title">
            Do you need to add another service?
          </Trans>
        </h3>
        <RadioGroup
          theme="brand"
          aria-label={_(
            msg({
              id: 'client.new.add-another-action.title',
              message: `Do you need to add another service?`,
            })
          )}
          options={yesNoRadioOptions.map((option) => ({ ...option, text: _(option.text) }))}
          onChange={(value) => {
            if (value === 'yes') {
              onYes();
            } else {
              onNo();
            }
          }}
          gap="1rem"
          value={null}
          scrollToOnMount
        />
      </Stack>
    </div>
  );
};

export default AddAnotherDefect;
