import IconWrapper from './IconWrapper';

const IconCommentNumber = IconWrapper({
  name: 'comment-number',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0116 1.74904C5.42373 1.74904 1.77223 5.26933 1.77223 9.53048C1.77223 10.6334 2.01478 11.6816 2.45252 12.6317C2.58285 12.9145 2.60658 13.226 2.6023 13.4828C2.59781 13.7527 2.55979 14.0446 2.50856 14.3333C2.42457 14.8069 2.29102 15.3444 2.16371 15.8569C2.13718 15.9636 2.11093 16.0693 2.08545 16.1731C1.93154 16.8 1.80482 17.3615 1.76299 17.805C1.74373 18.0092 1.74619 18.1548 1.75968 18.2509C1.85173 18.2523 1.99004 18.2374 2.18303 18.1936C2.60999 18.0966 3.14115 17.8979 3.74026 17.6619C3.82234 17.6296 3.90573 17.5966 3.99001 17.5632C4.49505 17.363 5.03238 17.1501 5.51468 17.0015C5.79883 16.9139 6.08999 16.8402 6.36664 16.8049C6.63383 16.7709 6.95099 16.7639 7.25368 16.8654C8.11466 17.1542 9.0427 17.3119 10.0116 17.3119C14.5995 17.3119 18.251 13.7916 18.251 9.53048C18.251 5.26933 14.5995 1.74904 10.0116 1.74904ZM1.67609 18.2425L1.67791 18.243L1.67609 18.2425ZM0.274161 9.53048C0.274161 4.36912 4.67115 0.250969 10.0116 0.250969C15.3521 0.250969 19.7491 4.36912 19.7491 9.53048C19.7491 14.6918 15.3521 18.81 10.0116 18.81C8.87941 18.81 7.79053 18.6256 6.77725 18.2857C6.76994 18.2843 6.70668 18.2718 6.55598 18.291C6.39902 18.311 6.19925 18.3581 5.95579 18.4331C5.53467 18.5629 5.06104 18.7504 4.55129 18.9522C4.46496 18.9864 4.3776 19.021 4.28929 19.0558C3.70468 19.286 3.07034 19.5282 2.51502 19.6544C2.23642 19.7177 1.93731 19.7618 1.64954 19.7457C1.36191 19.7296 1.0172 19.6489 0.733086 19.3943C0.450411 19.1411 0.335268 18.8066 0.287286 18.5301C0.23865 18.2499 0.244663 17.9493 0.271539 17.6644C0.325199 17.0954 0.479438 16.4316 0.63058 15.8159C0.658678 15.7015 0.686712 15.5885 0.714324 15.4773C0.841281 14.9657 0.959302 14.4901 1.03352 14.0717C1.07823 13.8196 1.10182 13.6152 1.10444 13.4578C1.10691 13.31 1.09018 13.255 1.09018 13.255L1.09194 13.2586M0.274161 9.53048C0.274161 10.8547 0.565255 12.1146 1.09018 13.255L0.274161 9.53048Z"
      />
    </svg>
  ),
});

export default IconCommentNumber;
