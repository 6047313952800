export const refreshBrowserTabTitle = (): void => {
  const notificationCount = document
    .querySelector('meta[name="notifications-count"]')
    ?.getAttribute('content');

  const documentSubtitle =
    document.querySelector('meta[name="subtitle"]')?.getAttribute('content') || 'Prolong'; // eslint-disable-line lingui/no-unlocalized-strings

  document.title =
    notificationCount && parseInt(notificationCount) > 0
      ? `(${notificationCount}) ${documentSubtitle}`
      : documentSubtitle;
};
