import { useLocalStorageState } from '@/services/localStorage';

export const REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY = 'request-in-progress';

type RequestInProgress = {
  id: string;
  clientToken?: string;
  storeToken?: string;
  date: Date;
};

export const useRequestInProgress = () => {
  const [requestInProgress, setRequestInProgress] = useLocalStorageState<RequestInProgress | null>(
    REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY,
    null
  );

  return { requestInProgress, setRequestInProgress } as const;
};
