import React from 'react';
import {
  Checkbox as AriaCheckbox,
  CheckboxProps as AriaCheckboxProps,
} from 'react-aria-components';

import { createBEMClasses } from '@/utils/classname';

import './Checkbox.css';

const { block, element } = createBEMClasses('checkbox');

interface CheckboxProps extends AriaCheckboxProps {
  size?: 'small' | 'large';
  variant?: 'default' | 'brand';
  align?: 'left' | 'right';
  isSelected?: boolean;
  isIndeterminate?: boolean;
  isDisabled?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const Checkbox = ({
  size = 'large',
  variant = 'default',
  align = 'left',
  children,
  className,
  ...props
}: CheckboxProps) => (
  <AriaCheckbox
    {...props}
    className={block(
      {
        size,
        variant,
        align,
      },
      className
    )}
  >
    {({ isIndeterminate }) => (
      <>
        <div className={element('box')}>
          <svg viewBox="0 0 18 18" aria-hidden="true">
            {isIndeterminate ? (
              <rect x={1} y={7.5} width={15} height={3} />
            ) : (
              <polyline points="1 9 7 14 15 4" />
            )}
          </svg>
        </div>
        {children}
      </>
    )}
  </AriaCheckbox>
);

export default Checkbox;
