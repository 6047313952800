import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import { useDeleteCurrentSession } from '@/models/user';

const NoOrganizations = () => {
  const {
    mutateAsync: deleteCurrentSession,
    isPending: isDeletingCurrentSession,
    isSuccess: hasDeletedCurrentSession,
  } = useDeleteCurrentSession();

  return (
    <>
      <h1 className="headline-200-bold text-center">
        <Trans id="no-organizations.title">You are not part of any organization</Trans>
      </h1>
      <p className="paragraph-100-regular text-center">
        <Trans id="no-organizations.subtitle">
          Please contact your organization administrator to be added to the organization.
        </Trans>
      </p>
      <div style={{ textAlign: 'right' }}>
        <Button
          type="button"
          variant="secondary"
          isLoading={isDeletingCurrentSession}
          disabled={hasDeletedCurrentSession}
          onPress={() => {
            deleteCurrentSession();
          }}
        >
          <Trans id="no-organizations.logout">Logout</Trans>
        </Button>
      </div>
    </>
  );
};

export default NoOrganizations;
