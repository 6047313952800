import { Trans } from '@lingui/macro';
import * as Sentry from '@sentry/react';

export function initSentry() {
  try {
    if (import.meta.env.VITE_SENTRY_DSN) {
      Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: window.location.host,
        integrations: [
          Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: true,
            blockAllMedia: true,
          }),
          Sentry.captureConsoleIntegration({ levels: ['error'] }),
        ],
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
        // Sentry SDK normalize any contextual data to a given depth. Any data beyond this depth
        // will be trimmed and marked using its type instead ([Object] or [Array]),
        // without walking the tree any further. By default, walking is performed 3 levels deep.
        // Arbitrary set it to 10 to ensure enough (most of the time all) data is captured.
        normalizeDepth: 10,
      });
    }
  } catch (err) {
    console.error(err);
  }
}

export function captureException(error: Error) {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.captureException(error);
  } else {
    console.error(error);
  }
}

export function captureMessage(
  message: string,
  captureContext?: Parameters<typeof Sentry.captureMessage>[1]
) {
  if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.captureMessage(message, captureContext);
  } else {
    console.error(message);
  }
}

export const ErrorBoundary = (props: Sentry.ErrorBoundaryProps) => {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <p className="paragraph-100-regular text-primary">
          <Trans id="errorBoundary.fallback">
            An unknown error occurred. Please {/* eslint-disable-next-line */}
            <a
              onClick={() => window.location.reload()}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              refresh the page
            </a>
          </Trans>
        </p>
      }
      {...props}
    />
  );
};
