import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity, BaseActivityText } from '@/components/activities/BaseActivity/BaseActivity';
import IconShipmentNew from '@/icons/ShipmentNew.svg';

export const ShipmentCreatedActivity = ({
  activity,
  isShipment,
}: {
  activity: ActivityOfType<'shipment_created'>;
  isShipment: boolean;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconShipmentNew />}
      type="primary"
      creatorName={activity.creator ? activity.creator.name : ''}
      message={
        <BaseActivityText>
          {isShipment ? (
            activity.creator ? (
              <>
                <Trans id="activities.shipment_created.created_the">created the</Trans>{' '}
                <Trans id="activities.shipment_created.shipment">shipment</Trans>
              </>
            ) : (
              <Trans id="activities.shipment_created.no-creator">The shipment was created</Trans>
            )
          ) : activity.creator ? (
            <>
              <Trans id="activities.shipment_created.created_a">created a</Trans>{' '}
              <Link to={`/shipments/${activity.shipmentId}`} className="text-link">
                <Trans id="activities.shipment_created.shipment">shipment</Trans>
              </Link>
            </>
          ) : (
            <Trans id="activities.shipment_created.no-creator_a">
              A{' '}
              <Link to={`/shipments/${activity.shipmentId}`} className="text-link">
                shipment
              </Link>{' '}
              was created
            </Trans>
          )}
        </BaseActivityText>
      }
    />
  );
};
