import { createContext, useContext } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { DefectPhotos } from '@/components/DefectPhotos';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Footer, Header, Row, Table } from '@/design_system/Table/Table';
import Toggle from '@/design_system/Toggle';
import IconAdd from '@/icons/Add.svg';
import IconTrash from '@/icons/Trash.svg';
import { useDefectName } from '@/models/defectType';
import {
  ArticleDefectWithRelations,
  ArticleWithRelations,
  RequestWithRelations,
} from '@/models/request';

interface DefectsTableContextData {
  request: RequestWithRelations;
  showDeleteColumn: boolean;
  isReadonly: boolean;
}

const DefectsTableContext = createContext({} as DefectsTableContextData);

export const DefectsTable = ({
  request,
  article,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
}) => {
  const { _ } = useLingui();

  const defects = article.snapshot.articleDefects;

  // TODO: Implement the logic to determine if the delete column should be shown
  const showDeleteColumn = true;

  const context = {
    request,
    showDeleteColumn,
    // TODO: Implement the logic to determine if the table should be readonly
    isReadonly: true,
  } satisfies DefectsTableContextData;

  return (
    <Stack gap="0.25rem">
      <p className="label-100 text-primary">
        <Trans id="defects-table.title.label">What defects have been noted?</Trans>
      </p>
      <DefectsTableContext.Provider value={context}>
        <Table
          ariaLabel={_(
            msg({
              id: 'defects-table.title.label',
              message: 'What defects have been noted?',
            })
          )}
          bordered
          variant="grid"
          columnWidths={[
            'minmax(250px, 1fr)',
            'minmax(250px, 1fr)',
            '120px',
            showDeleteColumn && '56px',
          ]}
        >
          <Header>
            <Row>
              {defects.length === 0 ? (
                <Cell isWholeRow>
                  <span className="paragraph-200-regular">
                    <Trans id="defects-table.column.header.no-defect-added">
                      No defects added yet
                    </Trans>
                  </span>
                </Cell>
              ) : (
                <>
                  <Column>
                    <Trans id="defects-table.column.header.defect">Defect</Trans>
                  </Column>
                  <Column>
                    <Trans id="defects-table.column.header.defect-photos">Defect photos</Trans>
                  </Column>
                  <Column justifyContent="center">
                    <Trans id="defects-table.column.header.reparable">Reparable?</Trans>
                  </Column>
                  {showDeleteColumn && <Column justifyContent="flex-end" />}
                </>
              )}
            </Row>
          </Header>
          <Body>
            {defects.map((defect) => (
              <DefectRow key={defect.id} defect={defect} />
            ))}
          </Body>
          <Footer>
            <Row>
              <Cell isWholeRow>
                <Button
                  size="medium"
                  variant="secondary"
                  ariaLabel={_(
                    msg({ id: 'defects-table.column.footer.add-defect', message: 'Add a defect' })
                  )}
                  // eslint-disable-next-line lingui/no-unlocalized-strings
                  style={{ flex: '0 auto' }}
                >
                  <IconAdd />
                  <Trans id="defects-table.column.footer.add-defect">Add a defect</Trans>
                </Button>
              </Cell>
            </Row>
          </Footer>
        </Table>
      </DefectsTableContext.Provider>
    </Stack>
  );
};

const DefectRow = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  const { showDeleteColumn } = useContext(DefectsTableContext);

  return (
    <Row>
      <DefectNameCell defect={defect} />
      <DefectPhotosCell defect={defect} />
      <DefectReparableCell defect={defect} />
      {showDeleteColumn && <DefectDeleteCell />}
    </Row>
  );
};

const DefectNameCell = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  const defectName = useDefectName(defect);

  return <Cell>{defectName}</Cell>;
};

const DefectPhotosCell = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  const { request, isReadonly } = useContext(DefectsTableContext);

  return (
    <Cell stretch>
      <DefectPhotos
        request={request}
        actionOrDefect={defect}
        size="small"
        isDisabled={isReadonly}
      />
    </Cell>
  );
};

const DefectReparableCell = ({ defect }: { defect: ArticleDefectWithRelations }) => {
  return (
    <Cell justifyContent="center">
      <Toggle isSelected={defect.isReparable}></Toggle>
    </Cell>
  );
};

const DefectDeleteCell = () => {
  const { _ } = useLingui();

  return (
    <Cell>
      <Button
        variant="secondary"
        size="medium"
        iconOnly
        tooltip={_(msg({ id: 'defects-table.column.deletion', message: 'Remove' }))}
        ariaLabel={_(msg({ id: 'defects-table.column.deletion', message: 'Remove' }))}
        onPress={() => {
          const deletionConfirmed = confirm(
            _(
              msg({
                id: 'defects-table.column.deletion.confirmation',
                message:
                  'Are you sure you want to delete this defect? The associated photos will be deleted as well.',
              })
            )
          );
          if (deletionConfirmed) {
            // TODO: Delete the ArticleDefect
          }
        }}
      >
        <IconTrash />
      </Button>
    </Cell>
  );
};
