import { lazy, Suspense, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import InputSearch from '@/design_system/InputSearch';
import Stack from '@/design_system/Stack';
import IconArrow from '@/icons/Arrow.svg';
import IconError from '@/icons/Error.svg';
import IconScan from '@/icons/Scan.svg';
import { createBEMClasses } from '@/utils/classname';
import useDebouncedState from '@/utils/useDebouncedState';

const Scanner = lazy(() => import('./Scanner'));

const { block, element } = createBEMClasses('scan');

const Desktop = ({
  onClose,
  onResult,
  resultStatus,
}: {
  onClose: () => void;
  onResult: (code: string) => void;
  resultStatus: 'idle' | 'loading' | 'success' | 'error';
}) => {
  const { _ } = useLingui();
  const [state, setState] = useState<'input' | 'scan' | 'error'>('input');
  const [text, , setText] = useDebouncedState<string>('', 1000, onResult);

  return (
    <Dialog
      isOpen
      title={
        <>
          {state === 'input' && _(msg({ id: 'scan.desktop.title.input', message: 'Search' }))}
          {(state === 'scan' || state === 'error') && (
            <Button variant="secondary" onPress={() => setState('input')}>
              <IconArrow left />
              <Trans id="scan.desktop.back">Back to search</Trans>
            </Button>
          )}
        </>
      }
      style={{ width: '32rem' }}
      onOpenChange={onClose}
      className={block({ desktop: true, status: resultStatus })}
    >
      <main>
        {state === 'input' && (
          <Stack gap="16px">
            <p className="paragraph-100-regular">
              <Trans id="scan.desktop.input.description">
                Search a request or a shipment by entering its reference. You can also scan it if
                you have the required tool.
              </Trans>
            </p>
            <Stack gap="8px">
              <InputSearch
                ariaLabel={_(
                  msg({ id: 'scan.desktop.input.label', message: 'Request or shipment reference' })
                )}
                placeholder={_(
                  msg({
                    id: 'scan.desktop.input.placeholder',
                    message: 'Enter the request or shipment reference...',
                  })
                )}
                value={text}
                onChange={setText}
                isLoading={resultStatus === 'loading'}
                isInvalid={resultStatus === 'error'}
                isSuccess={resultStatus === 'success'}
                messageType={
                  resultStatus === 'error'
                    ? 'error'
                    : resultStatus === 'success'
                      ? 'success'
                      : undefined
                }
                messageText={
                  resultStatus === 'error'
                    ? _(
                        msg({
                          id: 'scan.desktop.input.message.error',
                          message: 'Reference not recognized',
                        })
                      )
                    : resultStatus === 'success'
                      ? _(
                          msg({
                            id: 'scan.desktop.input.message.success',
                            message: 'Redirecting...',
                          })
                        )
                      : undefined
                }
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
              <p className="paragraph-100-regular text-center">
                <Trans id="_general.or">or</Trans>
              </p>
              <Button
                variant="secondary"
                size="large"
                style={{ height: 'auto' }}
                onPress={() => setState('scan')}
              >
                <Stack gap="8px" padding="4px 0" alignItems="center">
                  <IconScan />
                  <span className="paragraph-100-regular">
                    <Trans id="scan.desktop.button.label">Click here to use your camera</Trans>
                  </span>
                </Stack>
              </Button>
            </Stack>
          </Stack>
        )}
        {state === 'scan' && (
          <Suspense fallback={'loading...'}>
            <Scanner
              onInitializationFail={() => {
                setState('error');
              }}
              onResult={onResult}
              resultStatus={resultStatus}
            />
          </Suspense>
        )}
        {state === 'error' && (
          <div className={element('fail-state')}>
            <IconError />
            <h3 className="paragraph-50-medium">
              <Trans id="scan.desktop.error.title">Device unauthorized or not found</Trans>
            </h3>
            <p className="paragraph-100-regular">
              <Trans id="scan.desktop.error.description">
                Please verify that you have a camera and that you have authorized the use of it for
                this website.
                <br />
                Alternatively, you can go back and enter the reference manually.
              </Trans>
            </p>
          </div>
        )}
      </main>
    </Dialog>
  );
};

export default Desktop;
