import Stack from '@/design_system/Stack';
import IconCheck from '@/icons/Check.svg';
import { createBEMClasses } from '@/utils/classname';

import './IconSuccess.css';

const { block } = createBEMClasses('client-request-icon-success');

const IconSuccess = () => (
  <Stack alignItems="center" justifyContent="center" className={block()}>
    <IconCheck />
  </Stack>
);

export default IconSuccess;
