import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconItemDelete from '@/icons/ItemDelete.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const RequalificationRefusedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'requalification_refused'>;
}) => {
  const { _ } = useLingui();

  return (
    <BaseNotification
      icon={<IconItemDelete />}
      type="danger"
      notification={notification}
      notificationLabel={_(
        msg({
          id: 'notification.requalification-refused.label',
          message: `Re-qualification refused for request ${notification.request.reference}`,
        })
      )}
      notificationHeader={
        <Trans id="notification.requalification-refused">
          Your re-qualification request has been refused
        </Trans>
      }
    />
  );
};
