import { Trans } from '@lingui/macro';

import Stack from '@/design_system/Stack';
import { createBEMClasses } from '@/utils/classname';

import './Archived.css';

const { block } = createBEMClasses('client-request-archived');

const Archived = () => {
  return (
    <div className={block()}>
      <Stack gap="1rem">
        <Stack row justifyContent="center" gap="0.5rem" alignItems="center">
          <p className="headline-200-bold text-center">
            <Trans id="client.request.archived.title">
              Your care & repair service has been archived
            </Trans>
          </p>
        </Stack>
      </Stack>
    </div>
  );
};

export default Archived;
