import IconWrapper from './IconWrapper';

const IconBandage = IconWrapper({
  name: 'bandage',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4583 9.25208C11.7512 8.95918 12.2261 8.95918 12.519 9.25208L12.5264 9.25951C12.8193 9.5524 12.8193 10.0273 12.5264 10.3202C12.2335 10.6131 11.7586 10.6131 11.4658 10.3202L11.4583 10.3127C11.1654 10.0198 11.1654 9.54497 11.4583 9.25208Z" />
      <path d="M10.3129 11.4583C10.02 11.1654 9.54513 11.1654 9.25224 11.4583C8.95935 11.7512 8.95935 12.2261 9.25224 12.519L9.25967 12.5264C9.55256 12.8193 10.0274 12.8193 10.3203 12.5264C10.6132 12.2335 10.6132 11.7587 10.3203 11.4658L10.3129 11.4583Z" />
      <path d="M13.6873 11.481C13.9802 11.1881 14.4551 11.1881 14.7479 11.481L14.7554 11.4885C15.0483 11.7814 15.0483 12.2562 14.7554 12.5491C14.4625 12.842 13.9876 12.842 13.6947 12.5491L13.6873 12.5417C13.3944 12.2488 13.3944 11.7739 13.6873 11.481Z" />
      <path d="M12.5418 13.6872C12.2489 13.3943 11.774 13.3943 11.4811 13.6872C11.1882 13.9801 11.1882 14.455 11.4811 14.7479L11.4885 14.7553C11.7814 15.0482 12.2563 15.0482 12.5492 14.7553C12.8421 14.4624 12.8421 13.9875 12.5492 13.6946L12.5418 13.6872Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0993 3.89623C14.9543 2.04124 17.9618 2.04124 19.8168 3.89623L20.1039 4.1833C21.9588 6.03829 21.9588 9.04583 20.1039 10.9008L10.9009 20.1038C9.0459 21.9588 6.03837 21.9588 4.18338 20.1038L3.89631 19.8167C2.04132 17.9617 2.04132 14.9542 3.89631 13.0992L13.0993 3.89623ZM18.7561 4.95689C17.4869 3.68769 15.4291 3.68769 14.1599 4.95689L13.0445 6.07234L17.9278 10.9556L19.0432 9.84016C20.3124 8.57096 20.3124 6.51317 19.0432 5.24396L18.7561 4.95689ZM16.8671 12.0163L11.9838 7.133L7.13307 11.9838L12.0163 16.867L16.8671 12.0163ZM10.9557 17.9277L6.07241 13.0444L4.95697 14.1599C3.68777 15.4291 3.68777 17.4868 4.95697 18.7561L5.24404 19.0431C6.51324 20.3123 8.57103 20.3123 9.84023 19.0431L10.9557 17.9277Z"
      />
    </svg>
  ),
});

export default IconBandage;
