import { useEffect, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { FiltersButton, FiltersDrawer, hasFilterBeenUpdated } from '@/components/ListFilters';
import { TableQueryWrapper } from '@/components/TableQueryWrapper';
import InputSearch from '@/design_system/InputSearch';
import Pagination from '@/design_system/Pagination';
import Stack from '@/design_system/Stack';
import { ShipmentCarrier, useShipments } from '@/models/shipment';
import { ShipmentsEmptyState } from '@/routes/Shipments/components/ShipmentsEmptyState';
import { CarrierSelect } from '@/routes/Shipments/Tabs/components/CarrierSelect';
import {
  SHIPMENTS_PER_PAGE,
  ShipmentsList,
  ShipmentsTable,
} from '@/routes/Shipments/Tabs/components/ShipmentsTable';
import { ErrorBoundary } from '@/services/sentry';
import useViewport from '@/utils/useViewport';

const DoneTab = ({
  search,
  debouncedSearch,
  debouncedSetSearch,
  page,
  setPage,
  carriers,
  setCarriers,
}: {
  search: string;
  debouncedSearch: string;
  debouncedSetSearch: (ref: string) => void;
  page: number;
  setPage: (page: number) => void;
  carriers: ShipmentCarrier[];
  setCarriers: (carriers: ShipmentCarrier[]) => void;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewport();

  const {
    data: { shipments, meta } = {},
    isLoading,
    isError,
  } = useShipments({
    limit: SHIPMENTS_PER_PAGE,
    offset: (page - 1) * SHIPMENTS_PER_PAGE,
    search: debouncedSearch || undefined,
    carriers,
    tab: 'archived',
  });

  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const hasActiveFilters = !!carriers.length;

  return (
    <Stack gap="1rem" style={{ height: '100%' }}>
      <Stack row gap="1rem">
        <ErrorBoundary>
          <InputSearch
            placeholder={_(
              msg({
                id: 'shipments.search.placeholder',
                message: 'Search a reference, origin or destination...',
              })
            )}
            ariaLabel={_(
              msg({
                id: 'shipments.search.placeholder',
                message: 'Search a reference, origin or destination...',
              })
            )}
            style={{ flex: 1, minWidth: 175 }}
            value={search}
            onChange={debouncedSetSearch}
            size="medium"
          />
          {isMobile ? (
            <>
              <FiltersButton
                hasActiveFilters={hasActiveFilters}
                isFiltersDrawerOpen={isFiltersDrawerOpen}
                setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
              />
              <ShipmentMobileFilters
                isOpen={isFiltersDrawerOpen}
                onOpenChange={setIsFiltersDrawerOpen}
                setPage={setPage}
                carriers={carriers}
                setCarriers={setCarriers}
              />
            </>
          ) : (
            <CarrierSelect
              selectedKeys={carriers}
              onSelectionChange={(keys) => {
                setCarriers([...keys] as ShipmentCarrier[]);
                setPage(1);
              }}
            />
          )}
        </ErrorBoundary>
      </Stack>

      <TableQueryWrapper isLoading={isLoading} isError={isError}>
        {!shipments?.length ? (
          <ShipmentsEmptyState
            subtitle={
              <Trans id="shipments.empty-state.no-shipment-subtitle">No shipments to display</Trans>
            }
          />
        ) : (
          <>
            {isMobile ? (
              <ShipmentsList
                shipments={shipments ?? []}
                pagination={
                  <Pagination
                    page={page}
                    itemsPerPage={SHIPMENTS_PER_PAGE}
                    count={meta?.count}
                    onPageChange={setPage}
                  />
                }
              />
            ) : (
              <ShipmentsTable
                shipments={shipments ?? []}
                pagination={
                  <Pagination
                    page={page}
                    itemsPerPage={SHIPMENTS_PER_PAGE}
                    count={meta?.count}
                    onPageChange={setPage}
                  />
                }
              />
            )}
          </>
        )}
      </TableQueryWrapper>
    </Stack>
  );
};

const ShipmentMobileFilters = ({
  isOpen,
  onOpenChange,
  setPage,
  carriers,
  setCarriers,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  setPage: (page: number) => void;
  carriers: ShipmentCarrier[];
  setCarriers: (carriers: ShipmentCarrier[]) => void;
}) => {
  const [newCarriers, setNewCarriers] = useState<ShipmentCarrier[]>([]);

  const handleClearFilters = () => {
    setNewCarriers([]);
  };

  const handleApplyFilters = () => {
    const hasCarriersFilterBeenUpdated = hasFilterBeenUpdated(carriers, newCarriers);

    if (hasCarriersFilterBeenUpdated) {
      setCarriers(newCarriers);
    }

    if (hasCarriersFilterBeenUpdated) {
      setPage(1);
    }

    onOpenChange(false);
  };

  // Those useEffect aim to fill the local state of the drawer with the already applied filters
  useEffect(() => {
    setNewCarriers(carriers);
  }, [carriers, isOpen]);

  return (
    <FiltersDrawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      handleClearFilters={handleClearFilters}
      handleApplyFilters={handleApplyFilters}
    >
      <CarrierSelect
        selectedKeys={newCarriers}
        onSelectionChange={(keys) => {
          setNewCarriers(keys);
        }}
      />
    </FiltersDrawer>
  );
};

export default DoneTab;
