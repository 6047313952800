import IconWrapper from './IconWrapper';

const IconToggleOff = IconWrapper({
  name: 'toggle',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect y="5" width="24" height="14" rx="7" />
      <circle cx="7" cy="12" r="5" fill="white" />
    </svg>
  ),
});

const IconToggleOn = IconWrapper({
  name: 'toggle',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect y="5" width="24" height="14" rx="7" />
      <circle cx="17" cy="12" r="5" fill="white" />
    </svg>
  ),
});

const IconToggle = ({ on }: { on: boolean }) => {
  return on ? <IconToggleOn /> : <IconToggleOff />;
};

export default IconToggle;
