import { useQuery } from '@tanstack/react-query';

import { Endpoints, Permission } from '@/api';
import { useFetch } from '@/utils/fetch';

export interface Role {
  id: string;
  name: string;
  permissions: Permission[];
  scopeOrganization: boolean;
  scopeStore: boolean;
  scopeWorkshop: boolean;
}

export const useRoles = () => {
  const fetch = useFetch<Endpoints['GET /roles']>();

  return useQuery({
    queryKey: ['roles'],
    queryFn: () => fetch('/roles'),
  });
};
