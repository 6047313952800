import { Navigate, useParams } from 'react-router-dom';

import { BrandWrapper } from '@/layouts/Brand';
import { useClientRequest } from '@/models/request';
import { useClientToken, useStoreToken } from '@/services/auth';

import ClientRequestView from './components/ClientRequestView';
import StoreRequestView from './components/StoreRequestView';

const ClientRequest = () => {
  const { id } = useParams();

  const { data: request, isLoading } = useClientRequest(id);
  const clientToken = useClientToken();
  const storeToken = useStoreToken();

  if (isLoading) {
    return <BrandWrapper>{null}</BrandWrapper>;
  }

  if (!id || !request || (!clientToken && !storeToken)) {
    return <Navigate to="../error" />;
  }

  if (clientToken) {
    return <ClientRequestView request={request} />;
  } else {
    return <StoreRequestView request={request} />;
  }
};

export default ClientRequest;
