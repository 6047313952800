import IconWrapper from './IconWrapper';

const IconPhone = IconWrapper({
  name: 'phone',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0831 2.91699C13.1186 2.91699 13.9581 3.75646 13.9581 4.79199L13.9581 15.2087C13.9581 16.2442 13.1186 17.0837 12.0831 17.0837H7.91699C6.88129 17.0837 6.04175 16.2439 6.04199 15.2082L6.04443 4.79155C6.04467 3.75619 6.88407 2.91699 7.91943 2.91699L8.54141 2.91699C8.54141 3.26217 8.82123 3.54199 9.16641 3.54199H10.8331C11.1783 3.54199 11.4581 3.26217 11.4581 2.91699L12.0831 2.91699ZM15.2081 4.79199C15.2081 3.0661 13.809 1.66699 12.0831 1.66699L7.91943 1.66699C6.19383 1.66699 4.79483 3.06566 4.79443 4.79126L4.79199 15.2079C4.79159 16.9341 6.19082 18.3337 7.91699 18.3337H12.0831C13.809 18.3337 15.2081 16.9345 15.2081 15.2087L15.2081 4.79199ZM9.16641 15.0003C9.16641 15.4606 9.5395 15.8337 9.99974 15.8337C10.46 15.8337 10.8331 15.4606 10.8331 15.0003C10.8331 14.5401 10.46 14.167 9.99974 14.167C9.5395 14.167 9.16641 14.5401 9.16641 15.0003Z"
      />
    </svg>
  ),
});

export default IconPhone;
