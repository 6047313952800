import IconWrapper from './IconWrapper';

const IconCheck = IconWrapper({
  name: 'check',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7769 7.21445C20.0727 7.50443 20.0774 7.97928 19.7874 8.27506L11.4528 16.7763C11.3118 16.9201 11.1188 17.0012 10.9173 17.0012C10.7158 17.0012 10.5228 16.9201 10.3817 16.7763L6.21445 12.5257C5.92447 12.2299 5.92917 11.755 6.22494 11.4651C6.52072 11.1751 6.99557 11.1798 7.28555 11.4756L10.9173 15.1799L18.7163 7.22494C19.0063 6.92917 19.4812 6.92447 19.7769 7.21445Z"
      />
    </svg>
  ),
});

export default IconCheck;
