import { useMemo, useState } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import debounce from 'lodash.debounce';

import { PaymentStepConfig } from '@/api';
import FileUpload from '@/components/FileUpload';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import { useMedia } from '@/models/medium';
import { RequestWithRelations, useUpdateRequest } from '@/models/request';
import { isExternalPaymentReferenceInvalid } from '@/routes/Requests/Request/isExternalReferenceInvalid';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

export const PaymentAction = ({ request }: { request: RequestWithRelations }) => {
  const { currentSession } = useCurrentSession();

  if (
    !currentSession?.hasPermission('validate_external_payment', {
      organizationId: request.organizationId,
      storeId: request.storeId,
    })
  ) {
    return null;
  }

  const stepConfig = request.articles.find((article) => article.step?.step === 'payment')?.step
    ?.config as PaymentStepConfig['config'];

  if (!stepConfig?.externalPayment) {
    return null;
  }

  return <PaymentForm request={request} stepConfig={stepConfig} />;
};

const PaymentForm = ({
  request,
  stepConfig,
}: {
  request: RequestWithRelations;
  stepConfig: Extract<PaymentStepConfig['config'], { externalPayment: true }>;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const { data: { media } = { media: [] }, isLoading } = useMedia({
    requestId: request.id,
    types: ['proof-of-external-payment'],
  });

  const { mutateAsync, isPending } = useUpdateRequest();

  const debouncedMutate = useMemo(() => {
    return debounce(mutateAsync, 500);
  }, [mutateAsync]);

  if (isLoading) {
    return null;
  }

  return (
    <Stack row mobileColumn gap="24px" flexWrap="nowrap">
      <FileUpload
        variant={isMobile ? 'default' : 'inline'}
        style={{
          flex: 1,
          /**
           * This is needed to allow the file name to be truncated if it's too long.
           * Source: https://css-tricks.com/flexbox-truncated-text/
           */
          minWidth: 0,
        }}
        label={_(
          msg({ id: 'request.step-actions.payment.proof-of-payment', message: 'Receipt / invoice' })
        )}
        media={media}
        uploadData={{
          type: 'proof-of-external-payment',
          requestId: request.id,
        }}
        allowsMultiple={false}
        deleteWithApi
      />
      <InputText
        style={{ flex: 1 }}
        label={_(
          msg({
            id: 'request.step-actions.payment.payment-reference.label',
            message: 'Receipt / invoice number',
          })
        )}
        placeholder={_(
          msg({
            id: 'request.step-actions.payment.payment-reference.placeholder',
            message: 'i.e: 0141490090000',
          })
        )}
        isLoading={isPending}
        defaultValue={request.externalPaymentReference ?? ''}
        onChange={(externalPaymentReference) => {
          setIsInvalid(
            isExternalPaymentReferenceInvalid(
              externalPaymentReference,
              stepConfig.externalPaymentReferenceFormat
            )
          );
          debouncedMutate({
            id: request.id,
            data: {
              externalPaymentReference: externalPaymentReference || null,
            },
          });
        }}
        isInvalid={isInvalid}
        error={
          isInvalid
            ? _(
                msg({
                  id: 'request.step-actions.payment.payment-reference.invalid-format',
                  message: 'Invalid format',
                })
              )
            : undefined
        }
      />
    </Stack>
  );
};
