import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconAnalysisValidation from '@/icons/AnalysisValidation.svg';

export const ArticleRequalificationValidatedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_requalification_validated'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconAnalysisValidation />}
      creatorName={activity.creator.name}
      message={
        <>
          <BaseActivityText>
            <Trans id="activities.article_requalification_validated.message">
              validated the re-qualification for the item
            </Trans>
          </BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
        </>
      }
    />
  );
};
