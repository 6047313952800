import IconWrapper from './IconWrapper';

const IconHelp = IconWrapper({
  name: 'help',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 8.25C11.0335 8.25 10.25 9.0335 10.25 10C10.25 10.2529 10.0684 10.5 9.81548 10.5H9.29017C9.00136 10.5 8.75 10.2888 8.75 10C8.75 8.20507 10.2051 6.75 12 6.75C13.7949 6.75 15.25 8.20507 15.25 10C15.25 11.7108 13.9281 13.1129 12.25 13.2405V14C12.25 14.4142 11.9142 14.75 11.5 14.75C11.0858 14.75 10.75 14.4142 10.75 14V13C10.75 12.3096 11.3096 11.75 12 11.75V12.7928L12 12.7908V11.75L12 11.75C12.9665 11.75 13.75 10.9665 13.75 10C13.75 9.0335 12.9665 8.25 12 8.25ZM10.75 16.5C10.75 16.0858 11.0858 15.75 11.5 15.75C11.9142 15.75 12.25 16.0858 12.25 16.5C12.25 16.9142 11.9142 17.25 11.5 17.25C11.0858 17.25 10.75 16.9142 10.75 16.5Z"
      />
    </svg>
  ),
});

export default IconHelp;
