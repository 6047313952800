import IconWrapper from './IconWrapper';

const IconWeight = IconWrapper({
  name: 'item-weight',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C11.0335 3.75 10.25 4.5335 10.25 5.5C10.25 6.1981 10.6588 6.80073 11.25 7.08159V7C11.25 6.58579 11.5858 6.25 12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V7.08159C13.3412 6.80073 13.75 6.1981 13.75 5.5C13.75 4.5335 12.9665 3.75 12 3.75ZM8.75 5.5C8.75 7.03679 9.81665 8.32445 11.25 8.66303V10.25H7.63961C6.32874 10.25 5.2001 11.1753 4.94301 12.4607L3.74301 18.4607C3.40268 20.1624 4.70423 21.75 6.43961 21.75H17.5604C19.2958 21.75 20.5973 20.1624 20.257 18.4607L19.057 12.4607C18.7999 11.1753 17.6713 10.25 16.3604 10.25H12.75V8.66303C14.1833 8.32445 15.25 7.03679 15.25 5.5C15.25 3.70507 13.7949 2.25 12 2.25C10.2051 2.25 8.75 3.70507 8.75 5.5ZM12 11.75H7.63961C7.04376 11.75 6.53074 12.1706 6.41388 12.7549L5.21388 18.7549C5.05919 19.5283 5.6508 20.25 6.43961 20.25H17.5604C18.3492 20.25 18.9408 19.5283 18.7861 18.7549L17.5861 12.7549C17.4693 12.1706 16.9562 11.75 16.3604 11.75H12Z"
      />
    </svg>
  ),
});

export default IconWeight;
