import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconExternalLink from '@/icons/ExternalLink.svg';
import IconPackage from '@/icons/Package.svg';
import IconTruck from '@/icons/Truck.svg';
import { ShipmentWithRelations, useCarrierName } from '@/models/shipment';
import { ShipmentMessageTitle } from '@/routes/Shipments/Shipment/components/ShipmentStep/components/ShipmentMessageTitle';
import { useCurrentSession } from '@/services/auth';
import { formatDate } from '@/utils/date';

const HandoverMessage = ({
  shipment,
  hideIcon,
  className,
}: {
  shipment: ShipmentWithRelations;
  hideIcon?: boolean;
  className?: string;
}) => {
  const { currentSession } = useCurrentSession();

  const carrierName = useCarrierName(shipment);

  const canCreateShipment = shipment.canBeCreatedBy(currentSession);

  if (!canCreateShipment) {
    if (shipment.status === 'pending-pickup') {
      return (
        <AlertBar
          type="info"
          size="large"
          icon={<IconPackage />}
          hideIcon={hideIcon}
          className={className}
          title={
            <ShipmentMessageTitle shipment={shipment}>
              <Trans id="requests.transit.transit-pending-message.pending-pickup.title">
                Waiting for carrier pickup
              </Trans>
            </ShipmentMessageTitle>
          }
        >
          {shipment.pickupDate && (
            <p>
              <Trans id="requests.transit.transit-pending-message.pending-pickup.text">
                The pickup is scheduled for {formatDate(new Date(shipment.pickupDate), {})}
              </Trans>
            </p>
          )}
        </AlertBar>
      );
    }

    if (shipment.status === 'pending-dropoff') {
      return (
        <AlertBar
          type="info"
          size="large"
          icon={<IconPackage />}
          hideIcon={hideIcon}
          className={className}
          title={
            <ShipmentMessageTitle shipment={shipment}>
              <Trans id="requests.transit.transit-pending-message.dropoff.title">
                Waiting for carrier drop off
              </Trans>
            </ShipmentMessageTitle>
          }
        >
          {!!shipment.originStoreId && (
            <p>
              <Trans id="requests.transit.transit-pending-message.dropoff.text">
                The store will drop the package off in the nearest {carrierName} pickup point.
              </Trans>
            </p>
          )}
          {!!shipment.originWorkshopId && (
            <p>
              <Trans id="requests.delivery.delivery-pending-message.dropoff.text">
                The workshop will drop the package off in the nearest {carrierName} pickup point.
              </Trans>
            </p>
          )}
        </AlertBar>
      );
    }
  }

  if (shipment.status === 'pending-pickup') {
    return (
      <AlertBar
        type="info"
        size="large"
        icon={<IconTruck />}
        hideIcon={hideIcon}
        className={className}
        title={
          <ShipmentMessageTitle shipment={shipment}>
            <Trans id="shipment.handover-message.pickup.title">Waiting for carrier pickup</Trans>
          </ShipmentMessageTitle>
        }
      >
        {shipment.pickupDate && (
          <p>
            <Trans id="requests.transit.transit-pending-message.pending-pickup.text">
              The pickup is scheduled for {formatDate(new Date(shipment.pickupDate), {})}
            </Trans>
          </p>
        )}
      </AlertBar>
    );
  }

  if (shipment.status === 'pending-dropoff') {
    return (
      <AlertBar
        type="warning"
        size="large"
        icon={<IconPackage />}
        hideIcon={hideIcon}
        className={className}
        title={
          <ShipmentMessageTitle shipment={shipment}>
            <Trans id="shipment.handover-message.dropoff.title">Drop the package</Trans>
          </ShipmentMessageTitle>
        }
      >
        <Stack gap="8px">
          <p>
            <Trans id="shipment.handover-message.dropoff.text">
              Go to the nearest {carrierName} pickup point and drop the package
            </Trans>
          </p>
          {shipment.carrier === 'mondial_relay' && (
            <div>
              <Button
                variant="secondary"
                size="medium"
                href="https://www.mondialrelay.fr/trouver-le-point-relais-le-plus-proche-de-chez-moi/"
                target="_blank"
              >
                <Trans id="shipment.handover-message.dropoff.mondial-relay-points">
                  View Mondial Relay drop-off points
                </Trans>
                <IconExternalLink />
              </Button>
            </div>
          )}
        </Stack>
      </AlertBar>
    );
  }
};

export default HandoverMessage;
