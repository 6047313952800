import IconWrapper from './IconWrapper';

const IconUnread = IconWrapper({
  name: 'unread',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 7C20.1046 7 21 6.10457 21 5C21 3.89543 20.1046 3 19 3C17.8954 3 17 3.89543 17 5C17 6.10457 17.8954 7 19 7ZM20.75 9.94513C20.75 9.46454 20.75 9.01671 20.745 8.60034C20.2879 8.82229 19.7822 8.95975 19.2481 8.99243C19.2499 9.30261 19.25 9.63745 19.25 10V14C19.25 15.4354 19.2484 16.4365 19.1469 17.1919C19.0482 17.9257 18.8678 18.3142 18.591 18.591C18.3142 18.8678 17.9257 19.0482 17.1919 19.1469C16.4365 19.2484 15.4354 19.25 14 19.25H10C8.56459 19.25 7.56347 19.2484 6.80812 19.1469C6.07435 19.0482 5.68578 18.8678 5.40901 18.591C5.13225 18.3142 4.9518 17.9257 4.85315 17.1919C4.7516 16.4365 4.75 15.4354 4.75 14V10C4.75 8.56459 4.7516 7.56347 4.85315 6.80812C4.9518 6.07435 5.13225 5.68577 5.40901 5.40901C5.68578 5.13225 6.07435 4.9518 6.80812 4.85315C7.56347 4.75159 8.56459 4.75 10 4.75H14C14.3626 4.75 14.6974 4.7501 15.0076 4.75189C15.0403 4.21785 15.1777 3.71214 15.3997 3.25504C14.9833 3.24999 14.5355 3.24999 14.0549 3.25H14.0549H14H10H9.94513H9.94512C8.57753 3.24998 7.47522 3.24996 6.60825 3.36652C5.70814 3.48754 4.95027 3.74643 4.34835 4.34835C3.74644 4.95027 3.48754 5.70814 3.36653 6.60825C3.24997 7.47521 3.24998 8.57751 3.25 9.94508V9.94512V9.94513V10V14V14.0549V14.0549V14.0549C3.24998 15.4225 3.24997 16.5248 3.36653 17.3918C3.48754 18.2919 3.74644 19.0497 4.34835 19.6517C4.95027 20.2536 5.70814 20.5125 6.60825 20.6335C7.47522 20.75 8.57754 20.75 9.94513 20.75H10H14H14.0549C15.4225 20.75 16.5248 20.75 17.3918 20.6335C18.2919 20.5125 19.0497 20.2536 19.6517 19.6517C20.2536 19.0497 20.5125 18.2919 20.6335 17.3918C20.75 16.5248 20.75 15.4225 20.75 14.0549V14V10V9.94513Z"
        fill="#3F4643"
      />
    </svg>
  ),
});

export default IconUnread;
