import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import IconCancel from '@/icons/Cancel.svg';
import { useRemoveArticleFromShipment } from '@/models/shipment';
import useViewPort from '@/utils/useViewport';

export const ArticleDeleteArticleActionCell = ({
  shipmentId,
  articleId,
  isDisabled,
}: {
  shipmentId: string;
  articleId: string;
  isDisabled: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();

  const { mutateAsync: removeArticleFromShipment, isPending: isPendingRemoveArticleFromShipment } =
    useRemoveArticleFromShipment({ shipmentId });

  const label = _(msg({ id: 'article.remove.message', message: 'Remove' }));

  return (
    <Button
      size="medium"
      variant="secondary-danger"
      iconOnly={!isMobile}
      onPress={() => removeArticleFromShipment({ articleId })}
      ariaLabel={label}
      tooltip={label}
      disabled={isDisabled || isPendingRemoveArticleFromShipment}
      style={{ flex: 1 }}
    >
      <IconCancel />
      {isMobile && <div>{label}</div>}
    </Button>
  );
};
