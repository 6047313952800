import { UserAvatar } from '@/components/UserAvatar/UserAvatar';
import Stack from '@/design_system/Stack';
import { UserWithRelations } from '@/models/user';

export const UserCard = ({
  user,
  size = 'large',
}: {
  user: UserWithRelations;
  size?: 'small' | 'large';
}) => (
  <Stack row gap="8px" alignItems="center" flexWrap="nowrap">
    <UserAvatar user={user} size={size} />
    {user && (
      <Stack alignItems="flex-start" className="sentry-mask">
        <span className={size === 'large' ? 'paragraph-100-regular' : 'paragraph-200-regular'}>
          {user.name}
        </span>
        <span
          className={`${
            size === 'large' ? 'paragraph-200-regular' : 'paragraph-300-regular'
          } text-disabled`}
        >
          {user.roles.map((role) => role.role.name).join(', ')}
        </span>
      </Stack>
    )}
  </Stack>
);
