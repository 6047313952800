import { EmptyState } from '@/components/EmptyState';

import IllustrationRequestsEmptyState from './IllustrationRequestsEmptyState';

interface RequestsEmptyStateProps {
  title?: React.ReactNode;
  subtitle: React.ReactNode;
}

export const RequestsEmptyState = ({ title, subtitle }: RequestsEmptyStateProps) => {
  return (
    <EmptyState
      title={title}
      subtitle={subtitle}
      illustration={<IllustrationRequestsEmptyState />}
    />
  );
};
