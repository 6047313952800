import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import ClientForm, {
  type ClientState,
  getClientError,
  useClientState,
} from '@/components/ClientForm';
import ContactDetails from '@/components/ContactDetails';
import { UpdateStore } from '@/components/Store/UpdateStore';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import {
  ClientRequestWithRelations,
  useSendClientRequest,
  useUpdateRequestClient,
} from '@/models/request';
import { Store, useStore, useUpdateExternalStore } from '@/models/store';
import CartContent from '@/routes/Brand/Requests/New/components/CartContent';
import { useClientToken, useCurrentOrganization, useStoreToken } from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';
import { REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY } from '@/utils/requestInProgress';

import './Checkout.css';

const { block, element } = createBEMClasses('client-new-request-checkout');

const Checkout = ({
  request,
  onEditCart,
}: {
  request: ClientRequestWithRelations;
  onEditCart: () => void;
}) => {
  const navigate = useNavigate();
  const [organization] = useCurrentOrganization();
  const clientToken = useClientToken();
  const storeToken = useStoreToken();
  const { data: store } = useStore(request.storeId ?? undefined);
  const isExternalStoreRequest = !clientToken;

  const { mutateAsync: updateRequestClient } = useUpdateRequestClient();
  const clientState = useClientState(request.client);

  const [showErrors, setShowErrors] = useState(false);

  const { mutateAsync: sendRequest, isPending: isPendingSendRequest } = useSendClientRequest();

  const handleSendRequest = async () => {
    setShowErrors(true);

    if (request.client) {
      if (getClientError(clientState).hasError) {
        return;
      }

      await updateRequestClient({
        id: request.id,
        body: {
          client: {
            name: clientState.name,
            email: clientState.email,
            address: clientState.address ?? undefined,
            billingAddress: clientState.billingAddress ?? undefined,
            phone: clientState.phone,
          },
        },
      });
    }

    await sendRequest({
      id: request.id,
    });

    const tokenQueryParam = clientToken ? `clientToken=${clientToken}` : `storeToken=${storeToken}`;

    window.localStorage.removeItem(REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY);

    navigate(`/brand/${organization?.slug}/requests/${request.id}?${tokenQueryParam}`, {
      replace: true, // Replace the current entry (`/new?requestId=123`) to make sure the user can't go back to the checkout page
    });
  };

  return (
    <div className={block()}>
      <main>
        <div className={element('content')}>
          <div className={element('cart')}>
            <CartContent request={request} onEditCart={onEditCart} recap />
          </div>
          <Stack gap="1.5rem" className={element('client-form')}>
            {request.client ? (
              <ClientCheckoutForm
                request={request}
                clientState={clientState}
                showErrors={showErrors}
                isExternalStoreRequest={isExternalStoreRequest}
              />
            ) : store ? (
              <StoreOnlyCheckoutForm store={store} />
            ) : null}
          </Stack>
        </div>
      </main>
      <footer>
        <Stack row>
          <Button
            size="large"
            variant="brand"
            style={{ flex: 1 }}
            onPress={handleSendRequest}
            isLoading={isPendingSendRequest}
          >
            <Trans id="client.new.checkout.send">Send request</Trans>
          </Button>
        </Stack>
      </footer>
    </div>
  );
};

const ClientCheckoutForm = ({
  request,
  clientState,
  showErrors,
  isExternalStoreRequest,
}: {
  request: ClientRequestWithRelations;
  clientState: ClientState;
  showErrors: boolean;
  isExternalStoreRequest: boolean;
}) => {
  return (
    <>
      <h1 className="headline-300-bold">
        {isExternalStoreRequest ? (
          <Trans id="client.new.checkout.external-store.title">Client information</Trans>
        ) : (
          <Trans id="client.new.checkout.client.title">Please fill your information</Trans>
        )}
      </h1>

      <ClientForm
        request={request}
        state={clientState}
        hideVip
        hideLanguage={!isExternalStoreRequest}
        hideDeliveryOption={!isExternalStoreRequest}
        variant="brand"
        size="large"
        showErrors={showErrors}
      />
    </>
  );
};

const StoreOnlyCheckoutForm = ({ store }: { store: Store }) => {
  const { mutateAsync: updateExternalStore, isPending } = useUpdateExternalStore();

  return (
    <>
      <Stack row justifyContent="space-between" alignItems="center">
        <h1 className="headline-300-bold">
          <Trans id="client.new.checkout.external-store.store-only.title">Store information</Trans>
        </h1>
        <UpdateStore
          store={store}
          isPending={isPending}
          updateStore={async (store) => {
            await updateExternalStore(store);
          }}
          variant="brand"
          size="large"
        />
      </Stack>

      <Box gap="0.75rem" padding="12px 16px" style={{ flex: 'unset' }}>
        <ContactDetails
          type="store"
          name={store.name}
          email={store.address?.contactEmail}
          address={store.address}
          phone={store.phone}
        />
      </Box>
    </>
  );
};

export default Checkout;
