import { Trans } from '@lingui/macro';

import { ArticleWithRelations } from '@/models/request';

import { BaseMessage } from './BaseMessage';

export const AcceptRequalificationTaskMessage = ({
  article,
}: {
  article: ArticleWithRelations;
}) => {
  return (
    <BaseMessage
      title={
        <Trans id="article.task.accept-requalification.title">
          Analyze the requalification request
        </Trans>
      }
      message={
        <Trans id="article.task.accept-requalification.message">
          Please review the item requalification made by the workshop.
        </Trans>
      }
      article={article}
    />
  );
};
