import React, { forwardRef, useState } from 'react';
import {
  Button as AriaButton,
  Input,
  Label,
  TextField,
  TextFieldProps,
} from 'react-aria-components';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Hint from '@/design_system/Hint/Hint';
import Message from '@/design_system/Message/Message';
import IconVisibility from '@/icons/Visibility.svg';
import { createBEMClasses } from '@/utils/classname';

import './InputText.css';

const { block, element } = createBEMClasses('input-text');

interface InputTextProps extends TextFieldProps {
  label?: React.ReactNode;
  placeholder?: string;
  type?: 'text' | 'password' | 'email' | 'tel';
  showMessageIcon?: boolean;
  size?: 'small' | 'medium' | 'large';
  hintText?: React.ReactNode;
  messageType?: 'error' | 'success' | 'info';
  messageText?: string;
  isInvalid?: boolean;
  isSuccess?: boolean;
  isLoading?: boolean;
  error?: string;
  className?: string;
}

const InputText = (
  {
    label,
    placeholder,
    type = 'text',
    showMessageIcon = true,
    size = 'medium',
    hintText,
    messageType,
    messageText,
    isInvalid,
    isSuccess,
    isRequired,
    isLoading,
    error,
    className,
    ...props
  }: InputTextProps,
  ref?: React.Ref<HTMLInputElement>
) => {
  const { _ } = useLingui();
  const isPasswordField = type === 'password';

  const [visibility, setVisibility] = useState(false);
  const [adjustedType, setAdjustedType] = useState(type);

  return (
    <TextField
      {...props}
      className={block({ size, invalid: isInvalid || !!error, success: isSuccess }, className)}
    >
      {label && (
        <Label className={size === 'large' ? 'paragraph-50-medium' : 'label-100'}>{label}</Label>
      )}
      <div
        className={element('control', {
          loading: isLoading,
        })}
      >
        <Input
          ref={ref}
          required={isRequired}
          className={element('control__input', {
            'with-visibility': isPasswordField,
          })}
          placeholder={placeholder}
          type={adjustedType}
          size={10}
        />
        {isPasswordField && (
          <AriaButton
            className={element('control__visibility')}
            onPress={() => {
              setAdjustedType(visibility ? 'password' : 'text');
              setVisibility(!visibility);
            }}
            aria-label={_(
              msg({
                id: 'design-system.input-text.toggle-password-visibility',
                message: 'Toggle password visibility',
              })
            )}
          >
            <IconVisibility on={visibility} />
          </AriaButton>
        )}
      </div>
      {!!error && (
        <Message type="error" showMessageIcon={showMessageIcon}>
          {error}
        </Message>
      )}
      {hintText && <Hint>{hintText}</Hint>}
      {messageText && <Message type={messageType}>{messageText}</Message>}
    </TextField>
  );
};

export default forwardRef(InputText);
