import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ActionTypeSearchSelect from '@/components/ActionTypeSearchSelect';
import { ArticleServices } from '@/components/ArticleServices';
import Button from '@/design_system/Button';
import Drawer, { DrawerBody, DrawerFooter, DrawerHeader } from '@/design_system/Drawer';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import IconUndo from '@/icons/Undo.svg';
import { useDiscardRequalification, useRequalify } from '@/models/article';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { useActionsError } from '@/routes/Requests/Request/Article/useArticleErrors';
import { useFlags } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

const RequalificationDrawer = ({
  article,
  request,
  isOpen,
  setIsOpen,
  onRequalified,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onRequalified: () => void;
}) => {
  const {
    mutateAsync: discardRequalification,
    isPending: isPendingDiscardRequalification,
    isSuccess: isSuccessDiscardRequalification,
    reset: resetDiscardRequalification,
  } = useDiscardRequalification({ articleId: article.id });

  const discard = async () => {
    await discardRequalification();
    setIsOpen(false);

    // Wait for the Drawer CSS exit animation to finish
    setTimeout(() => {
      resetDiscardRequalification();
    }, 150);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onOpenChange={(newIsOpen) => {
        if (newIsOpen) {
          setIsOpen(true);
        } else {
          discard();
        }
      }}
    >
      <DrawerHeader>
        <h1 className="headline-200-bold">
          <Trans id="requalification-drawer.title">Propose actions re-qualification</Trans>
        </h1>
      </DrawerHeader>
      <RequalificationDrawerContent
        article={article}
        request={request}
        setIsOpen={setIsOpen}
        onRequalified={onRequalified}
        discard={discard}
        isPendingDiscard={isPendingDiscardRequalification || isSuccessDiscardRequalification}
      />
    </Drawer>
  );
};

const RequalificationDrawerContent = ({
  article,
  request,
  setIsOpen,
  onRequalified,
  discard,
  isPendingDiscard,
}: {
  article: ArticleWithRelations;
  request: RequestWithRelations;
  setIsOpen: (isOpen: boolean) => void;
  onRequalified: () => void;
  discard: () => Promise<void>;
  isPendingDiscard: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const { flags } = useFlags();

  const [comment, setComment] = useState(article?.requalificationComment ?? '');

  const [showErrors, setShowErrors] = useState(false);
  const actionsError = useActionsError(article, request);

  const {
    mutateAsync: requalify,
    isPending: isPendingRequalify,
    isSuccess: isSuccessRequalify,
  } = useRequalify({
    articleId: article.id,
  });

  const submit = async () => {
    setShowErrors(true);

    if (actionsError) {
      return;
    }

    await requalify({
      comment: comment ?? null,
    });

    setIsOpen(false);

    // Wait for the Drawer CSS exit animation to finish
    setTimeout(() => {
      onRequalified();
    }, 150);
  };

  return (
    <>
      <DrawerBody>
        <Stack gap="1rem" padding={isMobile ? '1rem' : '1.5rem 2rem'}>
          <p className="paragraph-100-regular text-secondary">
            <Trans id="requalification-modal.intro">
              If the initial required actions do not match the needs of the item, please search and
              add the appropriate ones below. Once submitted, the requalification will be reviewed
              by the After Sales Manager.
            </Trans>
          </p>

          {flags['enable-defect-action-split'] ? (
            <ArticleServices
              request={request}
              article={article}
              showServiceChoiceSelector={false}
            />
          ) : (
            <ActionTypeSearchSelect article={article} request={request} />
          )}

          <TextArea
            label={_(
              msg({
                id: 'requalification-modal.comment.label',
                message: 'Comments for the brand',
              })
            )}
            placeholder={_(
              msg({
                id: 'requalification-modal.comment.placeholder',
                message: 'Add a comment for the brand...',
              })
            )}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {showErrors && !!actionsError && <Message type="error">{actionsError}</Message>}
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <Stack gap="0.5rem" row={!isMobile}>
          <Button
            variant="secondary"
            size="medium"
            onPress={discard}
            isLoading={isPendingDiscard}
            disabled={isPendingRequalify || isSuccessRequalify}
          >
            <IconUndo />
            <Trans id="requalification-drawer.reset">Reset changes</Trans>
          </Button>
          <Button
            variant="primary"
            size="medium"
            onPress={submit}
            isLoading={isPendingRequalify || isSuccessRequalify}
            disabled={isPendingDiscard}
          >
            <Trans id="requalification-drawer.submit">Submit re-qualification proposal</Trans>
          </Button>
        </Stack>
      </DrawerFooter>
    </>
  );
};

export default RequalificationDrawer;
