import { ComponentProps, forwardRef } from 'react';
import { Button as AriaButton, Link as AriaLink } from 'react-aria-components';

import Tooltip from '@/design_system/Tooltip';
import { createBEMClasses } from '@/utils/classname';

import './Button.css';

const { block } = createBEMClasses('button');

export type Variant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'secondary-danger'
  | 'neutral'
  | 'brand'
  | 'secondary-brand'
  | 'basic'
  // This variant is used to display tooltip on icons see Tooltip.stories.tsx#OnIcon
  | 'style-less';

interface ButtonProps {
  variant?: Variant;
  size?: 'compact' | 'small' | 'medium' | 'large';
  ariaLabel?: string;
  iconOnly?: boolean;
  noBorder?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  tooltip?: React.ReactNode;
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
  children?: React.ReactNode;
  slot?: string;
  dataTrackingId?: string;

  // Button props
  onPress?: () => any;
  type?: 'button' | 'submit' | 'reset';
  // Internal link props
  to?: string;
  // External link props
  href?: string;
  target?: string;
  download?: string;
  rel?: string;
  displayOnly?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const Button = (
  {
    variant = 'primary',
    size = 'medium',
    ariaLabel,
    iconOnly = false,
    noBorder = false,
    disabled,
    isLoading,
    tooltip,
    tooltipPlacement,
    children,
    slot,
    dataTrackingId,
    onPress,
    type,
    to,
    href,
    target,
    download,
    rel,
    displayOnly = false,
    className: classNameProp,
    style,
  }: ButtonProps,
  ref: React.Ref<any>
) => {
  const className = block(
    {
      variant,
      size,
      'icon-only': iconOnly,
      'no-border': noBorder,
      loading: isLoading && !disabled,
    },
    classNameProp
  );

  const isDisabled = disabled || isLoading;

  const Base = () => {
    if (href || to) {
      return (
        <AriaLink
          ref={ref}
          className={className}
          isDisabled={isDisabled}
          aria-label={ariaLabel}
          style={style}
          href={href || to}
          target={target}
          download={download}
          rel={rel}
          data-tracking-id={dataTrackingId}
        >
          {children}
        </AriaLink>
      );
    }

    if (displayOnly) {
      return (
        <div className={className} style={style}>
          {children}
        </div>
      );
    }

    return (
      <AriaButton
        ref={ref}
        className={className}
        onPress={onPress}
        type={type}
        isDisabled={isDisabled}
        aria-label={ariaLabel}
        style={style}
        slot={slot}
        data-tracking-id={dataTrackingId}
      >
        {children}
      </AriaButton>
    );
  };

  if (tooltip) {
    return (
      <Tooltip content={tooltip} hideOnTouchDevice placement={tooltipPlacement}>
        <Base />
      </Tooltip>
    );
  } else {
    return <Base />;
  }
};

export default forwardRef(Button);
