import { useQuery } from '@tanstack/react-query';

import { useFetch } from '@/utils/fetch';

import { Endpoints } from '../../../api/src/endpoints';

export const useReference = (params: { brand?: string; ref?: string }) => {
  const fetch = useFetch<Endpoints['GET /reference/:brand/:ref']>();

  return useQuery({
    queryKey: ['reference', params],
    queryFn: () => fetch(`/reference/${params.brand}/${params.ref}`),
    enabled: !!params.brand && !!params.ref,
  });
};
