import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Drawer, { DrawerBody, DrawerFooter } from '@/design_system/Drawer';
import Stack from '@/design_system/Stack';

export const FiltersDrawer = ({
  children,
  isOpen,
  onOpenChange,
  handleClearFilters,
  handleApplyFilters,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  handleClearFilters: () => void;
  handleApplyFilters: () => void;
}) => {
  const { _ } = useLingui();

  return (
    <Drawer isOpen={isOpen} onOpenChange={onOpenChange} style={{ maxWidth: '21.875rem' }}>
      <DrawerBody
        style={{
          flex: 1,
          padding: '1.5rem 1rem' /* eslint-disable-line lingui/no-unlocalized-strings */,
        }}
      >
        <div className="headline-200-bold text-primary" style={{ marginBottom: '1.5rem' }}>
          <Trans id="list.filters.drawer">Filters</Trans>
        </div>

        <Stack gap="1rem">{children}</Stack>
      </DrawerBody>

      <DrawerFooter>
        <Stack row gap="1rem">
          <Button
            variant="neutral"
            size="medium"
            ariaLabel={_(msg({ id: 'list.filters.drawer.clear', message: 'Clear' }))}
            style={{ flex: 1 }}
            onPress={handleClearFilters}
          >
            <Trans id="list.filters.drawer.clear">Clear</Trans>
          </Button>

          <Button
            variant="primary"
            size="medium"
            ariaLabel={_(msg({ id: 'list.filters.drawer.show-results', message: 'Show results' }))}
            style={{ flex: 1 }}
            onPress={handleApplyFilters}
          >
            <Trans id="list.filters.drawer.show-results">Show results</Trans>
          </Button>
        </Stack>
      </DrawerFooter>
    </Drawer>
  );
};
