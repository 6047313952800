import { ReactNode, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import cn from 'classnames';

import Button from '@/design_system/Button';
import PhotoCard from '@/design_system/PhotoCard';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconError from '@/icons/Error.svg';
import IconPlace from '@/icons/Place.svg';
import { useArticleName } from '@/models/article';
import {
  ArticleWithRelations as ArticleWithRelationsFromRequest,
  ClientArticleWithRelations,
} from '@/models/request';
import { ArticleWithRelations as ArticleWithRelationsFromShipment } from '@/models/shipment';
import { useRequestContext } from '@/routes/Requests/RequestContext';
import { ShipmentArticlesContext } from '@/routes/Shipments/Shipment/components/ShipmentArticles/ShipmentArticles';
import useViewPort from '@/utils/useViewport';

const ArticleNameCell = ({
  id,
  article,
  badge,
  noLink,
}: {
  id?: string;
  article?:
    | ArticleWithRelationsFromRequest
    | ClientArticleWithRelations
    | ArticleWithRelationsFromShipment;
  badge?: ReactNode;
  noLink?: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const name = useArticleName({ article });

  const { request } = useRequestContext();
  const { shipment } = useContext(ShipmentArticlesContext);

  const isMissingCustomsInfo =
    article &&
    (request
      ? article.areCustomsExpected && !article.hasRequiredCustomsInfo
      : shipment
        ? shipment.areCustomsExpected && !article.hasRequiredCustomsInfo
        : false);

  const photoLabel = _(
    msg({
      id: 'article.photo.label',
      message: `Photo of ${name}`,
    })
  );

  const nameWithIcon = (
    <Stack
      row
      gap="4px"
      alignItems="center"
      flexWrap="nowrap"
      className={cn({
        'text-warning': request && isMissingCustomsInfo,
        'text-danger': shipment && isMissingCustomsInfo,
      })}
      style={{ overflow: 'hidden' }}
    >
      {request && isMissingCustomsInfo && (
        <Tooltip
          content={_(
            msg({
              id: 'article-name-cell.missing-customs.tooltip',
              message: 'Missing customs information',
            })
          )}
        >
          <Button variant="style-less">
            <IconPlace warning className="text-warning" style={{ fontSize: '1.25rem' }} />
          </Button>
        </Tooltip>
      )}
      {shipment && isMissingCustomsInfo && (
        <IconError className="text-danger" style={{ fontSize: '1.25rem' }} />
      )}
      <p className="paragraph-100-medium text-ellipsis" title={name}>
        {name}
      </p>
    </Stack>
  );

  return (
    <Stack
      row
      gap={isMobile ? '0.5rem' : '1rem'}
      alignItems="center"
      flexWrap="nowrap"
      style={{ minWidth: 0 }}
    >
      {article?.articlePhoto?.url ? (
        <div>
          <PhotoCard
            url={article?.articlePhoto?.url}
            name={photoLabel}
            alt={photoLabel}
            imgStyle={{
              height: isMobile ? '2.5rem' : '3.5rem',
              width: isMobile ? '2.5rem' : '3.5rem',
            }}
            size={isMobile ? 'small' : 'medium'}
            hideMoreIcon
          />
        </div>
      ) : undefined}
      <div style={{ overflow: 'hidden', flex: 1 }}>
        <Stack row alignItems="center" gap="0.5rem" flexWrap="nowrap">
          {id && article && !noLink ? (
            <NavLink to={`/requests/${article.requestId}/articles/${id}`} className="reset-link">
              {nameWithIcon}
            </NavLink>
          ) : (
            nameWithIcon
          )}
          {badge}
        </Stack>

        {article?.product?.data.reference && (
          <p className="paragraph-200-regular text-secondary">
            <Trans id="article.reference.short">Ref:</Trans>{' '}
            <span>{article.product.data.reference}</span>
          </p>
        )}

        <Stack row gap="0.25rem">
          {article?.data.color && (
            <p className="paragraph-200-regular text-secondary">
              <span>{article.data.color}</span>
            </p>
          )}
          {article?.data.color && article?.data.size && (
            <p className="paragraph-200-regular text-secondary">
              <span>•</span>
            </p>
          )}
          {article?.data.size && (
            <p className="paragraph-200-regular text-secondary">
              <Trans id="article.size">Size:</Trans> <span>{article.data.size}</span>
            </p>
          )}
        </Stack>
      </div>
    </Stack>
  );
};

export default ArticleNameCell;
