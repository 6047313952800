import { useContext } from 'react';

import Stack from '@/design_system/Stack';
import { PackActionTypeOrganizationWithRelations } from '@/models/actionType';
import { useCurrentSession } from '@/services/auth';
import { formatCurrency } from '@/utils/number';
import useViewPort from '@/utils/useViewport';

import { ActionTypeSearchSelectContext } from './ActionTypeSearchSelect';
import PackActionName from './PackActionName';

export const PackActionDropdownItem = ({
  packAction,
}: {
  packAction: PackActionTypeOrganizationWithRelations;
}) => {
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  const { showWorkshopPrice, showOrganizationPrice } = useContext(ActionTypeSearchSelectContext);

  return (
    <Stack
      row={!isMobile}
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent="space-between"
      gap="0.5rem"
      flexWrap="nowrap"
      style={{ flex: 1 }}
    >
      <PackActionName
        packActionType={
          !currentSession?.workshop && !!packAction.refashionBonus
            ? Object.assign(packAction, {
                actions: packAction.actions.map((action) =>
                  Object.assign(action, {
                    refashionStatus: action.refashionId ? 'applied' : undefined,
                  })
                ),
              })
            : packAction
        }
        alwaysShowActions
      />
      <Stack
        row
        alignItems="center"
        gap="0.5rem"
        flexWrap="nowrap"
        style={{ alignSelf: isMobile ? 'flex-end' : 'center' }}
      >
        {showWorkshopPrice && (
          <p className="paragraph-100-regular">
            {formatCurrency(packAction.dynamicCost?.amount, packAction.dynamicCost?.currency)}
          </p>
        )}
        {showOrganizationPrice && (
          <p className="paragraph-100-medium">
            {formatCurrency(packAction.dynamicPrice?.amount, packAction.dynamicPrice?.currency)}
          </p>
        )}
      </Stack>
    </Stack>
  );
};
