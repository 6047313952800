import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconPicture from '@/icons/Picture.svg';

export const ArticleMediumAddedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_medium_added'>;
  displayArticleName: boolean;
}) => {
  const { _ } = useLingui();

  let articleMediaAddedMessage: MessageDescriptor | undefined;
  if (activity.data.type === 'article-photo' || activity.data.type === 'defect-photo') {
    articleMediaAddedMessage = msg({
      id: 'activities.article_medium_added.message_defect_photo',
      message: 'added a photo to item',
    });
  } else if (activity.data.type === 'proof-of-purchase') {
    articleMediaAddedMessage = msg({
      id: 'activities.article_medium_added.message_proof_of_purchase',
      message: 'added a proof of purchase to item',
    });
  }

  if (!articleMediaAddedMessage) return null;

  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconPicture />}
      creatorName={activity.creator?.name ?? ''}
      message={
        <>
          <BaseActivityText>{_(articleMediaAddedMessage)}</BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
        </>
      }
    />
  );
};
