import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useMedia } from '@/models/medium';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

export const useActionsError = (article?: ArticleWithRelations, request?: RequestWithRelations) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  if (!article || !request) {
    return undefined;
  }

  if (!article.hasActions) {
    return _(
      msg({
        id: 'article.estimate.actions.error',
        message: 'Please add at least one care & repair action',
      })
    );
  }

  const incompleteWorkshopPrice = article.snapshot.articleActions.some(
    (action) => !!action.customDescription && action.manualWorkshopPrice === null
  );

  const incompleteOrganizationPrice = article.snapshot.articleActions.some(
    (action) => !!action.customDescription && action.manualOrganizationPrice === null
  );

  const missingPhotos = article.snapshot.articleActions.some((action) => action.media.length === 0);

  if (currentSession?.workshop) {
    if (incompleteWorkshopPrice) {
      return _(
        msg({
          id: 'article.estimate.actions.error.custom-actions-no-cost',
          message: 'Please provide cost details for the custom actions',
        })
      );
    }

    if (missingPhotos) {
      return _(
        msg({
          id: 'requalification-modal.actions.error.at-least-one-defect-photo',
          message: 'Please add at least one defect photo for each action',
        })
      );
    }
  } else {
    if (request.requestorType === 'store' && incompleteWorkshopPrice) {
      return _(
        msg({
          id: 'article.estimate.actions.error.custom-actions-no-cost',
          message: 'Please provide cost details for the custom actions',
        })
      );
    }

    if (
      request.requestorType !== 'store' &&
      (incompleteWorkshopPrice || incompleteOrganizationPrice)
    ) {
      return _(
        msg({
          id: 'article.estimate.actions.error.custom-actions',
          message: 'Please provide cost and price details for the custom actions',
        })
      );
    }
  }

  return undefined;
};

export const useArticleErrors = (
  article?: ArticleWithRelations,
  request?: RequestWithRelations
) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();

  const { data: { media: proofOfPurchaseMedia } = { media: [] } } = useMedia({
    articleId: article?.id,
    types: ['proof-of-purchase'],
    limit: 1,
  });

  const actionsError = useActionsError(article, request);

  const productError =
    !article?.product && !article?.productL1
      ? _(
          msg({
            id: 'article.form.external-id.error',
            message: 'Please select a product reference or type',
          })
        )
      : undefined;

  const productL2Error =
    !article?.product && !!article?.productL1 && !article?.productL2
      ? _(
          msg({
            id: 'article.form.category.error',
            message: 'Please select a category',
          })
        )
      : undefined;

  const otherBrandError =
    article?.data.brand?.isOther && !article?.data.brand?.name
      ? _(
          msg({
            id: 'article.form.brand.other.specify-name.error',
            message: 'Please select a brand name',
          })
        )
      : undefined;

  const photosError = !article?.articlePhoto
    ? _(
        msg({
          id: 'article.estimate.article-pictures.error',
          message: 'Please add item pictures',
        })
      )
    : undefined;

  const proofOfPurchaseError =
    !!article?.warranty && proofOfPurchaseMedia.length === 0
      ? _(
          msg({
            id: 'article.form.proof-of-purchase.error',
            message: 'Please add a proof of purchase, or remove the warranty on this item.',
          })
        )
      : undefined;

  const workshopError =
    !!article?.hasActions && !article?.workshopId
      ? _(msg({ id: 'article.estimate.dispatch.error', message: 'Please select a workshop' }))
      : undefined;

  const customsError =
    article?.areCustomsExpected &&
    !article?.hasRequiredCustomsInfo &&
    currentSession!.hasPermission('edit_article', {
      organizationId: article.organizationId,
    });

  return {
    productError,
    productL2Error,
    otherBrandError,
    actionsError,
    photosError,
    proofOfPurchaseError,
    workshopError,
    customsError,
  };
};
