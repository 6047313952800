import IconWrapper from './IconWrapper';

const IconUpload = IconWrapper({
  name: 'upload',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.2022 9.61476C8.02107 7.77795 9.86211 6.5 12 6.5C14.754 6.5 17.0134 8.62107 17.2325 11.3182L17.285 11.9639L17.9315 12.0059C19.3653 12.0989 20.5 13.2922 20.5 14.75C20.5 16.2688 19.2688 17.5 17.75 17.5H7.25C5.17893 17.5 3.5 15.8211 3.5 13.75C3.5 11.8876 4.85839 10.341 6.6384 10.0495L7.03752 9.98414L7.2022 9.61476ZM12 5C9.38885 5 7.12544 6.48257 6.003 8.64917C3.70536 9.20918 2 11.2798 2 13.75C2 16.6495 4.3505 19 7.25 19H17.75C20.0972 19 22 17.0972 22 14.75C22 12.7121 20.566 11.0096 18.6516 10.5959C18.1038 7.41751 15.3346 5 12 5ZM11.25 15.125C11.25 15.5392 11.5858 15.875 12 15.875C12.4142 15.875 12.75 15.5392 12.75 15.125V11.7358L13.2096 12.2133C13.4969 12.5118 13.9717 12.5208 14.2701 12.2336C14.5685 11.9464 14.5776 11.4716 14.2904 11.1731L12.5404 9.35491C12.399 9.20802 12.2039 9.12501 12 9.12501C11.7961 9.12501 11.601 9.20802 11.4596 9.35491L9.70964 11.1731C9.4224 11.4716 9.43147 11.9464 9.72991 12.2336C10.0283 12.5208 10.5031 12.5118 10.7904 12.2133L11.25 11.7358V15.125Z"
      />
    </svg>
  ),
});

export default IconUpload;
