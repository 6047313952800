import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconAnalysisValidation from '@/icons/AnalysisValidation.svg';

export const ArticleRequalificationAutoValidatedActivity = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'article_requalification_auto_validated'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconAnalysisValidation />}
      creatorName={''}
      message={
        <BaseActivityText>
          <Trans id="activities.article_requalification_auto_validated.message">
            The re-qualification for the item{' '}
            {
              <BaseActivityArticleName
                activity={activity}
                displayArticleName={displayArticleName}
              />
            }{' '}
            was automatically validated
          </Trans>
        </BaseActivityText>
      }
    />
  );
};
