import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { RequestWithRelations } from '@/models/request';
import { NewRequestArticles } from '@/routes/Requests/New/components/NewRequestArticles';

import { getArticleDetailsError, getArticleServicesError } from './ArticleForm';

const Articles = ({ error }: { error?: string }) => {
  return (
    <Stack gap="0.5rem">
      <h2 className="headline-400-bold">
        <Trans id="requests.new.articles.title">Items</Trans>
      </h2>
      {!!error && <Message type="error">{error}</Message>}
      <NewRequestArticles />
    </Stack>
  );
};

export default Articles;

export const useArticlesError = (request: RequestWithRelations) => {
  const { _ } = useLingui();

  const hasNoArticles = request.articles.length === 0;

  const hasIncompleteDraftArticles = request.articles.some(
    (article) => getArticleDetailsError({ article }).hasError
  );

  const hasIncompleteSavedArticles = request.articles.some(
    (article) =>
      getArticleDetailsError({ article }).hasError || getArticleServicesError({ article }).hasError
  );

  return {
    noArticlesError: hasNoArticles
      ? _(
          msg({
            id: 'requests.new.articles.error.no-article',
            message: 'Please add at least one item',
          })
        )
      : undefined,
    incompleteDraftArticlesError: hasIncompleteDraftArticles
      ? _(
          msg({
            id: 'requests.new.articles.error.incomplete-article',
            message: 'Please complete all the items',
          })
        )
      : undefined,
    incompleteSavedArticlesError: hasIncompleteSavedArticles
      ? _(
          msg({
            id: 'requests.new.articles.error.incomplete-article',
            message: 'Please complete all the items',
          })
        )
      : undefined,
  };
};
