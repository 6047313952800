import { msg } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconEmail from '@/icons/Email.svg';

export const PendingValidationNotifiedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'pending_validation_notified'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      icon={<IconEmail />}
      creatorName={activity.creator ? activity.creator.name : ''}
      message={
        activity.creator
          ? msg({
              id: 'activities.pending_validation_notified.message',
              message: 'sent an email reminder about the initial quote to the client',
            })
          : msg({
              id: 'activities.pending_validation_notified.message-no-creator',
              message: 'The initial quote has been sent by email to the client',
            })
      }
    />
  );
};
