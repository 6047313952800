import IconWrapper from './IconWrapper';

const IconZendesk = IconWrapper({
  name: 'zendesk',
  source: (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6348 3.68311V12.9789L18.3334 3.6838L10.6348 3.68311ZM1.66675 3.68449C1.66675 5.80949 3.39105 7.53449 5.51605 7.53449C7.64105 7.53449 9.36467 5.80949 9.36536 3.68449H1.66675ZM9.36536 7.02269L1.66675 16.3164H9.36536V7.02269ZM14.4834 12.4671C13.9779 12.467 13.4773 12.5664 13.0102 12.7598C12.5432 12.9532 12.1188 13.2367 11.7614 13.5942C11.404 13.9517 11.1205 14.3761 10.9272 14.8432C10.7339 15.3103 10.6345 15.8109 10.6348 16.3164H18.3334C18.3334 14.1914 16.6112 12.4671 14.4841 12.4671H14.4834Z" />
    </svg>
  ),
});

export default IconZendesk;
