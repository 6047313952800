import { Trans } from '@lingui/macro';

import PhotoCard from '@/design_system/PhotoCard';
import Stack from '@/design_system/Stack';

export const ProductPhoto = ({
  productName,
  productImage,
}: {
  productName: string;
  productImage: string;
}) => {
  return (
    <Stack gap="0.25rem">
      <h3 className="label-100">
        <Trans id="requests.articles.form.product-photo.label">Product photo</Trans>
      </h3>
      <div className="photo-card-grid">
        <PhotoCard name={productName} alt={productName} url={productImage} />
      </div>
    </Stack>
  );
};
