import { GridList, GridListItem } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { startOfDay } from 'date-fns/startOfDay';

import { ArticlePhotoGroup } from '@/components/ArticlePhotoGroup';
import { Card, CardContent, CardItem, CardItems } from '@/components/Card/Card';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import { Stepper } from '@/design_system/Stepper/Stepper';
import { Body, Cell, Column, Footer, Header, Row, Table } from '@/design_system/Table/Table';
import IconClock from '@/icons/Clock.svg';
import IconCloth from '@/icons/Cloth.svg';
import IconDone from '@/icons/Done.svg';
import IconTruck from '@/icons/Truck.svg';
import { Shipment, ShipmentWithRelations, useCarrierName } from '@/models/shipment';
import { useShipmentStepsToDisplay } from '@/routes/Shipments/Shipment/components/ShipmentStep/ShipmentStep';
import { createBEMClasses } from '@/utils/classname';
import { formatDate } from '@/utils/date';

export const SHIPMENTS_PER_PAGE = 10;

import { ShipmentStatusDueAtCell } from '@/routes/Shipments/Tabs/components/ShipmentStatusDueAtCell';
import { useCountries } from '@/services/i18n';
import useViewPort from '@/utils/useViewport';

import './ShipmentsTable.css';

const { block, element } = createBEMClasses('shipments-table');

export const ShipmentsTable = ({
  shipments,
  showStatusDueAt,
  pagination,
}: {
  shipments: ShipmentWithRelations[];
  showStatusDueAt?: boolean;
  pagination?: React.ReactNode;
}) => {
  const { _ } = useLingui();

  return (
    <Table
      className={block()}
      ariaLabel={_(msg({ id: 'shipments.table.label', message: 'Shipments' }))}
      columnWidths={[
        '104px',
        'minmax(80px, 1fr)',
        'minmax(120px, 1fr)',
        showStatusDueAt && 'minmax(120px, 1fr)',
        'minmax(120px, 1fr)',
        'minmax(120px, 1fr)',
      ]}
    >
      <Header>
        <Row>
          <Column>
            <Trans id="shipments.table.column.reference.title">Reference</Trans>
          </Column>
          <Column>
            <Trans id="shipments.table.column.articles.title">Items</Trans>
          </Column>
          <Column>
            <Trans id="shipments.table.column.step.title">Step</Trans>
          </Column>
          {showStatusDueAt && (
            <Column>
              <Trans id="shipments.table.column.step-due-date.title">Step due date</Trans>
            </Column>
          )}
          <Column>
            <Trans id="shipments.table.column.origin.title">Origin</Trans>
          </Column>
          <Column>
            <Trans id="shipments.table.column.destination.title">Destination</Trans>
          </Column>
        </Row>
      </Header>
      <Body>
        {shipments?.map((shipment) => (
          <ShipmentsTableRow
            key={shipment.id}
            shipment={shipment}
            showStatusDueAt={showStatusDueAt}
          />
        ))}
      </Body>
      {pagination ? (
        <Footer>
          <Row>
            <Cell isWholeRow>{pagination}</Cell>
          </Row>
        </Footer>
      ) : null}
    </Table>
  );
};

const ShipmentsTableRow = ({
  shipment,
  showStatusDueAt,
}: {
  shipment: ShipmentWithRelations;
  showStatusDueAt?: boolean;
}) => {
  const carrierName = useCarrierName(shipment);
  const steps = useShipmentStepsToDisplay(shipment);

  return (
    <Row to={`/shipments/${shipment.id}`}>
      <Cell isLink>
        <Stack>
          <span>{shipment.reference}</span>
          <small className="text-secondary paragraph-300-regular">{carrierName}</small>
        </Stack>
      </Cell>
      <Cell>
        <ArticlePhotoGroup articles={shipment.articles.map(({ article }) => article)} />
      </Cell>
      <Cell>
        {shipment.status === 'validated' ? (
          <Stack className="text-success" row alignItems="center" gap="0.5rem">
            <IconDone style={{ fontSize: '1.25rem' }} />
            <Trans id="shipment.status.validated">Validated</Trans>
          </Stack>
        ) : (
          <Stepper variant="round" size="medium" steps={steps} />
        )}
      </Cell>
      {showStatusDueAt && (
        <Cell>
          <ShipmentStatusDueAtCell shipment={shipment} variant="row" />
        </Cell>
      )}
      <Cell>
        <ShipmentOrigin shipment={shipment} />
      </Cell>
      <Cell>
        <ShipmentDestination shipment={shipment} />
      </Cell>
    </Row>
  );
};

export const ShipmentsList = ({
  shipments,
  showStatusDueAt,
  pagination,
}: {
  shipments: ShipmentWithRelations[];
  showStatusDueAt?: boolean;
  pagination?: React.ReactNode;
}) => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  return (
    <>
      <GridList
        className={block()}
        aria-label={_(
          msg({
            id: 'shipments.table.label',
            message: 'Shipments',
          })
        )}
        onAction={(id) => navigate(`/shipments/${id}`)}
      >
        {shipments?.map((shipment) => (
          <ShipmentCard key={shipment.id} shipment={shipment} showStatusDueAt={showStatusDueAt} />
        ))}
      </GridList>
      {pagination}
    </>
  );
};

const ShipmentCard = ({
  shipment,
  showStatusDueAt,
}: {
  shipment: ShipmentWithRelations;
  showStatusDueAt?: boolean;
}) => {
  const carrierName = useCarrierName(shipment);

  return (
    <GridListItem id={shipment.id} textValue={shipment.reference} className={element('card')}>
      <Card>
        <CardContent>
          <Stack flexWrap="nowrap" gap="0.5rem">
            <Stack>
              <span className="paragraph-200-regular text-ellipsis">{shipment.reference}</span>
              {!!shipment.carrier && <span className="paragraph-200-medium">{carrierName}</span>}
            </Stack>
            <Box>
              <Stack gap="0.75rem">
                <Stack>
                  <span className="paragraph-400-regular">
                    <Trans id="shipments.table.from">FROM</Trans>
                  </span>
                  <ShipmentOrigin shipment={shipment} />
                </Stack>
                <Stack>
                  <span className="paragraph-400-regular">
                    <Trans id="shipments.table.to">TO</Trans>
                  </span>
                  <ShipmentDestination shipment={shipment} />
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </CardContent>
        <CardItems>
          <ArticlesCardItem shipment={shipment} />
          <StepCardItem shipment={shipment} />
          {showStatusDueAt && <ShipmentStatusDueAtCell shipment={shipment} variant="card" />}
        </CardItems>
      </Card>
    </GridListItem>
  );
};

const ArticlesCardItem = ({ shipment }: { shipment: ShipmentWithRelations }) => (
  <CardItem>
    <IconCloth style={{ fontSize: '1rem' }} />
    <span>{shipment.articles.length}</span>
  </CardItem>
);

const StepCardItem = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const steps = useShipmentStepsToDisplay(shipment);

  if (shipment.status === 'validated') {
    return (
      <CardItem className="text-success">
        <IconDone style={{ fontSize: '1rem' }} />
        <Trans id="shipment.status.validated">Validated</Trans>
      </CardItem>
    );
  }

  return (
    <CardItem>
      <Stepper variant="round" size="x-small" steps={steps} />
    </CardItem>
  );
};

const ArrivalDate = ({ shipment }: { shipment: Shipment }) => {
  const { arrivalDate, updatedEtaDate, originalEtaDate } = shipment;

  if (arrivalDate || (shipment.carrier === 'private-carrier' && originalEtaDate)) {
    return (
      <span className={element('arrival-date', { arrived: true })}>
        <IconDone /> {formatDate(arrivalDate ?? originalEtaDate!, { dateStyle: 'short' })}
      </span>
    );
  }

  const eta = updatedEtaDate ?? originalEtaDate;

  if (eta) {
    const isLate = startOfDay(eta) < startOfDay(new Date());

    if (isLate) {
      return (
        <span className={element('arrival-date', { late: true })}>
          <IconClock /> {formatDate(eta, { dateStyle: 'short' })}{' '}
          <Trans id="shipments.estimated.short">(est.)</Trans>
        </span>
      );
    }

    return (
      <span className={element('arrival-date', { transit: true })}>
        <IconTruck /> {formatDate(eta, { dateStyle: 'short' })}{' '}
        <Trans id="shipments.estimated.short">(est.)</Trans>
      </span>
    );
  }

  return null;
};

const ShipmentOrigin = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { originCountry } = useDisplayedShipmentCountry({ shipment });
  const { isMobile } = useViewPort();

  if (!shipment.origin) {
    return '-';
  }

  const date = shipment.departureDate ?? shipment.pickupDateDate;

  return (
    <Stack gap={isMobile ? undefined : '0.125rem'}>
      <Stack
        row={isMobile}
        alignItems={isMobile ? 'center' : 'flex-start'}
        gap={isMobile ? '0.25rem' : '0'}
      >
        <span className="paragraph-100-medium text-primary">{shipment.origin.name}</span>
        {isMobile && <span className="paragraph-200-regular text-secondary">•</span>}
        <span className="paragraph-200-regular text-secondary">
          {shipment.originAddress?.city}
          {originCountry ? `, ${originCountry?.name}` : ''}
        </span>
      </Stack>
      {date && (
        <span className="paragraph-200-regular text-secondary">
          {formatDate(date, { dateStyle: 'short' })}
        </span>
      )}
    </Stack>
  );
};

const ShipmentDestination = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { destinationCountry } = useDisplayedShipmentCountry({ shipment });
  const { isMobile } = useViewPort();

  if (!shipment.destination) {
    return '-';
  }

  return (
    <Stack gap={isMobile ? undefined : '0.125rem'}>
      <Stack
        row={isMobile}
        alignItems={isMobile ? 'center' : 'flex-start'}
        gap={isMobile ? '0.25rem' : '0'}
      >
        <span className="paragraph-100-medium">{shipment.destination.name}</span>
        {isMobile && <span className="paragraph-200-regular text-secondary">•</span>}
        <span className="paragraph-200-regular text-secondary">
          {shipment.destinationAddress?.city}
          {destinationCountry ? `, ${destinationCountry?.name}` : ''}
        </span>
      </Stack>
      <ArrivalDate shipment={shipment} />
    </Stack>
  );
};

const useDisplayedShipmentCountry = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const countries = useCountries();

  const areCountriesDifferent =
    shipment.originAddress?.country !== shipment.destinationAddress?.country;

  const originCountry =
    !!shipment.originAddress?.country && areCountriesDifferent
      ? countries.find((country) => country.code === shipment.originAddress?.country)
      : undefined;

  const destinationCountry =
    !!shipment.destinationAddress?.country && areCountriesDifferent
      ? countries.find((country) => country.code === shipment.destinationAddress?.country)
      : undefined;

  return {
    originCountry,
    destinationCountry,
  };
};
