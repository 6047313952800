import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { useShowToast } from '@/design_system/Toast';
import IconEmail from '@/icons/Email.svg';
import { useActivities } from '@/models/request';
import { formatDate } from '@/utils/date';

export const PendingClientActionReminder = ({
  id,
  activityType,
  mutationFunction,
}: {
  id: string;
  activityType: 'pending_validation_notified' | 'pending_bill_payment_notified';
  mutationFunction: () => { mutateAsync: (id: string) => Promise<void>; isPending: boolean };
}) => {
  const { _ } = useLingui();
  const showToast = useShowToast();
  const { mutateAsync: notifyPendingValidation, isPending: isLoading } = mutationFunction();

  const { data: { activities } = { activities: [] } } = useActivities({
    requestId: id,
    types: [activityType],
    limit: 1,
  });

  const halfAnHourAgo = new Date(Date.now() - 30 * 60 * 1000);

  const activityCreationDate = activities.length > 0 ? new Date(activities[0].createdAt) : null;
  const canSendReminder =
    activities.length === 0 || (activityCreationDate && activityCreationDate < halfAnHourAgo);

  return (
    <Stack alignItems="flex-start" gap="0.5rem">
      <Button
        variant="secondary"
        size="medium"
        onPress={() => {
          if (!canSendReminder) {
            showToast({
              text: _(
                msg({
                  id: 'pending-client-action-reminder.send-reminder.too-soon',
                  message:
                    'The last reminder was sent less than 30 minutes ago, please try again later',
                })
              ),
              type: 'warning',
            });
            return;
          }

          notifyPendingValidation(id)
            .then(() => {
              showToast({
                text: _(
                  msg({
                    id: 'pending-client-action-reminder.send-reminder.success',
                    message: 'Your email reminder has been successfully sent',
                  })
                ),
                type: 'success',
              });
            })
            .catch(() => {
              showToast({
                text: _(
                  msg({
                    id: 'pending-client-action-reminder.send-reminder.error',
                    message: 'An error occurred, please try again',
                  })
                ),
                type: 'error',
              });
            });
        }}
        disabled={isLoading}
        isLoading={isLoading}
      >
        <IconEmail />
        <Trans id="pending-client-action-reminder.send-reminder">Send a reminder</Trans>
      </Button>
      {activityCreationDate && (
        <Stack row gap="4px" className="paragraph-200-regular">
          <Trans id="pending-client-action-reminder.last-reminder">Last email sent on</Trans>
          <span className="paragraph-200-medium">
            <Trans id="pending-client-action-reminder.last-reminder-date">
              {formatDate(activityCreationDate, { dateStyle: 'short' })} at{' '}
              {formatDate(activityCreationDate, { timeStyle: 'short' })}
            </Trans>
          </span>
        </Stack>
      )}
    </Stack>
  );
};
