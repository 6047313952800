import { Activities } from '@/components/activities/Activities/Activities';
import { ShipmentWithRelations, useInfiniteActivities } from '@/models/shipment';

export const ShipmentActivities = ({ shipment }: { shipment: ShipmentWithRelations }) => {
  const { data, isLoading, isError, fetchNextPage } = useInfiniteActivities({
    shipmentId: shipment.id,
  });
  const activities = data?.pages.flatMap((page) => page.activities) ?? [];

  return (
    <Activities
      activities={activities}
      isLoading={isLoading}
      isError={isError}
      isShipment
      nbOfArticles={shipment.articles.length}
      loadNextPage={() => {
        fetchNextPage();
      }}
    />
  );
};
