import { CSSProperties, forwardRef, Ref, useEffect, useRef } from 'react';
import {
  Label,
  TextArea as AriaTextArea,
  TextAreaProps as AriaTextAreaProps,
  TextField,
} from 'react-aria-components';

import Message from '@/design_system/Message';
import { createBEMClasses } from '@/utils/classname';

import './TextArea.css';

const { block, element } = createBEMClasses('text-area');

export interface TextAreaProps extends AriaTextAreaProps {
  label?: string;
  isInvalid?: boolean;
  error?: string;
  ariaLabel?: string;
  onChange?: (value: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isReadOnly?: boolean;
  actions?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  textAreaStyle?: CSSProperties;
  scrollToAndFocusOnRender?: boolean;
  size?: 'medium' | 'large';
  isNativeTextarea?: boolean;
}

const TextArea = forwardRef(function TextArea(
  {
    isInvalid,
    error,
    value,
    onChange,
    label,
    ariaLabel,
    isReadOnly,
    actions,
    className,
    style,
    textAreaStyle,
    scrollToAndFocusOnRender,
    size = 'medium',
    isNativeTextarea,
    ...props
  }: TextAreaProps,
  refProp: Ref<HTMLTextAreaElement>
) {
  const containerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (scrollToAndFocusOnRender) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });

      if (refProp && 'current' in refProp) {
        refProp.current?.focus();
      } else {
        textAreaRef.current?.focus();
      }
    }
  }, [refProp, scrollToAndFocusOnRender]);

  const TextAreaElement = isNativeTextarea ? 'textarea' : AriaTextArea;

  return (
    <TextField
      className={element('wrapper', { size }, className)}
      isReadOnly={isReadOnly}
      aria-label={ariaLabel}
      style={style}
      ref={containerRef}
    >
      {label && (
        <Label className={size === 'large' ? 'paragraph-50-medium' : 'label-100'}>{label}</Label>
      )}
      <TextAreaElement
        className={block({ invalid: isInvalid || !!error })}
        value={value}
        onChange={(event) => onChange?.(event)}
        ref={refProp ?? textAreaRef}
        style={textAreaStyle}
        {...props}
      />
      {actions && <div className={element('actions')}>{actions}</div>}
      {!!error && <Message type="error">{error}</Message>}
    </TextField>
  );
});

export default TextArea;
