export abstract class Model {
  /* Can't use this constructor because of babel:
   * https://stackoverflow.com/questions/54977979/object-assign-with-inheritance-not-working-in-typescript
   */
  /* constructor(data: any) {
    Object.assign(this, data);
  } */

  /**
   * Add relations between resources
   *
   * Usage:
   * ```
   * const raw = await fetch('requests/:id');
   *
   * const request = new Request(raw)
   *  .with('creator', new User(raw.creator))
   *    .with('workshop', new Workshop(raw.creator.workshop));
   *
   * console.log(request.creator.workshop.isInternal);
   * ```
   */
  with<T extends object, K extends string, V>(this: T, key: K, value: V): T & { [key in K]: V } {
    return Object.assign(this, { [key]: value }) as T & { [key in K]: V };
  }
}
