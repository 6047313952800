import IconWrapper from './IconWrapper';

const IconExternalLink = IconWrapper({
  name: 'external-link',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.24576 3.26079C8.66157 3.252 9 3.58995 9 4.00586C9 4.41851 8.66658 4.75217 8.25403 4.76101C7.6832 4.77323 7.21075 4.79896 6.80812 4.8531C6.07435 4.95175 5.68578 5.1322 5.40901 5.40896C5.13225 5.68572 4.9518 6.07429 4.85315 6.80807C4.7516 7.56342 4.75 8.56453 4.75 9.99995V13.9999C4.75 15.4354 4.7516 16.4365 4.85315 17.1918C4.9518 17.9256 5.13225 18.3142 5.40901 18.5909C5.68578 18.8677 6.07435 19.0481 6.80812 19.1468C7.56347 19.2484 8.56459 19.2499 10 19.2499H14C15.4354 19.2499 16.4365 19.2484 17.1919 19.1468C17.9257 19.0481 18.3142 18.8677 18.591 18.5909C18.8678 18.3142 19.0482 17.9256 19.1469 17.1918C19.201 16.7892 19.2267 16.3168 19.2389 15.7459C19.2478 15.3334 19.5814 14.9999 19.9941 14.9999C20.41 14.9999 20.7479 15.3384 20.7392 15.7542C20.7261 16.3736 20.6972 16.9178 20.6335 17.3917C20.5125 18.2918 20.2536 19.0497 19.6517 19.6516C19.0497 20.2535 18.2919 20.5124 17.3918 20.6334C16.5248 20.75 15.4225 20.75 14.0549 20.7499H14H10H9.94513C8.57754 20.75 7.47522 20.75 6.60825 20.6334C5.70814 20.5124 4.95027 20.2535 4.34835 19.6516C3.74644 19.0497 3.48754 18.2918 3.36653 17.3917C3.24997 16.5247 3.24998 15.4224 3.25 14.0549V14.0549V14.0548L3.25 13.9999V9.99995L3.25 9.94507V9.94503V9.94502C3.24998 8.57745 3.24997 7.47515 3.36653 6.6082C3.48754 5.70809 3.74644 4.95021 4.34835 4.3483C4.95027 3.74638 5.70814 3.48749 6.60825 3.36647C7.08214 3.30276 7.62635 3.27388 8.24576 3.26079ZM20.6997 4.05022C20.6997 3.63601 20.364 3.30022 19.9497 3.30022H12.1716C11.7574 3.30022 11.4216 3.63601 11.4216 4.05022C11.4216 4.46444 11.7574 4.80022 12.1716 4.80022L18.1391 4.80022L9.51992 13.4194C9.22703 13.7123 9.22703 14.1872 9.51992 14.48C9.81282 14.7729 10.2877 14.7729 10.5806 14.48L19.1997 5.86088L19.1997 11.8284C19.1997 12.2426 19.5355 12.5784 19.9497 12.5784C20.364 12.5784 20.6997 12.2426 20.6997 11.8284V4.05022Z"
      />
    </svg>
  ),
});

export default IconExternalLink;
