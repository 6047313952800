import { MessageDescriptor } from '@lingui/core';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import type { DefaultError, QueryKey } from '@tanstack/query-core';
import {
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  ArticleArchivalDetail,
  ArticleDisplayedStep,
  ArticleServiceChoice,
  ArticleSteps,
  ArticleTask,
  BaseStepConfig,
  Endpoints,
  Permission,
  PriceAggregate,
  ProductL1,
  ProductL2,
  ProductL3,
} from '@/api';
import { useCurrentSession } from '@/services/auth';
import { useFetch } from '@/utils/fetch';
import { Currency } from '@/utils/number';

import { Medium } from './medium';
import { Model } from './model';
import { DEFAULT_COLORS, Product, PRODUCT_CATEGORIES_L2, PRODUCT_MATERIALS } from './product';
import {
  ArticleActionWithRelations,
  ArticleDefectWithRelations,
  ArticleSnapshotWithRelations,
  ArticleWithRelations,
  instanciateClientRequestWithRelations,
  instanciateRequestWithRelations,
  RequestWithRelations,
} from './request';
import { instanciateArticleWithRelations } from './shipment';

export class Article extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);

    this.requestId = (data.requestId ?? data.archivedRequestId)!;
  }

  id!: string;

  statusStartedAt!: string | null;
  statusDueAt!: string | null;
  task!: ArticleTask | null;

  hasIssue!: boolean;
  archivedAt!: string | null;
  archivedById!: string | null;
  archivalDetail!: ArticleArchivalDetail | null;

  serviceChoice!: ArticleServiceChoice | null;

  serviceChosenAt!: string | null;
  dispatchedAt!: string | null;
  workshopAcceptedAt!: string | null;
  quoteAcceptedAt!: string | null;
  quoteRefusedAt!: string | null;
  analyzedAt!: string | null;
  requalifiedAt!: string | null;
  requalificationValidatedAt!: string | null;
  requalificationRefusedAt!: string | null;
  repairedAt!: string | null;
  paymentAcceptedAt!: string | null;
  paymentRefusedAt!: string | null;
  freeFinalQuoteAcceptedAt!: string | null;

  cancelledAt!: Date | null;
  cancellationDetail!: ArticleCancellationDetail | null;

  paidAt!: string | null;

  step!: BaseStepConfig | null;
  steps?: ArticleSteps;
  data!: ArticleData;

  productL1!: ProductL1 | null;
  productL2!: ProductL2 | null;
  productL3!: ProductL3 | null;

  currentCostManualDiscount!: number | null;
  currentPriceManualDiscount!: number | null;

  purchaseDate!: string | null;
  warranty!: boolean;
  clientComment!: string | null;

  requalificationComment!: string | null;
  requalificationAnalysisComment!: string | null;

  productId!: string | null;
  requestId!: string;
  archivedRequestId!: string | null;
  workshopId!: string | null;
  organizationId!: string;

  atClient!: boolean;
  atStoreId!: string | null;
  atWorkshopId!: string | null;
  toClient!: boolean;
  toStoreId!: string | null;
  toWorkshopId!: string | null;
  inTransit!: boolean;
  inTransitVerification!: boolean;
  areCustomsExpected!: boolean;
  wentThroughCustoms!: boolean;

  createdAt!: string;

  get articlePhoto() {
    if ('media' in this) {
      return (this.media as Medium[]).find((medium) => medium.type === 'article-photo');
    } else {
      return undefined;
    }
  }

  get archived() {
    return !!this.archivedAt;
  }

  get cancelled() {
    return !!this.cancelledAt;
  }

  get completed() {
    return this.archived && this.archivalDetail?.type === 'completed';
  }

  get createdAtDate() {
    return new Date(this.createdAt);
  }

  get archivedAtDate() {
    return this.archivedAt ? new Date(this.archivedAt) : null;
  }

  get statusDueAtDate() {
    return this.statusDueAt ? new Date(this.statusDueAt) : null;
  }

  get displayedStep(): ArticleDisplayedStep | null {
    if (!this.step) {
      return null;
    }

    return getDisplayedStep(this.step);
  }

  get estimatedDueAtDate() {
    if (this.archivedAt) {
      return null;
    }

    if (this.steps) {
      const stepsWithDueDate = this.steps.filter((step) => !!step.dueDate);

      if (stepsWithDueDate.length > 0) {
        return new Date(stepsWithDueDate.at(-1)!.dueDate!);
      }
    }

    return null;
  }

  get numberOfDefects() {
    if ('snapshot' in this) {
      const snapshot = this.snapshot as ArticleSnapshotWithRelations;

      return snapshot.articleDefects.length;
    }

    return 0;
  }

  get hasDefects() {
    return this.numberOfDefects > 0;
  }

  get numberOfActions() {
    if ('snapshot' in this) {
      const snapshot = this.snapshot as ArticleSnapshotWithRelations;

      return snapshot.articleActions.length;
    }

    return 0;
  }

  get hasActions() {
    return this.numberOfActions > 0;
  }

  get hasRequiredCustomsInfo() {
    if ('product' in this) {
      const product = this.product as Product | undefined;

      return (
        (product?.data.customsValue || this.data.customsValue) &&
        (product?.data.madein || this.data.madein) &&
        (product?.data.tariffCode || this.data.tariffCode) &&
        (product?.data.mid || this.data.mid)
      );
    }

    return false;
  }
}

export class ArticleSnapshot extends Model {
  constructor(data: any) {
    super();
    Object.assign(this, data);
  }

  price?: PriceAggregate;
  cost?: PriceAggregate;
}

type GetArticleNameProps = {
  article?: Pick<Article, 'data' | 'productL2' | 'productL3'> & {
    product: { name: string } | null;
  };
  product?: { name: string };
  color?: string;
  productL3?: ProductL3;
  productL2?: ProductL2;
  type?: 'short' | 'full';
};

export const useGetArticleName = () => {
  const { _ } = useLingui();

  return ({
    article,
    product: productProp,
    color: colorProp,
    productL3: productL3Prop,
    productL2: productL2Prop,
    type = 'full',
  }: GetArticleNameProps) => {
    const product = article?.product ?? productProp;
    const color = article?.data.color ?? colorProp;
    const productL3 = article?.productL3 ?? productL3Prop;
    const productL2 = article?.productL2 ?? productL2Prop;

    if (product) {
      return product.name;
    }

    const productL2Label = PRODUCT_CATEGORIES_L2.find((l2) => l2.id === productL2)?.labelOne;
    const productL2String = productL2Label ? _(productL2Label) : undefined;

    if (!productL2String) {
      return _(msg({ id: 'article.name.unnamed', message: 'Unnamed item' }));
    }

    if (type === 'short') {
      return productL2String;
    }

    const colorLabel = DEFAULT_COLORS.find(({ id }) => id === color)?.label;
    const colorString = colorLabel ? _(colorLabel) : color;

    const productL3Label = PRODUCT_MATERIALS.find((l3) => l3.id === productL3)?.label;
    const productL3String = productL3Label ? _(productL3Label) : undefined;

    if (productL3String && colorString) {
      return `${productL2String} (${productL3String}, ${colorString})`;
    } else if (productL3String || colorString) {
      return `${productL2String} (${productL3String ?? colorString})`;
    } else {
      return productL2String;
    }
  };
};

export const useArticleName = (props: GetArticleNameProps) => {
  const getArticleName = useGetArticleName();

  return getArticleName(props);
};

export type ArticleBrand = { isOther: boolean; name: string };

export type ArticleData = {
  size?: string;
  color?: string;
  brand?: ArticleBrand;
  madein?: string;
  customsValue?: {
    amount: number;
    currency: Currency;
  };
  tariffCode?: string;
  mid?: string;
};

export const ARTICLE_DISPLAYED_STEPS = [
  {
    id: 'service-choice',
    name: msg({ id: 'article.step.service-choice', message: 'Service Choice' }),
  },
  {
    id: 'validation',
    name: msg({ id: 'article.step.validation', message: 'Validation' }),
  },
  {
    id: 'transit',
    name: msg({ id: 'article.step.transit', message: 'Transit' }),
  },
  {
    id: 'analysis',
    name: msg({ id: 'article.step.analysis', message: 'Analysis' }),
  },
  {
    id: 'payment',
    name: msg({ id: 'article.step.payment', message: 'Payment' }),
  },
  {
    id: 'repair',
    name: msg({ id: 'article.step.repair', message: 'Repair' }),
  },
  {
    id: 'delivery',
    name: msg({ id: 'article.step.delivery', message: 'Delivery' }),
  },
] satisfies { id: ArticleDisplayedStep; name: MessageDescriptor }[];

export const getDisplayedStep = (step: BaseStepConfig): ArticleDisplayedStep | null => {
  if (step.step === 'creation' || step.step === 'archival') {
    return null;
  }

  if (
    step.step === 'transit' &&
    (step.config.destinationType === 'client' ||
      (step.config.originType === 'workshop' && step.config.destinationType === 'store'))
  ) {
    return 'delivery';
  }

  return step.step;
};

export const ARTICLE_CANCELLATION_REASONS = [
  {
    id: 'too-expensive',
    label: msg({
      id: 'article.archival.reason.too-expensive',
      message: 'The estimate is too expensive',
    }),
  },
  {
    id: 'no-longer-want-repair',
    label: msg({
      id: 'article.archival.reason.no-longer-want-repair',
      message: 'I no longer wish to have it repaired',
    }),
  },
  {
    id: 'found-another-solution',
    label: msg({
      id: 'article.archival.reason.found-another-solution',
      message: 'I found another solution',
    }),
  },
  {
    id: 'do-not-wish-to-answer',
    label: msg({
      id: 'article.archival.reason.do-not-wish-to-answer',
      message: 'I do not wish to answer',
    }),
  },
  {
    id: 'other',
    label: msg({ id: 'article.archival.reason.other', message: 'Other reason, please specify' }),
  },
] as const;
export type ArticleCancellationReason = (typeof ARTICLE_CANCELLATION_REASONS)[number]['id'];
export const ARTICLE_CANCELLATION_TYPES = [
  {
    id: 'requestor_refusal',
    name: msg({
      id: 'article.cancellation-type.requestor_refusal',
      message: 'Client refusal',
    }),
    color: 'black',
  },
] as const;
export type ArticleCancellationType = (typeof ARTICLE_CANCELLATION_TYPES)[number]['id'];

export const ARTICLE_ARCHIVAL_TYPES = [
  {
    id: 'manual',
    name: msg({ id: 'article.archival-type.manual', message: 'Cancelled' }),
    color: 'red',
  },
  {
    id: 'automatic',
    name: msg({ id: 'article.archival-type.automatic', message: 'Cancelled' }),
    color: 'red',
  },
  {
    id: 'completed',
    name: msg({ id: 'article.archival-type.completed', message: 'Completed' }),
    color: 'green',
  },
] as const;

export type ArticleArchivalType = (typeof ARTICLE_ARCHIVAL_TYPES)[number]['id'];

export type ArticleCancellationDetail = {
  type: ArticleCancellationType;
  reason?: ArticleCancellationReason;
  otherReason?: string;
};

export const useShipmentArticles = (
  params: {
    limit?: number;
    offset?: number;
    search?: string;
    originClient?: string;
    originStore?: string;
    originWorkshop?: string;
    destinationClient?: string;
    destinationStore?: string;
    destinationWorkshop?: string;
  },
  options?: {
    keepPreviousData?: boolean;
    enabled?: boolean;
  }
) => {
  const fetch = useFetch<Endpoints['GET /shipments/articles']>();

  return useQuery({
    queryKey: ['shipments/articles', params],
    queryFn: () =>
      fetch('/shipments/articles', params).then(({ articles, meta }) => ({
        articles: articles.map(instanciateArticleWithRelations),
        meta: meta,
      })),
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
    enabled: options?.enabled,
  });
};

export const useCreateAction = ({
  articleId,
  requestId,
}: {
  articleId: string;
  requestId: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/actions']>();
  const { currentSession } = useCurrentSession();
  const requestQueryKey = ['requests', requestId];

  return useMutation({
    mutationFn: ({
      actionTypeOrganization,
      packActionTypeOrganization,
      ...body
    }: Omit<
      Endpoints['POST /articles/:id/actions']['body'],
      'actionTypeOrganizationId' | 'packActionTypeOrganizationId'
    > & {
      actionTypeOrganization?: ArticleActionWithRelations['actionTypeOrganization'];
      packActionTypeOrganization?: ArticleActionWithRelations['packActionTypeOrganization'];
    }) => {
      const currentRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);

      if (currentRequest) {
        const updatedRequest = {
          ...currentRequest,
          articles: currentRequest.articles.map((article) =>
            article.id === articleId
              ? {
                  ...article,
                  snapshot: {
                    ...article.snapshot,
                    articleActions: [
                      ...article.snapshot.articleActions,
                      {
                        ...body,
                        id: crypto.randomUUID(),
                        brandResponsibility: body.brandResponsibility ?? false,
                        createdAt: new Date().toISOString(),
                        actionTypeOrganizationId: actionTypeOrganization?.id ?? null,
                        actionTypeOrganization: actionTypeOrganization ?? null,
                        packActionTypeOrganizationId: packActionTypeOrganization?.id ?? null,
                        packActionTypeOrganization: packActionTypeOrganization ?? null,
                        priceRefashionBonusDetail: [],
                        customDescription: body.description ?? null,
                        manualOrganizationPrice: body.manualOrganizationPrice ?? null,
                        manualWorkshopPrice: body.manualWorkshopPrice ?? null,
                        articleDefects: [],
                      },
                    ],
                  },
                }
              : article
          ),
        };

        queryClient.setQueryData(
          requestQueryKey,
          currentSession
            ? instanciateRequestWithRelations(updatedRequest)
            : instanciateClientRequestWithRelations(updatedRequest)
        );
      }

      return fetch(`/articles/${articleId}/actions`, undefined, {
        method: 'POST',
        body: {
          ...body,
          actionTypeOrganizationId: actionTypeOrganization?.id,
          packActionTypeOrganizationId: packActionTypeOrganization?.id,
        },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests', requestId] });
      queryClient.invalidateQueries({ queryKey: ['workshops'] });
    },
  });
};

export const useUpdateAction = ({
  articleId,
  requestId,
}: {
  articleId: string;
  requestId: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /articles/:id/actions/:actionId']>();
  const { currentSession } = useCurrentSession();
  const requestQueryKey = ['requests', requestId];

  return useMutation({
    mutationFn: ({
      actionId,
      body,
    }: {
      actionId: string;
      body: Endpoints['PATCH /articles/:id/actions/:actionId']['body'];
    }) => {
      const currentRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);

      if (currentRequest) {
        const updatedRequest = {
          ...currentRequest,
          articles: currentRequest.articles.map((article) =>
            article.id === articleId
              ? {
                  ...article,
                  snapshot: {
                    ...article.snapshot,
                    articleActions: article.snapshot.articleActions.map((action) =>
                      action.id === actionId
                        ? {
                            ...action,
                            ...body,
                            customDescription: body.description ?? null,
                          }
                        : action
                    ),
                  },
                }
              : article
          ),
        };

        queryClient.setQueryData(
          requestQueryKey,
          currentSession
            ? instanciateRequestWithRelations(updatedRequest)
            : instanciateClientRequestWithRelations(updatedRequest)
        );
      }

      return fetch(`/articles/${articleId}/actions/${actionId}`, undefined, {
        method: 'PATCH',
        body,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: requestQueryKey });
      queryClient.invalidateQueries({ queryKey: ['workshops'] });
      queryClient.invalidateQueries({ queryKey: ['media'] });
    },
  });
};

export const useDeleteAction = ({
  articleId,
  requestId,
}: {
  articleId: string;
  requestId: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['DELETE /articles/:id/actions/:actionId']>();
  const { currentSession } = useCurrentSession();
  const requestQueryKey = ['requests', requestId];

  return useMutation({
    mutationFn: (actionId: string) => {
      const currentRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);

      if (currentRequest) {
        const updatedRequest = {
          ...currentRequest,
          articles: currentRequest.articles.map((article) =>
            article.id === articleId
              ? {
                  ...article,
                  snapshot: {
                    ...article.snapshot,
                    articleActions: article.snapshot.articleActions.filter(
                      (action) => action.id !== actionId
                    ),
                  },
                }
              : article
          ),
        };

        queryClient.setQueryData(
          requestQueryKey,
          currentSession
            ? instanciateRequestWithRelations(updatedRequest)
            : instanciateClientRequestWithRelations(updatedRequest)
        );
      }

      return fetch(`/articles/${articleId}/actions/${actionId}`, undefined, {
        method: 'DELETE',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests', requestId] });
      queryClient.invalidateQueries({ queryKey: ['workshops'] });
    },
  });
};

export const useCreateDefect = ({
  articleId,
  requestId,
}: {
  articleId: string;
  requestId: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:articleId/defects']>();
  const { currentSession } = useCurrentSession();
  const requestQueryKey = ['requests', requestId];

  return useMutation({
    mutationFn: ({
      defectTypeOrganization,
      ...body
    }: Omit<Endpoints['POST /articles/:articleId/defects']['body'], 'defectTypeOrganizationId'> & {
      defectTypeOrganization?: ArticleDefectWithRelations['defectTypeOrganization'];
    }) => {
      const currentRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);

      if (currentRequest) {
        const updatedRequest = {
          ...currentRequest,
          articles: currentRequest.articles.map((article) =>
            article.id === articleId
              ? {
                  ...article,
                  snapshot: {
                    ...article.snapshot,
                    articleDefects: [
                      ...article.snapshot.articleDefects,
                      {
                        ...body,
                        id: crypto.randomUUID(),
                        isReparable: body.isReparable ?? true,
                        createdAt: new Date().toISOString(),
                        defectTypeOrganizationId: defectTypeOrganization?.id ?? null,
                        defectTypeOrganization: defectTypeOrganization ?? null,
                        customDescription: body.description ?? null,
                        articleActions: [],
                      },
                    ],
                  },
                }
              : article
          ),
        };

        queryClient.setQueryData(
          requestQueryKey,
          currentSession
            ? instanciateRequestWithRelations(updatedRequest)
            : instanciateClientRequestWithRelations(updatedRequest)
        );
      }

      return fetch(`/articles/${articleId}/defects`, undefined, {
        method: 'POST',
        body: {
          ...body,
          defectTypeOrganizationId: defectTypeOrganization?.id,
        },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests', requestId] });
    },
  });
};

export const useUpdateDefect = ({
  articleId,
  requestId,
}: {
  articleId: string;
  requestId: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /articles/:articleId/defects/:defectId']>();
  const { currentSession } = useCurrentSession();
  const requestQueryKey = ['requests', requestId];

  return useMutation({
    mutationFn: ({
      defectId,
      body,
    }: {
      defectId: string;
      body: Endpoints['PATCH /articles/:articleId/defects/:defectId']['body'];
    }) => {
      const currentRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);

      if (currentRequest) {
        const updatedRequest = {
          ...currentRequest,
          articles: currentRequest.articles.map((article) =>
            article.id === articleId
              ? {
                  ...article,
                  snapshot: {
                    ...article.snapshot,
                    articleDefects: article.snapshot.articleDefects.map((defect) =>
                      defect.id === defectId
                        ? {
                            ...defect,
                            ...body,
                            customDescription: body.description ?? null,
                          }
                        : defect
                    ),
                  },
                }
              : article
          ),
        };

        queryClient.setQueryData(
          requestQueryKey,
          currentSession
            ? instanciateRequestWithRelations(updatedRequest)
            : instanciateClientRequestWithRelations(updatedRequest)
        );
      }

      return fetch(`/articles/${articleId}/defects/${defectId}`, undefined, {
        method: 'PATCH',
        body,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: requestQueryKey });
      queryClient.invalidateQueries({ queryKey: ['media'] });
    },
  });
};

export const useDeleteDefect = ({
  articleId,
  requestId,
}: {
  articleId: string;
  requestId: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['DELETE /articles/:articleId/defects/:defectId']>();
  const { currentSession } = useCurrentSession();
  const requestQueryKey = ['requests', requestId];

  return useMutation({
    mutationFn: (defectId: string) => {
      const currentRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);

      if (currentRequest) {
        const updatedRequest = {
          ...currentRequest,
          articles: currentRequest.articles.map((article) =>
            article.id === articleId
              ? {
                  ...article,
                  snapshot: {
                    ...article.snapshot,
                    articleDefects: article.snapshot.articleDefects.filter(
                      (defect) => defect.id !== defectId
                    ),
                  },
                }
              : article
          ),
        };

        queryClient.setQueryData(
          requestQueryKey,
          currentSession
            ? instanciateRequestWithRelations(updatedRequest)
            : instanciateClientRequestWithRelations(updatedRequest)
        );
      }

      return fetch(`/articles/${articleId}/defects/${defectId}`, undefined, {
        method: 'DELETE',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests', requestId] });
      queryClient.invalidateQueries({ queryKey: ['workshops'] });
    },
  });
};

export const useUpdateArticle = (
  {
    articleId,
    requestId,
    shipmentId,
  }: {
    articleId: string;
    requestId: string;
    shipmentId?: string;
  },
  {
    optimistic = true,
  }: {
    optimistic?: boolean;
  } = {}
) => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  const requestQueryKey = ['requests', requestId];
  const shipmentQueryKey = ['shipments', shipmentId];

  return useMutation({
    mutationKey: ['articles', articleId],
    mutationFn: ({
      data,
      optimisticData,
    }: {
      data: {
        productId?: string | null;
        purchaseDate?: string;
        warranty?: boolean;
        costManualDiscount?: number | null;
        priceManualDiscount?: number | null;
        data?: {
          size?: string;
          color?: string;
          brand?: ArticleBrand;
          madein?: string;
          customsValue?: {
            amount: number;
            currency: Currency;
          };
          tariffCode?: string;
          mid?: string;
        };
        clientComment?: string | null;
        requalificationWorkshopComment?: string | null;
        requalificationAfterSalesComment?: string | null;
        productL1?: ProductL1 | null;
        productL2?: ProductL2 | null;
        productL3?: ProductL3 | null;
        proofOfPurchaseIds?: string[];
        defectPhotoIds?: string[];
      };
      optimisticData?: Partial<ArticleWithRelations>;
    }) => {
      const body: any = { ...data };

      /* Optimistic update */
      const currentRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);
      if (currentRequest && optimistic) {
        const newRequest = instanciateRequestWithRelations({
          ...currentRequest,
          articles: currentRequest?.articles.map((article) =>
            article.id === articleId ? { ...article, ...data, ...optimisticData } : article
          ),
        });

        queryClient.setQueryData(requestQueryKey, newRequest);
      }

      return fetch(`/articles/${articleId}`, undefined, {
        method: 'PATCH',
        body,
      });
    },
    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries({ queryKey: requestQueryKey });
      queryClient.invalidateQueries({ queryKey: ['activities'] });

      if (!!variables.data?.proofOfPurchaseIds || !!variables.data?.defectPhotoIds) {
        queryClient.invalidateQueries({ queryKey: ['media'] });
      }

      if (shipmentId) {
        queryClient.invalidateQueries({ queryKey: shipmentQueryKey });
      }
    },
  });
};

export const useDeleteArticle = () => {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  return useMutation({
    mutationFn: (id: string) => fetch(`/articles/${id}`, undefined, { method: 'DELETE' }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['requests'] }),
  });
};

export type ArticleActivity =
  Endpoints['GET /articles/:id/activities']['response']['activities'][number];
export type ArticleActivityOfType<T extends ArticleActivity['type']> = Extract<
  ArticleActivity,
  { type: T }
>;
export const useActivities = <T extends ArticleActivity['type']>({
  articleId,
  ...params
}: {
  articleId: string;
  limit?: number;
  types?: T[];
}) => {
  const fetch = useFetch<Endpoints['GET /articles/:id/activities']>();

  return useQuery({
    queryKey: ['activities', articleId, params],
    queryFn: () =>
      fetch<{
        activities: ArticleActivityOfType<T>[];
        meta: Endpoints['GET /articles/:id/activities']['response']['meta'];
      }>(`/articles/${articleId}/activities`, params),
  });
};

export const useInfiniteActivities = ({ articleId }: { articleId: string }) => {
  const fetch = useFetch<Endpoints['GET /articles/:id/activities']>();

  return useInfiniteQuery<
    Endpoints['GET /articles/:id/activities']['response'],
    DefaultError,
    InfiniteData<Endpoints['GET /articles/:id/activities']['response']>,
    QueryKey,
    string | null
  >({
    queryFn: ({ pageParam }) => {
      const requestParams = pageParam ? { before: pageParam } : undefined;

      return fetch(`/articles/${articleId}/activities`, requestParams);
    },
    queryKey: ['activities', articleId],
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.meta.next,
  });
};

export const useUpdateServiceChoice = ({
  requestId,
  articleId,
}: {
  requestId: string;
  articleId: string;
}) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['PATCH /articles/:id/service-choice']>();

  const requestQueryKey = ['requests', requestId];

  return useMutation({
    mutationFn: ({ workshopId }: Endpoints['PATCH /articles/:id/service-choice']['body']) => {
      /* Optimistic update */
      const currentRequest = queryClient.getQueryData<RequestWithRelations>(requestQueryKey);
      if (currentRequest) {
        const newRequest = instanciateRequestWithRelations({
          ...currentRequest,
          articles: currentRequest?.articles.map((article) =>
            article.id === articleId ? { ...article, workshopId: workshopId ?? null } : article
          ),
        });

        queryClient.setQueryData(requestQueryKey, newRequest);
      }

      return fetch(`/articles/${articleId}/service-choice`, undefined, {
        method: 'PATCH',
        body: {
          workshopId,
        },
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: requestQueryKey }),
  });
};

export const useValidateServiceChoice = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/validate-service-choice']>();

  return useMutation({
    mutationFn: () =>
      fetch(`/articles/${articleId}/validate-service-choice`, undefined, { method: 'POST' }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['requests'] }),
  });
};

export const useAcceptDispatch = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/accept-dispatch']>();

  return useMutation({
    mutationFn: () =>
      fetch(`/articles/${articleId}/accept-dispatch`, undefined, { method: 'POST' }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['requests'] }),
  });
};

export const useRefuseDispatch = ({ article }: { article: Article }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/refuse-dispatch']>();

  return useMutation({
    mutationFn: (body: Endpoints['POST /articles/:id/refuse-dispatch']['body']) =>
      fetch(`/articles/${article.id}/refuse-dispatch`, undefined, {
        method: 'POST',
        body,
      }),
    onSettled: () => {
      // After refusing the job, there is no need to refresh the request
      // because the user is redirected to the /requests route
      queryClient.removeQueries({ queryKey: ['requests', article.requestId] });
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useAnalyze = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/analyze']>();

  return useMutation({
    mutationFn: () => fetch(`/articles/${articleId}/analyze`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useRepair = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/repair']>();

  return useMutation({
    mutationFn: () => fetch(`/articles/${articleId}/repair`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useRequalify = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/requalify']>();

  return useMutation({
    mutationFn: (data: Endpoints['POST /articles/:id/requalify']['body']) =>
      fetch(`/articles/${articleId}/requalify`, undefined, { method: 'POST', body: data }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useDiscardRequalification = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/discard-requalification']>();

  return useMutation({
    mutationFn: () =>
      fetch(`/articles/${articleId}/discard-requalification`, undefined, { method: 'POST' }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
  });
};

export const useRefuseRequalification = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/refuse-requalification']>();

  return useMutation({
    mutationFn: (data: Endpoints['POST /articles/:id/refuse-requalification']['body']) =>
      fetch(`/articles/${articleId}/refuse-requalification`, undefined, {
        method: 'POST',
        body: data,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useValidateRequalification = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/validate-requalification']>();

  return useMutation({
    mutationFn: () =>
      fetch(`/articles/${articleId}/validate-requalification`, undefined, {
        method: 'POST',
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const useCompleteClientPickup = ({ articleId }: { articleId: string }) => {
  const queryClient = useQueryClient();
  const fetch = useFetch<Endpoints['POST /articles/:id/complete-client-pickup']>();

  return useMutation({
    mutationFn: () =>
      fetch(`/articles/${articleId}/complete-client-pickup`, undefined, {
        method: 'POST',
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['activities'] });
    },
  });
};

export const ARTICLE_TASK_TYPES = [
  {
    id: 'validate_external_payment',
    label: msg({
      id: 'article.task.type.validate_external_payment',
      message: 'Payment',
    }),
    permissions: ['validate_external_payment'],
  },
  {
    id: 'choose_article_service',
    label: msg({ id: 'article.task.type.choose_article_service', message: 'Service choice' }),
    permissions: ['choose_article_service'],
  },
  {
    id: 'accept_dispatch',
    label: msg({
      id: 'article.task.type.accept_dispatch',
      message: 'Dispatch (Job review)',
    }),
    permissions: ['accept_dispatch'],
  },
  {
    id: 'create_shipment',
    label: msg({ id: 'article.task.type.create_shipment', message: 'Shipment creation' }),
    permissions: ['create_shipment_from_origin'],
  },
  {
    id: 'prepare_shipment',
    label: msg({
      id: 'article.task.type.prepare_shipment',
      message: 'Shipment preparation',
    }),
    permissions: ['create_shipment_from_origin'],
  },
  {
    id: 'finalise_shipment',
    label: msg({
      id: 'article.task.type.finalise_shipment',
      message: 'Shipment finalisation',
    }),
    permissions: ['create_shipment_from_origin'],
  },
  {
    id: 'dropoff_shipment',
    label: msg({ id: 'article.task.type.dropoff_shipment', message: 'Shipment dropoff' }),
    permissions: ['create_shipment_from_origin'],
  },
  {
    id: 'verify_shipment_reception',
    label: msg({
      id: 'article.task.type.verify_shipment_reception',
      message: 'Shipment verification',
    }),
    permissions: ['verify_shipment_reception'],
  },
  {
    id: 'handle_shipment_issue',
    label: msg({
      id: 'article.task.type.handle_shipment_issue',
      message: 'Shipment issue',
    }),
    labelVariant: 'danger',
    permissions: [
      'verify_shipment_reception',
      'create_shipment_from_origin',
      'view_all_shipments_issues',
    ],
  },
  {
    id: 'analyze_article',
    label: msg({ id: 'article.task.type.analyze_article', message: 'Analysis' }),
    permissions: ['analyze_article'],
  },
  {
    id: 'accept_requalification',
    label: msg({
      id: 'article.task.type.accept_requalification',
      message: 'Re-qualification analysis',
    }),
    permissions: ['accept_requalification'],
  },
  {
    id: 'repair_article',
    label: msg({ id: 'article.task.type.repair_article', message: 'Repair' }),
    permissions: ['repair_article'],
  },
] satisfies {
  id: ArticleTask['type'];
  label: MessageDescriptor;
  labelVariant?: 'danger';
  permissions: Permission[];
}[];
