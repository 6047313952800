import IconWrapper from './IconWrapper';

const IconCancel = IconWrapper({
  name: 'cancel',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM8.21967 8.21967C8.51256 7.92678 8.98744 7.92678 9.28033 8.21967L12.001 10.9403L14.7216 8.21967C15.0145 7.92678 15.4894 7.92678 15.7823 8.21967C16.0752 8.51256 16.0752 8.98744 15.7823 9.28033L13.0616 12.001L15.7819 14.7212C16.0748 15.0141 16.0748 15.489 15.7819 15.7819C15.489 16.0748 15.0141 16.0748 14.7212 15.7819L12.001 13.0616L9.28071 15.7819C8.98781 16.0748 8.51294 16.0748 8.22005 15.7819C7.92715 15.489 7.92715 15.0141 8.22005 14.7212L10.9403 12.001L8.21967 9.28033C7.92678 8.98744 7.92678 8.51256 8.21967 8.21967Z"
      />
    </svg>
  ),
});

export default IconCancel;
