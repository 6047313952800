import { type NotificationCategory, type UserNotificationSettings } from '@/models/user';

export type SelectionState = 'selected' | 'unselected' | 'indeterminate';

type SelectionStateByCategory = { [K in NotificationCategory]: SelectionState };
type SelectionStateByCategoryIncludingAllEmails = {
  [K in NotificationCategory | 'all_emails']: SelectionState;
};

/**
 * For each notification category, return if email is selected, unselected or indeterminate (for all_emails category)
 */
export const getEmailSelectionStateByCategory = (
  userNotificationSettings: UserNotificationSettings
): SelectionStateByCategoryIncludingAllEmails => {
  const emailSelectionStateByCategory: SelectionStateByCategory = {
    new_tasks: userNotificationSettings.new_tasks.includes('email') ? 'selected' : 'unselected',
    task_reminders: userNotificationSettings.task_reminders.includes('email')
      ? 'selected'
      : 'unselected',
    task_alerts: userNotificationSettings.task_alerts.includes('email') ? 'selected' : 'unselected',
    new_comments: userNotificationSettings.new_comments.includes('email')
      ? 'selected'
      : 'unselected',
    new_assignations: userNotificationSettings.new_assignations.includes('email')
      ? 'selected'
      : 'unselected',
    new_events: userNotificationSettings.new_events.includes('email') ? 'selected' : 'unselected',
  };

  const allEmailsSelectionState = getAllEmailsSelectionState(emailSelectionStateByCategory);

  return { ...emailSelectionStateByCategory, all_emails: allEmailsSelectionState };
};

/**
 * Get the selection state of all_emails notification category depending on the
 * selection state of all other notification categories
 */
export const getAllEmailsSelectionState = (
  emailSelectionStateByCategory: SelectionStateByCategory
): SelectionState => {
  const selectedEmailCategories: NotificationCategory[] = [];
  const unselectedEmailCategories: NotificationCategory[] = [];

  for (const [notificationCategory, selectionState] of Object.entries(
    emailSelectionStateByCategory
  )) {
    if (selectionState === 'selected') {
      selectedEmailCategories.push(notificationCategory as NotificationCategory);
    } else if (selectionState === 'unselected') {
      unselectedEmailCategories.push(notificationCategory as NotificationCategory);
    }
  }

  if (selectedEmailCategories.length && !unselectedEmailCategories.length) {
    return 'selected';
  } else if (unselectedEmailCategories.length && !selectedEmailCategories.length) {
    return 'unselected';
  } else {
    return 'indeterminate';
  }
};
