import { Trans } from '@lingui/macro';

import { CardItem } from '@/components/Card/Card';
import Stack from '@/design_system/Stack';
import IconStoreRepair from '@/icons/StoreRepair.svg';
import { ArticleWithRelations } from '@/models/request';
import { formatCurrency } from '@/utils/number';

const ArticleWorkshopCell = ({
  article,
  showWorkshopPrice,
}: {
  article: ArticleWithRelations;
  showWorkshopPrice?: boolean;
}) => {
  if (!article.workshop) {
    return <p className="paragraph-100-medium">-</p>;
  }

  const workshopCost = article.snapshot.cost?.amountPerEntity.find(
    (amount) => amount.entityId === article.workshopId
  );

  return (
    <Stack style={{ minWidth: 0 }}>
      <p className="paragraph-100-medium text-ellipsis" title={article.workshop.name}>
        {article.workshop.name}
      </p>
      <p className="paragraph-200-regular text-secondary text-ellipsis">
        {article.workshop.external && (
          <>
            <Trans id="workshop.external">External</Trans>
            {article.workshop.address?.city && ' • '}
          </>
        )}
        {article.workshop.address?.city}
        {showWorkshopPrice && !!workshopCost && (
          <>
            {' '}
            •{' '}
            {workshopCost.cancelled ? (
              <s className="text-disabled">
                {formatCurrency(workshopCost.amount, workshopCost.currency)}
              </s>
            ) : (
              formatCurrency(workshopCost.amount, workshopCost.currency)
            )}
          </>
        )}
      </p>
    </Stack>
  );
};

export default ArticleWorkshopCell;

export const ArticleWorkshopCardItem = ({ article }: { article: ArticleWithRelations }) => {
  return (
    <CardItem>
      <IconStoreRepair style={{ fontSize: '1rem' }} />
      <span>{article.workshop?.name ?? '-'}</span>
    </CardItem>
  );
};
