import isNil from 'lodash/isNil';

import { PriceAmountWithDetails } from '@/api';
import { getBrowserLocale } from '@/services/i18n';
export const formatNumber = (value?: number | null, options: Intl.NumberFormatOptions = {}) => {
  if (isNil(value)) {
    return '-';
  }

  const locale = getBrowserLocale();

  return new Intl.NumberFormat(locale, options).format(value);
};

export const formatWeight = (value?: number | null) => {
  if (isNil(value)) {
    return '-';
  }

  return formatNumber(value / 1000, { style: 'unit', unit: 'kilogram', unitDisplay: 'short' });
};

/**
 * Extract from the price:
 *  - the total cancellation fees amount,
 *  - the total shipping management fees amount
 *  - the total fees amount
 *  - the initial amount without fees
 *
 * @param price {PriceAmountWithDetails} The price to extract fees from
 * @returns {Object} An object containing the extracted fees and the initial amount without fees
 */
export const extractFeesFromPrice = (
  price: PriceAmountWithDetails | undefined
): {
  cancellationFeesAmount: number | undefined;
  shippingManagementFeesAmount: number | undefined;
  totalFeesAmount: number | undefined;
  initialAmountWithoutFees: number | undefined;
} => {
  const cancellationFeesAmount = price?.details
    .filter(
      (detail) => detail.type === 'fee' && 'subType' in detail && detail.subType === 'cancellation'
    )
    .reduce((accumulator, currentDetail) => accumulator + currentDetail.amount, 0);

  const shippingManagementFeesAmount = price?.details
    .filter(
      (detail) =>
        detail.type === 'fee' && 'subType' in detail && detail.subType === 'shipping-management'
    )
    .reduce((accumulator, currentDetail) => accumulator + currentDetail.amount, 0);

  const totalFeesAmount = [cancellationFeesAmount, shippingManagementFeesAmount]
    .filter((fee) => fee !== undefined)
    .reduce((accumulator, currentFee) => accumulator + currentFee, 0);

  const initialAmountWithoutFees = price?.details
    .filter((detail) => detail.type !== 'fee')
    .reduce((accumulator, currentDetail) => accumulator + currentDetail.amount, 0);

  return {
    cancellationFeesAmount,
    shippingManagementFeesAmount,
    totalFeesAmount,
    initialAmountWithoutFees,
  };
};

export const formatCurrency = (
  value?: number | null,
  currency?: string | null,
  options: Omit<Intl.NumberFormatOptions, 'currency' | 'style'> = {}
) => {
  if (isNil(value) || isNil(currency)) {
    return '-';
  }

  // We handle everything in cents.
  // In the future, we may have to handle zero decimal currencies.
  // See https://stripe.com/docs/currencies
  value /= 100;

  const locale = getBrowserLocale();

  return new Intl.NumberFormat(locale, {
    ...options,
    style: 'currency',
    currency,
  }).format(value);
};

const CURRENCIES = ['EUR', 'GBP', 'NOK', 'USD', 'CHF'] as const;
export type Currency = (typeof CURRENCIES)[number];
