import IconWrapper from './IconWrapper';

const IconSearch = IconWrapper({
  name: 'search',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 10.75C3.5 6.74594 6.74594 3.5 10.75 3.5C14.7541 3.5 18 6.74594 18 10.75C18 14.7541 14.7541 18 10.75 18C6.74594 18 3.5 14.7541 3.5 10.75ZM10.75 2C5.91751 2 2 5.91751 2 10.75C2 15.5825 5.91751 19.5 10.75 19.5C12.8962 19.5 14.862 18.7273 16.3842 17.4449L20.7197 21.7803C21.0126 22.0732 21.4874 22.0732 21.7803 21.7803C22.0732 21.4874 22.0732 21.0126 21.7803 20.7197L17.4449 16.3842C18.7273 14.862 19.5 12.8962 19.5 10.75C19.5 5.91751 15.5825 2 10.75 2Z"
      />
    </svg>
  ),
});

export default IconSearch;
