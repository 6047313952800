import { msg } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity } from '@/components/activities/BaseActivity/BaseActivity';
import IconPackageValidation from '@/icons/PackageValidation.svg';

export const ShipmentVerificationCompletedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'shipment_verification_completed'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconPackageValidation />}
      creatorName={activity.creator?.name ?? ''}
      message={
        activity.creator
          ? msg({
              id: 'activities.shipment_verification_completed.message',
              message: 'completed the package verification',
            })
          : msg({
              id: 'activities.shipment_verification_completed.message.no-creator',
              message: 'The package verification was completed',
            })
      }
    />
  );
};
