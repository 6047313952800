import { Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import {
  BaseActivity,
  BaseActivityArticleName,
  BaseActivityDetailedContent,
  BaseActivityText,
} from '@/components/activities/BaseActivity/BaseActivity';
import IconPackageIssue from '@/icons/PackageIssue.svg';

export const ShipmentIssueReported = ({
  activity,
  displayArticleName,
}: {
  activity: ActivityOfType<'shipment_issue_reported'>;
  displayArticleName: boolean;
}) => {
  return (
    <BaseActivity
      key={activity.id}
      createdAt={activity.createdAt}
      type="warning"
      icon={<IconPackageIssue />}
      creatorName={activity.creator.name}
      message={
        <>
          <BaseActivityText>
            <Trans id="activities.shipment_issue_reported.message">
              reported a shipment issue on the item
            </Trans>
          </BaseActivityText>
          <BaseActivityArticleName activity={activity} displayArticleName={displayArticleName} />
        </>
      }
    >
      {!!activity.data.issue && (
        <BaseActivityDetailedContent>{activity.data.issue}</BaseActivityDetailedContent>
      )}
    </BaseActivity>
  );
};
