import { Trans } from '@lingui/macro';

import RefashionLogo from '@/components/RefashionLogo';
import Button from '@/design_system/Button';
import Tooltip from '@/design_system/Tooltip';
import IconBandage from '@/icons/Bandage.svg';
import IconInfo from '@/icons/Info.svg';
import IconTask from '@/icons/Task.svg';
import { getGroupedActions, useGetActionName } from '@/models/actionType';
import { getGroupedDefects, useGetDefectName } from '@/models/defectType';
import { ArticleActionWithRelations, ArticleDefectWithRelations } from '@/models/request';
import {
  useClientToken,
  useCurrentOrganization,
  useCurrentSession,
  useFlags,
} from '@/services/auth';
import { createBEMClasses } from '@/utils/classname';

import './ArticleActionsCell.css';

const { block, element } = createBEMClasses('article-actions-cell');

const ArticleActionsCell = ({
  defects,
  actions,
  mode,
  showBonus,
}: {
  defects: ArticleDefectWithRelations[];
  actions: ArticleActionWithRelations[];
  mode: 'need' | 'action';
  showBonus?: boolean;
}) => {
  const getDefectName = useGetDefectName();
  const getActionName = useGetActionName();
  const clientToken = useClientToken();
  const storeToken = useClientToken();
  const { flags } = useFlags();

  const priceWithPossibleBonus = clientToken || storeToken ? 'cost' : 'price';

  const nonReparableDefects = defects.filter((defect) => !defect.isReparable);

  if (!flags['enable-defect-action-split']) {
    return (
      <ul className={block()}>
        {getGroupedActions(actions).map((action) => (
          <li key={action.id} className="paragraph-100-regular">
            <ActionIcon brandResponsibility={action.brandResponsibility} mode={mode} />
            <p>
              {getActionName({ action, mode })}
              {action.quantity > 1 && ` (x${action.quantity})`}
              {showBonus && action[`${priceWithPossibleBonus}RefashionStatus`] === 'applied' && (
                <>
                  {' '}
                  <RefashionLogo />
                </>
              )}
            </p>
          </li>
        ))}
      </ul>
    );
  }

  if (mode === 'need') {
    return (
      <ul className={block()}>
        {getGroupedDefects(defects).map((defect) => (
          <li key={defect.id} className="paragraph-100-regular">
            <div className={element('icon')}>
              <IconBandage />
            </div>
            <p>
              {getDefectName(defect)}
              {defect.quantity > 1 && ` (x${defect.quantity})`}
            </p>
          </li>
        ))}
      </ul>
    );
  } else {
    return (
      <ul className={block()}>
        {getGroupedActions(actions).map((action) => (
          <li key={action.id} className="paragraph-100-regular">
            <ActionIcon brandResponsibility={action.brandResponsibility} mode="action" />
            <p>
              {getActionName({ action, mode: 'action' })}
              {action.quantity > 1 && ` (x${action.quantity})`}
              {showBonus && action[`${priceWithPossibleBonus}RefashionStatus`] === 'applied' && (
                <>
                  {' '}
                  <RefashionLogo />
                </>
              )}
            </p>
          </li>
        ))}
        {getGroupedDefects(nonReparableDefects).map((defect) => (
          <li key={defect.id} className="paragraph-100-regular text-disabled">
            <div className={element('icon')}>
              <IconBandage />
            </div>
            <p style={{ textDecoration: 'line-through' }}>
              {getDefectName(defect)}
              {defect.quantity > 1 && ` (x${defect.quantity})`}
            </p>
            <Tooltip
              content={
                <Trans id="article-actions.irreparable-defect">
                  This defect can&apos;t be repaired
                </Trans>
              }
            >
              <Button variant="style-less" style={{ alignSelf: 'center' }}>
                <IconInfo />
              </Button>
            </Tooltip>
          </li>
        ))}
      </ul>
    );
  }
};

const ActionIcon = ({
  brandResponsibility,
  mode,
}: {
  brandResponsibility?: boolean;
  mode: 'need' | 'action';
}) => {
  const [organization] = useCurrentOrganization();
  const { currentSession } = useCurrentSession();

  const isFromCurrentOrganization = !!currentSession?.organizations.find(
    ({ id }) => id === organization?.id
  );

  if (brandResponsibility && !!organization && isFromCurrentOrganization) {
    return (
      <Tooltip
        content={
          <Trans id="article-actions.brand-responsibility">
            Under {organization.name} responsibility
          </Trans>
        }
        className={element('tooltip')}
      >
        <Button variant="style-less" className={element('icon')}>
          {mode === 'action' && <IconTask />}
          {mode === 'need' && <IconBandage />}
          <div className={element('brand-responsibility')} />
        </Button>
      </Tooltip>
    );
  }

  return (
    <div className={element('icon')}>
      {mode === 'action' && <IconTask />}
      {mode === 'need' && <IconBandage />}
    </div>
  );
};

export default ArticleActionsCell;
