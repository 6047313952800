import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import WorkshopList, { WorkshopListEmptyState } from '@/components/WorkshopList';
import { WorkshopListItem } from '@/components/WorkshopList/WorkshopList';
import BasicRadioGroup from '@/design_system/BasicRadioGroup';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { useActivities, useUpdateServiceChoice } from '@/models/article';
import { ArticleWithRelations, Request } from '@/models/request';
import { useWorkshops } from '@/models/workshop';

export const ArticleDispatch = ({
  request,
  article,
  showSelector,
  error,
}: {
  request: Request;
  article: ArticleWithRelations;
  showSelector?: boolean;
  error?: string;
}) => {
  const {
    data: { workshops: internalWorkshops } = { workshops: [] },
    isLoading: isLoadingInternalWorkshops,
  } = useWorkshops(
    {
      limit: 1,
      internal: true,
    },
    {
      enabled: showSelector,
    }
  );

  const {
    data: { workshops: externalWorkshops } = { workshops: [] },
    isLoading: isLoadingExternalWorkshops,
  } = useWorkshops(
    {
      limit: 1,
      internal: false,
    },
    {
      enabled: showSelector,
    }
  );

  const availableWorkshopTypes: ('internal' | 'external')[] = [];

  if (internalWorkshops.length > 0) {
    availableWorkshopTypes.push('internal');
  }

  if (externalWorkshops.length > 0) {
    availableWorkshopTypes.push('external');
  }

  if (isLoadingInternalWorkshops || isLoadingExternalWorkshops) {
    return null;
  }

  return (
    <WorkshopSelector
      request={request}
      article={article}
      showSelector={showSelector}
      error={error}
      availableWorkshopTypes={availableWorkshopTypes}
    />
  );
};

const WorkshopSelector = ({
  request,
  article,
  showSelector,
  error,
  availableWorkshopTypes,
}: {
  request: Request;
  article: ArticleWithRelations;
  showSelector?: boolean;
  error?: string;
  availableWorkshopTypes: ('internal' | 'external')[];
}) => {
  const { _ } = useLingui();

  const [workshopType, setWorkshopType] = useState<'internal' | 'external' | undefined>(
    article.workshop
      ? article.workshop.external
        ? 'external'
        : 'internal'
      : availableWorkshopTypes.length === 1
        ? availableWorkshopTypes[0]
        : undefined
  );

  const { data: { workshops } = { workshops: [] }, isFetching: isFetchingWorkshops } = useWorkshops(
    {
      articleId: article.id,
      limit: 100,
    },
    {
      enabled: article.hasActions,
      keepPreviousData: true,
    }
  );

  const { data: { activities: dispatchRefusedActivities } = { activities: [] } } = useActivities({
    articleId: article.id,
    types: ['job_refused'],
  });

  const { mutateAsync: updateServiceChoice } = useUpdateServiceChoice({
    requestId: request.id,
    articleId: article.id,
  });

  const externalWorkshops = workshops.filter((workshop) => workshop.external);
  const internalWorkshops = workshops.filter((workshop) => !workshop.external);

  const listedWorkshops =
    workshopType === 'external'
      ? externalWorkshops
      : workshopType === 'internal'
        ? internalWorkshops
        : [];
  const selectedWorkshop = listedWorkshops.find((workshop) => workshop.id === article.workshopId);

  if (!showSelector) {
    return (
      <Stack gap="0.25rem" className="text-primary">
        <p className="label-100">
          <Trans id="article.service-choice.dispatch.assigned.label">Assigned workshop</Trans>
        </p>
        {selectedWorkshop && <WorkshopListItem workshop={selectedWorkshop} isDisabled />}
        {!selectedWorkshop && (
          <p className="paragraph-100-regular">
            <Trans id="article.service-choice.dispatch.assigned.none">None</Trans>
          </p>
        )}
      </Stack>
    );
  }

  return (
    <Stack gap="0.25rem">
      {availableWorkshopTypes.length > 1 && (
        <BasicRadioGroup
          value={workshopType ?? 'none'}
          onChange={(value) => {
            setWorkshopType(value as 'internal' | 'external');

            const defaultWorkshopId =
              value === 'internal' && internalWorkshops.length === 1
                ? internalWorkshops[0].id
                : value === 'external' && externalWorkshops.length === 1
                  ? externalWorkshops[0].id
                  : null;

            updateServiceChoice({
              workshopId: defaultWorkshopId,
            });
          }}
          label={_(
            msg({
              id: 'article.service-choice.dispatch.selector.label',
              message: 'Select a workshop to handle the job',
            })
          )}
          options={[
            {
              value: 'internal',
              children: _(msg({ id: 'workshop.internal', message: 'Internal' })),
            },
            {
              value: 'external',
              children: _(msg({ id: 'workshop.external', message: 'External' })),
            },
          ]}
          isDisabled={!article.hasActions}
        />
      )}
      {availableWorkshopTypes.length === 1 && (
        <p className="label-100 text-primary">
          <Trans id="article.service-choice.dispatch.selector.label">
            Select a workshop to handle the job
          </Trans>
        </p>
      )}

      {!article.hasActions && (
        <Message type="info">
          <Trans id="article.service-choice.dispatch.no-actions">
            Please set the repair actions before selecting the workshop.
          </Trans>
        </Message>
      )}

      {!!workshopType && article.hasActions && listedWorkshops.length > 0 && (
        <WorkshopList
          selected={article.workshopId ?? undefined}
          workshops={listedWorkshops}
          dispatchRefusedActivities={dispatchRefusedActivities}
          onSelect={(workshop) => {
            updateServiceChoice({
              workshopId: workshop.id,
            });
          }}
          isFetchingWorkshops={isFetchingWorkshops}
        />
      )}

      {!!workshopType && article.hasActions && listedWorkshops.length === 0 && (
        <WorkshopListEmptyState
          emptyStateText={_(
            msg({
              id: 'article.service-choice.dispatch.no-workshop-found',
              message: "We couldn't find any workshop for this kind of care & repair job needs.",
            })
          )}
        />
      )}

      {error && <Message type="error">{error}</Message>}
    </Stack>
  );
};
