import { Trans } from '@lingui/macro';

import Stack from '@/design_system/Stack';

const ClientArticleCommentCell = ({ comment }: { comment: string }) => {
  return (
    <div
      className="paragraph-100-regular bg-tertiary-200"
      style={{ padding: 16, borderRadius: 8, whiteSpace: 'pre-wrap' }}
    >
      <Stack gap="2px">
        <b>
          <Trans id="client.articles.after-sales-comments">After sales service comments</Trans>
        </b>
        <p>&rdquo;{comment}&rdquo;</p>
      </Stack>
    </div>
  );
};

export default ClientArticleCommentCell;
