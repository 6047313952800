import React, { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import { ShipmentWithRelations, useUpdateShipmentTrackingId } from '@/models/shipment';

export const TrackingIdModal = ({
  shipment,
  children,
}: {
  shipment: ShipmentWithRelations;
  children: React.ReactNode;
}) => {
  const { _ } = useLingui();

  const [isOpen, setIsOpen] = useState(false);
  const [trackingId, setTrackingId] = useState(shipment.trackingId ?? '');

  const { mutateAsync, isPending } = useUpdateShipmentTrackingId(shipment.id);

  return (
    <Dialog
      trigger={children}
      title={<Trans id="shipment.trackingNumber.modal.title">Add a tracking number</Trans>}
      style={{ maxWidth: '500px' }}
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (isOpen || !isPending) {
          setIsOpen(isOpen);
        }
      }}
    >
      <main>
        <Stack gap="8px">
          <p className="paragraph-100-regular text-primary">
            <Trans id="shipment.trackingNumber.modal.text">
              You can add a tracking number to this shipment in order to get tracking updates for
              you and the recipient.
            </Trans>
          </p>
          <InputText
            aria-label={_(
              msg({ id: 'shipment.trackingNumber.modal.label', message: 'Tracking number' })
            )}
            placeholder={_(
              msg({
                id: 'shipment.trackingNumber.modal.placeholder',
                message: 'Add courier tracking number',
              })
            )}
            value={trackingId}
            onChange={setTrackingId}
          />
        </Stack>
      </main>
      <footer>
        <Button
          variant="secondary"
          size="medium"
          onPress={() => setIsOpen(false)}
          disabled={isPending}
        >
          <Trans id="shipment.trackingNumber.modal.cancel">Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          size="medium"
          onPress={() => {
            mutateAsync(trackingId !== '' ? trackingId : null).then(() => {
              setIsOpen(false);
            });
          }}
          isLoading={isPending}
          disabled={isPending}
        >
          <Trans id="shipment.trackingNumber.modal.save">Add tracking number</Trans>
        </Button>
      </footer>
    </Dialog>
  );
};
