import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import {
  ShipmentWithRelations,
  useCompleteHandover,
  useCompleteInTransit,
} from '@/models/shipment';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

const HandoverAction = ({
  shipment,
  onHandOverDone,
}: {
  shipment: ShipmentWithRelations;
  onHandOverDone: () => void;
}) => {
  const { isMobile } = useViewPort();

  const {
    mutateAsync: completeHandover,
    isPending: isPendingCompleteHandover,
    isSuccess: isSuccessCompleteHandover,
  } = useCompleteHandover();
  const {
    mutateAsync: completeInTransit,
    isPending: isPendingCompleteInTransit,
    isSuccess: isSuccessCompleteInTransit,
  } = useCompleteInTransit();

  const { currentSession } = useCurrentSession();

  const canCreateShipment = shipment.canBeCreatedBy(currentSession);
  const canVerifyReception = shipment.canReceptionBeVerifiedBy(currentSession);

  if (canCreateShipment && shipment.handover) {
    return (
      <Stack alignItems={isMobile ? 'stretch' : 'flex-end'}>
        <Button
          variant="primary"
          onPress={async () => {
            await completeHandover(shipment.id);
            onHandOverDone();
          }}
          isLoading={isPendingCompleteHandover || isSuccessCompleteHandover}
        >
          {shipment.handover === 'dropoff' && (
            <Trans id="shipment.drop-off-done">Mark package drop-off as done</Trans>
          )}
          {shipment.handover === 'pickup' && (
            <Trans id="shipment.pickup-done">Mark package pickup as done</Trans>
          )}
        </Button>
      </Stack>
    );
  }

  if (canVerifyReception) {
    return (
      <Stack alignItems={isMobile ? 'stretch' : 'flex-end'}>
        <Button
          variant="primary"
          onPress={() => {
            completeInTransit(shipment.id);
          }}
          isLoading={isPendingCompleteInTransit || isSuccessCompleteInTransit}
        >
          <Trans id="shipment.package-received">Mark as received</Trans>
        </Button>
      </Stack>
    );
  }

  return null;
};

export default HandoverAction;
