import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconPackageValidation from '@/icons/PackageValidation.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import { useArticleName } from '@/models/article';
import type { TNotificationOfType } from '@/models/notification';

export const ShipmentIssueResolvedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'shipment_issue_resolved'>;
}) => {
  const { _ } = useLingui();
  const articleName = useArticleName({ article: notification.article });

  return (
    <BaseNotification
      icon={<IconPackageValidation />}
      type="primary"
      notification={notification}
      notificationLabel={_(
        msg({
          id: 'notification.shipment-issue-resolved.label',
          message: `Shipment issue resolved for item ${articleName} of request ${notification.request.reference}`,
        })
      )}
      notificationHeader={
        <Trans id="notification.shipment-issue-resolved">
          The shipment issue reported on the item{' '}
          <span className="paragraph-100-medium">{articleName}</span> has been resolved
        </Trans>
      }
    />
  );
};
