import IconWrapper from '@/icons/IconWrapper';

const IconHolePocket = IconWrapper({
  name: 'hole-pocket',
  source: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="129"
      height="128"
      fill="none"
      viewBox="0 0 129 128"
    >
      <g className="pocket">
        <g className="fill-accent">
          <path
            fill="#fff"
            d="M64.37 26.48c-12.89 0-22.371-5.84-34.702-6.432-1.103-.053-2.001.847-2.001 1.952v9.844c13.329 0 23.125 6.48 36.703 6.48 13.578 0 23.374-6.48 36.703-6.48v-9.846c0-1.104-.897-2.004-2-1.951-12.341.583-21.823 6.433-34.703 6.433Z"
          />
          <path
            fill="#fff"
            d="M27.667 31.833V82.18a23.808 23.808 0 0 0 16.59 22.691 66.203 66.203 0 0 0 40.225 0 23.808 23.808 0 0 0 16.591-22.691V31.833c-13.329 0-23.125 6.48-36.703 6.48-13.578 0-23.374-6.48-36.703-6.48Z"
          />
        </g>
        <g className="stroke">
          <path
            fill="#C6C6C6"
            d="M33.649 29.157a2.86 2.86 0 1 0 0-5.722 2.86 2.86 0 0 0 0 5.722Zm61.367 0a2.86 2.86 0 1 0 0-5.722 2.86 2.86 0 0 0 0 5.722Z"
          />
          <path
            fill="#575756"
            fillRule="evenodd"
            d="M29.788 26.296a3.86 3.86 0 1 1 7.722 0 3.86 3.86 0 0 1-7.722 0Zm3.86-1.86a1.86 1.86 0 1 0 0 3.72 1.86 1.86 0 0 0 0-3.72Zm61.368-2.001a3.86 3.86 0 1 0 0 7.722 3.86 3.86 0 0 0 0-7.722Zm-1.861 3.861a1.86 1.86 0 1 1 3.721 0 1.86 1.86 0 0 1-3.721 0Z"
            clipRule="evenodd"
          />
          <path
            fill="#575756"
            fillRule="evenodd"
            d="M102.073 21.998c0-1.644-1.344-3.03-3.047-2.95-5.935.28-11.22 1.755-16.404 3.201l-.966.27C76.135 24.054 70.66 25.48 64.37 25.48c-6.296 0-11.772-1.424-17.293-2.957l-.983-.274h-.003c-5.177-1.444-10.453-2.915-16.375-3.2-1.704-.081-3.049 1.306-3.049 2.951v60.18a24.808 24.808 0 0 0 17.287 23.644 67.2 67.2 0 0 0 40.831 0 24.807 24.807 0 0 0 17.288-23.644V21.998Zm-2 8.847v-8.847c0-.563-.45-.976-.952-.953-5.703.27-10.785 1.687-16.009 3.144h-.002l-.918.256C76.667 25.982 70.96 27.48 64.37 27.48c-6.594 0-12.303-1.496-17.828-3.03l-.942-.262c-5.215-1.454-10.29-2.868-15.98-3.14-.502-.025-.953.388-.953.952v8.845c6.352.15 11.927 1.704 17.399 3.23l.127.035c5.823 1.623 11.543 3.204 18.177 3.204s12.354-1.58 18.176-3.204l.128-.035c5.472-1.526 11.047-3.08 17.399-3.23Zm-71.406 2.012c6.08.15 11.443 1.645 16.959 3.182l.03.009c5.803 1.617 11.77 3.276 18.714 3.276 6.944 0 12.911-1.66 18.713-3.276l.031-.009c5.516-1.537 10.879-3.032 16.959-3.182V82.18a22.807 22.807 0 0 1-15.894 21.738 65.205 65.205 0 0 1-39.618 0 22.807 22.807 0 0 1-15.894-21.739V32.858Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  ),
});

export default IconHolePocket;
