import { Placement } from 'react-aria';
import {
  Header as AriaHeader,
  Key,
  Menu as AriaMenu,
  MenuItem as AriaMenuItem,
  MenuTrigger,
  Popover,
  Section as AriaSection,
  Separator as AriaSeparator,
} from 'react-aria-components';

import { createBEMClasses } from '@/utils/classname';

import './Menu.css';

const { block, element } = createBEMClasses('menu');

const Menu = ({
  trigger,
  onAction,
  children,
  placement,
}: {
  trigger: React.ReactNode;
  onAction: (id: string) => void;
  children: React.ReactNode;
  placement?: Placement;
}) => {
  return (
    <MenuTrigger>
      {trigger}
      <Popover placement={placement}>
        <AriaMenu className={block()} onAction={(key: Key) => onAction(key as string)}>
          {children}
        </AriaMenu>
      </Popover>
    </MenuTrigger>
  );
};

export default Menu;

export const MenuItem = ({
  id,
  children,
  size = 'default',
}: {
  id: string;
  children: React.ReactNode;
  size?: 'default' | 'large';
}) => {
  return (
    <AriaMenuItem id={id} className={element('item', { size })}>
      {children}
    </AriaMenuItem>
  );
};

export const DangerMenuItem = ({
  id,
  children,
  size = 'default',
}: {
  id: string;
  children: React.ReactNode;
  size?: 'default' | 'large';
}) => {
  return (
    <AriaMenuItem id={id} className={element('item', { danger: true, size })}>
      {children}
    </AriaMenuItem>
  );
};

export const Section = ({ children }: { children: React.ReactNode }) => {
  return <AriaSection className={element('section')}>{children}</AriaSection>;
};

export const Header = ({ children }: { children: React.ReactNode }) => {
  return <AriaHeader className={element('header')}>{children}</AriaHeader>;
};

export const Separator = () => {
  return <AriaSeparator className={element('separator')} />;
};
