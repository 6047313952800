import IconWrapper from '@/icons/IconWrapper';

const IconPilling = IconWrapper({
  name: 'pilling',
  source: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
    >
      <g className="piling">
        <path
          fill="#fff"
          d="M116 65.214C85.981 62.41 91.717 39.306 63.473 36.661L12 65.214c28.244 2.645 22.508 25.737 52.527 28.553L116 65.215Z"
          className="fill-accent"
        />
        <g className="stroke">
          <path
            fill="#3F4643"
            d="M58.757 56.118a4.772 4.772 0 0 0-6.474-1.912 5.717 5.717 0 0 0-2.289 7.757 6.9 6.9 0 0 0 9.345 2.77 1 1 0 0 0-.95-1.759 4.9 4.9 0 0 1-6.639-1.968 3.716 3.716 0 0 1 1.49-5.043A2.772 2.772 0 0 1 57 57.072a2.031 2.031 0 0 1-.812 2.743 1.428 1.428 0 0 1-1.918-.57.94.94 0 0 1 .38-1.269 1 1 0 0 0-.962-1.753 2.94 2.94 0 0 0-1.178 3.97l.004.01a3.428 3.428 0 0 0 4.626 1.371l.005-.002a4.031 4.031 0 0 0 1.614-5.45l-.002-.004Zm-27.629 3.203a4.772 4.772 0 0 1 6.474 1.913l.002.003a4.031 4.031 0 0 1-1.614 5.45l-.006.003a3.428 3.428 0 0 1-4.625-1.372l-.005-.008a2.94 2.94 0 0 1 1.179-3.972 1 1 0 0 1 .962 1.754.94.94 0 0 0-.38 1.268 1.427 1.427 0 0 0 1.918.571 2.031 2.031 0 0 0 .81-2.744 2.772 2.772 0 0 0-3.76-1.109 3.716 3.716 0 0 0-1.488 5.043 4.9 4.9 0 0 0 6.638 1.969 1 1 0 0 1 .95 1.76 6.9 6.9 0 0 1-9.344-2.772 5.717 5.717 0 0 1 2.29-7.757Zm41.333 16.426a4.772 4.772 0 0 0-6.474-1.912 5.717 5.717 0 0 0-2.29 7.757 6.9 6.9 0 0 0 9.345 2.771 1 1 0 1 0-.95-1.76 4.9 4.9 0 0 1-6.638-1.968 3.716 3.716 0 0 1 1.49-5.043 2.772 2.772 0 0 1 3.76 1.109 2.031 2.031 0 0 1-.812 2.744c-.68.364-1.54.113-1.918-.572a.94.94 0 0 1 .38-1.268 1 1 0 1 0-.962-1.753 2.94 2.94 0 0 0-1.178 3.97l.004.01a3.428 3.428 0 0 0 4.626 1.372l.005-.003a4.031 4.031 0 0 0 1.614-5.45l-.002-.004Zm6.044-25.512a4.772 4.772 0 0 1 6.474 1.912l.001.003a4.031 4.031 0 0 1-1.614 5.45l-.005.004a3.428 3.428 0 0 1-4.626-1.372l-.004-.009a2.94 2.94 0 0 1 1.178-3.971 1 1 0 0 1 .963 1.753.94.94 0 0 0-.38 1.268 1.428 1.428 0 0 0 1.917.572 2.031 2.031 0 0 0 .811-2.744 2.772 2.772 0 0 0-3.76-1.109 3.716 3.716 0 0 0-1.489 5.043 4.9 4.9 0 0 0 6.638 1.968 1 1 0 0 1 .95 1.76 6.9 6.9 0 0 1-9.344-2.77 5.717 5.717 0 0 1 2.29-7.758Z"
          />
          <path
            fill="#3F4643"
            fillRule="evenodd"
            d="m63.567 35.666-.308-.03L8.687 65.91l3.22.301c6.91.647 11.688 2.537 15.443 5.02 3.677 2.431 6.415 5.454 9.268 8.604l.239.264c2.921 3.224 5.983 6.554 10.237 9.237 4.271 2.695 9.695 4.71 17.34 5.428l.307.029 54.573-30.272-3.221-.301c-7.365-.688-12.481-2.614-16.459-5.123-3.996-2.52-6.894-5.658-9.822-8.89l-.293-.323c-2.815-3.11-5.702-6.3-9.593-8.873-4.05-2.68-9.148-4.67-16.36-5.344Zm-3.946 4.276L15.163 64.603c5.63.896 9.84 2.677 13.29 4.958 3.892 2.573 6.779 5.762 9.595 8.872l.291.322c2.928 3.231 5.826 6.368 9.822 8.889 3.922 2.475 8.951 4.383 16.152 5.098l48.507-26.907c-6.055-.917-10.58-2.731-14.253-5.048-4.254-2.683-7.316-6.013-10.237-9.238l-.24-.266c-2.853-3.15-5.59-6.173-9.267-8.605-2.727-1.804-5.994-3.295-10.225-4.224.056.088.109.179.16.272l.001.003a4.031 4.031 0 0 1-1.614 5.45l-.005.003a3.428 3.428 0 0 1-4.626-1.372l-.004-.008a2.94 2.94 0 0 1 1.178-3.971 1 1 0 0 1 .962 1.753.94.94 0 0 0-.38 1.268 1.427 1.427 0 0 0 1.918.571 2.031 2.031 0 0 0 .811-2.743 2.772 2.772 0 0 0-3.76-1.11 3.716 3.716 0 0 0-1.489 5.044 4.9 4.9 0 0 0 6.638 1.968 1 1 0 1 1 .95 1.76 6.9 6.9 0 0 1-9.344-2.771 5.696 5.696 0 0 1-.373-4.629Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  ),
});

export default IconPilling;
