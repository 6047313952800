import { GridList, GridListItem } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  ArticleNameCell,
  ArticleWorkshopCell,
  BaseMoneyCell,
} from '@/components/ArticlesTableCells';
import {
  ArticleCostCardItem,
  ArticlePriceCardItem,
} from '@/components/ArticlesTableCells/ArticleMoneyCell';
import {
  ArticleServiceTypeCardItem,
  ArticleServiceTypeCell,
} from '@/components/ArticlesTableCells/ArticleServiceTypeCell';
import { ArticleWorkshopCardItem } from '@/components/ArticlesTableCells/ArticleWorkshopCell';
import { ArticleStepper } from '@/components/ArticleStepper/ArticleStepper';
import { Card, CardContent, CardItems } from '@/components/Card/Card';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconChevron from '@/icons/Chevron.svg';
import { useArticleName } from '@/models/article';
import { ArticleWithRelations } from '@/models/request';
import { useRequestContext } from '@/routes/Requests/RequestContext';
import { createBEMClasses } from '@/utils/classname';

import './ArchivedArticlesTable.css';

const { block, element } = createBEMClasses('archived-articles-table');

export const ArchivedArticlesTable = ({ articles }: { articles: ArticleWithRelations[] }) => {
  const { _ } = useLingui();
  const {
    view: { workshop, price },
  } = useRequestContext();

  return (
    <Table
      aria-label={_(msg({ id: 'request.articles.table.label', message: 'Items' }))}
      columnWidths={[
        'minmax(220px, 1fr)',
        'minmax(140px, 1fr)',
        'minmax(128px, 1fr)',
        workshop.enabled && 'minmax(160px, 1fr)',
        price.enabled && 'minmax(100px, 1fr)',
      ]}
      className={block()}
    >
      <ArchivedArticlesTableHeader />
      <Body>
        {articles.map((article) => (
          <ArchivedArticlesTableRow key={article.id} article={article} />
        ))}
      </Body>
    </Table>
  );
};

const ArchivedArticlesTableHeader = () => {
  const {
    view: { workshop, price },
  } = useRequestContext();

  return (
    <Header>
      <Row>
        <Column>
          <Trans id="request.articles.table.label">Items</Trans>
        </Column>
        <Column>
          <Trans id="request.articles.table.column.step.label">Step</Trans>
        </Column>
        <Column>
          <Trans id="request.articles.table.column.service-type.label">Service Type</Trans>
        </Column>
        {workshop.enabled && (
          <Column>
            <Trans id="request.articles.table.column.workshop.label">Workshop</Trans>
          </Column>
        )}
        {price.enabled && <Column justifyContent="end">{price.label}</Column>}
      </Row>
    </Header>
  );
};

const ArchivedArticlesTableRow = ({ article }: { article: ArticleWithRelations }) => {
  const { _ } = useLingui();
  const {
    view: { workshop, price, cost },
  } = useRequestContext();

  return (
    <Row style={{ opacity: 0.3 }} to={`/requests/${article.requestId}/articles/${article.id}`}>
      <Cell isLink>
        <ArticleNameCell article={article} />
      </Cell>
      <Cell stretch>
        <ArticleStepper size="medium" article={article} mode="step" />
      </Cell>
      <Cell>
        <ArticleServiceTypeCell article={article} />
      </Cell>
      {workshop.enabled && (
        <Cell>
          <ArticleWorkshopCell article={article} showWorkshopPrice={cost.enabled} />
        </Cell>
      )}
      {price.enabled && (
        <Cell justifyContent="end">
          <BaseMoneyCell
            price={article.snapshot.price?.amountPerCurrency[0]}
            cancelledPriceTooltip={
              article.cancellationDetail?.type === 'requestor_refusal'
                ? _(
                    msg({
                      id: 'request.articles.cancelled-by-client',
                      message: 'Cancelled by the client',
                    })
                  )
                : undefined
            }
          />
        </Cell>
      )}
    </Row>
  );
};

export const ArchivedArticlesCardList = ({ articles }: { articles: ArticleWithRelations[] }) => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { request } = useRequestContext();

  return (
    <GridList
      className={block()}
      onAction={(key) => {
        navigate(`/requests/${request.id}/articles/${key}`);
      }}
      aria-label={_(
        msg({
          id: 'request.articles.table.archived-articles.label',
          message: 'Archived items',
        })
      )}
    >
      {articles.map((article) => (
        <ArchivedArticlesCard key={article.id} article={article} />
      ))}
    </GridList>
  );
};

const ArchivedArticlesCard = ({ article }: { article: ArticleWithRelations }) => {
  const {
    view: { workshop, price, cost },
  } = useRequestContext();

  const articleName = useArticleName({ article });

  return (
    <GridListItem
      id={article.id}
      textValue={articleName}
      style={{ opacity: 0.3 }}
      className={element('item')}
    >
      <Card>
        <CardContent>
          <Stack
            row
            gap="0.5rem"
            flexWrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <ArticleNameCell id={article.id} article={article} />
            <IconChevron right style={{ fontSize: '1.5rem' }} />
          </Stack>
          <ArticleStepper size="medium" article={article} mode="step" />
          <CardItems>
            {article.hasActions && <ArticleServiceTypeCardItem article={article} />}
            {workshop.enabled && <ArticleWorkshopCardItem article={article} />}
            {cost.enabled && <ArticleCostCardItem article={article} costLabel={cost.label} />}
            {price.enabled && <ArticlePriceCardItem article={article} priceLabel={price.label} />}
          </CardItems>
        </CardContent>
      </Card>
    </GridListItem>
  );
};
