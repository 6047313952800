import { ComponentProps } from 'react';

import Avatar from '@/design_system/Avatar';
import { useProfilePhoto } from '@/models/user';

export const UserAvatar = ({
  user,
  ariaLabel,
  ...props
}: { user?: { id: string; name: string } | null } & Omit<
  ComponentProps<typeof Avatar>,
  'url' | 'name'
>) => {
  const { data: avatarUrl } = useProfilePhoto(user?.id);

  return (
    <Avatar {...props} url={avatarUrl} name={user?.name} ariaLabel={ariaLabel ?? user?.name} />
  );
};
