import IconWrapper, { IconProps } from './IconWrapper';

const IconPlaceNone = IconWrapper({
  name: 'place',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 9.75C5.75 6.29822 8.54822 3.5 12 3.5C15.4518 3.5 18.25 6.29822 18.25 9.75C18.25 10.5275 17.8854 11.595 17.2411 12.8312C16.6093 14.0438 15.7584 15.3265 14.8947 16.5069C14.0331 17.6845 13.1698 18.7453 12.521 19.5125C12.3256 19.7436 12.1499 19.9478 12 20.1201C11.8501 19.9478 11.6744 19.7436 11.479 19.5125C10.8302 18.7453 9.96691 17.6845 9.10528 16.5069C8.24165 15.3265 7.39074 14.0438 6.75886 12.8312C6.11464 11.595 5.75 10.5275 5.75 9.75ZM11.447 21.7567C11.4471 21.7568 11.4472 21.7569 12 21.25L11.447 21.7567C11.5891 21.9116 11.7898 22 12 22C12.2102 22 12.4107 21.9118 12.5528 21.7569L12 21.25C12.5528 21.7569 12.5527 21.757 12.5528 21.7569L12.5546 21.7549L12.5589 21.7502L12.5749 21.7326L12.6356 21.6656C12.6883 21.6071 12.7651 21.5215 12.8624 21.4115C13.0571 21.1915 13.3342 20.8739 13.6665 20.481C14.3302 19.6961 15.2169 18.6068 16.1053 17.3926C16.9916 16.1812 17.8907 14.8305 18.5714 13.5244C19.2396 12.242 19.75 10.9055 19.75 9.75C19.75 5.46979 16.2802 2 12 2C7.71979 2 4.25 5.46979 4.25 9.75C4.25 10.9055 4.76036 12.242 5.42864 13.5244C6.10926 14.8305 7.00835 16.1812 7.89472 17.3926C8.78309 18.6068 9.6698 19.6961 10.3335 20.481C10.6658 20.8739 10.9429 21.1915 11.1376 21.4115C11.2349 21.5215 11.3117 21.6071 11.3644 21.6656L11.4251 21.7326L11.4411 21.7502L11.4454 21.7549L11.447 21.7567ZM9.75 9.75C9.75 8.50736 10.7574 7.5 12 7.5C13.2426 7.5 14.25 8.50736 14.25 9.75C14.25 10.9926 13.2426 12 12 12C10.7574 12 9.75 10.9926 9.75 9.75ZM12 6C9.92893 6 8.25 7.67893 8.25 9.75C8.25 11.8211 9.92893 13.5 12 13.5C14.0711 13.5 15.75 11.8211 15.75 9.75C15.75 7.67893 14.0711 6 12 6Z"
      />
    </svg>
  ),
});

const IconPlaceStart = IconWrapper({
  name: 'place',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 9C5.75 5.54822 8.54822 2.75 12 2.75C15.4518 2.75 18.25 5.54822 18.25 9C18.25 9.77753 17.8854 10.845 17.2411 12.0812C16.6093 13.2938 15.7584 14.5765 14.8947 15.7569C14.0331 16.9345 13.1698 17.9953 12.521 18.7625C12.3256 18.9936 12.1499 19.1978 12 19.3701C11.8501 19.1978 11.6744 18.9936 11.479 18.7625C10.8302 17.9953 9.96691 16.9345 9.10528 15.7569C8.24165 14.5765 7.39074 13.2938 6.75886 12.0812C6.11464 10.845 5.75 9.77753 5.75 9ZM11.447 21.0067C11.4471 21.0068 11.4472 21.0069 12 20.5L11.4472 21.0069C11.5893 21.1618 11.7898 21.25 12 21.25H21V19.75H13.6504C13.6557 19.7437 13.6611 19.7374 13.6665 19.731C14.3302 18.9461 15.2169 17.8568 16.1053 16.6426C16.9916 15.4312 17.8907 14.0805 18.5714 12.7744C19.2396 11.492 19.75 10.1555 19.75 9C19.75 4.71979 16.2802 1.25 12 1.25C7.71979 1.25 4.25 4.71979 4.25 9C4.25 10.1555 4.76036 11.492 5.42864 12.7744C6.10926 14.0805 7.00835 15.4312 7.89472 16.6426C8.78309 17.8568 9.6698 18.9461 10.3335 19.731C10.6658 20.1239 10.9429 20.4415 11.1376 20.6615C11.2349 20.7715 11.3117 20.8571 11.3644 20.9156L11.4251 20.9826L11.4411 21.0002L11.4454 21.0049L11.4466 21.0063L11.447 21.0067ZM12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75ZM8.25 9C8.25 6.92893 9.92893 5.25 12 5.25C14.0711 5.25 15.75 6.92893 15.75 9C15.75 11.0711 14.0711 12.75 12 12.75C9.92893 12.75 8.25 11.0711 8.25 9Z"
      />
    </svg>
  ),
});

const IconPlaceEnd = IconWrapper({
  name: 'place',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 9C5.75 5.54822 8.54822 2.75 12 2.75C15.4518 2.75 18.25 5.54822 18.25 9C18.25 9.77753 17.8854 10.845 17.2411 12.0812C16.6093 13.2938 15.7584 14.5765 14.8947 15.7569C14.0331 16.9345 13.1698 17.9953 12.521 18.7625C12.3256 18.9936 12.1499 19.1978 12 19.3701C11.8501 19.1978 11.6744 18.9936 11.479 18.7625C10.8302 17.9953 9.96691 16.9345 9.10528 15.7569C8.24165 14.5765 7.39074 13.2938 6.75886 12.0812C6.11464 10.845 5.75 9.77753 5.75 9ZM12 20.5C12.5528 21.0069 12.5529 21.0068 12.553 21.0067L12.5534 21.0063L12.5546 21.0049L12.5589 21.0002L12.5749 20.9826L12.6356 20.9156C12.6883 20.8571 12.7651 20.7715 12.8624 20.6615C13.0571 20.4415 13.3342 20.1239 13.6665 19.731C14.3302 18.9461 15.2169 17.8568 16.1053 16.6426C16.9916 15.4312 17.8907 14.0805 18.5714 12.7744C19.2396 11.492 19.75 10.1555 19.75 9C19.75 4.71979 16.2802 1.25 12 1.25C7.71979 1.25 4.25 4.71979 4.25 9C4.25 10.1555 4.76036 11.492 5.42864 12.7744C6.10926 14.0805 7.00835 15.4312 7.89472 16.6426C8.78309 17.8568 9.6698 18.9461 10.3335 19.731C10.3389 19.7374 10.3443 19.7437 10.3496 19.75H3V21.25H12C12.2102 21.25 12.4107 21.1618 12.5528 21.0069L12 20.5ZM12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75ZM8.25 9C8.25 6.92893 9.92893 5.25 12 5.25C14.0711 5.25 15.75 6.92893 15.75 9C15.75 11.0711 14.0711 12.75 12 12.75C9.92893 12.75 8.25 11.0711 8.25 9Z"
      />
    </svg>
  ),
});

const IconPlaceWarning = IconWrapper({
  name: 'place',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 9.75C5.75 6.29822 8.54822 3.5 12 3.5C15.4518 3.5 18.25 6.29822 18.25 9.75C18.25 9.83136 18.246 9.9159 18.2381 10.0035C18.7479 10.0184 19.2457 10.081 19.7272 10.1869C19.7422 10.0388 19.75 9.89302 19.75 9.75C19.75 5.46979 16.2802 2 12 2C7.71979 2 4.25 5.46979 4.25 9.75C4.25 10.9055 4.76036 12.242 5.42864 13.5244C6.10926 14.8305 7.00835 16.1812 7.89472 17.3926C8.78309 18.6068 9.6698 19.6961 10.3335 20.481L10.4304 20.5952C10.1514 19.7814 10 18.9084 10 18C10 17.899 10.0019 17.7984 10.0056 17.6983C9.71155 17.3207 9.40831 16.921 9.10528 16.5069C8.24165 15.3265 7.39074 14.0438 6.75886 12.8312C6.11464 11.595 5.75 10.5275 5.75 9.75ZM15.75 9.75C15.75 9.9487 15.7345 10.1438 15.7048 10.3341C14.9651 10.5553 14.2704 10.8805 13.6376 11.2929C14.0173 10.8901 14.25 10.3472 14.25 9.75C14.25 8.50736 13.2426 7.5 12 7.5C10.7574 7.5 9.75 8.50736 9.75 9.75C9.75 10.9926 10.7574 12 12 12C12.345 12 12.6719 11.9223 12.9641 11.7835C12.3722 12.2637 11.8502 12.8266 11.4157 13.4548C9.62217 13.1742 8.25 11.6223 8.25 9.75C8.25 7.67893 9.92893 6 12 6C14.0711 6 15.75 7.67893 15.75 9.75ZM18.0001 13.4999C15.5148 13.4999 13.5001 15.5147 13.5001 17.9999C13.5001 20.4852 15.5148 22.4999 18.0001 22.4999C20.4854 22.4999 22.5001 20.4852 22.5001 17.9999C22.5001 15.5147 20.4854 13.4999 18.0001 13.4999ZM12.0001 17.9999C12.0001 14.6862 14.6864 11.9999 18.0001 11.9999C21.3138 11.9999 24.0001 14.6862 24.0001 17.9999C24.0001 21.3136 21.3138 23.9999 18.0001 23.9999C14.6864 23.9999 12.0001 21.3136 12.0001 17.9999ZM17.9999 19.4999C17.5856 19.4999 17.2499 19.8357 17.2499 20.2499C17.2499 20.6642 17.5856 20.9999 17.9999 20.9999C18.4141 20.9999 18.7499 20.6642 18.7499 20.2499C18.7499 19.8357 18.4141 19.4999 17.9999 19.4999ZM17.2501 17.9999C17.2501 18.4142 17.5859 18.7499 18.0001 18.7499C18.4143 18.7499 18.7501 18.4142 18.7501 17.9999V15.7499C18.7501 15.3357 18.4143 14.9999 18.0001 14.9999C17.5859 14.9999 17.2501 15.3357 17.2501 15.7499V17.9999Z"
      />
    </svg>
  ),
});

const IconPlace = ({
  start,
  end,
  warning,
  ...props
}: { start?: boolean; end?: boolean; warning?: boolean } & IconProps) => {
  if (start) return <IconPlaceStart {...props} />;
  if (end) return <IconPlaceEnd {...props} />;
  if (warning) return <IconPlaceWarning {...props} />;
  return <IconPlaceNone {...props} />;
};

export default IconPlace;
