import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Stack from '@/design_system/Stack';
import IconStoreRepair from '@/icons/StoreRepair.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const DispatchRefusedNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'dispatch_refused'>;
}) => {
  const { _ } = useLingui();

  return (
    <BaseNotification
      notificationLabel={_(
        msg({
          id: 'notification.dispatch-refused.label',
          message: `${notification.data.workshopName} has refused the job for request ${notification.request.reference}, you need to dispatch it to another workshop`,
        })
      )}
      icon={<IconStoreRepair />}
      type="warning"
      notification={notification}
      notificationHeader={
        <Trans id="notification.dispatch-refused">
          <span className="paragraph-100-medium">{notification.data.workshopName}</span> has refused
          the job, you need to dispatch it to another workshop.
        </Trans>
      }
      notificationContent={
        <Stack
          className="bg-neutral-100"
          style={{
            padding: '8px 12px', // eslint-disable-line lingui/no-unlocalized-strings
            borderRadius: '0.5rem',
          }}
        >
          <p
            className="paragraph-100-regular"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {notification.data.refusalReason}
          </p>
        </Stack>
      }
    />
  );
};
