import IconWrapper from './IconWrapper';

const IconPayment = IconWrapper({
  name: 'payment',
  source: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 5.25H8.69513H8.69511C7.32753 5.24998 6.22521 5.24996 5.35825 5.36652C4.45814 5.48754 3.70027 5.74643 3.09835 6.34835C2.49643 6.95027 2.23754 7.70814 2.11652 8.60825C1.99996 9.47521 1.99998 10.5775 2 11.9451V11.9451V12V13V13.0549V13.0549C1.99998 14.4225 1.99996 15.5248 2.11652 16.3918C2.23754 17.2919 2.49643 18.0497 3.09835 18.6517C3.70027 19.2536 4.45814 19.5125 5.35825 19.6335C6.22522 19.75 7.32754 19.75 8.69512 19.75H8.69513H8.75H15.25H15.3049H15.3049C16.6725 19.75 17.7748 19.75 18.6418 19.6335C19.5419 19.5125 20.2997 19.2536 20.9017 18.6517C21.5036 18.0497 21.7625 17.2919 21.8835 16.3918C22 15.5248 22 14.4225 22 13.0549V13V12V11.9451C22 10.5775 22 9.47522 21.8835 8.60825C21.7625 7.70814 21.5036 6.95027 20.9017 6.34835C20.2997 5.74643 19.5419 5.48754 18.6418 5.36652C17.7748 5.24996 16.6725 5.24998 15.3049 5.25H15.3049H15.25H8.75ZM4.15901 7.40901C4.43577 7.13225 4.82435 6.9518 5.55812 6.85315C6.31347 6.75159 7.31459 6.75 8.75 6.75H15.25C16.6854 6.75 17.6865 6.75159 18.4419 6.85315C19.1757 6.9518 19.5642 7.13225 19.841 7.40901C20.1178 7.68577 20.2982 8.07435 20.3969 8.80812C20.4251 9.01835 20.4456 9.24763 20.4605 9.5H3.53946C3.55436 9.24763 3.57488 9.01835 3.60315 8.80812C3.7018 8.07435 3.88225 7.68577 4.15901 7.40901ZM3.5 12V13C3.5 14.4354 3.50159 15.4365 3.60315 16.1919C3.7018 16.9257 3.88225 17.3142 4.15901 17.591C4.43577 17.8678 4.82435 18.0482 5.55812 18.1469C6.31347 18.2484 7.31459 18.25 8.75 18.25H15.25C16.6854 18.25 17.6865 18.2484 18.4419 18.1469C19.1757 18.0482 19.5642 17.8678 19.841 17.591C20.1178 17.3142 20.2982 16.9257 20.3969 16.1919C20.4984 15.4365 20.5 14.4354 20.5 13V12L3.5 12Z"
      />
    </svg>
  ),
});

export default IconPayment;
