import { Trans } from '@lingui/macro';
import { TokenResult } from '@stripe/stripe-js';

import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import IconCheck from '@/icons/Check.svg';
import IconClock from '@/icons/Clock.svg';
import { Workshop as WorkshopModel } from '@/models/workshop';
import { useCurrentSession } from '@/services/auth';
import { useOnboardingLink, useStripe, useStripeAccount } from '@/services/stripe';
const Workshop = () => {
  const { currentSession } = useCurrentSession();

  if (!currentSession?.workshop) {
    return null;
  }

  const canManageBilling = currentSession.hasPermission('manage_billing', {
    workshopId: currentSession.workshop.id,
  });

  const hasPayoutEnabled = currentSession.workshop.organizationConfig?.enablePayout;

  return (
    <Stack gap="16px">
      <InputText
        label={<Trans id="settings.workshop.name.label">Name</Trans>}
        value={currentSession?.workshop?.name}
        style={{ maxWidth: '300px' }}
        isDisabled
      />
      {canManageBilling && hasPayoutEnabled && <StripeAccount workshop={currentSession.workshop} />}
    </Stack>
  );
};

const StripeAccount = ({ workshop }: { workshop: WorkshopModel }) => {
  const stripe = useStripe();
  const { data: { id, accountStatus } = {} } = useStripeAccount({
    type: 'workshop',
    id: workshop.id,
  });
  const { mutateAsync: getOnboardingLink, isPending } = useOnboardingLink();

  return (
    <Box>
      <Stack row gap="8px" justifyContent="space-between" alignItems="center">
        <b className="paragraph-100-medium">
          <Trans id="settings.workshop.stripe.label">Stripe account</Trans>
        </b>
        {accountStatus === 'complete' && (
          <Stack row gap="8px" alignItems="center" className="paragraph-100-regular">
            <IconCheck />
            <p>
              <Trans id="settings.workshop.status.ready">Ready</Trans>
            </p>
          </Stack>
        )}
        {accountStatus === 'pending' && (
          <Stack row gap="8px" alignItems="center" className="paragraph-100-regular">
            <IconClock />
            <p>
              <Trans id="settings.workshop.status.pending">Pending verification</Trans>
            </p>
          </Stack>
        )}
        {(accountStatus === 'none' || accountStatus === 'onboarding') && (
          <Button
            variant="primary"
            isLoading={!stripe || isPending}
            onPress={async () => {
              let stripeAccount: TokenResult | undefined = undefined;

              if (!id) {
                stripeAccount = await stripe!.createToken('account', {
                  business_type: 'company',
                  tos_shown_and_accepted: true,
                });
              }

              const { url } = await getOnboardingLink({
                type: 'workshop',
                id: workshop.id,
                accountTokenId: stripeAccount?.token?.id,
              });

              window.location.href = url;
            }}
          >
            <Trans id="settings.workshop.status.finish-onboarding">Finish onboarding</Trans>
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default Workshop;
