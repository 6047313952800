import { Dispatch, ReactNode, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import FileCard from '@/design_system/FileCard/FileCard';
import InputFile, { InputFileProps } from '@/design_system/InputFile';
import Message from '@/design_system/Message';
import { PhotoCardGrid } from '@/design_system/PhotoCard';
import Stack from '@/design_system/Stack';
import { CreateMediumBody, Medium, useCreateMedium, useDeleteMedium } from '@/models/medium';

type FileWithMediumId = { file: File; mediumId: string | null };

export type FileUploadProps = Omit<
  InputFileProps,
  'onUpload' | 'ariaLabel' | 'size' | 'variant'
> & {
  uploadData: CreateMediumBody;
  ariaLabel?: string;
  label?: ReactNode;
  icon?: ReactNode;
  prompt?: ReactNode;
  media?: Medium[];
  maxNumberOfMedia?: number;
  onChange?: Dispatch<SetStateAction<Medium[]>>;
  onUpload?: (medium: Medium) => void;
  onDelete?: (mediumId: string) => void;
  deleteWithApi?: boolean;
  disabled?: boolean;
  theme?: 'default' | 'brand';
  variant?: 'default' | 'inline' | 'grid';
  size?: 'large' | 'medium' | 'small';
  onMount?: (ref: RefObject<HTMLDivElement>) => void;
  isInvalid?: boolean;
  error?: string;
  style?: React.CSSProperties;
};

const FileUpload = ({
  ariaLabel,
  allowsMultiple = true,
  type,
  uploadData,
  label,
  prompt,
  icon,
  media = [],
  maxNumberOfMedia,
  onChange,
  onUpload,
  onDelete,
  deleteWithApi = false,
  disabled,
  theme = 'default',
  variant = 'default',
  size = 'medium',
  onMount,
  isInvalid,
  error,
  style,
}: FileUploadProps) => {
  const { _ } = useLingui();
  const { mutateAsync: createMedium } = useCreateMedium();
  const { mutateAsync: deleteMedium } = useDeleteMedium();

  const [loadingUploads, setLoadingUploads] = useState<FileWithMediumId[]>([]);
  const [failedUploads, setFailedUploads] = useState<File[]>([]);

  const handleCloseFailedUpload = (indexToDelete: number) => {
    setFailedUploads(failedUploads.filter((_, index) => indexToDelete !== index));
  };

  const handleUpload = async (files: File[]) => {
    setLoadingUploads((prev) => [...prev, ...files.map((file) => ({ file, mediumId: null }))]);

    await Promise.all(
      files.map(async (file) => {
        try {
          const medium = await createMedium({ file: file, ...uploadData });

          // Find the related loading upload and add the id of the medium that has just been created
          setLoadingUploads((previousLoadingUploads) =>
            previousLoadingUploads.map((previousLoadingUpload) =>
              previousLoadingUpload.file === file
                ? { ...previousLoadingUpload, mediumId: medium.id }
                : previousLoadingUpload
            )
          );

          onChange?.((prev) => [medium, ...prev]);
          onUpload?.(medium);
        } catch (e) {
          console.error('Error uploading', file.name, ':', e); // eslint-disable-line lingui/no-unlocalized-strings

          setLoadingUploads((prev) => prev.filter((loadingFile) => loadingFile.file !== file));
          setFailedUploads((failedUploads) => [...failedUploads, file]);
        }
      })
    );
  };

  const handleDelete = async (idToDelete: string) => {
    if (deleteWithApi) {
      await deleteMedium(idToDelete);
    }

    onChange?.((prev) => prev.filter(({ id }) => id !== idToDelete));
    onDelete?.(idToDelete);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onMount?.(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filter loading uploads by removing those for which a medium has been created
  useEffect(() => {
    if (media.length) {
      const filteredLoadingUploads = loadingUploads.filter(
        (loadingUpload) => !media.find((medium) => medium.id === loadingUpload.mediumId)
      );

      if (filteredLoadingUploads.length !== loadingUploads.length) {
        setLoadingUploads(filteredLoadingUploads);
      }
    }
  }, [loadingUploads, media]);

  const images = media.filter((medium) => medium.isImage);
  const otherFiles = media.filter((medium) => !medium.isImage);

  const totalNumberOfFiles =
    loadingUploads.length + failedUploads.length + images.length + otherFiles.length;

  const hasMaximumNumberOfMedia =
    maxNumberOfMedia !== undefined && totalNumberOfFiles >= maxNumberOfMedia;

  const shouldShowInput =
    !disabled &&
    (variant !== 'inline' || (variant === 'inline' && totalNumberOfFiles === 0)) &&
    !hasMaximumNumberOfMedia;

  const { loadingImageUploads, loadingFileUploads } = extractImageAndFileUploads(
    loadingUploads,
    media
  );

  const shouldShowPhotoCardGrid = [...images, ...loadingImageUploads].length > 0;
  const shouldShowInputInsidePhotoCardGrid = variant === 'grid' && shouldShowPhotoCardGrid;

  const input = shouldShowInput ? (
    <InputFile
      ariaLabel={ariaLabel ?? (typeof label === 'string' ? label : '')}
      icon={icon}
      prompt={!shouldShowInputInsidePhotoCardGrid ? prompt : null}
      type={type}
      onUpload={(files) => {
        handleUpload(files);
      }}
      allowsMultiple={allowsMultiple}
      theme={theme}
      variant={
        variant === 'grid' ? (shouldShowInputInsidePhotoCardGrid ? 'square' : 'default') : variant
      }
      size={size === 'large' ? 'medium' : size}
      isInvalid={isInvalid || !!error}
    />
  ) : null;

  return (
    <Stack gap="0.25rem" ref={ref} style={style}>
      {label && (
        <h3
          className={theme === 'brand' ? 'paragraph-50-medium' : 'label-100'}
          style={theme === 'brand' ? { marginBottom: '0.25rem' } : undefined}
        >
          {label}
        </h3>
      )}
      <Stack gap="0.25rem">
        {!shouldShowInputInsidePhotoCardGrid && input}
        {failedUploads.map((file, index) => (
          <FileCard
            key={index}
            name={file.name}
            size={file.size}
            error={true}
            onDelete={() => handleCloseFailedUpload(index)}
            ariaLabel={_(
              msg({
                id: 'components.file-upload.failed',
                message: `Upload failed for file ${file.name}`,
              })
            )}
          />
        ))}
        {loadingFileUploads.map((loadingFileUpload) => (
          <FileCard
            key={loadingFileUpload.file.name}
            name={loadingFileUpload.file.name}
            size={loadingFileUpload.file.size}
            loading={true}
          />
        ))}
        {otherFiles.map((medium) => (
          <FileCard
            key={medium.id}
            name={medium.originalPath}
            url={medium.url}
            size={medium.size}
            onDelete={
              disabled
                ? undefined
                : () => {
                    handleDelete(medium.id);
                  }
            }
          />
        ))}
        {variant !== 'inline' && shouldShowPhotoCardGrid && (
          <PhotoCardGrid
            loadingUploads={loadingImageUploads.map(
              (loadingImageUpload) => loadingImageUpload.file
            )}
            media={images}
            onDelete={
              disabled
                ? undefined
                : (id) => {
                    handleDelete(id);
                  }
            }
            size={size}
            variant={variant === 'grid' ? 'default' : variant}
            input={shouldShowInputInsidePhotoCardGrid ? input : null}
          />
        )}
        {variant === 'inline' &&
          images.length > 0 &&
          images.map((medium) => (
            <FileCard
              key={medium.id}
              name={medium.originalPath}
              url={medium.url}
              size={medium.size}
              onDelete={
                disabled
                  ? undefined
                  : () => {
                      handleDelete(medium.id);
                    }
              }
            />
          ))}
        {media.length === 0 && disabled && <p className="paragraph-100-regular">-</p>}
        {error && <Message type="error">{error}</Message>}
      </Stack>
    </Stack>
  );
};

export default FileUpload;

/**
 *
 * @param loadingUploads All files in loading state.
 * @returns Only files for which the medium hasn't been created yet. Also, it separates images and files.
 */
const extractImageAndFileUploads = (
  loadingUploads: FileWithMediumId[],
  media: Medium[]
): {
  loadingImageUploads: FileWithMediumId[];
  loadingFileUploads: FileWithMediumId[];
} => {
  return loadingUploads.reduce(
    (
      accumulator: {
        loadingImageUploads: FileWithMediumId[];
        loadingFileUploads: FileWithMediumId[];
      },
      loadingUpload
    ) => {
      // Do not keep loading files and images if the related medium is successfully downloaded
      // @ts-ignore added after createMedium(). Necessary to be able to filter loading uploads having a media downloaded.
      if (media.find((medium) => medium.id === loadingUpload.mediumId)) {
        return accumulator;
      }

      if (loadingUpload.file.type.startsWith('image/')) {
        return {
          loadingImageUploads: [...accumulator.loadingImageUploads, loadingUpload],
          loadingFileUploads: accumulator.loadingFileUploads,
        };
      } else {
        return {
          loadingImageUploads: accumulator.loadingImageUploads,
          loadingFileUploads: [...accumulator.loadingFileUploads, loadingUpload],
        };
      }
    },
    { loadingFileUploads: [], loadingImageUploads: [] }
  );
};
