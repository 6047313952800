import IconWrapper from '@/icons/IconWrapper';

const IconNotWaterproofSeam = IconWrapper({
  name: 'not-waterproof-seam',
  source: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
    >
      <g className="non-waterproof">
        <path
          fill="#fff"
          d="M23.079 71.056c-5.015 0-9.079-4.259-9.079-9.502 0-7.733 6.011-19.139 8.25-23.12a.943.943 0 0 1 1.657 0c2.24 3.98 8.25 15.379 8.25 23.12.012 5.243-4.052 9.502-9.078 9.502Zm81 18c-5.015 0-9.079-4.259-9.079-9.502 0-7.733 6.011-19.139 8.251-23.12a.943.943 0 0 1 1.656 0c2.24 3.98 8.251 15.379 8.251 23.12.011 5.243-4.053 9.502-9.079 9.502Zm-32-41c-5.015 0-9.079-4.259-9.079-9.502 0-7.733 6.011-19.139 8.25-23.12a.943.943 0 0 1 1.657 0c2.24 3.98 8.25 15.379 8.25 23.12.012 5.243-4.052 9.502-9.078 9.502Zm-17 65c-5.015 0-9.079-4.259-9.079-9.502 0-7.733 6.011-19.139 8.25-23.12a.943.943 0 0 1 1.657 0c2.24 3.98 8.25 15.379 8.25 23.12.012 5.243-4.052 9.502-9.078 9.502Z"
          className="fill-accent"
        />
        <path
          fill="#3F4643"
          fillRule="evenodd"
          d="M70.38 14.944a1.943 1.943 0 0 1 3.399 0c1.13 2.007 3.214 5.89 5.018 10.257 1.791 4.336 3.36 9.273 3.36 13.35l-1 .003h1v-.002c.013 5.755-4.459 10.504-10.078 10.504-5.61 0-10.079-4.75-10.079-10.502 0-4.074 1.57-9.011 3.361-13.349 1.804-4.367 3.888-8.252 5.018-10.261ZM72.08 16c-1.12 1.994-3.132 5.759-4.87 9.968C65.436 30.263 64 34.895 64 38.554c0 4.735 3.66 8.502 8.079 8.502 4.432 0 8.089-3.768 8.079-8.5v-.002c0-3.664-1.436-8.296-3.21-12.589-1.738-4.208-3.75-7.97-4.869-9.963ZM24.779 37.943a1.943 1.943 0 0 0-3.4 0c-1.13 2.01-3.214 5.895-5.018 10.263C14.57 52.543 13 57.48 13 61.553c0 5.751 4.47 10.502 10.079 10.502 5.62 0 10.09-4.75 10.079-10.503 0-4.078-1.57-9.015-3.361-13.352-1.804-4.366-3.888-8.25-5.018-10.258Zm-6.57 11.026c1.74-4.209 3.751-7.974 4.87-9.968 1.12 1.993 3.13 5.756 4.87 9.964 1.773 4.293 3.209 8.925 3.209 12.589v.002c.01 4.732-3.647 8.5-8.08 8.5-4.419 0-8.078-3.767-8.078-8.502 0-3.66 1.436-8.291 3.21-12.585Zm14.949 12.584h-1l1-.002v.002Zm72.621-5.61a1.944 1.944 0 0 0-3.4 0c-1.13 2.01-3.214 5.895-5.018 10.263C95.57 70.543 94 75.48 94 79.553c0 5.751 4.47 10.502 10.079 10.502 5.619 0 10.091-4.75 10.079-10.504v.002h-1l1-.003c-.001-4.077-1.57-9.014-3.361-13.35-1.804-4.366-3.888-8.25-5.018-10.258Zm-6.57 11.026c1.74-4.209 3.751-7.974 4.87-9.967 1.119 1.992 3.131 5.755 4.869 9.963 1.774 4.293 3.21 8.925 3.21 12.589v.002c.01 4.732-3.647 8.5-8.079 8.5-4.42 0-8.079-3.767-8.079-8.502 0-3.66 1.436-8.291 3.21-12.585Zm-42.43 12.974a1.943 1.943 0 0 0-3.4 0c-1.13 2.01-3.214 5.895-5.018 10.263C46.57 94.543 45 99.48 45 103.553c0 5.752 4.47 10.502 10.079 10.502 5.62 0 10.09-4.749 10.079-10.504v.002h-1l1-.003c0-4.077-1.57-9.014-3.361-13.35-1.804-4.366-3.888-8.25-5.018-10.258Zm-6.57 11.026c1.74-4.209 3.751-7.974 4.87-9.968 1.12 1.993 3.13 5.756 4.87 9.964 1.773 4.293 3.209 8.925 3.209 12.589v.002c.01 4.732-3.647 8.5-8.08 8.5-4.419 0-8.078-3.767-8.078-8.502 0-3.66 1.436-8.291 3.21-12.585Z"
          className="stroke"
          clipRule="evenodd"
        />
      </g>
    </svg>
  ),
});

export default IconNotWaterproofSeam;
