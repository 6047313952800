import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { useAcceptInvitation, useDeleteUserRole, useUserRole } from '@/models/userRole';
import { useCurrentSession } from '@/services/auth';
import { ORGANIZATION_LOCAL_STORAGE_KEY } from '@/utils/organization';

const Organization = () => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { organization } = useParams();
  const { currentSession } = useCurrentSession();

  const [error, setError] = useState('');

  const { data: userRole, isLoading } = useUserRole({
    slug: organization!,
  });

  const {
    mutateAsync: deleteUserRole,
    isPending: isPendingDeleteUserRole,
    isSuccess: isSuccessDeleteUserRole,
  } = useDeleteUserRole();

  const {
    mutateAsync: acceptInvitation,
    isPending: isPendingAcceptInvitation,
    isSuccess: isSuccessAcceptInvitation,
  } = useAcceptInvitation();

  if (isLoading) {
    return null;
  }

  if (!userRole) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <h1 className="headline-200-bold text-center">
        <Trans id="join.organization.title">
          You have been invited to join the {userRole.organization.name} organization
        </Trans>
      </h1>
      <p className="paragraph-100-regular text-center">
        <Trans id="join.organization.subtitle">
          By accepting the invitation, you will be able to access the organization&apos;s data and
          contribute to it.
        </Trans>
      </p>
      {error && <span className="paragraph-100-medium text-danger text-center">{error}</span>}
      <Stack row justifyContent="center" gap="1rem">
        <Button
          variant="secondary"
          type="button"
          isLoading={isPendingDeleteUserRole}
          disabled={
            isSuccessDeleteUserRole || isPendingAcceptInvitation || isSuccessAcceptInvitation
          }
          onPress={() => {
            deleteUserRole({ email: currentSession!.email })
              .then(() => navigate('/', { replace: true }))
              .catch((err) => {
                console.error(err);
                setError(
                  (err.message as string) ??
                    _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
                );
              });
          }}
        >
          <Trans id="join.organization.decline">Decline invitation</Trans>
        </Button>
        <Button
          variant="primary"
          type="button"
          isLoading={isPendingAcceptInvitation}
          disabled={isSuccessAcceptInvitation || isPendingDeleteUserRole || isSuccessDeleteUserRole}
          onPress={() => {
            acceptInvitation({ slug: userRole.organization.slug })
              .then(() => {
                // setCurrentOrganization of useCurrentOrganization seems to redirect too fast, so we do it manually
                localStorage.setItem(
                  ORGANIZATION_LOCAL_STORAGE_KEY,
                  `"${userRole.organization.slug}"`
                );
                window.location.href = '/';
              })
              .catch((err) => {
                console.error(err);
                setError(
                  (err.message as string) ??
                    _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
                );
              });
          }}
        >
          <Trans id="join.organization.accept">Accept invitation</Trans>
        </Button>
      </Stack>
    </>
  );
};

export default Organization;
