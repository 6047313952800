import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import './ProgressBar.css';

export const ProgressBar = ({ progress }: { progress: number }) => {
  const { _ } = useLingui();

  return (
    <div
      className="client__progress-bar"
      aria-label={_(
        msg({
          id: 'client.progress-bar.label',
          message: `Progress: ${progress}% complete`,
        })
      )}
    >
      <div className="client__progress-bar__fill" style={{ width: `${progress}%` }} />
    </div>
  );
};
