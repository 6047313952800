import { addBusinessDays } from 'date-fns/addBusinessDays';

import Stack from '@/design_system/Stack';
import IconCalendar from '@/icons/Calendar.svg';
import { formatDate } from '@/utils/date';

const DueAtType = ({ type, days }: { type: string; days: number }) => {
  const isBefore10 = new Date().getUTCHours() < 10;
  const dueAt = addBusinessDays(new Date(), isBefore10 ? days - 1 : days);

  return (
    <Stack row gap="0.5rem" alignItems="center">
      <IconCalendar style={{ fontSize: '1.25rem' }} />
      <span className="paragraph-100-regular" style={{ textTransform: 'capitalize' }}>
        {type}:{' '}
        <span className="paragraph-100-medium">
          {formatDate(dueAt, { day: '2-digit', month: '2-digit' })}
        </span>
      </span>
    </Stack>
  );
};

export default DueAtType;
