import Stack from '@/design_system/Stack';

import { Invitations } from './Invitations';
import { Members } from './Members';

export const Users = () => {
  return (
    <Stack gap="1rem">
      <Members />
      <Invitations />
    </Stack>
  );
};
