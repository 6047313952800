import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

import { useSearchParamState } from './useSearchParams';

const useDebouncedState = <T>(
  initialState: T,
  delay = 500,
  onStateChanged?: (value: T) => void
): [T, T, (value: T) => void] => {
  const [state, setState] = useState<T>(initialState);
  const [debouncedState, setDebouncedState] = useState<T>(initialState);
  const debouncedSetState = useMemo(
    () =>
      debounce((value: T) => {
        setDebouncedState(value);
        onStateChanged?.(value);
      }, delay),
    [delay, onStateChanged]
  );
  const setStateAndDebouncedState = useCallback(
    (value: T) => {
      setState(value);
      debouncedSetState(value);
    },
    [debouncedSetState]
  );

  return [state, debouncedState, setStateAndDebouncedState];
};

export default useDebouncedState;

export const useDebouncedSearchParamState = <T extends string | number | string[]>(
  group: string,
  key: string,
  initialState: T,
  delay = 500,
  onStateChanged = () => {}
): [T, T, (value: T) => void, [string, T, T]] => {
  const [debouncedState, setDebouncedState, syncDebouncedState] = useSearchParamState<T>(
    group,
    key,
    initialState
  );
  const [state, setState] = useState<T>(debouncedState);
  const debouncedSetState = useMemo(
    () =>
      debounce((value: T) => {
        setDebouncedState(value);
        onStateChanged();
      }, delay),
    [delay, onStateChanged, setDebouncedState]
  );

  return [
    state,
    debouncedState,
    (value: T) => {
      setState(value);
      debouncedSetState(value);
    },
    syncDebouncedState,
  ];
};
