import { createContext, useContext } from 'react';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CreationStepConfig } from '@/api';
import { RequestWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';

interface RequestContextData {
  request: RequestWithRelations;
  view: {
    workshop: {
      enabled: boolean;
    };
    cost: {
      enabled: boolean;
      label: string;
    };
    price: {
      enabled: boolean;
      label: string;
    };
  };
}

const RequestContext = createContext({} as RequestContextData);

export const RequestContextProvider = ({
  request,
  isCreation,
  children,
}: {
  request: RequestWithRelations;
  isCreation?: boolean;
  children: React.ReactNode;
}) => {
  const { _ } = useLingui();
  const { isWorkshop } = useCurrentSession();

  let showWorkshop = false;
  let showCost = false;
  let showPrice = false;

  if (isCreation) {
    const creationConfig = request.articles[0]?.step?.config as
      | CreationStepConfig['config']
      | undefined;

    showWorkshop = !!creationConfig?.requireDispatch || !!creationConfig?.requireServiceChoice;
    showCost = !!creationConfig?.requireCost;
    showPrice = creationConfig?.requirePrice === true && request.requestorType !== 'store';
  } else {
    showWorkshop = !isWorkshop;
    showCost = !isWorkshop;
    showPrice = isWorkshop || (!isWorkshop && request.requestorType !== 'store');
  }

  const value = {
    request,
    view: {
      workshop: {
        enabled: showWorkshop,
      },
      cost: {
        enabled: showCost,
        label: _(
          msg({
            id: 'request-context.cost',
            message: 'Internal cost',
          })
        ),
      },
      price: {
        enabled: showPrice,
        label: isWorkshop
          ? _(
              msg({
                id: 'request-context.price.workshop',
                message: 'Price',
              })
            )
          : _(
              msg({
                id: 'request-context.price.organization',
                message: 'Client price',
              })
            ),
      },
    },
  };

  return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
};

export const useRequestContext = () => useContext(RequestContext);
