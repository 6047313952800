import { msg, Trans } from '@lingui/macro';

import { ActivityOfType } from '@/components/activities/Activity';
import { BaseActivity, BaseActivityText } from '@/components/activities/BaseActivity/BaseActivity';
import IconShipmentDone from '@/icons/ShipmentDone.svg';
import { SHIPMENT_CARRIERS } from '@/models/shipment';

export const ShipmentTransitCompletedActivity = ({
  activity,
}: {
  activity: ActivityOfType<'shipment_transit_completed'>;
}) => {
  return (
    <BaseActivity
      createdAt={activity.createdAt}
      type="primary"
      icon={<IconShipmentDone />}
      creatorName={
        SHIPMENT_CARRIERS.find((c) => c.id === activity.shipment.carrier)?.name ??
        msg({
          id: 'activities.shipment_transit_completed.private-carrier',
          message: 'A private carrier',
        })
      }
      message={
        <BaseActivityText>
          <Trans id="activities.shipment_transit_completed.message">
            delivered the package to{' '}
            <span className="paragraph-100-medium">
              {activity.shipment.destinationAddress?.contactName}
            </span>
          </Trans>
        </BaseActivityText>
      }
    />
  );
};
