import { useState } from 'react';
import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import IconSend from '@/icons/Send.svg';

import { UserDialog } from './UserDialog';

export const InviteUser = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button variant="secondary" size="small" onPress={() => setIsOpen(true)}>
        <IconSend />
        <Trans id="settings.users.invite-modal.trigger">Invite new member</Trans>
      </Button>
      <UserDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
