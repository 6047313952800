import { CSSProperties } from 'react';
import { Button as AriaButton } from 'react-aria-components';

import { createBEMClasses } from '@/utils/classname';

import './Badge.css';

const { block, element } = createBEMClasses('badge');

const Ellipse = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="6"
    viewBox="0 0 6 6"
    fill="currentColor"
    className="badge__ellipse"
    style={{ minWidth: 6, minHeight: 6 }}
  >
    <circle cx="3" cy="3" r="3" />
  </svg>
);

export interface BadgeProps {
  size?: 'x-small' | 'small' | 'large';
  color?:
    | 'black'
    | 'green'
    | 'red'
    | 'purple'
    | 'deep-purple'
    | 'orange'
    | 'deep-orange'
    | 'blue'
    | 'deep-blue';
  variant?: 'low' | 'high';
  ellipse?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  style?: CSSProperties;
}

const Badge = ({
  size = 'small',
  color = 'black',
  variant = 'high',
  ellipse,
  onClick,
  children,
  style,
}: BadgeProps) => {
  const className = block({
    size,
    variant,
    color,
    clickable: !!onClick,
  });

  if (onClick) {
    return (
      <AriaButton id="" className={className} onPress={onClick} style={style}>
        {ellipse && <Ellipse />}
        <div className={element('content')}>{children}</div>
      </AriaButton>
    );
  }

  return (
    <div className={className} style={style}>
      {ellipse && <Ellipse />}
      <div className={element('content')}>{children}</div>
    </div>
  );
};

export default Badge;
