import { CSSProperties, useState } from 'react';
import {
  Button as AriaButton,
  Calendar as AriaCalendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  DateInput,
  DatePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Label,
  Popover,
} from 'react-aria-components';
import { DateValue, fromDate, getLocalTimeZone, isToday, parseDate } from '@internationalized/date';

import Button from '@/design_system/Button';
import Hint from '@/design_system/Hint';
import Message from '@/design_system/Message';
import IconCalendar from '@/icons/Calendar.svg';
import IconChevron from '@/icons/Chevron.svg';
import { createBEMClasses } from '@/utils/classname';

import './InputDate.css';

const { block, element } = createBEMClasses('input-date');

export type InputDateProps = {
  /** Only the YYYY-MM-DD section of the date string is considered */
  value?: string | null;
  minValue?: string;
  maxValue?: string;

  /** The value is sent as a ISO datetime string, with the time part at midnight on UTC  timezone: YYYY-MM-DDT00:00:00Z */
  onChange?: (value: string | undefined) => void;

  label?: React.ReactNode;
  ariaLabel?: string;
  hintText?: string;
  messageType?: 'error' | 'success' | 'info';
  messageText?: string;

  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'brand';
  isDisabled?: boolean;
  className?: string;
  style?: CSSProperties;
  onBlur?: () => void;
};

const InputDate = ({
  value,
  onChange,
  minValue,
  maxValue,
  label,
  ariaLabel,
  hintText,
  messageType,
  messageText,
  size = 'medium',
  variant = 'default',
  isDisabled,
  className,
  style,
  onBlur,
}: InputDateProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DatePicker
      value={tryParseIsoString(value) ?? null}
      onChange={(value) => onChange?.(value?.toDate('Etc/UTC').toISOString())} // eslint-disable-line lingui/no-unlocalized-strings
      minValue={tryParseIsoString(minValue)}
      maxValue={tryParseIsoString(maxValue)}
      granularity="day"
      isDisabled={isDisabled}
      aria-label={ariaLabel}
      className={block({ size, variant }, className)}
      style={style}
      onBlur={onBlur}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      onFocusChange={(isFocused) => (isFocused ? setIsOpen(true) : undefined)}
    >
      {label && (
        <Label className={size === 'large' ? 'paragraph-50-medium' : 'label-100'}>{label}</Label>
      )}
      <Group className={element('input')}>
        <DateInput className={element('input__date')}>
          {(segment) => (
            <DateSegment segment={segment} className={element('input__date__segment')} />
          )}
        </DateInput>
        <AriaButton isDisabled={isDisabled}>
          <IconCalendar />
        </AriaButton>
      </Group>
      {hintText && <Hint>{hintText}</Hint>}
      {messageText && <Message type={messageType}>{messageText}</Message>}
      <Popover placement="bottom">
        <Dialog>
          <Calendar embedded variant={variant} size={size === 'large' ? 'large' : 'medium'} />
        </Dialog>
      </Popover>
    </DatePicker>
  );
};

const tryParseIsoString = (value?: string | null) => {
  let parsedValue;

  if (value) {
    try {
      parsedValue = parseDate(value.slice(0, 10));
    } catch (e) {
      console.error(e);
    }
  }

  return parsedValue;
};

export const Calendar = ({
  value,
  onChange,
  embedded,
  minValue,
  variant = 'default',
  size = 'medium',
  isDateUnavailable,
}: {
  /** Only the YYYY-MM-DD section of the date string is considered */
  value?: string | null;
  /** The value is sent as a ISO datetime string, with the time part at midnight on UTC  timezone: YYYY-MM-DDT00:00:00Z */
  onChange?: (value: string | undefined) => void;
  embedded?: boolean;
  minValue?: Date;
  variant?: 'default' | 'brand';
  size?: 'medium' | 'large';
  isDateUnavailable?: (date: DateValue) => boolean;
}) => {
  return (
    <AriaCalendar
      className={element('calendar', { embedded, variant, size })}
      minValue={
        minValue ? fromDate(minValue, Intl.DateTimeFormat().resolvedOptions().timeZone) : undefined
      }
      isDateUnavailable={isDateUnavailable}
      value={value ? (tryParseIsoString(value) ?? null) : undefined}
      onChange={
        onChange
          ? (value) =>
              onChange(
                /* eslint-disable-next-line lingui/no-unlocalized-strings */
                value?.toDate('Etc/UTC').toISOString()
              )
          : undefined
      }
    >
      <header>
        <Button
          slot="previous"
          variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
          size="medium"
          iconOnly
        >
          <IconChevron left></IconChevron>
        </Button>
        <Heading />
        <Button
          slot="next"
          variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
          size="medium"
          iconOnly
        >
          <IconChevron right></IconChevron>
        </Button>
      </header>
      <CalendarGrid className={element('calendar__grid')}>
        <CalendarGridHeader>
          {(day) => (
            <CalendarHeaderCell>
              <div className={element('calendar__header-cell')}>{day}</div>
            </CalendarHeaderCell>
          )}
        </CalendarGridHeader>
        <CalendarGridBody>
          {(date) => (
            <CalendarCell
              date={date}
              className={element('calendar__cell', {
                today: isToday(date, getLocalTimeZone()),
              })}
            />
          )}
        </CalendarGridBody>
      </CalendarGrid>
    </AriaCalendar>
  );
};

export default InputDate;
