import { Button as AriaButton } from 'react-aria-components';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconCross from '@/icons/Cross.svg';
import { createBEMClasses } from '@/utils/classname';

import './Box.css';

const { block, element } = createBEMClasses('box');

interface BoxProps {
  className?: string;
  width?: string;
  padding?: string;
  gap?: React.CSSProperties['gap'];
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  flexWrap?: React.CSSProperties['flexWrap'];
  onClose?: () => void;
  ariaLabel?: string;
  removeStyleOnMobile?: boolean;
  children: React.ReactNode;
  role?: string;
  style?: React.CSSProperties;
}

const Box = ({
  className,
  width,
  padding = '16px',
  gap,
  alignItems,
  justifyContent,
  flexWrap,
  onClose,
  ariaLabel,
  removeStyleOnMobile,
  children,
  role,
  style,
}: BoxProps) => {
  const { _ } = useLingui();

  return (
    <div
      className={block({ 'remove-style-on-mobile': removeStyleOnMobile }, className)}
      style={{ width, padding, gap, alignItems, justifyContent, flexWrap, ...style }}
      aria-label={ariaLabel}
      role={role}
    >
      {onClose && (
        <AriaButton
          className={element('close')}
          onPress={onClose}
          aria-label={_(msg({ id: 'design-system.box.close', message: 'Close' }))}
        >
          <IconCross />
        </AriaButton>
      )}
      {children}
    </div>
  );
};

export default Box;
