import { ReactNode, useEffect } from 'react';
import { Dialog, Modal } from 'react-aria-components';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import { DialogWrapper } from '@/design_system/Dialog';
import IconChevron from '@/icons/Chevron.svg';
import IconCross from '@/icons/Cross.svg';
import { createBEMClasses } from '@/utils/classname';

import './PhotoDialog.css';

export type PhotoDialogProps = {
  url: string;
  alt: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onPrevious?: () => void;
  onNext?: () => void;
  disablePrevious?: boolean;
  disableNext?: boolean;
  variant?: 'default' | 'brand';
  customIcon?: ReactNode;
};

const { block, element } = createBEMClasses('photo-dialog');

const PhotoDialog = ({
  url,
  alt,
  isOpen,
  onOpenChange,
  onPrevious,
  onNext,
  disablePrevious,
  disableNext,
  variant = 'default',
  customIcon,
}: PhotoDialogProps) => {
  const { _ } = useLingui();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowRight':
          onNext?.();
          break;
        case 'ArrowLeft':
          onPrevious?.();
          break;
        default:
          break;
      }
    };

    // On Chrome, the keydown event is not triggered with the Escape key
    // Therefore, for that key we use the keyup event instead.
    // But the UX is nicer with the keydown event, so we keep it for the other keys
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onOpenChange(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [onNext, onOpenChange, onPrevious]);

  return (
    <DialogWrapper isOpen={isOpen} onOpenChange={onOpenChange}>
      <Modal>
        <Dialog
          className={block()}
          aria-label={_(msg({ id: 'design-system.photo-dialog', message: 'Photo in full screen' }))}
        >
          {onPrevious && onNext && (
            <div className={element('previous')}>
              <Button
                iconOnly
                variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
                size="large"
                onPress={onPrevious}
                disabled={disablePrevious}
                ariaLabel={_(
                  msg({ id: 'design-system.photo-dialog.previous', message: 'Previous photo' })
                )}
                tooltip={_(
                  msg({ id: 'design-system.photo-dialog.previous', message: 'Previous photo' })
                )}
              >
                <IconChevron left />
              </Button>
            </div>
          )}
          <div className={element('image-close')}>
            <div>
              <img src={url} alt={alt} />
              <Button
                iconOnly
                variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
                size="large"
                onPress={() => onOpenChange(false)}
                ariaLabel={_(
                  msg({ id: 'design-system.photo-dialog.close', message: 'Close preview' })
                )}
                tooltip={_(
                  msg({ id: 'design-system.photo-dialog.close', message: 'Close preview' })
                )}
                className={element('close')}
              >
                <IconCross />
              </Button>
              <div className={element('custom-icon')}>{customIcon}</div>
            </div>
          </div>
          {onPrevious && onNext && (
            <div className={element('next')}>
              <Button
                iconOnly
                variant={variant === 'brand' ? 'secondary-brand' : 'secondary'}
                size="large"
                onPress={onNext}
                disabled={disableNext}
                ariaLabel={_(msg({ id: 'design-system.photo-dialog.next', message: 'Next photo' }))}
                tooltip={_(msg({ id: 'design-system.photo-dialog.next', message: 'Next photo' }))}
              >
                <IconChevron right />
              </Button>
            </div>
          )}
        </Dialog>
      </Modal>
    </DialogWrapper>
  );
};

export default PhotoDialog;
