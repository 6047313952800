import { useContext } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ActionName from '@/components/ActionTypeSearchSelect/ActionName';
import { ActionTypeSearchSelectContext } from '@/components/ActionTypeSearchSelect/ActionTypeSearchSelect';
import PackActionName from '@/components/ActionTypeSearchSelect/PackActionName';
import { BaseMoneyCell } from '@/components/ArticlesTableCells';
import { DefectPhotos } from '@/components/DefectPhotos';
import Box from '@/design_system/Box';
import { InputMoney } from '@/design_system/InputNumber';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Footer, Header, Row, Table } from '@/design_system/Table/Table';
import IconTools from '@/icons/Tools.svg';
import {
  computeDuplicateActionNumber,
  getPackActionTypeOrganizationWithRefashionStatus,
  useActionName,
} from '@/models/actionType';
import { ArticleActionWithRelations } from '@/models/request';
import { useCurrentSession } from '@/services/auth';
import useViewPort from '@/utils/useViewport';

import { BrandResponsibilityCell, DeleteButton, DeleteCell, EditCustomActionCell } from './Cells';

export const ActionsTable = ({
  missingDefectPhotoActionIds,
  children,
}: {
  missingDefectPhotoActionIds?: string[];
  children: React.ReactNode;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  const {
    actions,
    oldActions = [],
    isEditActionDisabled,
    isDisabled,
    showDefectPhotos,
    showWorkshopPrice,
    showOrganizationPrice,
    showWarrantyCover,
  } = useContext(ActionTypeSearchSelectContext);

  const isWorkshop = !!currentSession?.workshop;

  if (isMobile) {
    return (
      <Stack gap="0.5rem">
        {oldActions.map((action) => (
          <ActionCard
            key={action.id}
            action={action}
            duplicateActionNumber={computeDuplicateActionNumber(action, oldActions)}
            old
          />
        ))}
        {actions.map((action) => (
          <ActionCard
            key={action.id}
            action={action}
            duplicateActionNumber={computeDuplicateActionNumber(action, actions)}
          />
        ))}
      </Stack>
    );
  }

  const showEditColumn =
    actions.filter((action) => !!action.customDescription).length > 0 && !isEditActionDisabled;

  const editAndDeleteColumnWidth =
    showEditColumn && !isDisabled ? '110px' : showEditColumn || !isDisabled ? '60px' : undefined;

  return (
    <Table
      ariaLabel={_(
        msg({
          id: 'article.form.actions.table',
          message: 'Actions',
        })
      )}
      columnWidths={[
        'minmax(250px, 1fr)',
        showDefectPhotos && 'minmax(250px, 1fr)',
        showWarrantyCover && '120px',
        showWorkshopPrice && '120px',
        showOrganizationPrice && '120px',
        editAndDeleteColumnWidth,
      ]}
      bordered
      variant="grid"
    >
      <Header>
        <Row>
          {![...oldActions, ...actions].length ? (
            <Cell isWholeRow>
              <span className="paragraph-200-regular">
                <Trans id="article.form.actions.table.no-action-added">No action added yet</Trans>
              </span>
            </Cell>
          ) : (
            <>
              <Column>
                <Trans id="article.form.actions.table.column.name.title.action">Action</Trans>
              </Column>

              {showDefectPhotos && (
                <Column>
                  <Trans id="article.form.actions.table.column.defect-photos.title">
                    Defect photos
                  </Trans>
                </Column>
              )}

              {showWarrantyCover && (
                <Column justifyContent="center">
                  <Trans id="article.form.actions.table.column.warranty-cover.title">
                    Warranty cover?
                  </Trans>
                </Column>
              )}
              {showWorkshopPrice && (
                <Column justifyContent="end">
                  {isWorkshop ? (
                    <Trans id="article.form.actions.table.column.cost.title-external-workshop">
                      Price
                    </Trans>
                  ) : (
                    <Trans id="article.form.actions.table.column.cost.title">Cost</Trans>
                  )}
                </Column>
              )}
              {showOrganizationPrice && (
                <Column justifyContent="end">
                  <Trans id="article.form.actions.table.column.client-price.title">
                    Client price
                  </Trans>
                </Column>
              )}
              {(showEditColumn || !isDisabled) && <Column justifyContent="flex-end" />}
            </>
          )}
        </Row>
      </Header>

      <Body>
        {oldActions.map((action) => (
          <ActionRow
            key={action.id}
            action={action}
            duplicateActionNumber={computeDuplicateActionNumber(action, oldActions)}
            showEditColumn={showEditColumn}
            old
          />
        ))}
        {actions.map((action) => (
          <ActionRow
            key={action.id}
            action={action}
            duplicateActionNumber={computeDuplicateActionNumber(action, actions)}
            showEditColumn={showEditColumn}
            showMissingDefectPhotoError={
              !!missingDefectPhotoActionIds?.find((actionId) => action.id === actionId)
            }
          />
        ))}
      </Body>
      {!isDisabled && (
        <Footer>
          <Row>
            <Cell isWholeRow>{children}</Cell>
          </Row>
        </Footer>
      )}
    </Table>
  );
};

export const LegacyActionsTable = () => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const { currentSession } = useCurrentSession();

  const {
    actions,
    oldActions = [],
    isEditActionDisabled,
    mode,
    isDisabled,
    showWorkshopPrice,
    showOrganizationPrice,
    showWarrantyCover,
  } = useContext(ActionTypeSearchSelectContext);

  const isWorkshop = !!currentSession?.workshop;

  if (isMobile) {
    return (
      <Stack gap="0.5rem">
        {oldActions.map((action) => (
          <LegacyActionCard
            key={action.id}
            action={action}
            duplicateActionNumber={computeDuplicateActionNumber(action, oldActions)}
            old
          />
        ))}
        {actions.map((action) => (
          <LegacyActionCard
            key={action.id}
            action={action}
            duplicateActionNumber={computeDuplicateActionNumber(action, actions)}
          />
        ))}
      </Stack>
    );
  }

  const showEditColumn =
    actions.filter((action) => !!action.customDescription).length > 0 && !isEditActionDisabled;

  const editAndDeleteColumnWidth =
    showEditColumn && !isDisabled ? '110px' : showEditColumn || !isDisabled ? '60px' : undefined;

  return (
    <Table
      ariaLabel={_(
        mode === 'need'
          ? msg({
              id: 'article.form.actions.table.column.name.title.defect',
              message: 'Needs',
            })
          : mode === 'action'
            ? msg({
                id: 'article.form.legacy-actions.table.column.name.title.action',
                message: 'Actions',
              })
            : msg({
                id: 'article.form.actions.table.column.name.title',
                message: 'Needs & actions',
              })
      )}
      columnWidths={[
        'minmax(250px, 1fr)',
        'minmax(250px, 1fr)',
        showWorkshopPrice && '110px',
        showOrganizationPrice && '110px',
        showWarrantyCover && '110px',
        editAndDeleteColumnWidth,
      ]}
      bordered
    >
      <Header>
        <Row>
          <Column>
            {mode === 'need' ? (
              <Trans id="article.form.actions.table.column.name.title.defect">Needs</Trans>
            ) : mode === 'action' ? (
              <Trans id="article.form.legacy-actions.table.column.name.title.action">Actions</Trans>
            ) : (
              <Trans id="article.form.actions.table.column.name.title">Needs & actions</Trans>
            )}
          </Column>
          <Column>
            <Trans id="article.form.actions.table.column.defect-photos.title">Defect photos</Trans>
          </Column>
          {showWorkshopPrice && (
            <Column justifyContent="end">
              {isWorkshop ? (
                <Trans id="article.form.actions.table.column.cost.title-external-workshop">
                  Price
                </Trans>
              ) : (
                <Trans id="article.form.actions.table.column.cost.title">Cost</Trans>
              )}
            </Column>
          )}
          {showOrganizationPrice && (
            <Column justifyContent="end">
              <Trans id="article.form.actions.table.column.client-price.title">Client price</Trans>
            </Column>
          )}
          {showWarrantyCover && (
            <Column justifyContent="center">
              <Trans id="article.form.actions.table.column.brand-responsibility.title">
                Brand Resp.
              </Trans>
            </Column>
          )}
          {/* Edit and/or delete buttons */}
          {(showEditColumn || !isDisabled) && <Column justifyContent="flex-end" />}
        </Row>
      </Header>

      <Body>
        {oldActions.map((action) => (
          <LegacyActionRow
            key={action.id}
            action={action}
            duplicateActionNumber={computeDuplicateActionNumber(action, oldActions)}
            showEditColumn={showEditColumn}
            old
          />
        ))}
        {actions.map((action) => (
          <LegacyActionRow
            key={action.id}
            action={action}
            duplicateActionNumber={computeDuplicateActionNumber(action, actions)}
            showEditColumn={showEditColumn}
          />
        ))}
      </Body>
    </Table>
  );
};

const OLD_ACTION_STYLE = {
  textDecoration: 'line-through',
  color: 'var(--color-text-disabled-black)',
};

const ActionCard = ({
  action,
  duplicateActionNumber,
  old,
}: {
  action: ArticleActionWithRelations;
  duplicateActionNumber?: number;
  old?: boolean;
}) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const isWorkshop = !!currentSession?.workshop;
  const {
    request,
    article,
    isDisabled,
    isEditActionDisabled,
    showWorkshopPrice,
    showOrganizationPrice,
    isRequalification,
  } = useContext(ActionTypeSearchSelectContext);

  const actionName = useActionName({ action, mode: 'action' });

  const refashionStatus = currentSession?.workshop
    ? undefined
    : currentSession
      ? action.priceRefashionStatus
      : action.costRefashionStatus;

  const packActionType = currentSession?.workshop
    ? action.packActionTypeOrganization
    : currentSession
      ? getPackActionTypeOrganizationWithRefashionStatus(action, action.price)
      : getPackActionTypeOrganizationWithRefashionStatus(action, action.cost);

  return (
    <Box padding="0" ariaLabel={actionName} style={old ? OLD_ACTION_STYLE : undefined}>
      <Stack padding="16px" gap="1rem" className="bg-neutral-0" style={{ borderRadius: '8px' }}>
        {old && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.old-action">Previous action</Trans>
          </span>
        )}
        <Stack row gap="0.5rem" flexWrap="nowrap">
          <IconTools style={{ fontSize: '1.5rem' }} />
          {!!action.actionTypeOrganization && (
            <ActionName
              actionType={action.actionTypeOrganization.actionType}
              refashionStatus={refashionStatus}
              duplicateActionNumber={duplicateActionNumber}
            />
          )}
          {!!packActionType && (
            <PackActionName
              packActionType={packActionType}
              duplicateActionNumber={duplicateActionNumber}
            />
          )}
          {!!action.customDescription && (
            // FIXME: How to handle update of the description of a custom action?
            <p className="paragraph-100-regular">
              {action.customDescription}
              {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
            </p>
          )}
        </Stack>
        <hr />
        <DefectPhotos
          request={request}
          actionOrDefect={action}
          size="medium"
          isDisabled={isDisabled || old}
          isRequalification={isRequalification}
        />
        <CardBrandResponsibility
          action={action}
          isEditActionDisabled={isEditActionDisabled || old}
        />
        {showWorkshopPrice &&
          (isWorkshop ? (
            <InputMoney
              isDisabled
              label={_(
                msg({
                  id: 'article.form.actions.table.column.cost.title-external-workshop',
                  message: 'Price',
                })
              )}
              currency={action.price?.amountPerEntity[0]?.currency ?? 'EUR'}
              value={action.price?.amountPerEntity[0]?.amount}
            />
          ) : (
            <InputMoney
              isDisabled
              label={_(
                msg({
                  id: 'article.form.actions.table.column.cost.title',
                  message: 'Cost',
                })
              )}
              currency={
                action.cost?.amountPerEntity.find(
                  (amount) => amount.entityId === article.workshopId
                )?.currency ?? 'EUR'
              }
              value={
                action.cost?.amountPerEntity.find(
                  (amount) => amount.entityId === article.workshopId
                )?.amount
              }
            />
          ))}
        {showOrganizationPrice && (
          <InputMoney
            isDisabled
            label={_(
              msg({
                id: 'article.form.actions.table.column.client-price.title',
                message: 'Client price',
              })
            )}
            currency={action.price?.amountPerEntity[0]?.currency ?? 'EUR'}
            value={action.price?.amountPerEntity[0]?.amount}
          />
        )}
        {!(isDisabled || old) && <DeleteButton action={action} />}
      </Stack>
    </Box>
  );
};

const LegacyActionCard = ({
  action,
  duplicateActionNumber,
  old,
}: {
  action: ArticleActionWithRelations;
  duplicateActionNumber?: number;
  old?: boolean;
}) => {
  const { currentSession } = useCurrentSession();
  const {
    request,
    mode,
    isDisabled,
    isEditActionDisabled,
    handleStartEditCustomAction,
    isRequalification,
  } = useContext(ActionTypeSearchSelectContext);

  const actionName = useActionName({ action, mode });

  const refashionStatus = currentSession?.workshop
    ? undefined
    : currentSession
      ? action.priceRefashionStatus
      : action.costRefashionStatus;

  const packActionType = currentSession?.workshop
    ? action.packActionTypeOrganization
    : currentSession
      ? getPackActionTypeOrganizationWithRefashionStatus(action, action.price)
      : getPackActionTypeOrganizationWithRefashionStatus(action, action.cost);

  return (
    <Box padding="0" ariaLabel={actionName} style={old ? OLD_ACTION_STYLE : undefined}>
      <Stack padding="16px" gap="1rem" className="bg-neutral-0" style={{ borderRadius: '8px' }}>
        {old && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.old-action">Previous action</Trans>
          </span>
        )}

        <Stack row justifyContent="space-between" gap="0.5rem" flexWrap="nowrap">
          {!!action.actionTypeOrganization && (
            <ActionName
              actionType={action.actionTypeOrganization.actionType}
              refashionStatus={refashionStatus}
              duplicateActionNumber={duplicateActionNumber}
            />
          )}
          {!!packActionType && (
            <PackActionName
              packActionType={packActionType}
              duplicateActionNumber={duplicateActionNumber}
            />
          )}
          {!!action.customDescription && (
            <p className="paragraph-100-regular">
              {action.customDescription}
              {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
            </p>
          )}
          <Stack row gap="0.5rem">
            {!!action.customDescription && !(isEditActionDisabled || old) && (
              <EditCustomActionCell onPress={() => handleStartEditCustomAction(action)} />
            )}
            {!(isDisabled || old) && <DeleteCell action={action} />}
          </Stack>
        </Stack>

        <DefectPhotos
          request={request}
          actionOrDefect={action}
          size="medium"
          isDisabled={isDisabled || old}
          isRequalification={isRequalification}
        />
        <hr />
        <CardBrandResponsibility
          action={action}
          isEditActionDisabled={isEditActionDisabled || old}
        />
      </Stack>
      <CardPrices action={action} />
    </Box>
  );
};

const CardBrandResponsibility = ({
  action,
  isEditActionDisabled,
}: {
  action: ArticleActionWithRelations;
  isEditActionDisabled?: boolean;
}) => {
  const { showWarrantyCover } = useContext(ActionTypeSearchSelectContext);

  if (!showWarrantyCover) {
    return null;
  }

  return (
    <Stack row gap="0.5rem" alignItems="center" justifyContent="space-between">
      <span className="paragraph-100-regular">
        <Trans id="article.form.actions.table.column.brand-responsibility.label">
          Brand responsibility:
        </Trans>
      </span>
      <BrandResponsibilityCell action={action} size="small" isDisabled={isEditActionDisabled} />
    </Stack>
  );
};

const CardPrices = ({ action }: { action: ArticleActionWithRelations }) => {
  const { _ } = useLingui();
  const { currentSession } = useCurrentSession();
  const isWorkshop = !!currentSession?.workshop;

  const { article, showWorkshopPrice, showOrganizationPrice } = useContext(
    ActionTypeSearchSelectContext
  );

  if (!(showWorkshopPrice || showOrganizationPrice)) {
    return null;
  }

  return (
    <Stack
      padding="12px 16px"
      gap="1rem"
      className="bg-neutral-100"
      // eslint-disable-next-line lingui/no-unlocalized-strings
      style={{ borderRadius: '0 0 8px 8px' }}
    >
      <Stack row gap="1rem" justifyContent="flex-end" flexWrap="nowrap" alignItems="flex-start">
        {showWorkshopPrice &&
          (isWorkshop ? (
            <BaseMoneyCell
              price={action.price?.amountPerEntity[0]}
              label={_(
                msg({
                  id: 'article.form.actions.table.column.cost.title-external-workshop',
                  message: 'Price',
                })
              )}
            />
          ) : (
            <BaseMoneyCell
              price={action.cost?.amountPerEntity.find(
                (amount) => amount.entityId === article.workshopId
              )}
              label={_(
                msg({ id: 'article.form.actions.table.column.cost.title', message: 'Cost' })
              )}
            />
          ))}
        {showOrganizationPrice && (
          <BaseMoneyCell
            price={action.price?.amountPerEntity[0]}
            label={_(
              msg({
                id: 'article.form.actions.table.column.client-price.title',
                message: 'Client price',
              })
            )}
          />
        )}
      </Stack>
    </Stack>
  );
};

const ActionRow = ({
  action,
  duplicateActionNumber,
  showEditColumn,
  old,
  showMissingDefectPhotoError,
}: {
  action: ArticleActionWithRelations;
  duplicateActionNumber?: number;
  showEditColumn?: boolean;
  old?: boolean;
  showMissingDefectPhotoError?: boolean;
}) => {
  const { currentSession } = useCurrentSession();

  const {
    request,
    article,
    showDefectPhotos,
    showWorkshopPrice,
    showOrganizationPrice,
    showWarrantyCover,
    isDisabled,
    isEditActionDisabled,
    isRequalification,
    handleStartEditCustomAction,
  } = useContext(ActionTypeSearchSelectContext);

  const refashionStatus = currentSession?.workshop
    ? undefined
    : currentSession
      ? action.priceRefashionStatus
      : action.costRefashionStatus;

  const packActionType = currentSession?.workshop
    ? action.packActionTypeOrganization
    : currentSession
      ? getPackActionTypeOrganizationWithRefashionStatus(action, action.price)
      : getPackActionTypeOrganizationWithRefashionStatus(action, action.cost);

  return (
    <Row>
      <Cell style={old ? OLD_ACTION_STYLE : undefined}>
        {old && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.old-action">Previous action</Trans>
          </span>
        )}
        {!!action.actionTypeOrganization && (
          <ActionName
            actionType={action.actionTypeOrganization.actionType}
            refashionStatus={refashionStatus}
            duplicateActionNumber={duplicateActionNumber}
          />
        )}
        {!!packActionType && (
          <PackActionName
            packActionType={packActionType}
            duplicateActionNumber={duplicateActionNumber}
          />
        )}
        {!!action.customDescription && (
          <p className="paragraph-100-medium">
            {action.customDescription} {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
          </p>
        )}
      </Cell>

      {showDefectPhotos && (
        <Cell stretch>
          <DefectPhotos
            request={request}
            actionOrDefect={action}
            size="small"
            isDisabled={isDisabled || old}
            isInvalid={showMissingDefectPhotoError}
            isRequalification={isRequalification}
          />
        </Cell>
      )}

      {showWarrantyCover && (
        <Cell justifyContent="center" style={old ? OLD_ACTION_STYLE : undefined}>
          <BrandResponsibilityCell action={action} isDisabled={isEditActionDisabled || old} />
        </Cell>
      )}

      {showWorkshopPrice && (
        <Cell justifyContent="flex-end" style={old ? OLD_ACTION_STYLE : undefined}>
          <BaseMoneyCell
            price={
              currentSession?.workshop
                ? action.price?.amountPerEntity[0]
                : action.cost?.amountPerEntity.find(
                    (amount) => amount.entityId === article.workshopId
                  )
            }
          />
        </Cell>
      )}

      {showOrganizationPrice && (
        <Cell justifyContent="flex-end" style={old ? OLD_ACTION_STYLE : undefined}>
          <BaseMoneyCell price={action.price?.amountPerEntity[0]} />
        </Cell>
      )}

      {((showEditColumn && !!action.customDescription) || !isDisabled) && (
        <Cell
          justifyContent="flex-end"
          style={{ gap: '0.5rem', ...(old ? OLD_ACTION_STYLE : undefined) }}
        >
          {showEditColumn && !!action.customDescription && !(isEditActionDisabled || old) && (
            <EditCustomActionCell onPress={() => handleStartEditCustomAction(action)} />
          )}
          {!(isDisabled || old) && <DeleteCell action={action} />}
        </Cell>
      )}
    </Row>
  );
};

const LegacyActionRow = ({
  action,
  duplicateActionNumber,
  showEditColumn,
  old,
}: {
  action: ArticleActionWithRelations;
  duplicateActionNumber?: number;
  showEditColumn?: boolean;
  old?: boolean;
}) => {
  const { currentSession } = useCurrentSession();

  const {
    request,
    article,
    showWorkshopPrice,
    showOrganizationPrice,
    showWarrantyCover,
    isDisabled,
    isEditActionDisabled,
    handleStartEditCustomAction,
    isRequalification,
  } = useContext(ActionTypeSearchSelectContext);

  const refashionStatus = currentSession?.workshop
    ? undefined
    : currentSession
      ? action.priceRefashionStatus
      : action.costRefashionStatus;

  const packActionType = currentSession?.workshop
    ? action.packActionTypeOrganization
    : currentSession
      ? getPackActionTypeOrganizationWithRefashionStatus(action, action.price)
      : getPackActionTypeOrganizationWithRefashionStatus(action, action.cost);

  return (
    <Row>
      <Cell style={old ? OLD_ACTION_STYLE : undefined}>
        {old && (
          <span className="visually-hidden">
            <Trans id="article.form.actions.old-action">Previous action</Trans>
          </span>
        )}
        {!!action.actionTypeOrganization && (
          <ActionName
            actionType={action.actionTypeOrganization.actionType}
            refashionStatus={refashionStatus}
            duplicateActionNumber={duplicateActionNumber}
          />
        )}
        {!!packActionType && (
          <PackActionName
            packActionType={packActionType}
            duplicateActionNumber={duplicateActionNumber}
          />
        )}
        {!!action.customDescription && (
          <p className="paragraph-100-medium">
            {action.customDescription}
            {!!duplicateActionNumber && ` #${duplicateActionNumber}`}
          </p>
        )}
      </Cell>
      <Cell stretch>
        <DefectPhotos
          request={request}
          actionOrDefect={action}
          size="small"
          isDisabled={isDisabled || old}
          isRequalification={isRequalification}
        />
      </Cell>
      {showWorkshopPrice && (
        <Cell justifyContent="flex-end" style={old ? OLD_ACTION_STYLE : undefined}>
          <BaseMoneyCell
            price={
              currentSession?.workshop
                ? action.price?.amountPerEntity[0]
                : action.cost?.amountPerEntity.find(
                    (amount) => amount.entityId === article.workshopId
                  )
            }
          />
        </Cell>
      )}
      {showOrganizationPrice && (
        <Cell justifyContent="flex-end" style={old ? OLD_ACTION_STYLE : undefined}>
          <BaseMoneyCell price={action.price?.amountPerEntity[0]} />
        </Cell>
      )}
      {showWarrantyCover && (
        <Cell justifyContent="center" style={old ? OLD_ACTION_STYLE : undefined}>
          <BrandResponsibilityCell action={action} isDisabled={isEditActionDisabled || old} />
        </Cell>
      )}
      {((showEditColumn && !!action.customDescription) || !isDisabled) && (
        <Cell
          justifyContent="flex-end"
          style={{ gap: '0.5rem', ...(old ? OLD_ACTION_STYLE : undefined) }}
        >
          {showEditColumn && !!action.customDescription && !(isEditActionDisabled || old) && (
            <EditCustomActionCell onPress={() => handleStartEditCustomAction(action)} />
          )}
          {!(isDisabled || old) && <DeleteCell action={action} />}
        </Cell>
      )}
    </Row>
  );
};
