import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import FileUpload from '@/components/FileUpload';
import IconPicture from '@/icons/Picture.svg';
import { ArticleWithRelations } from '@/models/request';

export const ArticlePhotoUpload = ({
  article,
  error,
  disabled,
}: {
  article: ArticleWithRelations;
  error?: string;
  disabled?: boolean;
}) => {
  const { _ } = useLingui();

  return (
    <FileUpload
      uploadData={{
        type: 'article-photo',
        articleId: article.id,
      }}
      type="photo"
      label={_(
        msg({
          id: 'article.form.article-photo.label',
          message: 'Full item picture',
        })
      )}
      prompt={_(
        msg({
          id: 'article.form.article-photo.prompt',
          message: 'Add a full view of the item',
        })
      )}
      icon={<IconPicture />}
      maxNumberOfMedia={1}
      allowsMultiple={false}
      media={article.articlePhoto ? [article.articlePhoto] : []}
      size="large"
      disabled={disabled}
      error={error}
      deleteWithApi
    />
  );
};
