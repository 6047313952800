import { Placement } from 'react-aria';

import Menu from '@/design_system/Menu';
import { MenuItem } from '@/design_system/Menu/Menu';
import { Organization } from '@/models/organization';

const OrganizationSwitch = ({
  children,
  organizations,
  onSelectOrganization,
  placement = 'right top', // eslint-disable-line lingui/no-unlocalized-strings
}: {
  children: React.ReactNode;
  organizations: Organization[];
  onSelectOrganization?: (organization: Organization) => void;
  placement?: Placement;
}) => {
  return (
    <Menu
      trigger={children}
      onAction={(id: string) => {
        onSelectOrganization?.(organizations.find((organization) => organization.id === id)!);
      }}
      placement={placement}
    >
      {organizations.map((organization) => (
        <MenuItem id={organization.id} key={organization.id}>
          {organization.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default OrganizationSwitch;
