import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import ProductSearchSelect from '@/components/ProductSearchSelect';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import { useUpdateArticle } from '@/models/article';
import { Product } from '@/models/product';
import { ArticleWithRelations, Request } from '@/models/request';

const ProductInfo = ({
  request,
  article,
  row = true,
  error,
  showReference,
}: {
  request: Request;
  article: ArticleWithRelations;
  row?: boolean;
  error?: string;
  showReference?: boolean;
}) => {
  const { _ } = useLingui();

  const { mutate: updateArticle } = useUpdateArticle({
    articleId: article.id,
    requestId: request.id,
  });

  const updateProduct = (product: Product | null) => {
    if (product === null && !!article.product) {
      updateArticle({
        data: {
          productId: null,
        },
        optimisticData: {
          product: null,
          productL1: null,
          productL2: null,
          productL3: null,
          data: {},
        },
      });
    } else if (product && article.product?.externalId !== product.externalId) {
      const invalidColor = article.data.color && !product.data.colors?.includes(article.data.color);
      const invalidSize = article.data.size && !product.data.sizes?.includes(article.data.size);

      updateArticle({
        data: {
          productId: product.id,
        },
        optimisticData: {
          product,
          productL1: product.productL1,
          productL2: product.productL2,
          productL3: product.productL3,
          data: {
            color: invalidColor ? undefined : article.data.color,
            size: invalidSize ? undefined : article.data.size,
            brand: article.data.brand,
          },
        },
      });
    }
  };

  return (
    <Stack row={row} mobileColumn gap="1rem" style={{ flex: 1, maxWidth: '100%' }}>
      <ProductSearchSelect
        value={article.product ?? undefined}
        onChange={(product) => updateProduct(product ?? null)}
        error={error}
      />
      {showReference && (
        <InputText
          label={_(msg({ id: 'article.form.reference.label', message: 'Product reference' }))}
          value={article.product?.data.reference ?? '-'}
          isDisabled
          style={{ flex: 1 }}
        />
      )}
    </Stack>
  );
};

export default ProductInfo;
