import { useEffect, useRef, useState } from 'react';
import { Plural, Trans } from '@lingui/macro';
import { addBusinessDays } from 'date-fns/addBusinessDays';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconPrint from '@/icons/Print.svg';
import IconTruck from '@/icons/Truck.svg';
import { BrandWrapper, BrandWrapperCenter } from '@/layouts/Brand';
import { ClientArticleWithRelations, ClientRequestWithRelations } from '@/models/request';
import { useShipExternalStoreToWorkshopShipment, useShipments } from '@/models/shipment';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import { formatDate } from '@/utils/date';
import { useBlobUrl } from '@/utils/useBlobUrl';

const Transit = ({ request }: { request: ClientRequestWithRelations }) => {
  const activeArticlesInTransit = request.articles.filter(
    (article) => article.step?.step === 'transit' && !article.cancelledAt
  );
  const isPendingTransit = activeArticlesInTransit.some(
    (article) => article.atClient || !!article.atStoreId
  );

  return (
    <BrandWrapper>
      <BrandWrapperCenter>
        <Stack gap="1rem">
          {isPendingTransit && (
            <PendingTransitHeader request={request} articlesInTransit={activeArticlesInTransit} />
          )}
          {!isPendingTransit && (
            <InTransitHeader request={request} articlesInTransit={activeArticlesInTransit} />
          )}
          <ClientArticlesTable request={request} showPrice={!!request.client} showArticleComment />
          {!!request.client && <ClientInfo request={request} />}
        </Stack>
      </BrandWrapperCenter>
    </BrandWrapper>
  );
};

const PendingTransitHeader = ({
  request,
  articlesInTransit,
}: {
  request: ClientRequestWithRelations;
  articlesInTransit: ClientArticleWithRelations[];
}) => {
  const ref = useRef<HTMLButtonElement>();
  const { data: { shipments } = { shipments: [] }, isLoading: isLoadingShipment } = useShipments({
    requestId: request.id,
  });
  const [showError, setShowError] = useState(false);
  const {
    mutateAsync: shipShipment,
    isPending: isPendingShipShipment,
    isSuccess: isSuccessShipShipment,
    error,
    reset,
  } = useShipExternalStoreToWorkshopShipment();

  const shipment = shipments[0];
  const shippingLabelUrl = useBlobUrl(shipment?.shippingLabel, 'application/pdf');

  // Automatically download the shipping label when it's ready
  useEffect(() => {
    if (isSuccessShipShipment && shippingLabelUrl) {
      ref.current?.click();
      reset();
    }
  }, [isSuccessShipShipment, shippingLabelUrl, ref, reset]);

  return (
    <Stack gap="1rem">
      <p className="headline-200-medium headline-300-medium-mobile ">
        <Trans id="store.request.transit.pending.title">It&apos;s time to ship the item!</Trans>
      </p>

      <Stack row gap="0.25rem">
        {!!request.client && (
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            <Trans id="store.request.transit.pending.text-client">
              The client has accepted the estimate.
            </Trans>
          </p>
        )}
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="store.request.transit.pending.text">
            It&apos;s now time to ship the{' '}
            <Plural value={articlesInTransit.length} one="item" other="items" /> to the workshop.
          </Trans>
        </p>
        {shipment?.pickupDate && (
          <p className="paragraph-50-regular paragraph-100-regular-mobile">
            <Trans id="store.request.transit.pending.pickup-date">
              The package will be picked up on{' '}
              {formatDate(new Date(shipment?.pickupDate), {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
              .
            </Trans>
          </p>
        )}
      </Stack>
      <Button
        ref={ref}
        variant="brand"
        size="large"
        disabled={isLoadingShipment || shipments.length === 0 || isPendingShipShipment}
        href={shippingLabelUrl ?? undefined}
        download={`${shipment?.reference}.pdf`}
        onPress={() => {
          if (!shippingLabelUrl) {
            setShowError(false);
            const nextBusinessDate = addBusinessDays(new Date(), 1);
            nextBusinessDate.setUTCHours(0, 0, 0, 0);
            shipShipment({ id: shipment.id, pickupDate: nextBusinessDate.toISOString() })
              .catch((error) => {
                // If this is a "Failed to ship the shipment" error, try again with the following business day and
                // cross-fingers
                if (error?.message === 'Failed to ship the shipment') {
                  const nextNextBusinessDate = addBusinessDays(nextBusinessDate, 1);
                  return shipShipment({
                    id: shipment.id,
                    pickupDate: nextNextBusinessDate.toISOString(),
                  });
                } else {
                  throw error;
                }
              })
              .catch(() => {
                setShowError(true);
              });
          }
        }}
      >
        <IconPrint />
        <Trans id="store.request.transit.pending.print">Print shipping label</Trans>
      </Button>
      {showError && (
        <span className="text-danger paragraph-100-regular" style={{ whiteSpace: 'pre-wrap' }}>
          {error?.message ?? ''}{' '}
          <Trans id="store.request.transit.pending.error">
            An error occurred, please contact Support.
          </Trans>
        </span>
      )}
    </Stack>
  );
};

const InTransitHeader = ({
  request,
  articlesInTransit,
}: {
  request: ClientRequestWithRelations;
  articlesInTransit: ClientArticleWithRelations[];
}) => {
  return (
    <>
      <ClientStepper request={request} />
      <Stack row gap="0.5rem">
        <IconTruck className="color-tertiary-700" style={{ fontSize: '24px' }} />
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="store.request.transit.in-transit.store-shipment">
            The{' '}
            <Plural
              value={articlesInTransit.length}
              one="item is on its"
              other="items are on their"
            />{' '}
            way to the workshop to be taken care of.
          </Trans>
        </p>
      </Stack>
    </>
  );
};

export default Transit;
