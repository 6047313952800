import IconWrapper from './IconWrapper';

const IconWarning = IconWrapper({
  name: 'warning',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5383 2.91498C12.8759 1.695 11.1248 1.69501 10.4624 2.91498L2.21453 18.1059C1.58141 19.272 2.4256 20.6909 3.75246 20.6909H20.2483C21.5751 20.6909 22.4193 19.272 21.7862 18.1059L13.5383 2.91498ZM11.7807 3.63071C11.8753 3.45643 12.1254 3.45643 12.2201 3.63071L20.468 18.8216C20.5584 18.9882 20.4378 19.1909 20.2483 19.1909H3.75246C3.56291 19.1909 3.44231 18.9882 3.53276 18.8216L11.7807 3.63071ZM11 16.85C11 16.2977 11.4477 15.85 12 15.85C12.5523 15.85 13 16.2977 13 16.85C13 17.4023 12.5523 17.85 12 17.85C11.4477 17.85 11 17.4023 11 16.85ZM12.0001 14.35C11.4478 14.35 11.0001 13.9023 11.0001 13.35L11.0001 9.35001C11.0001 8.79772 11.4478 8.35001 12.0001 8.35001C12.5523 8.35001 13.0001 8.79772 13.0001 9.35001L13.0001 13.35C13.0001 13.9023 12.5523 14.35 12.0001 14.35Z"
      />
    </svg>
  ),
});

export default IconWarning;
