import IconWrapper from '@/icons/IconWrapper';

const IconHook = IconWrapper({
  name: 'hook',
  source: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
    >
      <g className="clip">
        <path
          fill="#CCD1CF"
          d="M85.496 88.29V75.882l-8.754 3.64 8.754 8.768Zm-8.754-71.098 8.754 8.78L98 20.769 89.255 12l-12.513 5.192ZM30 44.902v62.329L38.744 116V53.671L30 44.902Z"
          className="fill-grey"
        />
        <path
          fill="#fff"
          d="M85.496 88.29 98 83.088V20.779l-12.504 5.193V88.29Z"
          className="fill-white"
        />
        <path
          fill="#fff"
          d="M56.446 33.847 30 44.83v.071l8.744 8.77v42.625l17.844-7.407c12.909-5.365 21.319-17.986 21.319-31.998v-8.657c0-11.096-11.237-18.626-21.46-14.388Zm-1.834 40.431c-6.07 2.327-10.984-.711-10.984-6.788 0-6.086 4.915-12.904 10.984-15.231 6.07-2.327 10.984.711 10.984 6.787 0 6.077-4.914 12.895-10.984 15.232Z"
          className="fill-accent"
        />
        <g className="stroke">
          <path
            fill="#3F4643"
            fillRule="evenodd"
            d="M89.964 11.294a1 1 0 0 0-1.092-.218L76.36 16.27a1 1 0 0 0-.325 1.63l8.462 8.486v48.83l-8.138 3.383a1 1 0 0 0-.324 1.63l8.748 8.763.015.014a1.005 1.005 0 0 0 .625.283.998.998 0 0 0 .459-.074l12.503-5.203a1 1 0 0 0 .616-.937V20.772a1 1 0 0 0-.292-.709l-8.744-8.77ZM85.73 24.79l-7.227-7.247L89.02 13.18l7.219 7.239-10.508 4.372Zm-1.235 52.59v8.492l-5.992-6.002 5.992-2.49Zm2-50.742V86.79L97 82.421V22.277L86.496 26.64ZM62.906 51.65c2.334 1.444 3.69 4.078 3.69 7.396 0 3.296-1.326 6.72-3.405 9.603-2.081 2.887-4.975 5.313-8.22 6.562-3.248 1.246-6.335 1.107-8.652-.325-2.335-1.443-3.69-4.077-3.69-7.396 0-3.3 1.325-6.726 3.404-9.61 2.081-2.887 4.975-5.31 8.221-6.555 3.248-1.245 6.335-1.106 8.652.326Zm-1.052 1.702c-1.657-1.024-4.062-1.241-6.884-.16-2.824 1.083-5.422 3.232-7.315 5.858-1.895 2.628-3.027 5.654-3.027 8.44 0 2.759 1.102 4.681 2.742 5.695 1.657 1.024 4.062 1.241 6.883.16 2.825-1.088 5.423-3.24 7.316-5.865 1.895-2.63 3.027-5.653 3.027-8.434 0-2.758-1.102-4.68-2.742-5.694Z"
            clipRule="evenodd"
          />
          <path
            fill="#3F4643"
            fillRule="evenodd"
            d="M56.063 32.923C66.95 28.41 78.907 36.43 78.907 48.235v8.657c0 14.415-8.652 27.4-21.935 32.921l-17.228 7.152V116a.999.999 0 0 1-1.708.706l-8.744-8.769a.999.999 0 0 1-.292-.706v-62.4a1 1 0 0 1 .616-.924l26.447-10.984Zm-24.351 12.28L56.829 34.77c9.562-3.963 20.078 3.078 20.078 13.465v8.657c0 13.61-8.168 25.865-20.703 31.074L39.744 94.8V53.67a1 1 0 0 0-.291-.706l-7.741-7.762ZM31 47.32v59.496l6.744 6.764V54.084L31 47.322Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  ),
});

export default IconHook;
