import { Plural, Trans } from '@lingui/macro';

import { CardItem } from '@/components/Card/Card';
import Stack from '@/design_system/Stack';
import IconTools from '@/icons/Tools.svg';
import { ArticleWithRelations } from '@/models/request';

export const ArticleServiceTypeCell = ({ article }: { article: ArticleWithRelations }) => {
  const numberOfActions = article.numberOfActions;
  return (
    <Stack>
      <span className="paragraph-100-medium">
        <Trans id="request.articles.table.column.service-type.care-and-repair">Care & repair</Trans>
      </span>
      <span className="paragraph-200-regular text-secondary">
        <Trans id="request.articles.table.column.service-type.actions">
          {numberOfActions} <Plural value={numberOfActions} one="action" other="actions" />
        </Trans>
      </span>
    </Stack>
  );
};

export const ArticleServiceTypeCardItem = ({ article }: { article: ArticleWithRelations }) => {
  const numberOfActions = article.numberOfActions;
  return (
    <CardItem>
      <IconTools style={{ fontSize: '1rem' }} />
      <Trans id="request.articles.table.column.service-type.actions">
        {numberOfActions} {}
        <Plural value={numberOfActions} one="action" other="actions" />
      </Trans>
    </CardItem>
  );
};
