import { useCallback, useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { InputLocale } from '@/components/InputLocale/InputLocale';
import { getStoreError, StoreForm, useStoreState } from '@/components/Store/StoreForm';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import InputSearch from '@/design_system/InputSearch';
import InputText from '@/design_system/InputText';
import { PaginationFooter } from '@/design_system/Pagination/Pagination';
import Stack from '@/design_system/Stack';
import { Body, Cell, Column, Header, Row, Table } from '@/design_system/Table/Table';
import IconAdd from '@/icons/Add.svg';
import IconEdit from '@/icons/Edit.svg';
import IconTrash from '@/icons/Trash.svg';
import { Address, AddressRaw } from '@/models/address';
import { Store, useCreateStore, useDeleteStore, useStores, useUpdateStore } from '@/models/store';
import useDebouncedState from '@/utils/useDebouncedState';

const STORES_PER_PAGE = 10;

export const ExternalStores = () => {
  const { _ } = useLingui();
  const [page, setPage] = useState(1);
  const resetPage = useCallback(() => setPage(1), [setPage]);
  const [search, debouncedSearch, setSearch] = useDebouncedState('', 300, resetPage);

  const { data: { stores, meta } = {} } = useStores({
    limit: STORES_PER_PAGE,
    offset: (page - 1) * STORES_PER_PAGE,
    search: debouncedSearch,
    internal: false,
  });

  const noData = stores && stores.length === 0;

  return (
    <Stack gap="40px">
      <Box padding="24px" gap="24px">
        <Stack row alignItems="center" justifyContent="space-between">
          <h2 className="headline-200-bold">
            <Trans id="settings.external-stores.title">External stores</Trans>
          </h2>
          <AddExternalStore />
        </Stack>
        <InputSearch
          ariaLabel={_(msg({ id: 'settings.external-stores.search.label', message: 'Search' }))}
          placeholder={_(
            msg({
              id: 'settings.external-stores.search.placeholder',
              message: 'Search by name, address, contact name, external id…',
            })
          )}
          style={{ flex: 1, minWidth: 175, maxWidth: 500 }}
          size="medium"
          value={search}
          onChange={setSearch}
        />
        {noData && (
          <p className="paragraph-100-regular">
            <Trans id="settings.external-stores.empty">No items for now</Trans>
          </p>
        )}
        {!noData && (
          <Table
            ariaLabel={_(
              msg({ id: 'settings.external-stores.table.label', message: 'External stores' })
            )}
            columnWidths="minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) auto"
          >
            <Header>
              <Row>
                <Column>
                  <Trans id="settings.external-stores.column.name">Name</Trans>
                </Column>
                <Column>
                  <Trans id="settings.external-stores.column.address">Address</Trans>
                </Column>
                <Column>
                  <Trans id="settings.external-stores.column.contact">Contact</Trans>
                </Column>
                <Column
                  ariaLabel={_(
                    msg({
                      id: 'settings.external-stores.column.actions',
                      message: 'Actions',
                    })
                  )}
                />
              </Row>
            </Header>
            <Body>{stores?.map((store) => <ExternalStoreRow key={store.id} store={store} />)}</Body>
            <PaginationFooter
              page={page}
              itemsPerPage={STORES_PER_PAGE}
              count={meta?.count}
              onPageChange={setPage}
            />
          </Table>
        )}
      </Box>
    </Stack>
  );
};

const ExternalStoreRow = ({ store }: { store: Store & { address: Address | null } }) => {
  const { _ } = useLingui();

  const { mutateAsync: deleteStore, isPending: isPendingDelete } = useDeleteStore();

  return (
    <Row>
      <Cell>
        <b>
          {store.name} ({store.organizationConfig?.externalId})
        </b>
      </Cell>
      <Cell>
        <p>
          {store.address?.formattedStreet ?? '-'}
          <br />
          {store.address?.formattedZip ?? '-'}
        </p>
      </Cell>
      <Cell>
        <p>
          {store.address?.contactName ?? '-'}
          <br />
          {store.address?.contactEmail ?? '-'}
          <br />
          {store.formattedPhone ?? '-'}
        </p>
      </Cell>
      <Cell justifyContent="flex-end">
        <Stack row gap="4px">
          <UpdateExternalStore store={store} />
          <Button
            variant="secondary"
            iconOnly
            size="small"
            ariaLabel={_(
              msg({
                id: 'settings.external-stores.delete.tooltip',
                message: `Remove ${store.name}`,
              })
            )}
            tooltip={_(
              msg({
                id: 'settings.external-stores.delete.tooltip',
                message: `Remove ${store.name}`,
              })
            )}
            isLoading={isPendingDelete}
            disabled={isPendingDelete}
            onPress={() => {
              if (
                confirm(
                  _(
                    msg({
                      id: 'settings.external-stores.delete.confirm-dialog',
                      message: `Are you sure you want to remove the ${store.name} store from your organization?`,
                    })
                  )
                )
              ) {
                deleteStore(store.id).catch(console.error);
              }
            }}
          >
            <IconTrash />
          </Button>
        </Stack>
      </Cell>
    </Row>
  );
};

const AddExternalStore = () => {
  const { _ } = useLingui();
  const [open, setOpen] = useState(false);

  return (
    <Dialog
      isOpen={open}
      onOpenChange={setOpen}
      trigger={
        <Button variant="secondary" size="small">
          <IconAdd />
          <Trans id="settings.external-stores.add">Add an external store</Trans>
        </Button>
      }
      title={_(
        msg({ id: 'settings.external-stores.add-modal.title', message: 'Add an external store' })
      )}
    >
      <AddExternalStoreContent setOpen={setOpen} />
    </Dialog>
  );
};

const AddExternalStoreContent = ({ setOpen }: { setOpen: (open: boolean) => void }) => {
  const { mutateAsync: createStore, isPending } = useCreateStore();

  const state = useStoreState({
    organizationConfig: { external: true, externalId: '' },
  });

  return (
    <>
      <main style={{ overflow: 'auto', maxHeight: '70vh' }}>
        <StoreForm state={state} allowExternalIdEdition />
      </main>
      <footer>
        <Button
          variant="primary"
          size="small"
          type="submit"
          isLoading={isPending}
          onPress={() => {
            if (getStoreError(state).hasError) {
              state.setShowErrors(true);
              return;
            }

            createStore({
              name: state.name,
              address: state.address as AddressRaw,
              phone: state.phone,
              external: true,
              externalId: state.externalId || undefined,
              defaultLocale: state.defaultLocale,
            })
              .then(() => {
                state.setShowErrors(false);
                setOpen(false);
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          <Trans id="settings.external-stores.add-modal.submit">Add store</Trans>
        </Button>
      </footer>
    </>
  );
};

const UpdateExternalStore = ({ store }: { store: Store & { address: Address | null } }) => {
  const { _ } = useLingui();
  const [open, setOpen] = useState(false);

  return (
    <Dialog
      isOpen={open}
      onOpenChange={setOpen}
      trigger={
        <Button
          variant="secondary"
          iconOnly
          size="small"
          ariaLabel={_(
            msg({
              id: 'settings.external-stores.edit-modal.trigger',
              message: `Edit ${store.name}`,
            })
          )}
          tooltip={_(
            msg({
              id: 'settings.external-stores.edit-modal.trigger',
              message: `Edit ${store.name}`,
            })
          )}
        >
          <IconEdit />
        </Button>
      }
      title={_(
        msg({ id: 'settings.external-stores.edit-modal.title', message: `Edit ${store.name}` })
      )}
    >
      <UpdateExternalStoreContent store={store} setOpen={setOpen} />
    </Dialog>
  );
};

const UpdateExternalStoreContent = ({
  store,
  setOpen,
}: {
  store: Store & { address: Address | null };
  setOpen: (open: boolean) => void;
}) => {
  const [externalId, setExternalId] = useState(store.organizationConfig?.externalId);

  const { mutateAsync: updateStore, isPending } = useUpdateStore();

  return (
    <>
      <main style={{ maxHeight: '70vh' }}>
        <Stack gap="24px">
          <InputText
            label={
              <Trans id="settings.external-stores.edit-modal.field.name.label">
                Name of the store
              </Trans>
            }
            type="text"
            size="small"
            value={store.name}
            isDisabled={true}
          />
          <InputText
            label={
              <Trans id="settings.external-stores.edit-modal.field.external-id.label">
                External ID
              </Trans>
            }
            value={externalId ?? ''}
            onChange={setExternalId}
            size="small"
            isDisabled={isPending}
          />
          <InputLocale value={store.defaultLocale} size="small" isDisabled={true} />
        </Stack>
      </main>
      <footer>
        <Button
          variant="primary"
          size="small"
          type="submit"
          isLoading={isPending}
          onPress={() =>
            updateStore({
              storeId: store.id,
              externalId: externalId || null,
            }).then(() => setOpen(false))
          }
        >
          <Trans id="settings.external-stores.edit-modal.submit">Update store</Trans>
        </Button>
      </footer>
    </>
  );
};
