import Stack from '@/design_system/Stack';

interface NotificationBadgeProps {
  count: number;
}

const NotificationBadge = ({ count }: NotificationBadgeProps) => {
  return (
    <Stack
      className="paragraph-400-medium text-neutral-0 bg-danger-700"
      alignItems="center"
      justifyContent="center"
      style={{
        minWidth: '1rem',
        height: '1rem',
        // eslint-disable-next-line lingui/no-unlocalized-strings
        padding: '2px 4px',
        borderRadius: '4px',
      }}
    >
      {count > 99 ? '99+' : count}
    </Stack>
  );
};

export default NotificationBadge;
