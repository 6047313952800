import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import Tabs from '@/design_system/Tabs';
import { Tab, TabList, TabPanel } from '@/design_system/Tabs/Tabs';
import IconChevron from '@/icons/Chevron.svg';
import { useCurrentSession } from '@/services/auth';
import useViewport from '@/utils/useViewport';

import { InformationTab } from './Tabs/InformationTab';
import { NotificationsTab } from './Tabs/NotificationsTab/NotificationsTab';

const tabs = ['information', 'notifications'];
const isValidTab = (tab: string | null): tab is Tab => !!tab && tabs.includes(tab);
type Tab = (typeof tabs)[number];

const Profile = () => {
  const { isMobile } = useViewport();
  const { currentSession, setCurrentSession } = useCurrentSession();
  const location = useLocation();
  const tabQueryParam = new URLSearchParams(location.search).get('tab');

  const initialTab = isValidTab(tabQueryParam) ? tabQueryParam : 'information';
  const [tab, setTab] = useState<Tab>(initialTab);

  return (
    <div className="container">
      <Stack gap="24px">
        <Stack row alignItems="center" gap="1rem">
          {isMobile && (
            <Button iconOnly variant="secondary" to="/account">
              <IconChevron left />
            </Button>
          )}

          <h1 className="headline-200-bold">
            <Trans id="profile.title">Profile</Trans>
          </h1>
        </Stack>

        <Tabs
          gap="24px"
          selectedKey={tab}
          onSelectionChange={(tab) => {
            setTab(isValidTab(tab) ? tab : 'information');
          }}
          style={{ flex: 1, paddingBottom: 24 }}
        >
          <TabList>
            <Tab id={'information' satisfies Tab}>
              <Trans id="profile.tabs.information">Information</Trans>
            </Tab>

            <Tab id={'notifications' satisfies Tab}>
              <Trans id="profile.tabs.notification">Notifications</Trans>
            </Tab>
          </TabList>

          <TabPanel id={'information' satisfies Tab} style={{ flex: 1 }}>
            <InformationTab currentSession={currentSession} setCurrentSession={setCurrentSession} />
          </TabPanel>

          <TabPanel id={'notifications' satisfies Tab} style={{ flex: 1 }}>
            <NotificationsTab user={currentSession!} />
          </TabPanel>
        </Tabs>
      </Stack>
    </div>
  );
};

export default Profile;
