import Stack from '@/design_system/Stack';
import { type ArticleWithRelations, type RequestWithRelations } from '@/models/request';
import { ArticleDispatch } from '@/routes/Requests/components/ArticleDispatch';

import ActionTypeSearchSelect from './ActionTypeSearchSelect';
import { ServiceChoiceRadio } from './ServiceChoiceRadio';

export const ServiceChoice = ({
  request,
  article,
  isDisabled = false,
  isEditActionDisabled = false,
  isEditCustomActionWorkshopPriceDisabled = false,
  showServiceChoiceSelector,
  showDispatchSection,
  showWorkshopSelector = false,
  errors,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  isDisabled?: boolean;
  isEditActionDisabled?: boolean;
  isEditCustomActionWorkshopPriceDisabled?: boolean;
  showServiceChoiceSelector: boolean;
  showDispatchSection: boolean;
  showWorkshopSelector?: boolean;
  errors?: {
    actionsError?: string;
    workshopError?: string;
    missingDefectPhotoActionIds?: string[];
  };
}) => {
  return (
    <Stack gap="2rem">
      {showServiceChoiceSelector && <ServiceChoiceRadio />}

      <ActionTypeSearchSelect
        request={request}
        article={article}
        isDisabled={isDisabled}
        isEditActionDisabled={isEditActionDisabled}
        isEditCustomActionWorkshopPriceDisabled={isEditCustomActionWorkshopPriceDisabled}
        missingDefectPhotoActionIds={errors?.missingDefectPhotoActionIds}
        error={errors?.actionsError}
      />

      {showDispatchSection && (
        <ArticleDispatch
          request={request}
          article={article}
          showSelector={showWorkshopSelector}
          error={errors?.workshopError}
        />
      )}
    </Stack>
  );
};
