import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import IconShipmentDone from '@/icons/ShipmentDone.svg';
import { BaseNotification } from '@/layouts/App/Navbar/NotificationList/components/BaseNotification/BaseNotification';
import type { TNotificationOfType } from '@/models/notification';

export const ShipmentArrivedAtPickupPointNotification = ({
  notification,
}: {
  notification: TNotificationOfType<'shipment_arrived_at_pickup_point'>;
}) => {
  const { _ } = useLingui();

  return (
    <BaseNotification
      icon={<IconShipmentDone />}
      type="new"
      notification={notification}
      notificationLabel={_(
        msg({
          id: 'notification.shipment-arrived-at-pickup-point.label',
          message: `The package of shipment ${notification.shipment.reference} has arrived at the pickup point, you can go and pick it up.`,
        })
      )}
      notificationHeader={
        <Trans id="notification.shipment-arrived-at-pickup-point">
          The package has arrived at the pickup point. You can go and pick it up.
        </Trans>
      }
    />
  );
};
