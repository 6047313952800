import { useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { MIN_PASSWORD_LENGTH, NewPassword } from '@/components/NewPassword/NewPassword';
import Button from '@/design_system/Button';
import InputText from '@/design_system/InputText';
import Stack from '@/design_system/Stack';
import { useAcceptInvitationAndLogin } from '@/models/userRole';
import { useCurrentSession, useFlags, usePublicKeys } from '@/services/auth';

const Join = () => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email')?.replace(/\s/g, '+') ?? '';
  const invitationToken = searchParams.get('token') ?? '';

  const { mutateAsync: acceptAndLogin, isPending, isSuccess } = useAcceptInvitationAndLogin();
  const { setCurrentSession } = useCurrentSession();
  const { setFlags } = useFlags();
  const { setPublicKeys } = usePublicKeys();

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordScore, setPasswordScore] = useState(0);

  const [error, setError] = useState('');

  if (!email || !invitationToken) {
    return <Navigate to="/login" />;
  }

  const onSubmit = async () => {
    setError('');

    if (!name) {
      setError(
        _(
          msg({
            id: 'join.error.missing-name',
            message: 'Name is required',
          })
        )
      );

      return;
    }

    if (!password) {
      setError(
        _(
          msg({
            id: 'forgotten-password.error.missing-password',
            message: 'Password is required',
          })
        )
      );

      return;
    }

    if (password.length < MIN_PASSWORD_LENGTH) {
      setError(
        _(
          msg({
            id: 'new-password.complexity.min-chars',
            message: `The password must be at least ${MIN_PASSWORD_LENGTH} characters long`,
          })
        )
      );

      return;
    }

    if (passwordScore < 1) {
      setError(
        _(
          msg({
            id: 'new-password.complexity.too-weak',
            message: 'The complexity of this password is too weak',
          })
        )
      );

      return;
    }

    await acceptAndLogin({
      email,
      invitationToken,
      name,
      password,
    })
      .then(({ user, flags, publicKeys }) => {
        setCurrentSession(user);
        setFlags(flags);
        setPublicKeys(publicKeys);
      })
      .then(() => navigate('/requests', { replace: true }))
      .catch((err: any) => {
        if (err.message === 'Invalid email or invitation token') {
          setError(
            _(msg({ id: 'join.error.email-or-token-invalid', message: 'Email or token invalid' }))
          );
        } else {
          console.error(err);
          setError(
            (err.message as string) ??
              _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
          );
        }
      });
  };

  return (
    <>
      <h1 className="headline-200-bold text-center">
        <Trans id="join.title">Welcome to Prolong!</Trans>
      </h1>
      <Stack gap="1.5rem">
        <InputText
          isDisabled
          value={email}
          label={<Trans id="join.field.email.label">Email</Trans>}
        />
        <InputText
          isRequired
          name="name"
          label={<Trans id="join.field.name.label">Name</Trans>}
          placeholder={_(msg({ id: 'join.field.name.placeholder', message: 'Enter your name...' }))}
          autoComplete="name"
          value={name}
          onChange={(name) => {
            setName(name);
            setError('');
          }}
        />
        <NewPassword
          password={password}
          setPassword={(password) => {
            setPassword(password);
            setError('');
          }}
          setStrengthScore={setPasswordScore}
        />
        {error && <span className="paragraph-100-medium text-danger text-center">{error}</span>}
        <Button
          type="submit"
          isLoading={isPending || isSuccess}
          disabled={isPending || isSuccess}
          onPress={onSubmit}
        >
          <Trans id="join.create-account">Create account</Trans>
        </Button>
      </Stack>
    </>
  );
};

export default Join;
