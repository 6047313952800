import IconWrapper from './IconWrapper';

const IconBuilding = IconWrapper({
  name: 'building',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 3.75C5.05964 3.75 4.5 4.30964 4.5 5V20.75H8.25V18.5C8.25 18.2239 8.47386 18 8.75 18H10.75C11.0261 18 11.25 18.2239 11.25 18.5V20.75H15V7V6.25V5C15 4.30964 14.4404 3.75 13.75 3.75H5.75ZM4.25 22.25H15H15.75H16.5H20.25C20.9404 22.25 21.5 21.6904 21.5 21V9C21.5 7.48122 20.2688 6.25 18.75 6.25H16.5V5C16.5 3.48122 15.2688 2.25 13.75 2.25H5.75C4.23122 2.25 3 3.48122 3 5V21C3 21.6904 3.55964 22.25 4.25 22.25ZM20 20.75H16.5V16H17.25C17.5261 16 17.75 15.7761 17.75 15.5V14.5C17.75 14.2239 17.5261 14 17.25 14H16.5V12H17.25C17.5261 12 17.75 11.7761 17.75 11.5V10.5C17.75 10.2239 17.5261 10 17.25 10H16.5V7.75H18.75C19.4404 7.75 20 8.30964 20 9V20.75ZM7.25 6C6.97386 6 6.75 6.22386 6.75 6.5V7.5C6.75 7.77614 6.97386 8 7.25 8H8.25C8.52614 8 8.75 7.77614 8.75 7.5V6.5C8.75 6.22386 8.52614 6 8.25 6H7.25ZM7.25 10C6.97386 10 6.75 10.2239 6.75 10.5V11.5C6.75 11.7761 6.97386 12 7.25 12H8.25C8.52614 12 8.75 11.7761 8.75 11.5V10.5C8.75 10.2239 8.52614 10 8.25 10H7.25ZM6.75 14.5C6.75 14.2239 6.97386 14 7.25 14H8.25C8.52614 14 8.75 14.2239 8.75 14.5V15.5C8.75 15.7761 8.52614 16 8.25 16H7.25C6.97386 16 6.75 15.7761 6.75 15.5V14.5ZM10.75 6.5C10.75 6.22386 10.9739 6 11.25 6H12.25C12.5261 6 12.75 6.22386 12.75 6.5V7.5C12.75 7.77614 12.5261 8 12.25 8H11.25C10.9739 8 10.75 7.77614 10.75 7.5V6.5ZM11.25 10C10.9739 10 10.75 10.2239 10.75 10.5V11.5C10.75 11.7761 10.9739 12 11.25 12H12.25C12.5261 12 12.75 11.7761 12.75 11.5V10.5C12.75 10.2239 12.5261 10 12.25 10H11.25ZM11.25 14C10.9739 14 10.75 14.2239 10.75 14.5V15.5C10.75 15.7761 10.9739 16 11.25 16H12.25C12.5261 16 12.75 15.7761 12.75 15.5V14.5C12.75 14.2239 12.5261 14 12.25 14H11.25Z"
      />
    </svg>
  ),
});

export default IconBuilding;
