import { Plural, Trans } from '@lingui/macro';

import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import IconShipmentDone from '@/icons/ShipmentDone.svg';
import IconStar from '@/icons/Star.svg';
import { BrandWrapper, BrandWrapperCenter } from '@/layouts/Brand';
import { ClientRequestWithRelations } from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';

export const Completed = ({ request }: { request: ClientRequestWithRelations }) => {
  return (
    <BrandWrapper>
      <BrandWrapperCenter>
        <Stack gap="1.5rem">
          <ClientStepper request={request} />
          {!request.feedback && <RequestCompletedMessage request={request} />}
          {request.feedback && <FeedbackInfo request={request} />}
          <Stack gap="1rem">
            <ClientArticlesTable
              request={request}
              showPrice={!!request.client}
              showArticleComment
            />
            {!!request.client && <ClientInfo request={request} canEdit={false} />}
          </Stack>
        </Stack>
      </BrandWrapperCenter>
    </BrandWrapper>
  );
};

const RequestCompletedMessage = ({ request }: { request: ClientRequestWithRelations }) => {
  return (
    <Stack row gap="0.5rem">
      <IconShipmentDone className="color-primary-700" style={{ fontSize: '24px' }} />
      {request.client && (
        <p className="paragraph-50-regular paragraph-100-regular-mobile">
          <Trans id="store.request.completed.client-text">
            The <Plural value={request.articles.length} one="item has" other="items have" /> been
            successfully delivered back to the client.
          </Trans>
        </p>
      )}
    </Stack>
  );
};

const FeedbackInfo = ({ request }: { request: ClientRequestWithRelations }) => {
  return (
    <Box gap="0.5rem" padding="16px">
      <Stack row gap="0.5rem" className="paragraph-50-regular text-secondary">
        <Trans id="store.request.completed.review.client">
          {request.client?.name} left a review
        </Trans>
        <Stack
          row
          style={{
            color: 'var(--color-warning-500)',
            height: '24px',
            display: 'inline-flex',
            lineHeight: '24px',
            fontSize: '20px',
          }}
          alignItems="center"
        >
          {([1, 2, 3, 4, 5] as const).map((rating) => (
            <IconStar key={rating} filled={rating <= (request.feedback?.global ?? 0)} />
          ))}
        </Stack>
      </Stack>
      <Stack row gap="0.25rem" className="paragraph-100-regular text-secondary">
        {request.feedback?.quality != null && (
          <span>
            <Trans id="store.request.completed.review.quality">Quality:</Trans>{' '}
            {request.feedback?.quality}/5{' • '}
          </span>
        )}
        {request.feedback?.speed != null && (
          <span>
            <Trans id="store.request.completed.review.speed">Speed:</Trans>{' '}
            {request.feedback?.speed}
            /5
            {' • '}
          </span>
        )}
        {request.feedback?.communication != null && (
          <span>
            <Trans id="store.request.completed.review.communication">Communication:</Trans>{' '}
            {request.feedback?.communication}/5
          </span>
        )}
      </Stack>
      {request.feedback?.comment && (
        <div
          className="paragraph-100-regular"
          style={{
            backgroundColor: 'var(--color-neutral-200)',
            borderRadius: '8px',
            padding: '8px 12px',
            flex: 1,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        >
          {request.feedback?.comment}
        </div>
      )}
    </Box>
  );
};
