import IconWrapper from '@/icons/IconWrapper';

const IconMaleBuckle = IconWrapper({
  name: 'male-buckle',
  source: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
    >
      <g className="type=male" clipPath="url(#a)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M30.203 81.8C16.953 78.005 6.21 73.144-1 67.577V33.29c.278.215.561.429.85.642 7.35 5.42 18.085 10.145 31.215 13.822 2.024-5.61 7.375-9.61 13.668-9.61h45.601c8.034 0 14.533 6.522 14.533 14.585V81.5c-.018 8.062-6.517 14.585-14.533 14.585H44.733c-7.934 0-14.371-6.362-14.53-14.286Zm8.761 2.267v.24a2.356 2.356 0 0 0 2.34 2.349h52.44c1.284 0 2.34-1.06 2.34-2.35V49.94a2.356 2.356 0 0 0-2.34-2.35h-52.44c-1.227 0-2.247.97-2.334 2.182.788.183 1.582.362 2.383.538 1.083.254 1.86 1.196 1.86 2.301v29.48c0 1.503-1.373 2.627-2.835 2.3-.474-.106-.945-.214-1.414-.324Z"
          className="fill-white"
          clipRule="evenodd"
        />
        <path
          fill="#3F4643"
          d="M80.008 68.31a2.4 2.4 0 0 1-.505-.054 92.516 92.516 0 0 0-36.666 0c-.668.127-1.354 0-1.913-.363a7.968 7.968 0 0 0-8.738 0 2.583 2.583 0 0 1-3.575-.743 2.604 2.604 0 0 1 .74-3.587 13.102 13.102 0 0 1 13.432-.562c12.529-2.391 25.202-2.319 37.73.2 1.39.271 2.293 1.648 2.022 3.044a2.588 2.588 0 0 1-2.527 2.065Z"
          className="fill-accent"
        />
        <path
          fill="#3F4643"
          fillRule="evenodd"
          d="M28.255 61.89a15.103 15.103 0 0 1 13.957-1.367v-7.912c0-.619-.433-1.172-1.081-1.326C23.704 47.46 9.372 41.928 0 35.274v-2.48c.38.289.772.575 1.172.86 7.034 4.99 17.065 9.394 29.296 12.89 2.382-5.533 7.867-9.402 14.265-9.402h45.601c8.59 0 15.533 6.974 15.533 15.586v28.775c-.019 8.609-6.96 15.583-15.533 15.583H44.733c-8.234 0-14.955-6.408-15.497-14.525-12.072-3.514-22.09-7.931-29.236-13v-2.478c8.838 6.672 22.972 12.36 40.596 16.332.822.184 1.616-.445 1.616-1.325V70.302a4.577 4.577 0 0 1-2.376-.73l-.009-.006a5.967 5.967 0 0 0-6.545 0l-.001.001a4.583 4.583 0 0 1-6.335-1.31l-.006-.009-.006-.009a4.604 4.604 0 0 1 1.305-6.338l.008-.005.008-.005Zm3.043 21.257c.809 6.735 6.512 11.94 13.435 11.94h45.601c7.459 0 13.516-6.072 13.533-13.589v-28.77c0-7.514-6.055-13.586-13.533-13.586H44.733c-5.475 0-10.187 3.254-12.318 7.946 1.898.518 3.846 1.015 5.84 1.489a3.352 3.352 0 0 1 3.05-1.986h52.44c1.839 0 3.34 1.511 3.34 3.349v34.367c0 1.837-1.501 3.349-3.34 3.349h-52.44c-1.648 0-3.023-1.212-3.293-2.785a183.69 183.69 0 0 1-6.714-1.724Zm9.277 2.287c.211.14.463.222.73.222h52.44c.727 0 1.34-.61 1.34-1.35V49.94c0-.74-.612-1.35-1.34-1.35h-52.44c-.395 0-.755.18-1.003.461.42.095.842.19 1.265.282l.014.003c1.515.355 2.631 1.685 2.631 3.275v8.098c12.195-2.1 24.513-1.918 36.691.53 2.501.492 4.07 2.942 3.596 5.387l-.003.013-.003.013a4.588 4.588 0 0 1-4.485 3.658 4.4 4.4 0 0 1-.916-.097 90.494 90.494 0 0 0-34.88-.188V82.09c0 1.987-1.7 3.53-3.637 3.344ZM29.352 63.563a13.102 13.102 0 0 1 13.431-.562c12.529-2.391 25.202-2.319 37.73.2 1.39.271 2.293 1.648 2.022 3.044a2.588 2.588 0 0 1-2.527 2.065c-.162 0-.343-.018-.505-.054a92.516 92.516 0 0 0-36.666 0 2.62 2.62 0 0 1-1.914-.363 7.967 7.967 0 0 0-8.737 0 2.583 2.583 0 0 1-3.575-.743 2.604 2.604 0 0 1 .74-3.587Z"
          className="stroke"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a" className="a">
          <path fill="#fff" d="M0 0h128v128H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
});

export default IconMaleBuckle;
