import { ReactNode } from 'react';
import { Button as AriaButton } from 'react-aria-components';

import Tooltip from '@/design_system/Tooltip';
import IconAdd from '@/icons/Add.svg';
import { createBEMClasses } from '@/utils/classname';

import './Avatar.css';

const { block } = createBEMClasses('avatar');

interface AvatarProps {
  variant?: 'primary' | 'greyedOut';
  url?: string | null;
  name?: string;
  icon?: ReactNode;
  count?: number;
  size?: 'xx-small' | 'x-small' | 'small' | 'large';
  isButton?: boolean;
  onPress?: () => void;
  ariaLabel?: string;
  tooltip?: React.ReactNode;
  disabled?: boolean;
}

const Avatar = ({
  variant,
  url,
  name,
  icon,
  count,
  size = 'large',
  isButton,
  onPress,
  ariaLabel,
  tooltip,
  disabled,
}: AvatarProps) => {
  const Base = ({ ...props }) =>
    tooltip ? (
      <Tooltip content={tooltip}>
        <AriaButton {...props} onPress={onPress} aria-label={ariaLabel} />
      </Tooltip>
    ) : isButton ? (
      <AriaButton {...props} onPress={onPress} aria-label={ariaLabel} />
    ) : (
      <div {...props} aria-label={ariaLabel} />
    );

  if (url) {
    return (
      <Base className={block({ size, image: true, variant, disabled })}>
        <img src={url} alt={name} />
      </Base>
    );
  }

  if (icon) {
    return <Base className={block({ size, icon: true, disabled })}>{icon}</Base>;
  }

  if (count) {
    return <Base className={block({ size, count: true, disabled })}>+{count}</Base>;
  }

  if (name) {
    const initials = (
      name.split(' ')[0].slice(0, 1) + name.split(' ').at(-1)!.slice(0, 1)
    ).toUpperCase();

    return <Base className={block({ size, variant, disabled }, 'sentry-mask')}>{initials}</Base>;
  }

  return <Base className={block({ size, empty: true, disabled })}>{!disabled && <IconAdd />}</Base>;
};

export default Avatar;
