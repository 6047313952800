/**
 *
 * @param externalPaymentReference The external reference entered by the user
 * @returns If the value doesn't respect the format eventually provided by the organization
 */
export const isExternalPaymentReferenceInvalid = (
  externalPaymentReference?: string,
  externalPaymentReferenceFormat?: string
): boolean => {
  // If there is no format provided, reference can't be invalid
  if (!externalPaymentReferenceFormat) {
    return false;
  }

  if (!externalPaymentReference) {
    return true;
  }

  return externalPaymentReference.match(externalPaymentReferenceFormat)?.length !== 1;
};
