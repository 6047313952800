import IconWrapper from './IconWrapper';

const IconValidation = IconWrapper({
  name: 'validation',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4726 2.4176C12.1971 2.19413 11.8029 2.19413 11.5274 2.4176C9.8148 3.80724 7.33866 4.5391 5.63603 4.8943C4.31916 5.16901 3.25 6.31307 3.25 7.73577V14.3833C3.25 16.0412 4.11441 17.5792 5.53068 18.441L10.5704 21.508C11.4485 22.0424 12.5514 22.0424 13.4296 21.508L18.4693 18.441C19.8856 17.5792 20.75 16.0412 20.75 14.3833V7.73577C20.75 6.31307 19.6808 5.16901 18.364 4.8943C16.6613 4.5391 14.1852 3.80724 12.4726 2.4176ZM5.94236 6.36268C7.56408 6.02437 10.052 5.32532 12 3.941C13.948 5.32532 16.4359 6.02437 18.0576 6.36268C18.7564 6.50845 19.25 7.09948 19.25 7.73577V14.3833C19.25 15.5177 18.6586 16.57 17.6895 17.1597L12.6498 20.2266C12.2507 20.4695 11.7493 20.4695 11.3502 20.2266L6.31046 17.1597C5.34144 16.57 4.75 15.5177 4.75 14.3833V7.73577C4.75 7.09948 5.24363 6.50845 5.94236 6.36268ZM16.5303 10.5303C16.8232 10.2374 16.8232 9.76256 16.5303 9.46967C16.2374 9.17678 15.7626 9.17678 15.4697 9.46967L11.5 13.4393L9.53033 11.4697C9.23744 11.1768 8.76256 11.1768 8.46967 11.4697C8.17678 11.7626 8.17678 12.2374 8.46967 12.5303L10.9697 15.0303C11.2626 15.3232 11.7374 15.3232 12.0303 15.0303L16.5303 10.5303Z"
      />
    </svg>
  ),
});

export default IconValidation;
