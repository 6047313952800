import { useState } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Button from '@/design_system/Button';
import Dialog from '@/design_system/Dialog';
import Stack from '@/design_system/Stack';
import TextArea from '@/design_system/TextArea';
import { useArticleName } from '@/models/article';
import { RequestWithRelations } from '@/models/request';
import { ShipmentWithRelations, useArchiveShipmentIssue } from '@/models/shipment';

const ArchiveShipmentIssueDialog = ({
  shipmentArticle,
  request,
  isOpen,
  setIsOpen,
  onActionDone,
}: {
  shipmentArticle: ShipmentWithRelations['articles'][number];
  request: RequestWithRelations;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onActionDone: () => void;
}) => {
  const { _ } = useLingui();

  return (
    <Dialog
      title={_(msg({ id: 'archive-article-issue-modal.title', message: 'Archive item' }))}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
    >
      <ArchiveShipmentIssueForm
        shipmentArticle={shipmentArticle}
        request={request}
        close={() => setIsOpen(false)}
        onActionDone={onActionDone}
      />
    </Dialog>
  );
};

const ArchiveShipmentIssueForm = ({
  shipmentArticle,
  request,
  close,
  onActionDone,
}: {
  shipmentArticle: ShipmentWithRelations['articles'][number];
  request: RequestWithRelations;
  close: () => void;
  onActionDone: () => void;
}) => {
  const { _ } = useLingui();

  const [archivalReason, setArchivalReason] = useState('');
  const [showErrors, setShowErrors] = useState(false);

  const articleName = useArticleName({ article: shipmentArticle.article });

  const {
    mutateAsync: archiveShipmentIssue,
    isPending,
    isSuccess,
  } = useArchiveShipmentIssue({
    shipmentId: shipmentArticle.shipmentId,
    articleId: shipmentArticle.articleId,
  });

  const save = async () => {
    setShowErrors(true);

    if (!archivalReason) {
      return;
    }

    await archiveShipmentIssue({ archivalReason });

    close();
    onActionDone();
  };

  const isLastActiveArticle =
    request.articles.length === 1 && request.articles[0].id === shipmentArticle.articleId;

  return (
    <>
      <main>
        <Stack gap="1rem">
          <Stack gap="0.5rem">
            <p className="paragraph-100-regular text-secondary">
              <Trans id="archive-article-issue-modal.intro">
                You are about to archive the item <b>{articleName}</b>.
              </Trans>{' '}
              {isLastActiveArticle ? (
                <Trans id="archive-article-issue-modal.intro.last">
                  The request will be automatically archived.
                </Trans>
              ) : (
                <Trans id="archive-article-issue-modal.intro.not-last">
                  The item will no longer be associated with this request.
                </Trans>
              )}
            </p>
          </Stack>
          <TextArea
            ariaLabel={_(
              msg({
                id: 'archive-article-issue-modal.label',
                message: 'Archival reason',
              })
            )}
            placeholder={_(
              msg({
                id: 'archive-article-issue-modal.placeholder',
                message: 'Describe archival reason...',
              })
            )}
            value={archivalReason}
            onChange={(e) => setArchivalReason(e.target.value)}
            error={
              showErrors && !archivalReason
                ? _(
                    msg({
                      id: 'archive-article-issue-modal.error',
                      message: 'Please specify the archival reason',
                    })
                  )
                : undefined
            }
            rows={4}
          />
        </Stack>
      </main>
      <footer>
        <Button variant="secondary" size="medium" onPress={close}>
          <Trans id="archive-article-issue-modal.actions.cancel">Cancel</Trans>
        </Button>
        <Button variant="danger" size="medium" onPress={save} isLoading={isPending || isSuccess}>
          {isLastActiveArticle ? (
            <Trans id="archive-article-issue-modal.actions.archive.last">
              Archive item & request
            </Trans>
          ) : (
            <Trans id="archive-article-issue-modal.actions.archive.not-last">Archive item</Trans>
          )}
        </Button>
      </footer>
    </>
  );
};

export default ArchiveShipmentIssueDialog;
