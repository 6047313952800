import { ReactNode } from 'react';
import { Button as AriaButton } from 'react-aria-components';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Loader from '@/components/Loader';
import Badge from '@/design_system/Badge';
import Button from '@/design_system/Button/Button';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconInfo from '@/icons/Info.svg';
import IconPhone from '@/icons/Phone.svg';
import IconPlace from '@/icons/Place.svg';
import IconRefusal from '@/icons/Refusal.svg';
import IconValidation from '@/icons/Validation.svg';
import { ArticleActivityOfType } from '@/models/article';
import { WorkshopFee, WorkshopWithRelations } from '@/models/workshop';
import { createBEMClasses } from '@/utils/classname';
import { formatCurrency } from '@/utils/number';

import './WorkshopList.css';

type WorkshopListProps = {
  selected?: string;
  workshops: WorkshopWithRelations[];
  dispatchRefusedActivities: ArticleActivityOfType<'job_refused'>[];
  onSelect: (workshop: WorkshopWithRelations) => void;
  isFetchingWorkshops?: boolean;
};

const { block, element } = createBEMClasses('workshop-list');

const WorkshopList = ({
  workshops,
  dispatchRefusedActivities,
  selected,
  onSelect,
  isFetchingWorkshops,
}: WorkshopListProps) => {
  const { _ } = useLingui();

  const otherRegion = _(msg({ id: 'workshop.region.other', message: 'Other' }));

  const workshopsByRegion = workshops.reduce<Record<string, WorkshopWithRelations[]>>(
    (result, workshop) => {
      result[workshop.region ?? otherRegion] ??= [];
      result[workshop.region ?? otherRegion].push(workshop);

      return result;
    },
    {}
  );

  return (
    <Stack gap="8px" className={block({}, 'inner-shadow-on-scroll')}>
      {Object.entries(workshopsByRegion)
        .sort(([regionA], [regionB]) => (regionA > regionB ? 1 : -1))
        .map(([region, workshops]) => (
          <Stack
            key={region}
            gap="8px"
            ariaLabel={_(
              msg({ id: 'workshop-list.region.label', message: `Workshops in ${region}` })
            )}
          >
            <p className="paragraph-100-medium">{region}</p>
            {workshops.map((workshop) => {
              const hasAlreadyRefusedJob = dispatchRefusedActivities.some(
                (activity) => activity.workshopId === workshop.id
              );

              return (
                <WorkshopListItem
                  key={workshop.id}
                  workshop={workshop}
                  hasAlreadyRefusedJob={hasAlreadyRefusedJob}
                  onSelect={() => onSelect(workshop)}
                  isFetchingWorkshops={isFetchingWorkshops}
                  selected={selected}
                />
              );
            })}
          </Stack>
        ))}
    </Stack>
  );
};

export const WorkshopListItem = ({
  workshop,
  hasAlreadyRefusedJob,
  isDisabled,
  onSelect,
  isFetchingWorkshops,
  selected,
}: {
  workshop: WorkshopWithRelations;
  hasAlreadyRefusedJob?: boolean;
  onSelect?: () => void;
  selected?: string;
  isFetchingWorkshops?: boolean;
  isDisabled?: boolean;
}) => {
  const { _ } = useLingui();

  const cancellationFee = workshop.organizationConfig?.fees.find(
    (fee) => fee.type === 'cancellation'
  );

  const shippingManagementFee = workshop.organizationConfig?.fees.find(
    (fee) => fee.type === 'shipping-management'
  );
  const shippingManagementFeeAmount = shippingManagementFee
    ? Math.round(
        (shippingManagementFee.amountBeforeTax * (100 + shippingManagementFee.taxRate)) / 100
      )
    : null;

  const descriptionItems = [
    workshop.areCustomsExpected ? (
      <p key="city" className="text-warning">
        <IconPlace warning />
        {workshop.address?.city} <Trans id="workshop-list.customs-expected">(Customs)</Trans>
      </p>
    ) : (
      <p key="city">
        <IconPlace />
        {workshop.address?.city}
      </p>
    ),
    <p key="phone">
      <IconPhone />
      {workshop.formattedPhone}
    </p>,
    workshop.organizationConfig?.autoAcceptDispatch ? <AutoAcceptBadge key="auto-accept" /> : null,
    cancellationFee ? (
      <CancellationFeeBadge key="cancellation-fee" cancellationFee={cancellationFee} />
    ) : null,
  ]
    .filter(Boolean)
    .reduce((acc, item, index) => {
      if (index !== 0) {
        acc.push(
          <span key={`separator-${index}`} className="separator">
            •
          </span>
        );
      }

      acc.push(item);

      return acc;
    }, [] as ReactNode[]);

  const base = (
    <>
      <img src={workshop.logoSquare ?? workshop.logo ?? '/workshop.png'} alt="" />
      <div className={element('item__header')}>
        <div className={element('item__header__title')}>
          {workshop.name}
          {hasAlreadyRefusedJob && <RefusedBadge />}
        </div>
        <Stack row alignItems="center" gap="0.5rem">
          <p className={element('item__header__pricing')}>
            {isFetchingWorkshops && <Loader style={{ fontSize: '0.75rem' }} />}
            {formatCurrency(workshop.cost, workshop.currency)}
          </p>
          {!!shippingManagementFee && (
            <Stack row alignItems="center" gap="0.25rem">
              <p className={element('item__header__shipping-management-fee')}>
                <Trans id="components.workshop-list.shipping-management">
                  + {formatCurrency(shippingManagementFeeAmount, shippingManagementFee?.currency)}
                  /item
                </Trans>
              </p>
              <Tooltip
                content={_(
                  msg({
                    id: 'components.workshop-list.shipping-management.detail',
                    message: 'Per item sent by the workshop',
                  })
                )}
              >
                <Button variant="style-less">
                  <IconInfo style={{ fontSize: '1rem' }} className="text-disabled" />
                </Button>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </div>
      <div className={element('item__description')}>{descriptionItems}</div>
    </>
  );

  if (isDisabled) {
    return (
      <div
        className={element('item')}
        aria-label={_(
          msg({
            id: 'components.workshop-list.workshop-selected',
            message: `Workshop ${workshop.name} selected`,
          })
        )}
      >
        {base}
      </div>
    );
  }

  return (
    <AriaButton
      onPress={onSelect}
      className={element('item', { selected: workshop.id === selected })}
      aria-label={
        workshop.id === selected
          ? _(
              msg({
                id: 'components.workshop-list.workshop-selected',
                message: `Workshop ${workshop.name} selected`,
              })
            )
          : _(
              msg({
                id: 'components.workshop-list.select-workshop',
                message: `Select workshop ${workshop.name}`,
              })
            )
      }
    >
      {base}
    </AriaButton>
  );
};

const AutoAcceptBadge = () => (
  <Badge size="large" color="purple" variant="low">
    <Stack row alignItems="center" gap="4px">
      <IconValidation />
      <Trans id="components.workshop-list.auto-accept">Auto-accept</Trans>
    </Stack>
  </Badge>
);

const RefusedBadge = () => (
  <Badge size="large" color="red" variant="low">
    <Trans id="components.workshop-list.refused">Refused the job</Trans>
  </Badge>
);

const CancellationFeeBadge = ({ cancellationFee }: { cancellationFee: WorkshopFee }) => {
  const amount = Math.round(
    (cancellationFee.amountBeforeTax * (100 + cancellationFee.taxRate)) / 100
  );

  return (
    <Badge size="large" color="orange" variant="low">
      <Stack row alignItems="center" gap="4px" className="paragraph-200-regular">
        <IconRefusal />
        <Trans id="components.workshop-list.cancellation-cost">
          Cancellation cost:{' '}
          <span className="paragraph-200-medium">
            {formatCurrency(amount, cancellationFee.currency)}/item
          </span>
        </Trans>
      </Stack>
    </Badge>
  );
};

export default WorkshopList;
