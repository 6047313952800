import IconWrapper, { IconProps } from './IconWrapper';

const IconChevronUp = IconWrapper({
  name: 'chevron',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.96967 14.7803C6.67677 14.4874 6.67677 14.0126 6.96967 13.7197L11.4697 9.21967C11.7626 8.92677 12.2374 8.92678 12.5303 9.21967L17.0303 13.7197C17.3232 14.0126 17.3232 14.4874 17.0303 14.7803C16.7374 15.0732 16.2626 15.0732 15.9697 14.7803L12 10.8107L8.03033 14.7803C7.73743 15.0732 7.26256 15.0732 6.96967 14.7803Z"
      />
    </svg>
  ),
});

const IconChevronDown = IconWrapper({
  name: 'chevron',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0303 9.21967C17.3232 9.51256 17.3232 9.98744 17.0303 10.2803L12.5303 14.7803C12.2374 15.0732 11.7626 15.0732 11.4697 14.7803L6.96967 10.2803C6.67678 9.98744 6.67678 9.51256 6.96967 9.21967C7.26256 8.92678 7.73744 8.92678 8.03033 9.21967L12 13.1893L15.9697 9.21967C16.2626 8.92678 16.7374 8.92678 17.0303 9.21967Z"
      />
    </svg>
  ),
});

const IconChevronLeft = IconWrapper({
  name: 'chevron',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7803 17.0303C14.4874 17.3232 14.0126 17.3232 13.7197 17.0303L9.21967 12.5303C8.92678 12.2374 8.92678 11.7626 9.21967 11.4697L13.7197 6.96967C14.0126 6.67678 14.4874 6.67678 14.7803 6.96967C15.0732 7.26256 15.0732 7.73744 14.7803 8.03033L10.8107 12L14.7803 15.9697C15.0732 16.2626 15.0732 16.7374 14.7803 17.0303Z"
      />
    </svg>
  ),
});

const IconChevronRight = IconWrapper({
  name: 'chevron',
  source: (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21967 6.96967C9.51256 6.67677 9.98744 6.67677 10.2803 6.96967L14.7803 11.4697C15.0732 11.7626 15.0732 12.2374 14.7803 12.5303L10.2803 17.0303C9.98744 17.3232 9.51256 17.3232 9.21967 17.0303C8.92678 16.7374 8.92678 16.2626 9.21967 15.9697L13.1893 12L9.21967 8.03033C8.92678 7.73743 8.92678 7.26256 9.21967 6.96967Z"
      />
    </svg>
  ),
});

const IconChevron = ({
  up,
  down,
  left,
  right,
  ...props
}: {
  up?: boolean;
  down?: boolean;
  left?: boolean;
  right?: boolean;
} & IconProps) => {
  if (up) return <IconChevronUp {...props} />;
  if (down) return <IconChevronDown {...props} />;
  if (left) return <IconChevronLeft {...props} />;
  if (right) return <IconChevronRight {...props} />;
  return null;
};

export default IconChevron;
