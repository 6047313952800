import { Trans } from '@lingui/macro';

import AlertBar from '@/design_system/AlertBar';
import IconTruck from '@/icons/Truck.svg';
import { ShipmentWithRelations } from '@/models/shipment';
import { ShipmentMessageTitle } from '@/routes/Shipments/Shipment/components/ShipmentStep/components/ShipmentMessageTitle';

const InTransitMessage = ({
  shipment,
  hideIcon,
  className,
}: {
  shipment: ShipmentWithRelations;
  hideIcon?: boolean;
  className?: string;
}) => {
  return (
    <AlertBar
      type="info"
      size="large"
      icon={<IconTruck />}
      hideIcon={hideIcon}
      className={className}
      title={
        <ShipmentMessageTitle shipment={shipment}>
          <Trans id="requests.transit.in-transit-message.title">The delivery is in progress</Trans>
        </ShipmentMessageTitle>
      }
    >
      <p>
        <Trans id="requests.transit.in-transit-message.text">
          The carrier has taken charge of the package.
        </Trans>
      </p>
    </AlertBar>
  );
};

export default InTransitMessage;
