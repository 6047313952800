/* eslint-disable lingui/no-unlocalized-strings */
import React, { ReactElement } from 'react';
import cn from 'classnames';

export type IconWrapperProps = {
  name: string;
  source: ReactElement;
  className?: string;
};

export type IconProps = {
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
  [x: string]: any;
};

export type FillableIconProps = {
  filled?: boolean;
} & IconProps;

const IconWrapper = ({ name, className: defaultClassName, source }: IconWrapperProps) => {
  const Component = ({ alt, className, ...props }: IconProps): JSX.Element => {
    const attributes: Record<string, any> = {
      className: cn('icon-svg', `icon-${name}`, defaultClassName, className),
      ...props,
    };

    if (alt) {
      attributes.role = 'img';
      attributes.alt = alt;
    } else {
      attributes['aria-hidden'] = true;
    }

    return React.cloneElement(source, attributes);
  };

  Component.displayName = `Icon${name
    .split('-')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join('')}`;

  return Component;
};

export default IconWrapper;
