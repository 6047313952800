import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { type CreationStepConfig } from '@/api';
import { ArticleServices } from '@/components/ArticleServices';
import Box from '@/design_system/Box';
import Button from '@/design_system/Button';
import Drawer from '@/design_system/Drawer';
import { DrawerBody, DrawerFooter } from '@/design_system/Drawer/Drawer';
import Stack from '@/design_system/Stack';
import Tooltip from '@/design_system/Tooltip';
import IconClock from '@/icons/Clock.svg';
import IconInfo from '@/icons/Info.svg';
import IconTrash from '@/icons/Trash.svg';
import {
  ArticleWithRelations,
  RequestWithRelations,
  useDeleteDraftRequestArticle,
} from '@/models/request';
import {
  ArticleDetails,
  ArticleFormGlobalError,
  LegacyArticleServices,
  useArticleDetailsError,
  useArticleServicesError,
  useHasNoAvailableActions,
} from '@/routes/Requests/New/components/ArticleForm';
import Warranty from '@/routes/Requests/Request/Article/Warranty';
import { RequestComments } from '@/routes/Requests/Request/components/RequestComments';
import { useRequestContext } from '@/routes/Requests/RequestContext';
import { useFlags } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

import NewArticleHeader from './components/NewArticleHeader';

import './NewArticle.css';

const { block, element } = createBEMClasses('new-article');

const NewArticle = () => {
  const { id, articleId } = useParams();
  const { request } = useRequestContext();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const article = request.articles.find(({ id }) => id === articleId);

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (state?.from) {
      // Wait for the Drawer CSS enter animation to finish before resetting state
      setTimeout(
        () =>
          navigate(pathname, {
            state: { ...state, from: undefined, skipRouterBlocker: undefined },
            replace: true,
          }),
        150
      );
    }
  }, [navigate, pathname, state]);

  const articleIsEmpty =
    !!article &&
    !article.productId &&
    !article.productL1 &&
    !article.productL2 &&
    !article.productL3 &&
    !article.data?.color &&
    !article.data?.size &&
    !article.purchaseDate &&
    !article.warranty &&
    !article.snapshot.articleActions.length &&
    !article.articlePhoto;

  const { mutateAsync: deleteArticle } = useDeleteDraftRequestArticle(request.id);

  const deleteAndClose = async () => {
    if (article) {
      await deleteArticle(article.id);
    }

    close();
  };

  const close = () => {
    setIsOpen(false);

    // Wait for the Drawer CSS exit animation to finish before navigating away
    setTimeout(
      () => navigate(`/requests/new/${id}`, { state: { skipRouterBlocker: true }, replace: true }),
      150
    );
  };

  const isEditing = !state?.isNew;

  return (
    <Drawer
      isOpen={isOpen}
      animateEntry={state?.from === 'request'}
      onOpenChange={isEditing ? close : deleteAndClose}
      closeConfirmation={
        articleIsEmpty || isEditing
          ? undefined
          : {
              title: <Trans id="dialog.discard-changes.title">Discard changes</Trans>,
              content: (
                <Trans id="dialog.discard-changes.content">
                  You&apos;re about to leave this page without saving your changes. All unsaved
                  changes will be lost.
                  <br />
                  Are you sure?
                </Trans>
              ),
              confirm: <Trans id="dialog.discard-changes.confirm">Discard changes</Trans>,
            }
      }
    >
      {!request || !article ? null : (
        <DrawerContent request={request} article={article} isEditing={isEditing} close={close} />
      )}
    </Drawer>
  );
};

const DrawerContent = ({
  request,
  article,
  isEditing,
  close,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  isEditing: boolean;
  close: () => void;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const { flags } = useFlags();

  const creationConfig = article.step?.config as CreationStepConfig['config'];

  const allowWarranty =
    (article.step && 'allowWarranty' in creationConfig && creationConfig.allowWarranty) ?? false;

  // Errors

  const [showSaveForLaterErrors, setShowSaveForLaterErrors] = useState(false);
  const [showSaveErrors, setShowSaveErrors] = useState(false);

  const articleDetailsError = useArticleDetailsError(article);
  const articleServicesError = useArticleServicesError(article);

  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();
  const hasNoAvailableActions = useHasNoAvailableActions(article);

  // Actions

  const {
    mutateAsync: deleteArticle,
    isPending: isPendingDeleteArticle,
    isSuccess: isSuccessDeleteArticle,
  } = useDeleteDraftRequestArticle(article.requestId);

  const handleDelete = async () => {
    await deleteArticle(article.id);
    close();
  };

  const handleSaveForLater = () => {
    setShowSaveForLaterErrors(true);
    setShowSaveErrors(false);

    if (articleDetailsError.hasError) {
      scrollAlertIntoView();
      return;
    }

    close();
  };

  const handleSave = () => {
    setShowSaveErrors(true);
    setShowSaveForLaterErrors(false);

    if (articleDetailsError.hasError || articleServicesError.hasError) {
      scrollAlertIntoView();
      return;
    }

    close();
  };

  return (
    <>
      <NewArticleHeader request={request} article={article} isEditing={isEditing} />
      <DrawerBody className={block()}>
        <Stack padding={isMobile ? '1rem' : '1.5rem 2rem'}>
          <ArticleFormGlobalError
            ref={alertRef}
            showGlobalError={
              (showSaveForLaterErrors && articleDetailsError.hasError) ||
              (showSaveErrors && (articleDetailsError.hasError || articleServicesError.hasError))
            }
            showNoActionsWarning={
              hasNoAvailableActions && article.snapshot.articleActions.length === 0
            }
          />
          <Stack gap="2rem">
            <Stack gap="0.5rem">
              <h2 className="headline-400-bold">
                <Trans id="requests.new.articles.drawer.information.title">Information</Trans>
              </h2>

              <Box
                padding={isMobile ? '1rem' : '1rem 1.5rem'}
                gap="1rem"
                className={element('details')}
              >
                <ArticleDetails
                  request={request}
                  article={article}
                  showErrors={showSaveForLaterErrors || showSaveErrors}
                />
              </Box>
            </Stack>
            {!!request.client && allowWarranty && (
              <Stack gap="0.5rem">
                <h2 className="headline-400-bold">
                  <Trans id="requests.new.articles.drawer.warranty.title">Warranty</Trans>
                </h2>

                <Box
                  padding={isMobile ? '1rem' : '1rem 1.5rem'}
                  gap="1rem"
                  className={element('details')}
                >
                  <Warranty
                    request={request}
                    article={article}
                    key={`purchase-date ${article.purchaseDate ?? ''}`}
                  />
                </Box>
              </Stack>
            )}
            <Stack gap="0.5rem">
              <h2 className="headline-400-bold">
                <Trans id="requests.new.articles.drawer.services.title">Services</Trans>
              </h2>

              <Box
                padding={isMobile ? '1rem' : '1rem 1.5rem'}
                gap="1rem"
                className={element('services')}
              >
                {flags['enable-defect-action-split'] ? (
                  <ArticleServices
                    request={request}
                    article={article}
                    showDispatchSection={
                      !!creationConfig?.requireDispatch || !!creationConfig?.requireServiceChoice
                    }
                    showWorkshopSelector
                    errors={showSaveErrors ? articleServicesError : undefined}
                  />
                ) : (
                  <LegacyArticleServices
                    request={request}
                    article={article}
                    showErrors={showSaveErrors}
                  />
                )}
              </Box>
            </Stack>

            <Stack gap={isMobile ? undefined : '0.5rem'}>
              <Stack row alignItems="center" gap="0.25rem">
                <h2 className="headline-400-bold">
                  <Trans id="article.comment.title">Item comments</Trans>
                </h2>
                <Tooltip
                  content={_(
                    msg({
                      id: 'comments.title.tooltip',
                      message: "These comments won't be visible by the client",
                    })
                  )}
                >
                  <Button variant="style-less">
                    <IconInfo className="headline-300-bold text-disabled" />
                  </Button>
                </Tooltip>
              </Stack>
              <ErrorBoundary>
                <Box
                  className={element('comments', { 'no-background': isMobile })}
                  padding="0"
                  removeStyleOnMobile
                >
                  <RequestComments requestId={request.id} articleId={article.id} />
                </Box>
              </ErrorBoundary>
            </Stack>
          </Stack>
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <Stack gap="0.5rem" row={!isMobile}>
          {request.draft && isEditing && (
            <Button
              variant="secondary-danger"
              size="medium"
              onPress={handleDelete}
              isLoading={isPendingDeleteArticle || isSuccessDeleteArticle}
              dataTrackingId="requests.new.articles.drawer.actions.remove"
            >
              <IconTrash />
              <Trans id="requests.new.articles.drawer.actions.remove">Remove</Trans>
            </Button>
          )}
          <Button
            variant="secondary"
            size="medium"
            onPress={handleSaveForLater}
            dataTrackingId="requests.new.articles.drawer.actions.save-for-later"
          >
            <IconClock />
            <Trans id="requests.new.articles.drawer.actions.save-for-later">Save for later</Trans>
          </Button>
          <Button
            variant="primary"
            size="medium"
            onPress={handleSave}
            dataTrackingId="requests.new.articles.drawer.actions.save"
          >
            {isEditing ? (
              <Trans id="requests.new.articles.drawer.actions.save">Save</Trans>
            ) : (
              <Trans id="requests.new.articles.drawer.actions.add">Add item</Trans>
            )}
          </Button>
        </Stack>
      </DrawerFooter>
    </>
  );
};

export default NewArticle;
