import IconWrapper from '@/icons/IconWrapper';

const IconLabel = IconWrapper({
  name: 'label',
  source: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      fill="none"
      viewBox="0 0 128 128"
    >
      <g className="label">
        <path
          fill="#fff"
          d="M31.146 24.397c0 28.311-16.078 79.206 0 79.206H96.84V24.397H31.146Z"
          className="fill-accent"
        />
        <path
          fill="#CCD1CF"
          d="M96.854 24.397c0 28.311 16.078 79.206 0 79.206s0-50.882 0-79.206Z"
          className="fill-grey"
        />
        <g className="stroke">
          <path
            fill="#3F4643"
            d="M40.529 33.046h6.493v-2h-6.493v2Zm16.521 0h-6.493v-2h6.493v2Zm3.536 0h6.493v-2h-6.493v2Zm16.521 0h-6.48v-2h6.48v2Zm3.549 0h6.48v-2h-6.48v2Z"
          />
          <path
            fill="#3F4643"
            fillRule="evenodd"
            d="M97.841 23.397H30.146v1c0 7-.995 15.428-2.251 24.178-.455 3.172-.945 6.39-1.433 9.594-.853 5.597-1.698 11.148-2.338 16.325-1.005 8.13-1.526 15.497-.75 20.857.386 2.678 1.111 4.972 2.36 6.614 1.288 1.696 3.09 2.638 5.412 2.638H97.84v-.06c1.852-.232 3.326-1.13 4.426-2.578 1.247-1.642 1.973-3.936 2.36-6.614.774-5.36.254-12.727-.751-20.857-.64-5.177-1.485-10.728-2.338-16.324v-.002c-.488-3.203-.978-6.42-1.433-9.593-1.256-8.75-2.25-17.178-2.25-24.178h-.014v-1Zm-6.4 78.569c.172.226.353.438.543.637H31.146c-1.698 0-2.91-.65-3.82-1.847-.95-1.252-1.607-3.156-1.973-5.691-.733-5.067-.249-12.194.756-20.326.637-5.154 1.474-10.649 2.323-16.223.489-3.21.982-6.445 1.443-9.657.781-5.446 1.469-10.812 1.877-15.813h5.241v-2h-5.091c.131-1.953.214-3.842.237-5.649h63.702v.428a104.68 104.68 0 0 1-.235 5.221h-4.922v2h4.77c-.404 4.885-1.078 10.149-1.85 15.534-.456 3.175-.947 6.395-1.435 9.6-.852 5.596-1.697 11.144-2.336 16.319-1.005 8.13-1.526 15.495-.751 20.854.387 2.678 1.112 4.971 2.36 6.613Zm1.593-1.21c.91 1.198 2.123 1.847 3.82 1.847 1.698 0 2.91-.65 3.82-1.847.95-1.252 1.607-3.156 1.973-5.691.733-5.067.249-12.194-.756-20.326-.637-5.154-1.474-10.649-2.323-16.223-.489-3.21-.982-6.446-1.443-9.657a345.834 345.834 0 0 1-1.27-9.702c-.363 3.166-.8 6.422-1.272 9.707a1098.32 1098.32 0 0 1-1.444 9.664c-.848 5.572-1.685 11.065-2.322 16.216-1.004 8.132-1.488 15.257-.756 20.323.367 2.535 1.023 4.438 1.973 5.689Z"
            clipRule="evenodd"
          />
        </g>
      </g>
    </svg>
  ),
});

export default IconLabel;
